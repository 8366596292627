<template>
  <div v-if="campaign" fluid>
    <div class="content-main-container container-distance">

      <!-- title -->
      <div class="title-container">{{ $t('campaign') }}: {{ campaignName }}</div>
      <div class="content-container">

        <!-- name & slug  -->
        <b-row>
          <b-col lg="6">
            <b-form-group :label="$t('campaign_name')">
              <b-form-input v-model="campaign.name"
                            :state="campaign.name === null || campaign.name === '' ? false : null"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group :label="$t('slug')">
              <b-form-input v-model="campaign.slug"
                            :state="campaign.slug === null || campaign.slug === '' ? false : null"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Time Frame  -->
        <b-row>
          <b-col lg="6">
            <date-time-picker :label="$t('campaign_timeframe_from')" v-model="campaign.starts_at"></date-time-picker>
          </b-col>
          <b-col lg="6">
            <date-time-picker :label="$t('campaign_timeframe_to')" v-model="campaign.ends_at"></date-time-picker>
          </b-col>
        </b-row>

        <b-form-group :label="$t('event_day_selector')" class="mt-4">
          <b-dropdown id="dropdown-Checkbox" text="Event Dropdown" ref="dropdown" class="w-100">
            <b-form-group class="allowed-events-container">
              <b-form-input v-model="searchEvents" :placeholder="$t('search')" size="sm" class="mb-2"></b-form-input>
              <b-form-checkbox-group v-model="campaign.events" id="checkbox-group-2" name="flavour-2">
                <b-form-checkbox
                    v-for="ev in events.filter(e => searchEvents ? e.name.toLowerCase().includes(searchEvents.toLowerCase()) : true)"
                    :key="ev.id"
                    :value="ev.id" class="w-100">{{ ev.name }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-dropdown>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox-group v-model="campaign.days" :options="days"></b-form-checkbox-group>
        </b-form-group>

        <div class="my-5" v-if="campaign.rules.length">
          <b-form-group>
            <b-form-checkbox v-model="campaign.highlight_discount">{{ $t('campaign_highlight_discount') }}</b-form-checkbox>
          </b-form-group>

          <div class="row" v-if="campaign.highlight_discount">
            <div class="col-md-4">
              <b-form-group :label="$t('campaign_highlight_discount_border_color')">
                <color-picker v-model="campaign.highlight_style.border_color"></color-picker>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group :label="$t('campaign_highlight_discount_background_color')">
                <color-picker v-model="campaign.highlight_style.background_color"></color-picker>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-2 mt-4">
          <h4 class="my-0">{{ $t('campaign_discount_rules') }}</h4>
          <b-button variant="new" @click="showCreateRuleModal()">{{ $t('new') }}</b-button>
        </div>
        <div class="tableBig mb-4">
          <b-table v-if="campaign.rules.length" :items="campaign.rules"
                   :fields="[{key: 'reference', label: $t('campaign_reference')}, {key: 'discount_type', label: $t('campaign_discount_type')}, {key: 'discount_value', label: $t('campaign_discount_value')}, {key: 'actions', label:  $t('actions')}]">
            <template v-slot:cell(discount_value)="{item}">
              <span v-if="item.discount_type === 'fixed'">{{ item.discount_value | currency }}</span>
              <span v-else>%{{ item.discount_value }}</span>
            </template>
            <template v-slot:cell(actions)="{item}">
              <b-button @click="showRuleModal(item)" class="mr-2">{{ $t('manage') }}</b-button>
              <b-icon @click="showDeleteModal(item)" icon="trash" font-scale="1.5"
                      class="ml-3 text-secondary btnEventDelete cursor-pointer"></b-icon>
            </template>
          </b-table>
        </div>

        <b-form-group>
          <b-form-checkbox v-model="campaign.hide_products">{{ $t('campaign_hide_products') }}</b-form-checkbox>
        </b-form-group>

        <div v-if="campaign.hide_products" class="mt-2">
          <div class="d-flex justify-content-between align-items-center mb-2 mt-4">
            <h5 class="my-0">{{ $t('campaign_when_hide_products') }}</h5>
            <b-button variant="new" class="add-discount-condition-btn" @click="campaign.hiderules.push({id: null, field: null, operation: null, value: null})">
              <b-icon class="mx-2" icon="plus"></b-icon>
            </b-button>
          </div>
          <b-row v-for="(rule, index) in campaign.hiderules" :key="rule.id" class="mb-1 rules-list">
            <b-col lg="3" class="mb-1">
              <b-form-select v-model="rule.field" :options="[{value: 'price', text: $t('campaign_rule_set_product_price')},{value: 'name', text: $t('campaign_rule_set_product_name')}]"></b-form-select>
            </b-col>
            <b-col lg="3" class="mb-1">
              <b-form-select v-model="rule.operation">
                <b-form-select-option v-if="rule.field === 'price'" value="greater">&gt;</b-form-select-option>
                <b-form-select-option v-if="rule.field === 'price'" value="less">&lt;</b-form-select-option>
                <b-form-select-option value="equal">=</b-form-select-option>
                <b-form-select-option v-if="rule.field === 'name'" value="contains">contains</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col lg="4" class="mb-1">
              <b-form-input :type="rule.field === 'price' ? 'number' : 'text'" v-model="rule.value"></b-form-input>
            </b-col>
            <b-col lg="2" class="mb-1 text-center">
              <b-icon font-scale="1.5" class="mt-2 cursor-pointer" icon="trash"  @click="campaign.hiderules.splice(index, 1)"></b-icon>
            </b-col>
          </b-row>
        </div>

        <!-- footer submit -->
        <b-row class="mt-3">
          <b-col class="text-right">
            <b-button @click="saveCampaign()" variant="success" :disabled="loading"><b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon>{{ $t('save') }}</b-button>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal :title="$t('campaign_new_discount_rule')" id="new-rule" size="lg">
      <b-form-group v-if="rule" :label="$t('campaign_reference')">
        <b-form-input v-model="rule.reference"></b-form-input>
      </b-form-group>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="createRule()" variant="success" :disabled="loading || !rule.reference">
          <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon>
          {{ $t('create') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('campaign_edit_rule')" id="rule-details" size="lg">
      <div v-if="rule">
        <b-form-group :label="$t('campaign_reference')">
          <b-form-input v-model="rule.reference"></b-form-input>
        </b-form-group>
        <b-row class="mt-3">
          <b-col md='6'>
            <b-form-group :label="$t('campaign_discount_type')">
              <b-form-select v-model="rule.discount_type" :options="discountTypeOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md='6'>
            <b-form-group :label="$t('campaign_discount_value')">
              <b-form-input type="number" v-model="rule.discount_value"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="d-flex justify-content-between align-items-center mb-2 mt-4">
          <h5 class="my-0">{{ $t('campaign_when_does_this_discount_apply') }}</h5>
          <b-button variant="new" class="add-discount-condition-btn" @click="addDiscountCondition()">
            <b-icon class="mx-2" icon="plus"></b-icon>
          </b-button>
        </div>
        <b-row v-for="(con, index) in rule.sets" :key="con.id" class="mb-1 rules-list">
          <b-col lg="3" class="mb-1">
            <b-form-select v-model="con.field" :options="[{value: 'price', text: $t('campaign_rule_set_product_price')},{value: 'name', text: $t('campaign_rule_set_product_name')}]"></b-form-select>
          </b-col>
          <b-col lg="3" class="mb-1">
            <b-form-select v-model="con.operation">
              <b-form-select-option v-if="con.field === 'price'" value="greater">&gt;</b-form-select-option>
              <b-form-select-option v-if="con.field === 'price'" value="less">&lt;</b-form-select-option>
              <b-form-select-option value="equal">=</b-form-select-option>
              <b-form-select-option v-if="con.field === 'name'" value="contains">contains</b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col lg="4" class="mb-1">
            <b-form-input :type="con.field === 'price' ? 'number' : 'text'" v-model="con.value"></b-form-input>
          </b-col>
          <b-col lg="2" class="mb-1 text-center">
            <b-icon font-scale="1.5" class="mt-2 cursor-pointer" icon="trash"  @click="deleteCondition(index)"></b-icon>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="updateRule()" variant="success" :disabled="loading || !canUpdateRule">
          <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon>
          {{ $t('save') }}
        </b-button>
      </template>
    </b-modal>

    <!-- delete modal -->
    <b-modal :title="$t('delete')" id="delete-rule" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('delete_entry') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button :disabled="loading" @click="deleteRule" variant="danger">
          <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon>
          {{ $t('delete') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import DateTimePicker from "@/components/DateTimePicker";
import {deepClone} from "@/functions";
import moment from 'moment'

export default {
  name: 'CampaignDetails',
  components: {
    DateTimePicker
  },
  data() {
    return {
      loading: false,
      events: [],
      campaign: null,
      campaignName: null,
      rule: null,
      searchEvents: null,
      days: [
        this.$t('day_mon'),
        this.$t('day_tue'),
        this.$t('day_wed'),
        this.$t('day_thu'),
        this.$t('day_fri'),
        this.$t('day_sat'),
        this.$t('day_sun')
      ],
      DiscountTypeValue: null,
      discountTypeOptions: [
        {value: 'fixed', text: 'fixed fee'},
        {value: 'percentage', text: 'percentage fee'},
      ],
    }
  },
  methods: {
    saveCampaign() {
      if (!moment(this.campaign.starts_at).isBefore(this.campaign.ends_at, 'minute')) {
        this.$store.commit('errors', [this.$t('end_date_must_be_greater')])
        return
      }

      this.loading = true;
      if (!this.campaign.hide_products) {
        this.campaign.hiderules = [];
      }

      api.campaign().update(this.campaign.unique_id, this.campaign).then(campaign => {
        if (campaign.days === null) {
          campaign.days = [];
        }
        if (campaign.events === null) {
          campaign.events = [];
        }
        this.loading = false;
        this.$store.commit('success', this.$t('campaign_updated'));
        campaign.hide_products = !!campaign.hiderules.length;
        this.campaign = campaign;
      }).catch(() => {
        this.loading = false;
      })
    },
    addDiscountCondition() {
      this.rule.sets.push({id: null, field: null, operation: null, value: null})
    },
    deleteCondition(index) {
      this.rule.sets.splice(index, 1);
    },
    showDeleteModal(rule) {
      this.rule = rule;
      this.$bvModal.show('delete-rule')
    },
    deleteRule() {
      this.loading = true;
      api.campaign().deleteRule(this.campaign.unique_id, this.rule.id).then(() => {
        this.loading = false;
        this.campaign.rules = deepClone(this.campaign.rules.filter(item => item.id !== this.rule.id));
        this.$bvModal.hide('delete-rule')
      }).catch(() => {
        this.loading = false;
      })
    },
    showRuleModal(rule) {
      this.rule = deepClone(rule);
      this.$bvModal.show('rule-details');
    },
    showCreateRuleModal() {
      this.rule = {
        reference: null,
        discount_type: null,
        discount_value: null,
        sets: []
      }
      this.$bvModal.show('new-rule');
    },
    createRule() {
      this.loading = true;
      api.campaign().createRule(this.campaign.unique_id, this.rule.reference).then(rule => {
        this.$bvModal.hide('new-rule');
        this.campaign.rules.push(deepClone(rule));
        this.showRuleModal(rule);
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    updateRule() {
      this.loading = true;
      api.campaign().updateRule(this.campaign.unique_id, this.rule.id, this.rule).then(rule => {
        for (let i = 0; i < this.campaign.rules.length; i++) {
          if (this.campaign.rules[i].id === rule.id) {
            this.campaign.rules[i] = rule;
            this.$forceUpdate();
            break;
          }
        }
        this.campaign = deepClone(this.campaign);
        this.$bvModal.hide('rule-details');
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
  },
  created() {
    if(this.$store.state.user) {
      if(!this.$can('campaigns_edit')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    document.title = this.$t('nav_campaigns')
    api.event().all(false).then(response => {
      this.events = response;
    })

    api.campaign().find(this.$route.params.id).then(campaign => {
      if(campaign.days === null) {
        campaign.days = [];
      }
      if(campaign.events === null) {
        campaign.events = [];
      }
      if(campaign.highlight_style === null) {
        campaign.highlight_style = {
          border_color: null,
          background_color: null
        };
      }
      campaign.hide_products = !!campaign.hiderules.length;
      this.campaign = campaign;
      this.campaignName = campaign.name;
    })
  },
  computed: {
    canUpdateRule() {
      return this.rule.reference !== null && this.rule.reference.length && this.rule.discount_type &&
        this.rule.discount_value !== null && `${this.rule.discount_value}` !== '' &&
        !this.rule.sets.some(s => (!s.field || !s.operation || s.value === null || `${s.value}` === ''));
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('campaigns_edit')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
  }
}
</script>

<style scoped>
.allowed-events-container {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 250px;
}

.DeleteBtn {
    background-color: transparent;
}

.DeleteBtn svg {
    fill: #DC3545;
    margin-left: 1rem
}

.add-discount-condition-btn {
    padding: .5rem
}

.add-discount-condition-btn svg {
    width: 1.6rem;
    height: 1.6rem;
}

@media (max-width: 992px) {
    .rules-list {
        background-color: #fafafa !important;
        border: 1px solid #f0efef;
        padding-top: .5rem;
        width: 90%;
        margin: .5rem auto;
        border-radius: .5rem;
    }
}
</style>
