export default class ScanOperationApi {

    constructor(axios) {
        this.axios = axios
    }

    get(show_archived) {
        return this.axios.get('/scan-operations?show_archived=' + show_archived)
    }

    find(id) {
        return this.axios.get('/scan-operation/' + id)
    }

    getAvailableEvents(){
        return this.axios.get('/scan-operation/available-events');
    }

    create(scan_operation) {
        return this.axios.post('/scan-operation', scan_operation)
    }

    save(scan_operation) {
        return this.axios.post('/scan-operation/' + scan_operation.id, scan_operation)
    }

}
