<template>
  <div>
    <b-row>
      <b-col v-if="$can('extension_mailchimp')" lg="3" sm="6" cols="12">
        <router-link :to="{name: 'MailchimpSettings'}"  class="info-card center text-center card">{{ $t('extension_mailchimp') }}</router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import api from "@/api";
import {mapState} from "vuex";

export default {
  data() {
    return {
      
    }
  },
  methods: {
    
  },
  created() {
    document.title = this.$t('nav_extensions')
    if (this.$store.state.user) {
      if (!this.$can('extensions_overview_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {
    '$store.state.user': {
      handler() {
        if (!this.$can('extensions_overview_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
  }
}
</script>

<style scoped>

</style>