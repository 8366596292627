<template>
  <b-container>

    <div id="circle-modal" v-if="show_next_modal">
      <b-row>
        <b-icon icon="check-circle-fill" font-scale="6" variant="success"></b-icon>
      </b-row>
    </div>

    <b-row v-if="ticket && customQuestions">
      <b-col class="content-block cart-container">
        <h1>{{ $t('tickets_fill_title', {todo, done}) }}</h1>
        <customer-form ref="customer-form" v-model="ticket.customer" level="ticket"
                       :fields="customerFields" :is-claim="true" :order-customer="ticket.order.customer"></customer-form>
        <h2 v-if="questions.length">{{ $t('customer_form_custom_questions') }}</h2>
        <customer-information-form :disabled="sending" ref="customer-information-form" v-model="ticket.answers"
                                   :questions="questions"></customer-information-form>
        <b-row>
          <b-col class="text-right">
            <b-form-group>
              <b-button v-if="!sending" size="lg" @click="next()" variant="success">
                {{ $t('assign_tickets_next') }}
              </b-button>
              <b-spinner v-else></b-spinner>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4" class=" ticket-list ">
        <div class="content-sidebar">
          <h1>{{ $t('tickets_fill_completed') }}</h1>
          <b-row v-for="(t, i) in tickets" :key="`sidebarticket` + t.id">
            <b-col>
              <b-button class="assign-tickets-button" variant="link" @click="loadTicketForm(i)">
                <b-icon v-if="i === ticketIndex" icon="chevron-right"></b-icon>
                <b-icon v-else-if="(t.claimed_at)" icon="check-circle-fill"></b-icon>
                <b-icon v-else icon="circle-fill" variant="green"></b-icon>
                {{ t.order_product.product.name }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="text-center content-block">
        <h1>{{ $t('order_loading') }}</h1>
        <b-spinner style="width: 10em; height: 10em;"></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'
import CustomerForm from "@/components/CustomerForm";
import CustomerInformationForm from "@/components/CustomerInformationForm";

export default {
  components: {
    CustomerForm,
    CustomerInformationForm
  },
  data() {
    return {
      errors: [],
      tickets: null,
      ticket: null,
      assignSelf: true,
      ticketIndex: 0,
      customQuestions: [],
      answers: {},
      sending: false,
      customerFields: [],
      show_next_modal: false,
      order_number: null,
      order_secret: null
    }
  },
  watch: {
    show_next_modal(newVal){
      if(newVal){
        setTimeout(function(){
          this.show_next_modal = false
        }.bind(this), 1200)
      }
    }
  },
  computed: {
    isValid() {
      return this.errors.length === 0
    },
    todo() {
      if (!this.tickets) {
        return 0
      }

      return this.ticketIndex + 1
    },
    done() {
      if (!this.tickets) {
        return 0
      }
      return this.tickets.length
    },
    questions() {
      if(!this.ticket){
        return [];
      }
      return this.customQuestions.filter(question => {
        if(question.where_to_show === 'products') {
          return question.products.some(product => {
            return product.product_id === this.ticket.order_product.product_id;
          })
        }
        return false;
      });
    }
  },
  methods: {
    loadTicketForm(index) {
      this.ticketIndex = index
      this.ticket = this.tickets[this.ticketIndex]

      if (this.$refs['customer-information-form']) {
        this.$refs['customer-information-form'].reset()
      }
      if (this.$refs['customer-form']) {
        this.$refs['customer-form'].reset()
      }

      if (!this.ticket.customer) {
        this.ticket.customer = {}
      }
      api.customerInformation().getAnswersByTicket(this.ticket.id, this.ticket.secret).then((answers) => {
        answers.forEach(answer => {
          this.ticket.answers[answer.customer_information_id] = answer.answer
        })
        if (answers.length === 0) {
          this.ticket.answers = {}
        }
      })
    },
    next() {
      let customerValid = this.$refs['customer-form'].isValid()
      let customerInformationValid = this.$refs['customer-information-form'].isValid()
      if (!customerValid || !customerInformationValid) {
        return
      }

      this.sending = true

      api.ticket().updateCustomer(this.ticket.id, this.ticket.secret, this.ticket.customer).then(() => {
        if (this.customQuestions.length > 0) {
          api.customerInformation().submit(this.ticket.id, this.ticket.secret, this.ticket.answers).then(() => {})
        }
        api.ticket().claim(this.ticket.id, this.ticket.secret).then(() => {
          this.sending = false
          if (this.ticketIndex + 1 < this.tickets.length) {
            if(document.body.contains(document.getElementById('company-header'))){
              document.getElementById("company-header").scrollIntoView();
            }
            this.show_next_modal = true
            this.tickets[this.ticketIndex].claimed_at = true
            this.loadTicketForm(this.ticketIndex + 1)
          } else {
            this.$router.push({
              name: 'OrderThankYou',
              query: {
                order_number: this.order_number,
                order_secret: this.order_secret
              }
            })
          }
        })
      })
    }
  },
  created() {
    document.title = this.$t('order_view_assign_title');
    if(!localStorage.getItem('ft_lang')) {
      api.pos().getByOrderIdAndSecret(this.$route.params.id, this.$route.params.secret).then(pos => {
        if (pos) {
          if (pos.language) {
            this.$store.commit('setLanguage', pos.language)
          }
        }
      })
    }
    api.order().getTickets(this.$route.params.id, this.$route.params.secret).then(tickets => {
      this.tickets = tickets.filter((t) => {
        if(t.order.order_number && !this.order_number){
          this.order_number = t.order.order_number
        }
        if(t.order.secret && !this.order_secret){
          this.order_secret = t.order.secret
        }
        return !t.invited_at
      }).map(t => Object.assign(t, {answers: {}}))
      if (this.tickets.length === 0) {
        this.$router.push({
          name: 'OrderThankYou',
          query: {
            order_number: this.order_number,
            order_secret: this.order_secret
          }
        })
        return
      }
      this.loadTicketForm(this.ticketIndex)
    })

    api.customerInformation().getByOrder(this.$route.params.id, this.$route.params.secret).then((customQuestions) => {
      this.customQuestions = customQuestions
    })

    api.customerInformation().getFieldsByOrder(this.$route.params.id, this.$route.params.secret).then((customerFields) => {
      this.customerFields = customerFields
    })

  }
}
</script>
<style scoped>
h1{
  font-size:1.6rem;
  font-weight:bold
}
.btn.assign-tickets-button{
  margin-bottom:0.2em;
}
.ticket-list .btn {
  font-size: 1.4em;
}
#circle-modal .bi-check-circle-fill{
  font-size:250px !important
}
#circle-modal{
  position:fixed;
  top:40%;
  left:50%;
  z-index:1000;
  margin-top:-100px;
  margin-left:-100px;
  background-color:#FFF;
  border-radius:1000px;
  display:flex;
  flex-direction: column;
  justify-content: center;
}
#circle-modal div.row{
  justify-content: center;
}
</style>
