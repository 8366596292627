<template>

  <b-container fluid>
    <b-row>
      <b-col align-v="center" class="vh-100">
        <router-view></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'App',
  methods: {
    logout() {
      this.$store.commit('logout')
    },
    dismissErrors() {
      this.$store.commit('errors', [])
    },
    dismissSuccess() {
      this.$store.commit('success', false)
    },
    ...mapActions(['loadUser', 'loadBoxOfficeUser', 'loadScannerTerminalUser'])
  },
  computed: {
    ...mapState(['success', 'errors'])
  },
  created() {
    if(this.$route.name === 'BoxOfficeLogin') {
      this.loadBoxOfficeUser().then((user) => {
        if(user) {
          this.$router.push({
            name: 'BoxOfficeEvents'
          })
        }
      })
    } else if(this.$route.name === 'ScannerTerminalLogin') {
      this.loadScannerTerminalUser().then((user) => {
        if(user) {
          this.$router.push({
            name: 'ScannerTerminalScan'
          })
        }
      })
    } else {
      this.loadUser().then((user) => {
        if (user && this.$route.name !== 'UserResetPassword') {
          this.$router.push({
            name: 'Dashboard'
          })
        }
      })
    }
  }
}
</script>

