import { render, staticRenderFns } from "./Static.vue?vue&type=template&id=18711cb9&scoped=true"
import script from "./Static.vue?vue&type=script&lang=js"
export * from "./Static.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18711cb9",
  null
  
)

export default component.exports