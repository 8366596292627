<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="content-main-container container-distance">
          <div class="title-container">{{ $t("scan_operation_session_configure_products") }}</div>
          <div class="content-container">
              <div v-for="(product, index) in products" :key="index">
                <b-row>
                  <b-col cols="4"></b-col>
                  <b-col cols="8">
                    <label>{{$t('sessions')}}</label>
                    <b-row>
                      <b-col cols="11">
                        <v-select v-model="product.session" :options="product.sessionsList" label="name"></v-select>
                      </b-col>
                      <b-col cols="1">
                        <b-button :disabled="loading" @click="addSession(index)" variant="primary" class="float-right"><b-icon icon="plus"></b-icon></b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col cols="4">
                    <h4>{{product.product_group.type !== 'default' ? product.product_group.name + ' - ' : ''}}{{ product.name }}</h4>
                  </b-col>
                  <b-col cols="8">
                    <b-table :items="product.sessions" :fields="['name', 'actions']" thead-class="d-none" borderless bordered>
                      <template v-slot:cell(name)="{item}" >
                        {{ item.name }} ( {{ formatSessionDate(item.starts_at, item.ends_at) }})
                      </template>
                      <template v-slot:cell(actions)="{item}" >
                        <div class="d-flex justify-content-end" >
                          <b-btn :disabled="loading" @click="handleDeleteClick(index, item)" pill variant="outline-secondary"><b-icon icon="x"></b-icon></b-btn>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <hr>
              </div>

              <b-row>
                <b-col cols="12">
                  <b-button @click="$router.push({name: 'ScanOperationScannersConfigure', params: {id: $route.params.id}})" class="mb-3 ml-3 btn-new float-right">{{ $t('next') }}</b-button>
                  <b-button @click="$router.push({name: 'ScanOperationManage', params: {id: $route.params.id}})" class="mb-3 btn-new float-right">{{ $t('previous') }}</b-button>
                </b-col>
              </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal :title="$t('scan_operation_session_modal_delete')" id="delete-session" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('delete_entry') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button :disabled="loading" @click="removeSession()" variant="danger">{{ $t('delete') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import moment from 'moment';

export default {
  data() {
    return {
      scanOperation: null,
      products: [],
      sessions: [],
      loading: false,

      selectedIndex: null,
      selectedItem: null,
    };
  },
  methods: {
    addSession(index) {
      if(!this.products[index].session) {
        return;
      }
      this.loading = true;
      api.scanOperationSession().saveProduct(this.products[index].session.id, this.products[index].id).then(() => {
        this.products[index].sessions.push(this.products[index].session);
        this.products[index].sessionsList = this.products[index].sessionsList.filter(s => s.id !== this.products[index].session.id)
        this.products[index].session = null;
        this.loading = false;
        this.$store.commit('success', 'scan_operation_session_product_saved')
      }).catch(() => {
        this.loading = false;
      })
    },
    handleDeleteClick(index, item) {
      this.selectedIndex = index;
      this.selectedItem = item;
      this.$bvModal.show('delete-session')
    },
    removeSession() {
      const index = this.selectedIndex;
      const item = this.selectedItem;

      this.loading = true;
      api.scanOperationSession().removeProduct(item.id, this.products[index].id).then(() => {
        this.$bvModal.hide('delete-session')
        this.loading = false;
        this.products[index].sessions = this.products[index].sessions.filter(session => session.id !== item.id)
        this.products[index].sessionsList.push(this.selectedItem);
        this.$store.commit('success', 'scan_operation_session_product_deleted')
        this.selectedIndex = null;
        this.selectedItem = null;
      }).catch(() => {
        this.loading = false;
      })
    },
    formatSessionDate(start, end) {
      return moment(start).format('DD-MM-YYYY HH:mm') + ' - ' + moment(end).format('DD-MM-YYYY HH:mm');
    }
  },
  created() {
    document.title = this.$t('backend_title_scan_operation_product_configuration')
    if(this.$store.state.user) {
      if (!this.$can('scan_operation_edit') && !this.$can('scan_operation_create')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.scanOperation().find(this.$route.params.id).then((response)=>{
      this.scanOperation = response;

      api.product().get(response.event_id).then(products => {
        api.scanOperationSession().getSessionsProducts(this.$route.params.id).then((response) => {
          this.sessions = response;
          this.products = products.map(product => {
            product.session = null;
            product.sessions = response.filter(session => {
              return session.products.filter(prod => prod.id === product.id).length
            });
            product.sessionsList = response.filter(session => {
              return !product.sessions.find(s => s.id === session.id)
            });
            return product;
          });
        })
      })
    });
  },
  watch: {
    '$store.state.user': {
      handler() {
        if (!this.$can('scan_operation_edit') && !this.$can('scan_operation_create')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
};
</script>
