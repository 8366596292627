<template>
  <div class="signup">
    <b-row>
      <b-col v-if="loading" cols="12" lg="6"  order="1" order-lg="1">
        <div class="loading-logo text-center d-block">
          <b-img :src="require('./../assets/fairtix.gif')"/>

          <ul class="list-unstyled d-block mt-2">
            <li v-for="(ll, index) in loadingList" :key="index">{{ ll }}</li>
          </ul>
        </div>
      </b-col>
      <!-- signup form -->
      <b-col v-else cols="12" lg="6" class="signup-form" order="1" order-lg="1">
        <div>
          <div class="step" v-if="step === 1">
            <h1>{{ $t('signup_create_account') }}</h1>
            <p class="signup-details mb-4">{{ $t('signup_enter_details') }}</p>
            <b-row>
              <b-col sm="12" md="6">
                <b-form-input
                    class="mr-0 mr-md-4"
                    :placeholder="$t('customer_first_name')"
                    type="text"
                    v-model="first_name"
                    :state="state.first_name"
                    @input="state.first_name = null"
                    aria-describedby="input-f-name-feedback"></b-form-input>
                <b-form-invalid-feedback v-if="state.first_name === false" id="input-f-name-feedback">{{$t('field_is_required') }}</b-form-invalid-feedback>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-input
                    :placeholder="$t('customer_last_name')"
                    type="text"
                    v-model="last_name"
                    :state="state.last_name"
                    @input="state.last_name = null"
                    aria-describedby="input-l-name-feedback"></b-form-input>
                <b-form-invalid-feedback v-if="state.last_name === false" id="input-l-name-feedback">{{$t('field_is_required') }}</b-form-invalid-feedback>
              </b-col>
            </b-row>
            <div class="d-block d-md-flex">

            </div>
            <b-form-input
                :placeholder="$t('login_email')"
                type="email"
                v-model="email"
                :state="state.email"
                @input="validateEmail($event)"
                aria-describedby="input-f-name-feedback"></b-form-input>
            <b-form-invalid-feedback  v-if="state.email === false" id="input-email-feedback">{{ $t('field_is_required') }}</b-form-invalid-feedback>
          </div>
          <div class="step" v-else-if="step === 2">

            <h2>{{ $t('signup_step_2_title') }}</h2>
            <p class="signup-details mb-4">{{ $t('signup_step_2_enter_details') }}</p>

            <b-form-input v-model="name" :state="state.name" @input="state.name = null" :placeholder="$t('customer_company_name')" type="text"></b-form-input>
            <b-form-invalid-feedback v-if="state.name === false" id="input-name-feedback">{{ $t('field_is_required') }}</b-form-invalid-feedback>

            <b-select
                v-model="country"
                :options="countries"
                text-field="name"
                value-field="code"
                :state="state.country"
                @change="state.country = null"
                aria-describedby="input-country-feedback"></b-select>
            <b-form-invalid-feedback v-if="state.country === false" id="input-country-feedback">{{ $t('field_is_required') }}</b-form-invalid-feedback>

            <b-form-input v-model="kvk" @input="state.kvk = null" :state="state.kvk" :placeholder="$t('signup_chamber_of_commerce')" type="text"></b-form-input>
            <b-form-invalid-feedback  v-if="state.kvk === false" id="input-chamber-of-commerce-feedback">{{ $t('field_is_required') }}</b-form-invalid-feedback>

            <b-form-input v-model="phone" :placeholder="$t('company_phone')" type="tel"></b-form-input>
            <b-form-input
                v-model="payout_iban"
                :placeholder="$t('signup_iban')"
                type="text"
                :state="state.payout_iban"
                @input="state.payout_iban = null"
                aria-describedby="input-iban-feedback"></b-form-input>
            <b-form-invalid-feedback v-if="state.payout_iban === false" id="input-iban-feedback">{{ $t('field_is_required') }}</b-form-invalid-feedback>

            <b-form-input
                v-model="ibanHolder"
                :placeholder="$t('signup_iban_holder_name')"
                type="text"
                :state="state.ibanHolder"
                @input="state.ibanHolder = null"
                aria-describedby="input-iban-holder-feedback"></b-form-input>
            <b-form-invalid-feedback v-if="state.ibanHolder === false" id="input-iban-holder-feedback">{{$t('field_is_required') }}</b-form-invalid-feedback>
            <hr>

            <b-form-input
                v-model="password"
                :placeholder="$t('login_password')"
                type="password"
                :state="state.password"
                @input="state.password = null; validatePassword($event)"
                aria-describedby="input-password-feedback"></b-form-input>

            <div v-if="state.passwordValidation.length">
              <span class="d-block text-danger small" v-for="er in state.passwordValidation" :key="er">{{ $t(er) }}</span>
            </div>
            <b-form-invalid-feedback v-if="state.passwordValidation === false"  aria-invalid="true">{{$t('signup_password_strength') }}</b-form-invalid-feedback>

            <b-form-input
                v-model="passwordConfirmation"
                :placeholder="$t('signup_confirm_password')"
                type="password"
                @input="state.passwordConfirmation = null"
                :state="state.passwordConfirmation"
                aria-describedby="input-password-c-feedback"></b-form-input>
            <b-form-invalid-feedback v-if="state.passwordConfirmation === false" id="input-password-c-feedback">
              {{ $t(password !== passwordConfirmation ? 'password_mismatch' : 'field_is_required') }}
            </b-form-invalid-feedback>
            <b-form-checkbox
                class="mt-2"
                id="terms"
                v-model="terms"
                name="terms"
            >
              <span v-html="$t('signup_terms')" class="terms-conditions-text"></span>
            </b-form-checkbox>
          </div>
          <div class="signup-footer mt-2">
            <b-form-group>
              <b-button id="back-button" class="w-20" v-if="!loading && step===2" @click="step--" :disabled="loading">
                <b-icon icon="arrow-left" class="mr-1"/> {{ $t('back') }}
              </b-button>
              <b-button id="submit-button" class="float-right" :class="{'w-100': step === 1, 'w-75': step === 2, 'mt-4': step === 1}"  @click="next" :disabled="loading || (step === 2 && !terms)">
                <b-icon v-if="loading" icon="three-dots" animation="cylon" class="mr-2"></b-icon>
                {{ $t(step === 1 ? 'next' : 'submit') }}
              </b-button>
            </b-form-group>
            <p class="text-center mt-4">{{ $t('signup_already_user') }}
              <router-link to="/login">{{ $t('login') }}</router-link>
            </p>
          </div>
        </div>
      </b-col>

      <!-- signup img -->
      <b-col cols="12" lg="6" class="signup-img" order="2" order-lg="2">
        <b-img class="logo" :src="require('../assets/signup-img.jpg')"></b-img>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import api from "@/api";
import {validateEmail, validatePassword} from "@/functions";
import {mapActions, mapMutations} from "vuex";

export default {
  name: 'Signup',
  data() {
    return {
      state: {
        first_name: null,
        last_name: null,
        email: null,
        country: null,
        payout_iban: null,
        ibanHolder: null,
        password: null,
        passwordValidation: [],
        passwordConfirmation: null,
        kvk: null
      },
      email: '',
      first_name: '',
      last_name: '',
      company: '',
      terms: false,
      loading: false,
      error: false,
      step: 1,
      country: null,
      countries: [],
      payout_iban: null,
      ibanHolder: null,
      password: null,
      passwordConfirmation: null,
      name: null,
      kvk: null,
      phone: null,

      loadingList: []
    }
  },
  created() {
    api.company().countries().then(countries => {
      this.countries = [{code: null, name: this.$t('company_country'), disabled: true}].concat(countries)
    });
  },
  methods: {
    next() {
      this.state = {
        first_name: null,
        last_name: null,
        email: null,
        country: null,
        payout_iban: null,
        ibanHolder: null,
        password: null,
        passwordValidation: [],
        passwordConfirmation: null,
        name: null
      }
      let valid = true;
      if (this.step === 1) {
        if (!this.first_name) {
          valid = false;
          this.state.first_name = false;
        }
        if (!this.last_name) {
          valid = false;
          this.state.last_name = false;
        }
        if (!this.email) {
          valid = false;
          this.state.email = false;
        }
        if (!validateEmail(this.email)) {
          valid = false;
          this.state.email = false;
        }
        if (!valid) {
          return;
        }

        this.step++;
        return;
      }

      if (this.step === 2) {
        if (!this.name) {
          valid = false;
          this.state.name = false;
        }
        if (!this.kvk) {
          valid = false;
          this.state.kvk = false;
        }
        if (!this.country) {
          valid = false;
          this.state.country = false;
        }
        if (!this.payout_iban) {
          valid = false;
          this.state.payout_iban = false;
        }
        if (!this.ibanHolder) {
          valid = false;
          this.state.ibanHolder = false;
        }
        if (!this.password) {
          valid = false;
          this.state.password = false;
        }
        if (this.state.passwordValidation.length) {
          valid = false;
          this.state.password = false;
        }
        if (!this.passwordConfirmation) {
          valid = false;
          this.state.passwordConfirmation = false;
        }
        if (this.password && this.passwordConfirmation && this.password !== this.passwordConfirmation) {
          valid = false;
          this.state.password = false;
          this.state.passwordConfirmation = false;
        }

        if (!valid) {
          return;
        }
      }

      this.loading = true;

      let finished = false;

      this.loadingList.push(this.$t('signup_loading_creating_account'));
      const t1 = setTimeout(() => {
        this.loadingList.push(this.$t('signup_loading_configuring_the_layout'));
      }, 2000);
      const t2 = setTimeout(() => {
        this.loadingList.push(this.$t('signup_loading_almost_done'));
      }, 4000);

      const t3 = setTimeout(() => {
        this.loadingList.push(this.$t('signup_loading_go_to_dashboard'));
        finished = true;
      }, 5500)

      api.company().signup({
        name: this.name,
        phone: this.phone,
        country: this.country,
        payout_iban: this.payout_iban,
        iban_holder: this.ibanHolder,
        kvk: this.kvk,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      }).then((token) => {
        if(finished) {
          this.$store.commit('login', token)
          this.loadUser()
          this.$router.push({name: 'Events'})
        }
        else {
          const int = setInterval(() => {
            if(finished) {
              clearInterval(int);
              this.$store.commit('login', token)
              this.loadUser()
              this.$router.push({name: 'Events'})
            }
          }, 100)
        }
      }).catch(() => {
        this.loading = false;
        finished = false;
        this.loadingList = [];
        clearTimeout(t1);
        clearTimeout(t2);
        clearTimeout(t3);
      });
    },
    validateEmail(email) {
      this.state.email = null
      if(!validateEmail(email)) {
        this.state.email = false
      }
    },
    validatePassword(password) {
      this.state.passwordValidation = validatePassword(password);
    },
    ...mapActions(['loadUser']),
    ...mapMutations(['setUser', 'setLanguage'])
  }
}
</script>
<style scoped lang="scss">

$main-color: #193E5B; 
$placeholder-color: #b1c6ca;

h1, h2{
  color: $main-color;
  font-weight:bold
}

p.signup-details{
  color: $main-color
}

#back-button{
  padding:15px 0px
}

#submit-button{
  background-color: $main-color;
  color:#FFF;
  padding:15px 0px
}

.terms-conditions-text{
  font-size:10pt
}

.signup, .signup > .row {
  min-height: 100vh;
  overflow: hidden;
}

.signup-form {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  & > div {
    width: 80%;
  }
}

.step {
  width: 80%;

  input, select {
    margin-top: 1rem;
    display: block;
    border: none;
    border-radius: .3rem;
    box-shadow: 2px 3px 5px #eee, -1px -2px 4px #eee;
  }

  input::placeholder, select::placeholder{
    color:$placeholder-color
  }

  .custom-control {
    margin-bottom: 2rem;
  }

  button {
    padding: .7rem;
    color: #fff;
    background-color: #1E4167;
    border-radius: .4rem;
  }
}

.signup-footer {
  width: 80%;
  display: block;
}

.signup-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F7FAFF;

  & > img {
    width: 70%;
  }
}

// responsive for laptop screen
@media (max-width: 1550px) {
  .signup-form {
    padding: 10px;

    p {
      font-size: .9rem;
    }
  }
  .step {
    input {
      border-radius: .2rem;
      font-size: .9rem;
      box-shadow: 1px 2px 4px #eee, -1px -1px 3px #eee;
    }

    button {
      padding: .6rem;
      border-radius: .3rem;
      font-size: .9rem;
    }

    .custom-control {
      font-size: .9rem;
    }
  }
}

// responsive for ipad screen
@media (max-width: 992px) {
  .step{
    width:100%
  }
  .signup-footer{
    width:100%
  }
  .signup-img {
    min-height: 30vh;

    & > img {
      width: 40%;
    }
  }
}

// responsive for phone screen
@media (max-width: 576px) {
  .signup-form {
    padding: 2rem 1.5rem;

    & > div {
      width: 100%;
    }
  }
  .signup-img {
    & > img {
      width: 60%;
    }
  }
}

.loading-logo {
  margin-top: 200px;
}

.loading-logo img {
  height: 200px;
}

.w-20 {
  width: 20%;
}
</style>
