<template>
  <div v-if="template" fluid> 
    <div class="content-main-container container-distance">
      <div class="title-container">{{ $t('template_title', template) }}</div>
      <div class="content-container">
        <b-row>
          <b-col class="mb-3">
            <b-button variant="warning" @click="generatePreview()" v-if="!generating">{{ $t('generate_preview') }}</b-button>
            <b-spinner v-else></b-spinner>
          </b-col>
          <b-col class="mb-3 text-right">
            
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('ticket_template_name')">
              <b-form-input v-model="template.name"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <h4>{{ $t('template_background') }}</h4>
            <p>{{ $t('template_background_hint') }}</p>
            <p>{{ $t('template_background_jpg_hint') }}</p>
            <b-row>
              <b-col>
                <b-form-group :label="$t('ticket_template_background')">
                  <input type="file" id="image" accept="image/*" ref="image" v-on:change="onImageChange()"/>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-img fluid v-if="template.background" :src="template.background"></b-img>
              </b-col>
            </b-row>
            <h4 class="mt-4">{{ $t('template_qr') }}</h4>
            <p>{{ $t('template_position_hints') }}</p>
            <b-row>
              <b-col>
                <b-form-group :label="$t('top')" label-cols="3">
                  <b-form-input type="number" v-model="template.qr.top"></b-form-input>
                </b-form-group>
                <b-form-group :label="$t('width')" label-cols="3">
                  <b-form-input type="number" v-model="template.qr.width"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('left')" label-cols="3">
                  <b-form-input type="number" v-model="template.qr.left"></b-form-input>
                </b-form-group>
                <b-form-group :label="$t('height')" label-cols="3">
                  <b-form-input type="number" v-model="template.qr.height"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col>

            <h4>{{ $t('template_texts') }}</h4>
            <p>{{ $t('template_texts_hint') }}</p>
            <b-form-group>
              <b-button variant="warning" @click="addTextBlock()">{{ $t('template_add_text') }}</b-button>
            </b-form-group>

            <hr>
            <div v-for="(text, i) in template.texts" :key="`text` + i">
              <b-row>
                <b-col cols="5">
                  <b-form-group :label="$t('top')" label-cols="3">
                    <b-form-input type="number" v-model="text.top"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="5">
                  <b-form-group :label="$t('left')" label-cols="3">
                    <b-form-input type="number" v-model="text.left"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="2" class="text-right">
                  <b-button variant="danger" @click="deleteText(i)" size="sm">{{ $t('delete') }}</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group :label="$t('template_text_block')">
                    <b-textarea v-model="text.content"></b-textarea>
                  </b-form-group>
                  <b-button size="sm" class="mr-1 mb-1" v-for="placeholder in placeholders" :key="placeholder"
                            @click="text.content += '[' + placeholder + ']'">
                    {{ '[' + placeholder + ']' }}
                  </b-button>
                  <hr>
                </b-col>
              </b-row>
            </div>

          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col class="text-right">
            <b-button @click="saveTemplate()" variant="success">{{ $t('template_save') }}</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
import axios from "axios";

export default {
  name: "DefaultCompanyTicketTemplate",
  data() {
    return {
      template: {
        name: '',
        background: '',
        qr: {
          top: 0,
          width: 0,
          left: 0,
          height: 0
        },
        texts: []
      },
      generating: false,
      placeholders: [
        'event_name',
        'event_date',
        'customer_first_name',
        'customer_last_name',
        'product_name',
        'order_number',
        'ticket_secret',
        'product_group_name',
        'timeslot',
        'event_start_time',
        'event_end_time'
      ]
    }
  },
  methods: {
    loadTemplate(){
      api.defaultValues().getByKey('default-ticket-template').then(template => {
        let tmpTemplate = template.value
        if(!tmpTemplate){
          tmpTemplate =  {
            name: '',
            background: '',
            qr: {
              top: 0,
              width: 0,
              left: 0,
              height: 0
            },
            texts: []
          }
        }
        this.template = tmpTemplate
      });
    },
    addTextBlock() {
      this.template.texts.push({content: ''})
    },
    generatePreview() {
      this.generating = true
      api.ticket().previewTemplate(this.template, {
        ticket: {
          id: new Date().getTime(),
          secret: 'PREVIEW',
          customer: {
            first_name: 'Jan',
            last_name: 'Janssen',
            company_name: 'Janssen BV'
          },
          order_product: {
            product: {
              name: 'VIP Ticket'
            }
          }
        },
        background: this.template.background,
        qr: Object.assign(this.template.qr, {
          data: {
            id: 12345667,
            secret: 'PREVIEW',
          }
        }),
        texts: this.template.texts
      }).then(url => {
        window.open(url, '_blank')
      }).catch(() => {
        this.$store.commit('errors', ['unable_to_generate_template'])
      }).finally(() => {
        this.generating = false
      })
    },
    onImageChange() {
      api.ticket().uploadDefaultTicketLayout(this.$refs['image'].files[0].name).then((response) => {
        // Upload the file
        axios.create().put(response.upload_url, this.$refs['image'].files[0], {
          headers: {
            'Content-Type': this.$refs['image'].files[0].type
          }
        }).catch((e) => {
          // Upload failed...
          console.error(e)
        }).then(() => {
          // upload done, create the video in the database
          this.template.background = response.target_url
        })
      })
    },
    deleteText(index) {
      this.template.texts.splice(index, 1)
    },
    saveTemplate() {
      api.defaultValues().save('default-ticket-template', this.template).then(() => {
        this.$store.commit('success', 'ticket_template_updated');
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  created() {
    document.title = this.$t('backend_title_default_ticket_template')    
    if(this.$store.state.user) {
      if(!this.$can('master_data_default_values_ticket_template_edit')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }

    this.loadTemplate();
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('master_data_default_values_ticket_template_edit')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>
