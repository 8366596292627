export default class reportApi {

    constructor(axios) {
        this.axios = axios
    }

    getFilters() {
        return this.axios.get('/report/filters')
    }

    getData(event_id, date_start, date_end, filter_box_office, timezone) {
        return this.axios.get('/report/load-data', {params: {event_id: event_id, date_start: date_start, date_end: date_end, filter_box_office: filter_box_office, timezone: timezone}})
    }

    getCitiesData(event_id, date_start, date_end, filter_box_office, timezone) {
        return this.axios.get('/report/load-cities-data', {params: {event_id: event_id, date_start: date_start, date_end: date_end, filter_box_office: filter_box_office, timezone: timezone}})
    }

    getTicketsScansData(period, event_id, date_start, date_end, filter_box_office, timezone) {
        return this.axios.get('/report/scans/' +period, {params: {event_id: event_id, date_start: date_start, date_end: date_end, filter_box_office: filter_box_office, timezone: timezone}})
    }
    
    getCustomAnswersData(event_id, date_start, date_end){
      return this.axios.get('/report/custom-question-answers', {params: {event_id: event_id, date_start: date_start, date_end: date_end}});
    }

    getStockReport() {
        return this.axios.get('/report/stock')
    }

    getSalesPerTickets(event_id, data) {
        return this.axios.post('/report/' + event_id  + '/sales-by-tickettype', data);
    }

    getSalesPerTicketsOrders(event_id, data) {
        return this.axios.post('/report/' + event_id  + '/sales-by-tickettype-orders', data);
    }

    getSalesPerTicketsRevenue(event_id, data) {
        return this.axios.post('/report/' + event_id  + '/sales-by-tickettype-revenue', data);
    }

    getOrdersGroupedByTickets(event_id, data) {
        return this.axios.post('/report/' + event_id  + '/order-grouped-by-tickets', data);
    }

}
