<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <div class="table-container no-margin">
          <b-row class="default-container-layout FirstDiv">
            <b-col cols="12" md="3">{{ $t('box_office_order_history_title') }}</b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    
    <div class="table-container no-margin">
      <b-row v-for="order in orders" :key="'order-' + order.id" class="default-container-layout order-history-row mt-2" :class="order.is_refunded ? 'refunded' : ''">
        <b-col class="vertical-align">{{ order.created_at | date_time }}</b-col>
        <b-col class="vertical-align">{{ order.order_products.length }} {{ $t('box_office_order_history_products') }}</b-col>
        <b-col class="vertical-align">{{ order.total | currency }}</b-col>
        <b-col class="vertical-align">{{ (order.customer) ? order.customer.first_name + ' ' + order.customer.last_name : $t('unkown') }}</b-col>
        <b-col>
          <b-button v-if="!order.is_refunded" class="manage-btn" variant="row-edit" @click="openOrderDetail(order)">{{ $t('manage') }}</b-button>
          <span v-if="order.is_refunded">{{ $t('order_status_refunded') }}</span>
        </b-col>
      </b-row>
    </div>

    <pagination v-if="page_data" :limit="3" :data="page_data" @pagination-change-page="getOrders"></pagination>

    <b-modal size="lg" id="order-detail-modal" >
      <div v-if="order">
        <b-row>
          <b-col>
            <b-form-group :label="$t('box_office_order_history_order_number')">
              {{order.order_number}}
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('box_office_order_history_order_placed')">
              {{order.created_at | date_time}}
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('box_office_order_history_payment_method')">
              {{ order.pos_payment_method.payment_method.name }}
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('box_office_order_history_customer')">
              {{ (order.customer) ? order.customer.first_name + ' ' + order.customer.last_name : $t('unkown') }}
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-2 mt-4">
          <b-col><strong>{{ $t('box_office_order_history_products') }}</strong></b-col>
        </b-row>

        <b-row v-for="orderProduct in order.order_products" :key="'order-product-' + orderProduct.id" class="mb-2" style="max-width:65%">
          <b-col>{{ orderProduct.product.product_group.type !== 'default' ? orderProduct.product.product_group.name + ' - ' : '' }}{{ orderProduct.product.name }}</b-col>
          <b-col style="text-align:right">{{ orderProduct.price | currency }}</b-col>
        </b-row>

        <b-row style="max-width:65%">
          <b-col><strong>{{ $t('box_office_order_history_total_price') }}</strong></b-col>
          <b-col style="text-align:right"><strong>{{ order.total | currency }}</strong></b-col>
        </b-row>
      </div>

      <template #modal-footer>
        <!-- <b-button variant="primary">{{ $t('refund') }}</b-button> -->
        <b-button variant="primary">{{ $t('close') }}</b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import api from '../api/boxOfficeApi'
export default {
  data(){
    return {
      orders: null,
      order: null,
      page_data: null,
      page: 1
    }
  },
  created(){
    this.getOrders()
  },
  methods: {
    getOrders(page = 1){
      if (!page) {
        page = 1
      }
      api.boxOfficePlaceOrder().getOrders(this.$route.params.boxOffice, page).then(order => {
        this.page_data = order
        this.orders = order.data.map(order => {
          return Object.assign(order, {is_refunded: order.order_products.filter(p => p.refunded_at === null).length === 0})
        })
      })
    },
    openOrderDetail(order){
      this.order = Object.assign({}, order)
      this.$bvModal.show('order-detail-modal')
    }
  }
}
</script>

<style scoped lang="scss">
.FirstDiv{
  padding: 7px 16px;
}
.order-history-row{
  padding: 14px 16px;
}
.order-history-row.refunded{
  background-color:#E8E8E8 !important
}
.order-history-row.refunded div.col{
  color:#A1A1A1
}
.manage-btn{
  background-color:#F0B52D;
  border-color:#F0B52D;
  color:#FFF
}
</style>
