export default class ScanOperationPrivilegesApi {

    constructor(axios) {
        this.axios = axios
    }

    create(scan_operation_privilege) {
        return this.axios.post('/scan-operation/scanners/configure', scan_operation_privilege)
    }

    delete(scan_operation_privilege) {
        return this.axios.delete('/scan-operation/scanners/configure/'
            + `${scan_operation_privilege.scanner_id}/${scan_operation_privilege.session_id}/${scan_operation_privilege.product_id}`)
    }

}
