<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group :label="$t('venue_name')">
          <b-form-input v-if="venue" v-model="venue.name"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group :label="$t('venue_address')">
          <b-form-input v-if="venue" v-model="venue.address"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
        <b-col>
          <b-form-group :label="$t('venue_zip')">
            <b-form-input v-if="venue" v-model="venue.zip"></b-form-input>
          </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
          <b-form-group :label="$t('venue_city')">
            <b-form-input v-if="venue" v-model="venue.city"></b-form-input>
          </b-form-group>
        </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group :label="$t('venue_country')">
          <CountrySelection v-model="venue.country_code"></CountrySelection>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox v-model="venue.verified">{{ $t('venue_verified') }}</b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-checkbox v-model="venue.active">{{ $t('venue_active') }}</b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>


  </div>
</template>

<script>
import CountrySelection from '../components/CountrySelection.vue'
export default {
  components: {
    CountrySelection
  },
  props: ['value'],
  data() {
    return {
      venue: {},
    }
  },
  mounted() {
    this.venue = this.value;
  }
}
</script>
