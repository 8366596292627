export default class administration {

    constructor(axios) {
        this.axios = axios
    }

    companiesWithEvents() {
        return this.axios.get('/administration/companies');
    }

    createEventFee(event_id, data) {
        return this.axios.post('/administration/event/' + event_id + '/fees', data)
    }

    updateEventFee(event_id, id, data) {
        return this.axios.put('/administration/event/' + event_id + '/fees/' + id, data)
    }
}
