export default class venuesApi {

    constructor(axios) {
        this.axios = axios
    }
    overview(page) {
        return this.axios.get('/venues/overview?page=' + page)
    }
    create(venue) {
        return this.axios.post('/venue', venue)
    }
    save(venue) {
        return this.axios.post('/venue/' + venue.id, venue)
    }
    delete(venue) {
        return this.axios.delete('/venue/' + venue)
    }

    getVenuesNames() {
        return this.axios.get('/venues-names/')
    }

}
