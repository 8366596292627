<template>
  <div>
    <b-card class="info-card">
      <b-card-body>
        <b-row class="Parent">
          <b-col cols="6" lg="3">
            <b-form-group :label="$t('company')">
              <v-select v-model="selectedCompany" @input="clearEvent" :options="companies" label="name"></v-select>
            </b-form-group>
          </b-col>
          <b-col cols="6" lg="3">
            <b-form-group :label="$t('order_event')">
              <v-select v-model="selectedEvent" :options="events" label="name"></v-select>
              <b-form-checkbox class="mt-2 report-switch-small" switch v-model="includeArchivedEvents" :value="true" :unchecked-value="false">{{ $t('reports_include_archived_events') }}</b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card v-if="selectedEvent" class="info-card">
      <b-card-body>
        <b-row>
          <b-col>
            <tr>
              <td>
                <span class="font-weight-bold mr-3">ID</span>
              </td>
              <td>{{ selectedEvent.id }}</td>
            </tr>
            <tr>
              <td>
                <span class="font-weight-bold mr-3">{{ $t('export_event_name') }}</span>
              </td>
              <td>{{ selectedEvent.name }}</td>
            </tr>
          </b-col>
          <b-col>
            <tr>
              <td><span class="font-weight-bold mr-3">{{ $t('event_starts_at') }}</span></td>
              <td>{{ selectedEvent.starts_at | date_time }}</td>
            </tr>
            <tr>
              <td><span class="font-weight-bold mr-3">{{ $t('event_ends_at') }}</span></td>
              <td>{{ selectedEvent.ends_at | date_time }}</td>
            </tr>
          </b-col>
          <b-col>
            <tr>
              <td><span class="font-weight-bold mr-3">{{ $t('event_status') }}</span></td>
              <td>{{ selectedEvent.status }}</td>
            </tr>
            <tr>
              <td><span class="font-weight-bold mr-3">{{ $t('event_continuous') }}</span></td>
              <td>{{ selectedEvent.continuous ? $t('yes') : $t('no') }}</td>
            </tr>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    
    <div v-if="selectedEvent" class="mt-5">

        <b-row class="Parent">
          <b-col>
            <b-button v-if="$can('administration_overrule_fees_per_event_edit')" variant="new" @click="showAddEventFeeModal" class="mb-4">{{ $t('admin_company_add_company_fee') }}</b-button>

            <div class="tableBig">
              <b-table class="table-container" :items="selectedEvent.fees" :fields="[{key: 'fee_type', label: $t('admin_company_fee_type')},{key: 'fee', label: $t('admin_company_fee')},{key: 'retroactive', label: $t('event_fees_retroactive')},{key: 'actions', label: $t('order_actions')}]">
                <template v-slot:cell(fee)="{item}">
                  <span v-if="item.fee_type === 'free-tickets-free-percentage'">{{ item.fee + '%' }}</span>
                  <span v-if="item.fee_type !== 'free-tickets-free-percentage'">{{ item.fee | currency }}</span>
                </template>
                <template v-slot:cell(retroactive)="{item}">
                  {{ item.retroactive ? $t('yes') : $t('no') }}
                </template>
                <template v-slot:cell(actions)="{item}">
                  <b-button v-if="$can('administration_overrule_fees_per_event_edit')" variant="primary" @click="showEditEventFeeModal(item)" class="mr-2">{{ $t('edit') }}</b-button>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
    </div>

    <b-modal id="add-event-fee-modal" @close="eventFee = null" :title="$t('admin_company_add_company_fee')">

      <div v-if="eventFee">
        <b-form-group :label="$t('admin_company_fee_type')">
          <b-select v-model="eventFee.fee_type" :options="eventFees"></b-select>
        </b-form-group>

        {{ $t('admin_company_fee') }}
        <b-input-group style="height:45px">
          <template #prepend>
            <b-input-group-text style="height:45px">{{ eventFee.fee_type === 'free-tickets-free-percentage' ? '%' : $t('currency') }}</b-input-group-text>
          </template>
          <b-form-input  style="height:45px"
                         type="number"
                         v-model="eventFee.fee"
                         :step="0.01"
                         :min="0"
          ></b-form-input>
        </b-input-group >
        <br>
        <b-form-group>
          <b-form-checkbox v-model="eventFee.retroactive">{{ $t('event_fees_retroactive') }}</b-form-checkbox>
        </b-form-group>
      </div>

      <template #modal-footer>
        <b-button :disabled="loading || !eventFee || !eventFee.fee_type || !eventFee.fee" @click="save">
          <b-icon v-if="loading" icon="three-dots" animation="cylon" class="mr-2"></b-icon> {{ (eventFee && eventFee.id) ? $t('update') : $t('add') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import {deepClone} from "@/functions";

export default {
  name: "AdministrationEventFeesOverview",
  data() {
    return {
      loading: false,
      companies: [],
      selectedCompany: null,
      selectedEvent: null,
      includeArchivedEvents: false,
      eventFee: null,
      eventFees: [
        {value: 'ticket-fee', text: this.$t('admin_company_company_fee_ticket_fee')},
        {value: 'box-office-ticket-fee', text: this.$t('admin_company_company_fee_box_office_ticket_fee')},
        {value: 'refund-fee', text: this.$t('admin_company_company_fee_refund_fee')},
        {value: 'free-tickets-free-percentage', text: this.$t('admin_company_company_fee_free_tickets_free_percentage')},
        {value: 'free-ticketfee', text: this.$t('admin_company_company_fee_free_ticketfee')},
      ]
    }
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    getCompanies() {
      api.administration().companiesWithEvents().then(companies => {
        const currentCompany = this.selectedCompany?.id;
        const currentEvent = this.selectedEvent?.id;

        this.companies = companies;

        if(currentCompany) {
          this.selectedCompany = this.companies.find(c => c.id === currentCompany)
        }
        if(currentEvent) {
          setTimeout(() => {
            this.selectedEvent = this.events.find(e => e.id === currentEvent)
          }, 200)
        }
      })
    },
    showAddEventFeeModal() {
      this.eventFee = {
        fee_type: null,
        fee: null,
        retroactive: false
      };
      this.$bvModal.show('add-event-fee-modal');
    },
    showEditEventFeeModal(item) {
      this.eventFee = deepClone(item);
      this.$bvModal.show('add-event-fee-modal');
    },
    save() {
      this.loading = true;

      const request = this.eventFee.id ?
          api.administration().updateEventFee(this.selectedEvent.id, this.eventFee.id, this.eventFee) :
          api.administration().createEventFee(this.selectedEvent.id, this.eventFee)

      request.then(() => {
        this.loading = false;
        this.getCompanies();
        this.$bvModal.hide('add-event-fee-modal');
      }).catch(() => {
        this.loading = false;
      })
    },
    clearEvent() {
      this.selectedEvent = null;
      this.eventFee = null;
    }
  },
  computed: {
    events() {
      if(this.selectedCompany) {
        return this.selectedCompany.events.filter(e => {
          if(this.includeArchivedEvents) {
            return true;
          }
          return e.status !== 'archived'
        });
      }

      let events = [];
      this.companies.forEach(company => {
        events = events.concat(company.events)
      })

      return events.filter(e => {
        if(this.includeArchivedEvents) {
          return true;
        }
        return e.status !== 'archived'
      }).sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at))
    }
  }
}
</script>

<style scoped>
.info-card {
  margin-left: 0 !important;
}
</style>
