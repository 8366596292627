<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" md="9">
        <div class="table-container no-margin">
          <b-row class="default-container-layout FistDiv">
            <b-col cols="12" md="3">{{ $t('cart_product') }}</b-col>
            <b-col cols="2">{{ $t('box_office_place_order_price') }}</b-col>
            <b-col cols="3" md="2">{{ $t('box_office_place_order_available') }}</b-col>
            <b-col cols="5" md="3">{{ $t('cart_price') }}</b-col>
            <b-col cols="2"></b-col>
          </b-row>

          <b-row v-for="group in groups" :key="group.id" class="mt-2">
            <b-col v-if="group.checked">
              <b-row class="table-head p-2">
                <b-col>{{ group.type === 'default' ? $t(group.name) : group.name }}</b-col>
              </b-row>

              <b-row v-for="product in group.products" :key="product.id">
               <b-col v-if="product.checked" >
                 <b-row class="default-container-layout p-2 mt-2">
                   <b-col cols="12" md="3" class="product-name vertical-align">
                     <label class="d-block mb-0">{{ product.product.name }}</label>
                   </b-col>
                   <b-col cols="2" style="text-align:right">{{ product.overrule_price ? product.overrule_price : product.product.price | currency }}</b-col>
                   <b-col cols="3" md="2" style="text-align:center">{{product.product.current_stock}}</b-col>
                   <b-col cols="5" md="3">
                     <b-form-spinbutton size="lg" v-if="product.product.current_stock != 0" min="0" v-model="product.amount" :max="product.product.current_stock" inline></b-form-spinbutton>
                     <b-alert v-else :show="true" variant="danger">{{ $t('pos_product_sold_out') }}</b-alert>
                   </b-col>
                   <b-col cols="2">
                     <b-icon @click="product.amount = 0" icon="x-circle-fill" font-scale="2"></b-icon>
                   </b-col>
                 </b-row>
               </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12" md="3">
        <div class="default-container-layout p-3 customerDetails">
          <label class="d-block m-0 p-0">{{ $t('box_office_place_order_customer_details') }}</label>
          <small>{{ $t('box_office_place_order_fill_customer_details') }}</small>
          <b-row class="customer-name-container">
            <b-col><b-form-input v-model="customer.first_name" class="mb-2 mt-3 customer-input" :placeholder="$t('first_name')"></b-form-input></b-col>
            <b-col><b-form-input v-model="customer.last_name" class="mb-2 mt-3 customer-input" :placeholder="$t('last_name')"></b-form-input></b-col>
          </b-row>
          <b-row>
            <b-col><b-form-input v-model="customer.email" class="mb-2 customer-input" type="email" :placeholder="$t('email')"></b-form-input></b-col>
          </b-row>
        </div>

        <div class="default-container-layout p-3 mt-3 PaymentMethod">
          <label class="d-block m-0 p-0">{{ $t('order_detail_payment_method') }}</label>
          <small>{{ $t('box_office_place_order_choose_payment') }}</small>
          <b-row class="mt-2">

            <b-col v-for="pm in paymentMethods" :key="'paymentMethod' + pm.id" class="text-center">
              <a @click="selectPayment( pm )" class="d-block payment-btn" :class="(paymentMethod && paymentMethod.id === pm.id) ? 'active' : ''" href="javascript:">
                <b-icon icon="credit-card-fill" font-scale="2" v-if="pm.payment_method.adapter === 'pin'"></b-icon>
                <b-icon icon="cash" font-scale="2" v-if="pm.payment_method.adapter === 'cash'"></b-icon>
                <small class="d-block">{{ pm.payment_method.name }} </small>
              </a>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="9">
        <div class="m-auto table">
          <span v-if="discount === 0" class="discount-icon mr-4">
            <b-icon icon="percent" font-scale="3" class="mr-2 cursor-hand" @click="showDiscountInput = !showDiscountInput"></b-icon>
            <a href="javascript:"  v-if="!showDiscountInput" @click="showDiscountInput = !showDiscountInput">{{ $t('add_discount') }}</a>
            <b-input-group v-if="showDiscountInput" prepend="">
              <b-form-input type="number" v-model="discountInpt" @keyup.enter="applyDiscount" :step="0.01" :min="0"></b-form-input>
              <b-input-group-append>
                <b-button class="add-discount-btn" @click="applyDiscount">{{ $t('box_office_place_order_discount_enter') }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </span>
          <span v-if="discount > 0" class="discount-icon danger mr-4">
            <b-icon icon="x" font-scale="3" class="mr-2 cursor-hand" @click="removeDiscount"></b-icon>
            <a href="javascript:" @click="removeDiscount">{{ $t('box_office_place_order_remove_discount') }}</a>
          </span>
          <span class="discount-icon">
            <b-icon icon="trash" font-scale="3" class="mr-2 cursor-hand" @click="emptyCart"></b-icon>
            <a @click="emptyCart" href="javascript:">{{ $t('box_office_place_order_empty_cart') }}</a>
          </span>
        </div>
      </b-col>
      <b-col cols="3">
        <small v-if="!productsAmount">- {{ $t('box_office_no_products_chosen') }}<br></small>
        <small v-if="!paymentMethod">- {{ $t('box_office_no_payment_method_chosen') }}</small>
      </b-col>
    </b-row>
    <b-row v-if="discountError" class="mt-2">
      <b-col cols="9" style="text-align:center;" class="error-color">
        {{ $t('box_office_place_order_discount_to_high') }}
      </b-col>
    </b-row>
    <b-row class="mt-3 lastTable">
      <b-col class="default-container-layout" cols="12" md="9">
        <b-row class="pr-3 pl-3">
          <b-col cols="8" md="6">
            <b-row class="m-0 p-0 ">
              <b-col class="m-0 p-0">
                <span class="d-block"><small>{{ $t('box_office_place_order_amount_of_products') }}</small></span>
              </b-col>
              <b-col class="m-0 p-0">
                <span class="d-block"><small class="ml-2">{{ productsAmount }}</small></span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4" md="3">
            <b-row class="m-0 p-0">
              <b-col class="m-0 p-0">
                <span class="d-block"><small>{{ $t('box_office_place_order_subtotal') }}</small></span>
                <span class="d-block"><small>{{ $t('box_office_place_order_discount') }}</small></span>
              </b-col>
              <b-col class="m-0 p-0">
                <span class="d-block"><small class="ml-2">&euro; {{ subtotal.toFixed(2).replace('.', ',') }}</small></span>
                <span class="d-block"><small class="ml-2">&euro; {{ discount.toFixed(2).replace('.', ',') }}</small></span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="3">
            <label class="font-weight-bold text-black-50">{{ $t('box_office_place_order_total') }} </label> <label
              class="font-weight-bold text-black-50 ml-2">&euro; {{ subtotal ? total.toFixed(2).replace('.', ',') : 0 }}</label>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="button-container" cols="12" md="3">
        <button class="btn place-order-btn" :disabled="!paymentMethod || !productsAmount || loading" :class="paymentMethod !== null && productsAmount > 0 ? 'active' : ''" @click="placeOrder()">
         {{ $t('box_office_place_order_place_order') }}
        </button>
      </b-col>
    </b-row>

    <b-modal id="place-order-setting" title="Setting" ok-only :ok-title="$t('save')" @cancel="resetSettings">
      <strong class="d-block mt-3 mb-2">{{ $t('box_office_place_order_which_product_to_show') }}</strong>
      <b-row v-for="(group, index) in groupsSettings" :key="group.id" class="setting-row">
        <b-col>
          <b-row class="table-head mb-2 p-2">
            <b-col cols="2">
              <b-form-checkbox v-model="group.checked" name="check-button" switch @change="groupCheckChanged(index)"></b-form-checkbox>
            </b-col>
            <b-col cols="8">{{ group.type === 'default' ? $t(group.name) : group.name }}</b-col>
            <b-col cols="2"></b-col>
          </b-row>
          <b-row class="table-head-white mb-2 p-2" v-for="(product, prodIndex) in group.products" :key="product.id">
            <b-col cols="2">
              <b-form-checkbox v-model="product.checked" name="check-button" switch @change="productCheckChanged(index, prodIndex)"></b-form-checkbox>
            </b-col>
            <b-col cols="7">{{ product.product.name }}</b-col>
            <b-col cols="3" style="text-align:right">
              {{ product.overrule_price ? product.overrule_price : product.product.price | currency}}
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button class="mr-auto" variant="primary" @click="logout">{{ $t('app_logout') }}</b-button>
        <b-button variant="primary" @click="saveSettings">{{ $t('save') }}</b-button>
      </template>
    </b-modal>

    <b-modal id="payment-modal" title="Payment" ok-only :ok-title="$t('save')" @cancel="resetSettings">
      <b-row>
        <b-col class="text-center">
          <label class="d-block font-weight-bold">{{ $t('box_office_place_order_customer_has_to_pay') }}</label>
          <h2 class="font-weight-bold">&euro;{{ total ? total.toFixed(2).replace('.', ',') : 0 }}</h2>
        </b-col>
      </b-row>
      <b-row v-if="paymentMethod && paymentMethod.payment_method.adapter === 'cash'" class="mt-5">
        <b-col>
          <label class="d-block font-weight-bold">{{ $t('box_office_place_order_given_by_customer_in') }} &euro;:</label>
          <b-input v-model="givenByCustomer" class="text-center font-weight-bold"></b-input>
        </b-col>
        <b-col>
          <label class="d-block font-weight-bold">{{ $t('box_office_place_order_return_to_customer_in') }} </label>
          <h3 class="d-block font-weight-bold">&euro; {{ givenByCustomer ? (givenByCustomer -  total).toFixed(2).replace('.', ',') : 0 }}</h3>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-row class="w-100">
          <b-col>
            <button class="btn payment-cancel-btn" @click="$bvModal.hide('payment-modal')">{{ $t('cancel') }}</button>
          </b-col>
          <b-col>
            <button @click="markAsPaid" :disabled="loading" class="btn payment-place-order-btn-green">{{ $t('box_office_place_order_place_order') }}</button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import api from '../api/boxOfficeApi'
export default {
  name: "BoxOfficePlaceOrder",
  data() {
    return {
      discountError: false,
      showDiscountInput: false,
      discountInpt: 0,
      discount: 0,
      givenByCustomer: 0,
      groups: [],
      groupsSettings: [],
      loading: false,
      boxOffice: null,
      paymentMethod: null,
      btnOrderEnabled: false,
      paymentMethods: null,
      order: null,
      customer: {
        first_name: null,
        last_name: null,
        email: null,
      }
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      api.event().boxOffice(this.$route.params.event, this.$route.params.boxOffice).then(boxOffice => {
       this.boxOffice = boxOffice;

        this.paymentMethods = boxOffice.payment_methods.filter(paymentMethod => paymentMethod.online)

        api.boxOfficePlaceOrder().getSettings(this.$route.params.boxOffice).then(settings => {
          this.groups = boxOffice.groups.map(group => {
            const groupSettings = settings.find(s => s.group_id === group.id);
            const products = group.products.map(prod => {
              const productSettings = groupSettings ? groupSettings.products.find(p => p.product_id === prod.product_id) : null;
              const prodClone = Object.assign({}, prod);
              prodClone.checked = productSettings ? productSettings.checked : true;
              prodClone.amount = 0;
              return prodClone;
            })
            const groupCloned = Object.assign({}, group);
            groupCloned.products = products;
            groupCloned.checked = groupSettings ? groupSettings.checked : true;
            return groupCloned;
          });
          this.groupsSettings = this.deepClone(this.groups);
        })
      })
    },
    groupCheckChanged(index) {
      for(let i=0; i<this.groupsSettings[index].products.length; i++) {
        this.groupsSettings[index].products[i].checked = this.groupsSettings[index].checked;
      }
    },
    productCheckChanged(index, prodIndex) {
      if(this.groupsSettings[index].products[prodIndex].checked) {
        this.groupsSettings[index].checked = true;
      }
    },
    logout(){
      this.$store.commit('boxOfficeLogout')
    },
    saveSettings() {
      const data = this.deepClone(this.groupsSettings).map(group => {
        return {
          group_id: group.id,
          checked: group.checked,
          products: group.products.map(product => {
            return {
              product_id: product.product_id,
              checked: product.checked,
            }
          })
        }
      })
      this.loading = true;
      api.boxOfficePlaceOrder().saveSettings(this.$route.params.boxOffice, data).then(() => {
        this.loading = false;
        this.groups = this.deepClone(this.groupsSettings);
        this.$bvModal.hide('place-order-setting')
        this.$store.commit('success', this.$t('box_office_place_save_settings_success'))
      })
    },
    resetSettings() {
      this.groupsSettings = this.deepClone(this.groups);
    },
    deepClone(object) {
      return JSON.parse(JSON.stringify(object));
    },
    applyDiscount() {
      if(parseFloat(this.discountInpt) > this.total){
        this.discountError = true
      } else {
        this.discount = parseFloat(this.discountInpt);
        this.showDiscountInput = false;
        this.discountError = false
      }
    },
    removeDiscount() {
      this.discount = 0;
      this.discountInpt = 0;
    },
    emptyCart() {
      this.discount = 0
      this.discountInpt = 0
      this.discountError = false
      for(let i=0; i< this.groups.length; i++) {
        for(let j=0; j< this.groups[i].products.length; j++) {
          this.groups[i].products[j].amount = 0;
        }
      }
      this.customer = {
        first_name: null,
        last_name: null,
        email: null,
      }
    },
    selectPayment(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },
    placeOrder(){
      const products = [];
      this.groups.forEach(group => {
        if(group.checked) {
          group.products.forEach(product => {
            if(product.checked) {
              products.push({
                product_id: product.product_id,
                amount: product.amount,
              });
            }
          })
        }
      })
      const order = {
        total: this.total,
        discount: this.discount,
        products,
        payment_method: this.paymentMethod.id,
        customer: this.customer
      }
      this.loading = true;

      api.boxOfficePlaceOrder().save(
          this.$route.params.boxOffice,
          order
      ).then(response => {
        this.loading = false;
        this.order = response;
        this.$bvModal.show('payment-modal')
      }).catch(() => {
        this.loading = false;
        this.refresh();
      })
    },
    markAsPaid() {
      this.loading = true;
      api.boxOfficePlaceOrder().markOrderPaid(
          this.$route.params.boxOffice,
          this.order.id
      ).then(() => {
        this.loading = false;
        this.$store.commit('success', this.$t('box_office_place_paid_success'))
        this.emptyCart();
        this.removeDiscount();
        this.order = null;
        this.paymentMethod = null;
        this.$bvModal.hide('payment-modal')
        this.refresh()
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  computed: {
    subtotal() {
      let total = 0;
      for(let i=0; i< this.groups.length; i++) {
        for(let j=0; j< this.groups[i].products.length; j++) {
          const price = this.groups[i].products[j].overrule_price ? this.groups[i].products[j].overrule_price : this.groups[i].products[j].product.price;
          total += parseFloat(price) * parseFloat(this.groups[i].products[j].amount);
        }
      }
      return total;
    },
    productsAmount() {
      let amount = 0;
      for(let i=0; i< this.groups.length; i++) {
        for(let j=0; j< this.groups[i].products.length; j++) {
          amount += parseFloat(this.groups[i].products[j].amount);
        }
      }
      return amount;
    },
    total() {
      return this.subtotal ? (this.subtotal - this.discount) : 0;
    }
  }
}
</script>
<style lang="scss">
#place-order-setting  {
  .modal-body, .modal-footer {
    background-color: #efefef !important;
  }
}
</style>
<style scoped lang="scss">
.product-name{
  flex-direction: column;
}
.customer-name-container div.col:first-of-type{
  padding-right:5px
}
.customer-name-container div.col:last-of-type{
  padding-left:5px
}
.setting-row{
  margin-left:0px;
  margin-right:0px
}
.cursor-hand{
  cursor:pointer
}
.error-color{
  color:#dc3545
}
.payment-cancel-btn {
  border: 2px solid #DC3545;
  width: 100%;
  height: 100%;
  color: #000000;
  background-color: #ffffff;
  font-weight: bold;

  &:hover {
    background-color: #f1f1f1;
  }
}
.payment-place-order-btn-green {
  border: 2px solid #28973D;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: #28973D;
  font-weight: bold;

  &:hover {
    color: #28973D;
    background-color: #f1f1f1;
  }
}
.table-container {
  overflow-y: scroll;
  height: 60vh;
}
.table-container > div{
  width: 100%;
  margin: 10px 0!important;
}
.table-container.no-margin > div{
  margin-top:0px !important
}
.table-head {
  background-color: #193E58;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 6px;
  box-shadow: 0 3px 6px #52575d19;
  font-weight: bold;
  * {
    color: #ffffff;
  }
}
.table-head-white {
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
  border-radius: 6px;
  box-shadow: 0 3px 6px #52575d19;
}

.place-order-btn {
  border: 2px solid #28973D;
  width: 100%;
  height: 100%;
  color: #28973D;
  background-color: #ffffff;
  font-weight: bold;
}
.place-order-btn.active{
  background-color:#28973D;
  color:#FFF
}

input.customer-input {
  background-color: #F5F5F5;
}

.payment-btn {
  border: 1px solid #A1A1A1;
  border-radius: 4px;
  background-color: #F5F5F5;
  padding: 5px 2px;
  color: #A1A1A1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;
}

.payment-btn:hover, .payment-btn.active {
  border: 1px solid #F0B52D;
  color: #F0B52D;
}
.discount-icon {
  .b-icon {
    color: #ffffff;
    background-color: #A1A1A1;
    border-radius: 50px;
    padding: 10px;
  }

  a {
    color: #000000;
    text-decoration: none;
  }

  &.danger {
    .b-icon {
      background-color: #DC3545;
    }

    a {
      color: #DC3545;
    }
  }
}

.add-discount-btn {
  color: #ffffff;
  background-color: #F0B52D;
  border-color: #F0B52D;
}
.default-container-layout > div:not(:first-of-type){
  display: flex;
  align-items: center;
}
.table{
  display: flex;
  justify-content: center;
  margin: 0!important;
}
.table > span{
  margin: 0 30px!important;
}
.discount-icon{
  display: flex;
  align-items: center;
}
.lastTable >div{
  padding: 10px 40px;
}
.lastTable >div.button-container{
  padding: 0px 20px
}
.lastTable >div > div>div:not(:first-of-type){
  display: flex;
  align-items: center;
}
.lastTable >div > div>div:last-of-type{
  justify-content: end;
}
.lastTable >div > div>div:last-of-type label{
margin-bottom: 0px!important;
font-size: 20px;
color: #6c6c6c !important
}
.FistDiv{
  padding: 7px 16px;
}
@media (max-width:992px) {
  .table-container {
  height: 60vh;
}
  .table{
    display: block;
  }
  .table >span{
    margin-bottom: 20px!important;
  }
 .lastTable >div{
  padding: 10px 20px;
}
 .lastTable >div > div>div:last-of-type label{
  margin: 10px 0px!important;
  }
  .lastTable >div > div>div:last-of-type{
  justify-content: start;
}
.table-container{
  margin-bottom: 30px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
}
.table-container .default-container-layout > div{
  padding: 0;
}
.table-container .default-container-layout > div:nth-of-type(3){
  padding-right: 15px;
}
.default-container-layout > div:not(:first-of-type){
justify-content: center;
}
.PaymentMethod label,.customerDetails label{
font-size: 15px;
}
.PaymentMethod >small,.customerDetails small{
font-size: 11px;
}
.PaymentMethod >div{
  margin-top: 20px;
}
.customerDetails input{
  margin: 10px 0;
  font-size: 14px;
  padding: 0px 6px;
  height: 40px;
}
}


@media (max-width:560px) {
.table-container div{
  font-size: 13px!important;
}
.table-container label{
  font-size: 14px!important;
}
.table-container small{
  font-size: 12px!important;
}


.table-container .default-container-layout > div:first-of-type{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.container-fluid{
  padding: 0!important;
}
}
</style>
