<template>
    <div>
      <b-form-group class="text-right container-distance">
        <b-button v-if="$can('ticket_template_create')" variant="new" @click="$bvModal.show('new-template')">{{ $t('create_template') }}</b-button>
      </b-form-group>
      
      <b-modal :title="$t('template_modal_create')" id="new-template" size="lg">
        <b-form-group v-if="template" :label="$t('template_name')">
          <b-form-input v-model="template.name"></b-form-input>
        </b-form-group>
        <template #modal-footer="{cancel}">
          <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
          <b-button @click="createTemplate()" variant="success">{{ $t('create') }}</b-button>
        </template>
      </b-modal>

      <b-table v-if="templates.length" :items="templates" :fields="[{key: 'name', label: $t('ticket_template_name')}, {key: 'actions', label: $t('order_actions')}]">
        <template v-slot:cell(actions)="{item, index}">
          <b-button v-if="$can('ticket_template_edit')" :to="{name: 'EditTicketTemplate', params: {id: item.id}}" class="mr-2">{{ $t('edit') }}</b-button>
          <b-button v-if="$can('ticket_template_duplicate')" @click="showDuplicateModal(item)" class="btnDuplicate" variant="row-edit">{{ $t('duplicate') }}</b-button>
          <b-icon v-if="$can('ticket_template_delete')" @click="showDeleteModal(item)" icon="trash" font-scale="1.5" class="ml-3 text-secondary btnEventDelete" :class="{cursor_pointer: item.events_count === 0 && item.products_count === 0 && index, disabled_danger: item.events_count || item.products_count || index === 0}"></b-icon>
        </template>
      </b-table>
      <pagination v-if="page_data" :limit="3" :data="page_data" @pagination-change-page="getTemplates"></pagination>

      <b-alert v-else show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>

      <b-modal :title="$t('ticket_template_duplicate')" id="duplicate-template" size="large">
        <div>
          <b-row>
            <b-col>
              <b-form-group v-if="template" :label="$t('template_name')">
                <b-form-input v-model="template.name"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <template #modal-footer>
          <b-button :disabled="(template && (!template.name || template.name === originalTemplateName)) || loading" variant="primary" @click="duplicateTemplate()">
            <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon> {{ $t('duplicate') }}
          </b-button>
        </template>
      </b-modal>

      <b-modal :title="$t('delete')" id="delete-template" size="lg">
        <div>
          <b-row>
            <b-col>{{ $t('ticket_template_delete_entry') }}</b-col>
          </b-row>
        </div>
        <template #modal-footer="{cancel}">
          <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
          <b-button :disabled="loading" @click="deleteTemplate" variant="danger">
            <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon> {{ $t('delete') }}
          </b-button>
        </template>
      </b-modal>
    </div>
</template>

<script>
import api from '../api'
import moment from "moment";
export default {
  data(){
    return {
      templates: [],
      page_data: null,
      template:{},
      loading: false,
      originalTemplateName: null
    }
  },
  methods: {
    cancel() {
      this.$bvModal.hide('new-template')
      this.template = {}
    },
    createTemplate(){
      api.ticket().createTemplate(this.template.name).then((template) => {
        this.cancel();
        if(this.$can('ticket_template_edit')) {
          this.$router.push({
            name: 'EditTicketTemplate',
            params: {
              id: template.id
            }
          })
        }
        else {
          this.$store.commit('success', this.$t('ticket_template_created'));
          this.getTemplates();
        }
      })
    },
    getTemplates(page = 1) {
      api.ticket().getTemplatesOverview(page).then((templates) => {
        this.templates = templates.data
        this.page_data = templates
      })
    },
    showDuplicateModal(template) {
      this.originalTemplateName = template.name;
      this.template = {
        id: template.id,
        name: template.name + ' - ' + moment().format('YYYY')
      };
      this.$bvModal.show('duplicate-template');
    },
    duplicateTemplate() {
      this.loading = true;
      api.ticket().duplicateTemplate(this.template.id, this.template.name).then(template => {
        this.loading = false;
        this.template = null;
        this.$bvModal.hide('duplicate-template');

        if(this.$can('ticket_template_edit')) {
          this.$router.push({
            name: 'EditTicketTemplate',
            params: {
              id: template.id
            }
          })
        }
        else {
          this.getTemplates();
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    deleteTemplate() {
      this.loading = true;
      api.ticket().deleteTemplate(this.template.id).then(() => {
        this.$store.commit('success', 'ticket_template_deleted');
        this.$bvModal.hide('delete-template')
        this.template = {};
        this.getTemplates();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        this.$store.commit('errors', ['ticket_template_cannot_deleted']);
      })
    },
    showDeleteModal(template) {
      if(template.events_count || template.products_count) {
        return;
      }
      this.template = template;
      this.$bvModal.show('delete-template')
    },
  },
  created() {
    document.title = this.$t('backend_title_ticket_templates')
    if(this.$store.state.user) {
      if(!this.$can('ticket_template_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.getTemplates();
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('ticket_template_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style scoped>
.btnEventDelete{
  position:relative;
  top:4px
}
.cursor_pointer {
  cursor: pointer;
}
.disabled_danger {
  opacity: .4;
}
table.b-table tbody tr:hover .btn.btn-row-edit{
  border-color: #f5f5f5 !important;
}
.btnDuplicate{
  background-color: transparent!important;
  color: #808090!important;
}
.btnDuplicate:hover{
  background-color: #F0B52D!important;
  border-color: #F0B52D!important;
  color:white!important;
}

tr:hover button {}
</style>