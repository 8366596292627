export default class productApi {

    constructor(axios) {
        this.axios = axios
    }

    get(eventId) {
        return this.axios.get('/event/' + eventId + '/product')
    }

    save(eventId, product) {
        return this.axios.post('/event/' + eventId + '/product/' + product.id, product)
    }

    create(eventId, product) {
        return this.axios.post('/event/' + eventId + '/product', product)
    }

    getGroups(eventId) {
        return this.axios.get('/event/' + eventId + '/product-groups')
    }

    getUngroupedProducts(eventId) {
        return this.axios.get('/event/' + eventId + '/products-ungrouped')
    }

    saveProductSort(eventId, sort) {
        return this.axios.post('/event/' + eventId + '/product-sort', {sort})
    }

    delete(eventId, productId) {
        return this.axios.delete('/event/' + eventId + '/product/' + productId)
    }

    getProductTypes(){
        return this.axios.get('/product-types')
    }

    getProductTypeDonation(){
        return this.axios.get('/product-type-donation')
    }

}
