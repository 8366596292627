<template>
  <b-row>
    <b-spinner v-if="!scanOperation"></b-spinner>
    <b-col v-else cols="12">
      <div class="content-main-container container-distance">
        <div class="title-container">{{ $t("scan_operation_overview_for", {event_name: scanOperation.event.name}) }}</div>
        <div class="content-container">
          <b-row>
            <b-col>
              <label class="d-block font-weight-bold">{{ $t("scan_operation_overview_event", {event_name: scanOperation.event.name}) }}</label>
              <label class="d-block font-weight-bold">{{ $t("scan_operation_overview_outgoing_scans") }} {{ $t("scan_operation_overview_outgoing_" + (scanOperation.outgoing_enabled ? 'enabled' : 'disabled')) }}</label>
              <label class="d-block font-weight-bold">{{ $t("scan_operation_overview_outgoing_test_mode") }} {{ $t("scan_operation_overview_outgoing_" + (scanOperation.status === 'test' ? 'true' : 'false')) }}</label>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="5">
              <label class="font-weight-bold">{{ $t("scan_operation_overview_scanners") }}</label>
              <b-button @click="$router.push({name: 'ScanOperationManage', params: {id: $route.params.id}})" class="float-right text-white" variant="primary">{{ $t("scan_operation_overview_edit") }}</b-button>
              <b-table class="mt-2" :items="scanOperation.scanners" :fields="['name']" thead-class="d-none">
              </b-table>
            </b-col>
            <b-col cols="2"></b-col>
            <b-col cols="5">
              <label class="font-weight-bold">{{ $t("scan_operation_overview_sessions") }}</label>
              <b-button @click="$router.push({name: 'ScanOperationManage', params: {id: $route.params.id}})" class="float-right text-white" variant="primary">{{ $t("scan_operation_overview_edit") }}</b-button>

              <b-table class="mt-2" :items="scanOperation.sessions" :fields="['name', 'dates']" thead-class="d-none">
                <template v-slot:cell(dates)="{item}" >
                  {{ formatSessionDate(item.starts_at, item.ends_at) }}
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="5">
              <label class="font-weight-bold">{{ $t("scan_operation_overview_product_configuration") }}</label>
              <b-button @click="$router.push({name: 'ScanOperationProductsConfigure', params: {id: $route.params.id}})" class="float-right text-white" variant="primary">{{ $t("scan_operation_overview_edit") }}</b-button>
              <div v-for="product in products" :key="product.id">
                <label class="d-block">{{ product.product_group && product.product_group.type !== 'default' ? product.product_group.name + ' - ' : '' }}{{ product.name }}</label>
                <hr>
                <b-table class="mt-2" :items="product.sessions" :fields="['name']" thead-class="d-none" borderless>
                  <template v-slot:cell(name)="{item}" >
                    {{ item.name }} ({{ formatSessionDate(item.starts_at, item.ends_at) }})
                  </template>
                </b-table>
                <hr>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="12">
              <label class="font-weight-bold">{{ $t("scan_operation_overview_scanner_configuration") }}</label>
              <b-button @click="$router.push({name: 'ScanOperationScannersConfigure', params: {id: $route.params.id}})" class="float-right text-white" variant="primary">{{ $t("scan_operation_overview_edit") }}</b-button>
              <b-table class="mt-2" :items="scanOperation.scanners" :fields="['name', 'sessions', 'products']" thead-class="d-none" borderless>
                <template v-slot:cell(sessions)="{item}" >
                  <label class="d-block" v-for="pr in item.privileges" :key="pr.id">
                    {{ pr.session.name }} ({{ formatSessionDate(pr.session.starts_at, pr.session.ends_at) }})
                  </label>
                </template>
                <template v-slot:cell(products)="{item}" >
                  <label class="d-block" v-for="pr in item.privileges" :key="pr.id">
                   {{ pr.product.product_group && pr.product.product_group.type !== 'default' ? pr.product.product_group.name + ' - ' : '' }}{{ pr.product.name }}
                  </label>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-button @click="$router.push({name: 'ScanOperationOverview'})" class="mb-3 ml-3 btn-new float-right">{{ $t('finish') }}</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import api from "@/api";
import moment from "moment";

export default {
  name: "ScanOperationSummary",
  data() {
    return {
      scanOperation: null,
      products: []
    }
  },
  mounted() {
    api.scanOperation().find(this.$route.params.id).then((response)=> {
      this.scanOperation = response;

      api.product().get(response.event_id).then(products => {
        api.scanOperationSession().getSessionsProducts(this.$route.params.id).then((response) => {
          this.products = products.map(product => {
            product.session = null;
            product.sessions = response.filter(session => {
              return session.products.filter(prod => prod.id === product.id).length
            });
            return product;
          });
        })
      })
    });
  },
  methods: {
    formatSessionDate(start, end) {
      return moment(start).format('DD-MM-YYYY HH:mm') + ' - ' + moment(end).format('DD-MM-YYYY HH:mm');
    }
  },
  created(){
    document.title = this.$t('backend_title_scan_operation_summary')
    if(this.$store.state.user) {
      if (!this.$can('scan_operation_edit') && !this.$can('scan_operation_create')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if (!this.$can('scan_operation_edit') && !this.$can('scan_operation_create')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
