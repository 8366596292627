<template>
  <div>
    <b-row>
      <b-col>
      </b-col>
      <b-col>
        <b-form-group class="text-right container-distance">
          <b-button v-if="$can('master_data_permission_roles_create')" variant="new" @click="showRoleModal()">{{ $t('new') }}</b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="roles.length">
      <b-col class="tableBig">
        <b-table class="table-container" :items="roles" :fields="tableFields">
          <template v-slot:cell(created_by)="{item}">
            {{ item.owner.name }}
          </template>
          <template v-slot:cell(admin_only)="{item}">
            <b-icon v-if="item.admin_only" icon="check-circle-fill" variant="success"></b-icon>
            <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
          </template>
          <template v-slot:cell(online)="{item}">
            <b-icon v-if="item.online" icon="check-circle-fill" variant="success"></b-icon>
            <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
          </template>
          <template v-slot:cell(actions)="{item}">
            <b-button v-if="$can('master_data_permissions_roles_edit')" class="mr-2 btn-action" variant="row-edit" @click="showRoleModal(item)">{{
                $t('manage')
              }}
            </b-button>
            <b-icon v-if="$can('master_data_permissions_roles_delete')" icon="trash" font-scale="1.5" class="ml-3 text-secondary cursor-pointer"
                    @click="showDeleteModal(item)"></b-icon>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-alert v-else show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>

    <b-modal :title="$t('roles_roles_permissions')" id="role-permissions-modal" size="lg">
      <div>
        <b-form-group :label="$t('roles_name')">
          <b-form-input v-model="role.name"></b-form-input>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-checkbox v-model="role.admin_only">{{ $t('roles_admin_only') }}</b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-form-checkbox v-model="role.online">{{ $t('roles_online') }}</b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-for="(group, index) in role.permissions" :key="index">
          <label class="group-label px-2 d-block mt-3 mb-1 mb-0">
            <b-form-checkbox @change="changePermissionsCheckbox(group, $event)" class="font-weight-bold py-1">{{ index }}</b-form-checkbox>
          </label>
          <b-row>
            <b-col cols="6" v-for="permission in group" :key="permission.id">
              <b-form-group class="px-3">
                <b-form-checkbox v-model="permission.selected">{{ $t('permission_' + permission.slug) }}</b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>

      <template #modal-footer>
        <b-button :disabled="!role.name || loading" variant="primary" @click="createRole">
          <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon>
          {{ $t('save') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('delete')" id="delete-role" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('delete_entry') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button :disabled="loading" @click="deleteRole" variant="danger">
          <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon>
          {{ $t('delete') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import {groupBy} from "@/functions";

export default {
  name: "PermissionRoles",
  data() {
    return {
      roles: [],
      role: {},
      loading: false,
      permissions: [],
      tableFields: [
        {key: 'name', label: this.$t('roles_name')},
        {key: 'admin_only', label: this.$t('roles_admin_only')},
        {key: 'online', label: this.$t('roles_online')},
        {key: 'created_by', label: this.$t('roles_created_by')},
        {key: 'actions', label: this.$t('actions')},
      ]
    }
  },
  mounted() {
    document.title = this.$t('roles_permission_roles')
    if(this.$store.state.user) {
      if(!this.$can('master_data_permissions_roles_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.getRoles();
    this.getPermissions();
  },
  methods: {
    showRoleModal(role = null) {
      this.role = JSON.parse(JSON.stringify(role)) ?? {};
      let permissions = null;
      if (role) {
        permissions = JSON.parse(JSON.stringify(this.permissions)).map(p => {
          const isSelected = !!role.permissions.filter(rp => rp.id === p.id).length;
          return Object.assign(p, {selected: isSelected})
        });
      } else {
        permissions = JSON.parse(JSON.stringify(this.permissions));
      }
      this.role.permissions = this.getSortedPermissions(permissions);
      this.$bvModal.show('role-permissions-modal')
    },
    getSortedPermissions(permissions) {
      const tempPermissions = groupBy(permissions, p => p.category);
      for (const group in tempPermissions) {
        tempPermissions[group] = tempPermissions[group].sort((a, b)=>{
          return a.sort - b.sort;
        })
      }

      return tempPermissions;
    },
    getRoles() {
      api.permission().getRoles().then(roles => {
        this.roles = roles;
      })
    },
    getPermissions() {
      api.permission().getPermissions().then(permissions => {
        this.permissions = permissions.map(permission => {
          return Object.assign(permission, {selected: false})
        });
      })
    },
    createRole() {
      const permissions = [];
      for (const group in this.role.permissions) {
        for (const permission of this.role.permissions[group]) {
          if (permission.selected) {
            permissions.push(permission.id);
          }
        }
      }
      this.loading = true;
      const body = {
        name: this.role.name,
        admin_only: this.role.admin_only,
        online: this.role.online,
        permissions
      };
      const request = this.role.id ? api.permission().updateRole(this.role.id, body) : api.permission().createRole(body);
      request.then(() => {
        this.getRoles();
        this.$bvModal.hide('role-permissions-modal')
        this.loading = false;
        this.$store.commit('success', this.$t('role_saved_successfully'))
      }).catch(() => {
        this.loading = false;
      })
    },
    showDeleteModal(role) {
      this.role = role;
      this.$bvModal.show('delete-role')
    },
    deleteRole() {
      this.loading = true;
      api.permission().deleteRole(this.role.id).then(() => {
        this.getRoles();
        this.$bvModal.hide('delete-role')
        this.loading = false;
        this.$store.commit('success', this.$t('role_deleted_successfully'))
      }).catch(() => {
        this.loading = false;
      })
    },
    changePermissionsCheckbox(permissions, event) {
      for(const permission of permissions) {
        permission.selected = event;
      }
      this.$forceUpdate();
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('master_data_permissions_roles_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
    '$store.state.language': {
      handler() {
        this.tableFields = [
          {key: 'name', label: this.$t('roles_name')},
          {key: 'admin_only', label: this.$t('roles_admin_only')},
          {key: 'online', label: this.$t('roles_online')},
          {key: 'created_by', label: this.$t('roles_created_by')},
          {key: 'actions', label: this.$t('actions')},
        ]
      }
    }
  }
}
</script>

<style scoped>
table.b-table tbody tr:hover .btn.btn-row-edit {
  border-color: #f5f5f5 !important;
}

.btn-action {
  background-color: transparent !important;
  color: #808090 !important;
}

.btn-action:hover {
  background-color: #F0B52D !important;
  border-color: #F0B52D !important;
  color: white !important;
}

.cursor-pointer {
  cursor: pointer;
}

.group-label {
  background-color: #EEEEEE !important;
  color: #000!important;
  font-size: 15px!important;
  padding-top: 12px ;
  padding-bottom: 12px ;
}
.group-label span {
  background-color: #EEEEEE;
  color: #4E4D4D;
}
</style>
