<template>
  <div>
    <b-form-group class="text-right container-distance">
      <b-button v-if="$can('batch_create')" variant="new" @click="$bvModal.show('new-batch')">{{ $t('create_batch') }}</b-button>
    </b-form-group>

    <b-modal :title="$t('batch_modal_create')" id="new-batch" size="lg">
      <b-form-group v-if="batch" :label="$t('batch_name')">
        <b-form-input v-model="batch.name"></b-form-input>
      </b-form-group>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="createBatch()" variant="success">{{ $t('create') }}</b-button>
      </template>
    </b-modal>

    <b-table v-if="batches.length" :items="batches" :fields="[{key: 'name', label: $t('batch_name')},{key: 'tickets_count', label: $t('tickets_count')}, {key: 'actions', label: $t('order_actions')}]">
      <template v-slot:cell(actions)="{item}">
        <b-button v-if="$can('batch_edit')" :to="{name: 'BatchDetails', params: {id: item.unique_id}}" class="mr-2">{{ $t('manage') }}</b-button>
        <b-button v-if="item.zip_file_path" :href="item.zip_file_path" target="_blank" variant="success">{{ $t('batch_download') }}</b-button>
      </template>
    </b-table>
    <pagination v-if="page_data.total > 0" :limit="3" :data="page_data" @pagination-change-page="getBatches"></pagination>

    <b-alert v-else show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "BatchesOverview",
  data(){
    return {
      batches: [],
      page_data: {},
      batch:{},
      loading: false,
    }
  },
  methods: {
    cancel() {
      this.$bvModal.hide('new-batch')
      this.batch = {}
    },
    createBatch(){
      api.batch().createBatch(this.batch.name).then((batch) => {
        this.cancel();
        if(this.$can('batch_edit')) {
          this.$router.push({
            name: 'BatchDetails',
            params: {
              id: batch.unique_id
            }
          })
        }
        else {
          this.$store.commit('success', this.$t('batch_created'));
          this.getBatches();
        }
      })
    },
    getBatches(page = 1) {
      api.batch().getBatches(page).then((batches) => {
        this.batches = batches.data
        this.page_data = batches
      })
    },
  },
  created() {
    document.title = this.$t('nav_batches')
    if(this.$store.state.user) {
      if(!this.$can('batch_overview_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.getBatches();
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('batch_overview_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
