export default class BoxOfficeEvent {

    constructor(axios) {
        this.axios = axios
    }

    all() {
        return this.axios.get('/box-office/events')
    }

    get(id) {
        return this.axios.get('/box-office/event/' + id)
    }

    boxOffices(id) {
        return this.axios.get('/box-office/event/' + id + '/box-office')
    }

    boxOffice(eventId, slug) {
        return this.axios.get('/box-office/event/' + eventId + '/box-office/' + slug)
    }
}
