<template>
  <div v-if="event">
    <div >
      <b-alert v-if="this.event && this.event.isArchived" variant="danger" show class="text-center">
        {{ $t('event_is_archived') }}
      </b-alert>
      <div class="d-flex flex-wrap" v-if="event">
        <div class="bigDiv px-0 pr-lg-3  pl-lg-0 order-2 order-lg-1 mb-3">
          <div class="margB content-main-container container-distance">
            <div class="title-container">{{ $t('event_manage_title', event) }}</div>
            <div class="content-container">
              <b-row>
                <b-col cols="12" lg="8">
                  <b-form-group :label="$t('event_name')">
                    <b-form-input v-model="event.name" :state="event.name && event.name.length > 64 ? false : null" aria-describedby="event-input-feedback" :disabled="isDisabled"></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{ $t('event_name_64_char') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-group :label="$t('event_event_type')">
                    <b-select v-model="event.event_type_id" text-field="name" value-field="id" :options="eventTypes" :disabled="isDisabled"></b-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" lg="6" xl="4">
                  <b-form-group :label="$t('event_status')">
                    <b-select v-model="event.status" :options="['draft', 'live', 'sold-out', 'archived']" :disabled="isDisabled"></b-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6" xl="4">
                  <b-form-group :label="$t('event_ticket_template')">
                    <b-select v-model="event.ticket_template_id" text-field="name" value-field="id" :options="templates" :disabled="isDisabled"></b-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6" xl="4">
                  <b-form-group :label="$t('event_minimum_age')">
                    <b-form-input v-model="event.minimum_age" :disabled="isDisabled"></b-form-input>
                  </b-form-group>
                  <b-form-group v-if="event.minimum_age && event.minimum_age > 0 && $can('event_show_minimum_age_checkbox')">
                    <b-checkbox v-model="event.age_confirmation_checkbox" :disabled="isDisabled">{{ $t('event_age_confirmation_form', {age: event.minimum_age}) }}</b-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mb-1 mb-md-0" cols="12">
                  <b-form-group>
                    <b-form-checkbox v-model="event.continuous" :disabled="isDisabled">{{ $t('event_continuous') }}</b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <template v-if="!event.continuous">
                <date-time-picker v-model="event.starts_at" :label="$t('event_starts_at')" :disabled="isDisabled"></date-time-picker>
                <date-time-picker v-model="event.ends_at" :label="$t('event_ends_at')" :disabled="isDisabled"></date-time-picker>
              </template>
              <b-form-group>
                <label class="col-form-label pt-0 mb-2">{{ $t('event_description') }}</label> <b-button @click="editingDescription = true" size="sm"><b-icon icon="pencil-square"/> {{ $t('edit') }}</b-button>
                <p v-if="!editingDescription" v-html="event.description"></p>
                <!-- <b-textarea v-model="event.description" rows="6"></b-textarea> -->
                <editor v-if="editingDescription" v-model="event.description" api-key='yhb5vrai2v53htowrumua3m9xlqk16dkgrnb937iuobfwstr' :init="editorData" :disabled="isDisabled"></editor>
              </b-form-group>

              <b-row>
                <b-col>
                  <b-form-group v-if="event.description" :label="$t('event_description_location')">
                      <b-select v-model="event.description_location" text-field="name" value-field="id" :disabled="isDisabled">
                        <option
                            v-for="option in description_location_options"
                            :value="option.value"
                            :key="option.value">
                            {{ option.text }}
                        </option>
                      </b-select>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group v-if="$can('add_location_event_details')" :label="$t('event_location')">
                    <v-select ref="venuesdropdownlist" v-model="venueObj" label="name" id="venues-dropdownlist" :options="venuesData" :disabled="isDisabled" @input="handleVenuesOptionChange">
                      <li v-if="$can('venue_create')" slot="list-footer">
                        <b-button class="new-venue-button" @click="handleVenuesOptionChange">{{ $t('new_venue') }}</b-button>
                      </li>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-show="showNewVenuePanel">
                <b-col>
                <b-form-group :label="$t('venue_name')">
                  <b-form-input v-model="venue.name"></b-form-input>
                </b-form-group>
                </b-col>
                <b-col>
                <b-form-group :label="$t('venue_address')">
                  <b-form-input v-model="venue.address"></b-form-input>
                </b-form-group>
                </b-col>
              </b-row>
              <b-row v-show="showNewVenuePanel">
                <b-col>
                <b-form-group :label="$t('venue_zip')">
                  <b-form-input v-model="venue.zip"></b-form-input>
                </b-form-group>
                </b-col>
                <b-col>
                <b-form-group :label="$t('venue_city')">
                  <b-form-input v-model="venue.city"></b-form-input>
                </b-form-group>
                </b-col>
              </b-row>
              <b-row v-show="showNewVenuePanel">
                <b-col>
                <b-form-group :label="$t('venue_country')">
                  <CountrySelection v-model="venue.country_code"></CountrySelection>
                </b-form-group>
                </b-col>
                <b-col></b-col>

              </b-row>

              <b-row>
                <b-col>
                  <b-tooltip target="is-multi-day-ticket-tooltip" triggers="hover">
                    {{ $t("manage_event_image_tooltip") }}
                  </b-tooltip>

                  <label class="col-form-label">{{ $t('manage_event_image') }}</label>
                  <b-icon id="is-multi-day-ticket-tooltip" v-b-tooltip.hover class="ml-2" icon="info-circle-fill"></b-icon>
                  <br/>
                  <b-img class="event-image" v-if="event.image" fluid :src="event.image_url"></b-img><br/><br/>
                  <b-form-group>
                    <input type="file" id="image" accept="image/*" ref="image" v-on:change="onImageChange()" :disabled="isDisabled"/>
                  </b-form-group>
                  <b-form-group v-if="event.image">
                    <b-checkbox v-model="event.show_image_in_pos" :disabled="isDisabled">{{ $t('event_show_image_in_pos') }}</b-checkbox>
                  </b-form-group>
                  <a v-if="event.image && !isDisabled" @click="removeProductImage" href="javascript:" class="d-block font-14">
                    {{ $t('manage_event_remove_image') }}
                  </a>
                </b-col>
                <b-col>
                  <b-tooltip target="pos-banner-tooltip" triggers="hover">
                    {{ $t("event_banner_tooltip") }}
                  </b-tooltip>

                  <label class="col-form-label">{{ $t('event_banner_image') }}</label>
                  <b-icon id="pos-banner-tooltip" v-b-tooltip.hover class="ml-2" icon="info-circle-fill"></b-icon>
                  <br/>
                  <b-img class="event-image" v-if="event.banner" fluid :src="event.banner_url"></b-img><br/><br/>
                  <b-form-group>
                    <input type="file" id="banner" accept="image/*" ref="banner" v-on:change="onBannerChange()" :disabled="isDisabled"/>
                  </b-form-group>
                  <span v-if="bannerError" class="text-danger d-block">{{ $t(bannerError) }}</span>
                  <a v-if="event.banner && !isDisabled" @click="removeBanner" href="javascript:" class="d-block font-14">
                    {{ $t('manage_event_remove_banner') }}
                  </a>
                </b-col>
              </b-row>
            </div>
          </div>
          <b-spinner v-if="saving" show variant="primary" class="text-right"></b-spinner>
          <b-form-group v-else class="text-right">
            <b-button v-if="$can('event_details_edit')" variant="save" @click="saveEvent()" :disabled="event.isArchived || (event.name && event.name.length > 64)">{{ $t('save') }}</b-button>
          </b-form-group>
        </div>
        <div class="SmallDiv px-0 pl-lg-3 pr-lg-0 order-1 order-lg-2 mb-1 mb-lg-1 marginTop0">
          <event-nav v-if="event && event.name" :event="event"></event-nav>
        </div>
      </div>

      <b-row v-else>
        <b-spinner></b-spinner>
      </b-row>
    </div>
  </div>
</template>

<script>
import api from '../api'
import DateTimePicker from "@/components/DateTimePicker";
import EventNav from "@/components/EventNav";
import moment from "moment";
import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
import CountrySelection from "../components/CountrySelection.vue";

export default {
  components: {
    CountrySelection,
    DateTimePicker,
    EventNav,
    Editor
  },
  name: 'ManageEvent',
  data() {
    return {
      saving: false,
      event: {},
      venue: {},
      venueObj: {
        id: null,
        name: null
      },
      templates: [],
      eventTypes: [],
      venuesData: [],
      showNewVenuePanel: false,
      editorData: {
        plugins: 'lists link image table code help wordcount',
        toolbar: 'bold italic underline | forecolor fontsizeselect  | numlist bullist | undo redo | alignleft aligncenter alignright',
        menubar: false 
      },
      description_location_options: [
        {value: 'top', text: this.$t('manage_event_desc_location_top')},
        {value: 'bottom', text: this.$t('manage_event_desc_location_bot')}
      ],
      bannerError: null,
      editingDescription: false,
    }
  },
  created() {
    document.title = this.$t('backend_title_events_manage')
    if(this.$store.state.user) {
      if(!this.$can('event_details_view') && !this.$can('event_details_edit')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.refreshVenuesList()
    api.event().get(this.$route.params.id).then(event => {
      this.event = {...event,isArchived :( moment(event.ends_at).isBefore(moment()) && !event.continuous) || event.status === "archived"}
      this.venueObj = this.venuesData.filter((item) => {
        return (item.id === this.event.venue_id)
      })
    })
    api.ticket().getTemplates().then((templates) => {
      this.templates = templates
    })
    api.event().getEventTypes().then((eventTypes) => {
      this.eventTypes = eventTypes
    })
  },
  methods: {
    handleVenuesOptionChange(val) {
      if (val.id === undefined) {
        this.showNewVenuePanel = true
        this.event.venue_id = 'new'
        this.venueObj = {
          id: null,
          name: null
        }
        const searchEl = this.$refs.venuesdropdownlist.searchEl
        if (searchEl) {
          searchEl.blur()
        }
      } else {
        this.showNewVenuePanel = false
      }
    },
    refreshVenuesList() {
      api.venues().getVenuesNames().then((venues) => {
        this.venuesData = venues
      })
    },
    saveEvent() {
      this.event.venue_id = (this.venueObj === null || this.venueObj.id === undefined || this.venueObj.id === null) ? this.event.venue_id : this.venueObj.id
      this.saving = true
      this.event.venue = this.venue
      api.event().save(this.event).then((event) => {
        this.event = event
        this.showNewVenuePanel = false
        this.$store.commit('success', 'event_updated')

        if (this.venue.name !== undefined) {
          this.venuesData.push({
            id: this.event.venue_id,
            name: this.venue.name
          })
        }
        this.venue = {}
        this.venueObj = this.venuesData.filter((item) => {
          return (item.id === this.event.venue_id)
        })
        this.refreshVenuesList()
      }).finally(() => {
        this.saving = false
      })
    },
    onImageChange() {
      this.saving = true;
      api.cdn().signUpload(this.$refs['image'].files[0].name).then((response) => {
        // Upload the file
        axios.create().put(response.upload_url, this.$refs['image'].files[0], {
          headers: {
            'Content-Type': this.$refs['image'].files[0].type
          }
        }).catch((e) => {
          // Upload failed...
          console.error(e)
          this.saving = false;
        }).then(() => {
          // upload done, create the video in the database
          this.event.image = response.path
          this.event.image_url = response.target_url
          this.saving = false;
        })
      })
    },
    onBannerChange() {
      this.bannerError = null;
      const img = document.createElement('img');

      const selectedImage = this.$refs['banner'].files[0];

      const objectURL = URL.createObjectURL(selectedImage);

      if(selectedImage.size > (1024 * 1024)) {
        this.bannerError = 'manage_event_banner_error_size';
        return;
      }

      img.onload = () => {
        if (img.width !== 855 || img.height !== 220) {
          this.bannerError = 'manage_event_banner_error_res';
          return;
        }

        URL.revokeObjectURL(objectURL);

        this.saving = true;
        api.cdn().signUpload(this.$refs['banner'].files[0].name).then((response) => {
          // Upload the file
          axios.create().put(response.upload_url, this.$refs['banner'].files[0], {
            headers: {
              'Content-Type': this.$refs['banner'].files[0].type
            }
          }).catch((e) => {
            // Upload failed...
            console.error(e)
            this.saving = false;
          }).then(() => {
            // upload done, create the video in the database
            this.event.banner = response.path
            this.event.banner_url = response.target_url
            this.saving = false;
          })
        })

      };

      img.src = objectURL;
    },
    removeProductImage() {
      this.saving = true;
      api.cdn().deleteUpload(this.event.image).then(() => {
        this.event.image = null;
        this.event.image_url = null;
        this.saving = false;
        this.saveEvent();
      }).catch(() => {
        this.saving = false;
      });
    },
    removeBanner() {
      this.saving = true;
      api.cdn().deleteUpload(this.event.banner).then(() => {
        this.event.banner = null;
        this.event.banner_url = null;
        this.saving = false;
        this.saveEvent();
      }).catch(() => {
        this.saving = false;
      });
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('event_details_view') && !this.$can('event_details_edit')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  },
  computed: {
    isDisabled() {
      if(!this.$store.state.user) {
        return false;
      }
      return !this.$can('event_details_edit') ;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .container-distance,.marginTop0 >div{
    margin-top: 0!important;
  }
img.event-image{
  max-width:100%;
 width:350px;
}

@media (max-width:992px) {
  .margB{
    margin-top: 1.5rem!important;
  }
}
@media (max-width:576px) {
  
img.event-image{
  min-width:100%;
}
}
  .new-venue-button {
    margin-top: 5px;
    text-align: center;
    width: 100%;
    border-radius: 0;
  }
</style>
<style>

.text-muted{
  font-size: 12px;
}
#venues-dropdownlist .vs__dropdown-menu {
  max-height: 250px!important;
}
</style>
