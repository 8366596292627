<template>
  <div class="result" :class="currBackgroundColor">
    <b-container fluid>
      <div>

        <b-row class="show-top" id="scan-terminal-event" v-if="scannerTerminalUser">
          <b-col>{{ $t('scanner_terminal_event') }}: {{ scannerTerminalUser.event_name }}</b-col>
        </b-row>

        <b-form-group class="show-top">
          <b-form-input autocomplete="off" :disabled="disabledField" ref="scanField" id="scan-field" v-model="scanField" debounce="250"></b-form-input>
        </b-form-group>

        <div class="text-center" v-if="errorMessage">{{ $t(errorMessage) }}</div>
      </div>

      <div class="mt-5">
        <b-row>
          <b-col cols="12" class="text-center">
            <h3 :class="currBackgroundColor + ' font-weight-bold py-2'">{{ stats.scanned }}/{{ stats.total }}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6" sm="6">
        <span v-if="tickets &&  tickets.total >  tickets.per_page" :class="currBackgroundColor" class="font-weight-bold d-block mt-2">
          {{ $t('scanner_terminal_showing_of_entries', {per_page: tickets.per_page, total: stats.total}) }}
        </span>
          </b-col>
          <b-col cols="12" lg="6" sm="6" class="text-right">
            <div class="float-right"><b-button @click="getTickets(1)" class="btn-filter" variant="new" :disabled="loading">
              <b-icon v-if="loading" icon="three-dots" animation="cylon" class="mr-2"></b-icon> {{ $t('scanner_terminal_filter_btn') }}
            </b-button></div>
            <div class="float-right mr-2" style="width: 200px;">
              <b-form-input autocomplete="off" class="v-text-field" v-model="search" @keyup="lastScreenAction = new Date()" @keyup.enter="getTickets(1)" debounce="750" :placeholder="$t('scanner_terminal_search')"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-table v-if="ticketData.length" class="orderTable scanner-terminal-tickets-table mt-3" responsive :fields="logsFields" :items="ticketData">
          <template v-slot:cell(product_name)="{item}">
            {{ item.group_name !== 'event_default_group' ? `${item.group_name} - ` : '' }} {{ item.product_name }}
          </template>
          <template v-slot:cell(is_scanned)="{item}">
            <div class="text-center">
              <b-icon v-if="item.is_scanned" icon="check-circle-fill" font-scale="2" variant="success"></b-icon>
              <b-button v-else :disabled="item.loading" @click="checkIn(item)"><b-icon v-if="item.loading" icon="arrow-clockwise" animation="spin"></b-icon> {{ $t('scanner_terminal_btn_check_in') }}</b-button>
            </div>
          </template>
        </b-table>
        <h3 v-else class="text-center">{{ $t('no_data_to_show') }}</h3>
        <pagination v-if="tickets" :limit="3" :data="tickets" @pagination-change-page="getTickets"></pagination>
      </div>
    </b-container>

  </div>
</template>

<script>
import api from '../api/scannerTerminalApi'
import {mapState, mapActions} from 'vuex'
export default {
  name: "ScannerTerminalScan",
  data() {
    return {
      disabledField: false,
      scanField: null,
      errorMessage:'',
      lastScanResultColor: '',
      currBackgroundColor: '',
      ticketData: [],
      tickets: null,
      logsFields: [
        {key: 'order_number', label: this.$t('order_number')},
        {key: 'secret', label: this.$t('ticket_secret')},
        {key: 'product_name', label: this.$t('report_ticket_type')},
        {key: 'email', label: this.$t('email')},
        {key: 'customer_name', label: this.$t('order_full_name')},
        {key: 'is_scanned', label: this.$t('product_status')},
      ],
      loading: false,
      search: null,
      lastScreenAction: false,
      isScreenActiveInterval: null,
      stats: {
        total: 0,
        scanned: 0,
        toScan: 0
      }
    }
  },
  watch: {
    scanField(newValue) {
      if (newValue.length === 0) {
        return
      }
      this.startScan()
    }
  },
  methods: {
    startScan(){

      // Disable new input
      this.disabledField = true

      // Check if input is value
      let go = true
      let lastClean = true
      let input = ''
      try {
        input = JSON.parse(this.scanField)
      } catch (e) {
        this.setScanResult(false)
        this.setErrorMessage('Invalid ticket')
        go = false
      }

      // If we have the correct values make the API call
      if(go){
        if(input.id && input.secret){
          lastClean = false
          api.scan().scan( this.$route.params.event, input ).then(result => {
            if( result && result['error'] ){
              this.setErrorMessage(result['message'])
              this.setScanResult(false)
            } else {
              this.setScanResult(true)
              this.setErrorMessage('')
            }
            this.allowInput()
            this.cleanField()
            this.loadStats();
          })
        } else {
          this.setScanResult(false)
          this.setErrorMessage('Invalid ticket')
        }
      }

      if(lastClean === true){
        this.cleanField()
        this.allowInput()
      }
    },
    allowInput(){
      this.disabledField = false
      this.$nextTick(() => {
        this.$refs.scanField.focus()
      })
    },
    setScanResult(result){
      this.setBackgroundColor(result)
    },
    setErrorMessage(message){
      this.errorMessage = message
    },
    setBackgroundColor(result){
      if(result){
        if(this.lastScanResultColor == '' || this.lastScanResultColor == 'redColorOne' || this.lastScanResultColor == 'redColorTwo' || this.lastScanResultColor == 'greenColorTwo'){
          this.currBackgroundColor = 'greenColorOne'
          this.lastScanResultColor = 'greenColorOne'
        } else if(this.lastScanResultColor == 'greenColorOne'){
          this.currBackgroundColor = 'greenColorTwo'
          this.lastScanResultColor = 'greenColorTwo'
        }
      } else {
        if(this.lastScanResultColor == '' || this.lastScanResultColor == 'greenColorOne' || this.lastScanResultColor == 'greenColorTwo' || this.lastScanResultColor == 'redColorTwo'){
          this.currBackgroundColor = 'redColorOne'
          this.lastScanResultColor = 'redColorOne'
        } else if(this.lastScanResultColor == 'redColorOne'){
          this.currBackgroundColor = 'redColorTwo'
          this.lastScanResultColor = 'redColorTwo'
        }
      }
    },
    cleanField(){
      this.scanField = ''
      document.getElementById('scan-field').value = ''
    },
    setFocus(){
      this.$refs.scanField.focus()
    },
    getTickets(page = 1) {
      this.loading = true;
      api.scan().tickets(this.$route.params.event,page, this.search).then(response => {
        this.ticketData = response.data.map(t => {
          return Object.assign(t, {loading: false})
        });
        this.tickets = response;
        this.loading = false;
        this.setFocus();
      }).catch(() => {
        this.loading = false;
      })
    },
    checkIn(ticket) {
      ticket.loading = true;
      api.scan().scan( this.$route.params.event, {id: ticket.ticket_id, secret: ticket.secret} ).then(result => {
        if( result && result['error'] ){
          this.setBackgroundColor(false);
          this.$store.commit('errors', [result['message']]);
        } else {
          this.setBackgroundColor(true);
          this.$store.commit('success', 'Checked in successfully');
          ticket.is_scanned = true;
          this.getTickets(1)
        }
        this.loadStats();
        this.setFocus();
        ticket.loading = false;
      }).catch(() => {
        ticket.loading = false;
      })
    },
    loadStats() {
      api.scan().stats(this.$route.params.event).then(data => {
        this.stats.total = data.total;
        this.stats.scanned = data.scanned;
        this.stats.toScan = data.to_scan;
      })
    },
    ...mapActions(['loadScannerTerminalUser'])
  },
  computed: {
    ...mapState(['scannerTerminalUser', 'success', 'errors', 'language'])
  },
  mounted() {
    if(!this.$route.params.event) {
      this.$router.push({name: 'ScannerTerminalEvents'})
      return;
    }
    this.loadScannerTerminalUser()
    this.getTickets();
    this.loadStats();
    document.onmouseup = () => {
      this.lastScreenAction = new Date();
    }
    this.isScreenActiveInterval = setInterval(() => {
      if(Math.abs((new Date()) - this.lastScreenAction) > 5000) {
        this.setFocus();
      }
      if(this.$can('scan_terminal_ticket_overview')) {
        this.loadStats();
      }
    }, 5000)
    addEventListener('paste', (event) => {
      this.scanField = (event.clipboardData || window.clipboardData).getData('text').toString();
    });
  },
  destroyed() {
    document.onmousemove = () => {}
    clearInterval(this.isScreenActiveInterval);
    try {
      removeEventListener('paste')
    }
    catch (e) {
      //
    }
  }
}
</script>

<style scoped>
.default-container-layout {
  width: 100%;
  color: #808080;
  font-weight: bold;
  font-size: 18px;
  border: none;
  background-color:#FFF;
  border:0px
}
h3{
  font-size:38pt
}
.show-top{
  position:relative;
  z-index:1000
}
.result {
  padding: 1rem !important;
  min-height: 90vh;
}
.result.greenColorOne{
  background-color:#15b000a2
}
.result.greenColorTwo{
  background-color:#75ff63a2
}
.result.greenColorOne div, .result.greenColorTwo div, .greenColorTwo span, .greenColorOne span, h3.greenColorOne, h3.greenColorTwo{
  color:#FFF;
}
.result.redColorOne{
  background-color:#a3003da2
}
.result.redColorTwo{
  background-color:#ff96bda2
}
.result.redColorOne div, .result.redColorTwo div, .redColorOne span, .redColorTwo span, h3.redColorOne, h3.redColorTwo{
  color:#FFF;
}
.btn-filter {
  height: 50px;
}
table.b-table tbody tr:hover {
  background-color: #FFF !important;
}
</style>
