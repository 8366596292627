<template>
  <div id="login">
    <b-row align-v="center">
      <div id="login-wrap">
        <b-alert variant="warning" show>{{ $t('password_reset_user_should_reset_password') }}</b-alert>
        <b-form-group>
          <b-form-input :placeholder="$t('login_password')" v-model="password" :state="inputState" type="password" name="password"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input :placeholder="$t('password_confirmation')" v-model="passwordConfirmation" :state="inputState" type="password" name="password_confirmation"></b-form-input>
          <b-form-invalid-feedback :state="inputState">
            {{ $t('password_not_match') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <b-button block class="float-right" variant="primary" @click="save" :disabled="loading"><b-icon v-if="loading" icon="three-dots" animation="cylon" class="mr-2"></b-icon>{{ $t('save') }}</b-button>
        </b-form-group>
      </div>
    </b-row>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "UserResetPassword",
  data() {
    return {
      password: null,
      passwordConfirmation: null,
      loading: false,
    }
  },
  methods: {
    save() {
      this.loading = true;
      api.user().resetPassword(this.password, this.passwordConfirmation).then(user => {
        this.$store.commit('setUser', user);
        this.$store.commit('success', this.$t('password_reset_successfully'));
        this.loading = false;
        this.$router.push({name: 'Dashboard'})
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  computed: {
    inputState() {
      if(this.password && this.passwordConfirmation) {
        return this.password === this.passwordConfirmation;
      }
      return null;
    }
  }
}
</script>

<style @scoped lang="scss">
#login, #login > .row {
  height: 100%;
}
#login-wrap {
  background: #193E58;
  padding: 50px;
  legend {
    color:white;
  }
  img {
    max-width: 100%;
  }
}

#login-wrap {
  margin: auto;
}

@media only screen and (max-width: 700px) {
  #login-wrap {
    width: 90%;
  }
}

@media only screen and (min-width: 701px) {
  #login-wrap {
    width: 450px;
  }
}
</style>
