<template>
  <p class="mt-4">
    {{ $t('order_expires_in', {timeLeft}) }}
  </p>
</template>
<script>
import moment from "moment";

export default {
  props: ['order'],
  data() {
    return {
      timeLeft: null,
      timeout: null
    }
  },
  methods: {
    updateTimer() {
      let diffSeconds = moment(this.order.expires_at).diff(moment(), 'seconds')
      let minutes = Math.floor(diffSeconds / 60)
      let seconds = diffSeconds - minutes * 60

      if (diffSeconds <= 0) {
        this.$store.commit('errors', ['order_expired']);
        return;
      }

      if (seconds < 10) {
        seconds = '0' + seconds
      }

      this.timeLeft = minutes + ':' + seconds
      this.timeout = setTimeout(this.updateTimer, 1000)
    }
  },
  created() {
    this.updateTimer()
  }
}
</script>
