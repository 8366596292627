<template>
  <div>
    <div class="mb-3">
      <b-badge variant="success" class="mr-1">SOLD</b-badge>
      <b-badge variant="warning" class="mr-1">IN REDIS</b-badge>
      <b-badge variant="info">POS STOCK</b-badge>
    </div>
    <b-row v-for="product in products" :key="product.id">
      <b-col>
        <p>{{ product.product }}</p>
      </b-col>
      <b-col>
        <b-progress class="mt-2" :max="product.product_stock" show-value>
          <b-progress-bar :value="product.pos_stock" variant="info"></b-progress-bar>
        </b-progress>
      </b-col>
      <b-col>
        <b-progress>
          <b-progress-bar :value="product.sold" variant="success"></b-progress-bar>
          <b-progress-bar :value="product.in_redis" variant="warning"></b-progress-bar>
        </b-progress>
      </b-col>
    </b-row>
    <b-table :items="products" :fields="[{key: 'pos', label: $t('pos_stock_pos')},{key: 'product', label: $t('pos_stock_product')},{key: 'product_stock', label: $t('pos_stock_product_stock')},{key: 'in_redis', label: $t('pos_stock_in_redis')},{key: 'pos_stock', label: $t('pos_stock_pos_stock')},{key: 'sold', label: $t('pos_stock_sold')}]"></b-table>
  </div>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      products: []
    }
  },
  computed: {
    ...mapState(['user'])
  },
  created() {
    if(this.$store.state.user) {
      if(!this.$can('stock_report_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.report().getStockReport().then((products) => {
      this.products = products
    })
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('stock_report_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
