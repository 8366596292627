export default class discountcodeApi {

    constructor(axios) {
        this.axios = axios
    }

    all(page, search) {
        return this.axios.get('/discountcode?page=' + page + (search && search.length ? ('&search='+search) : ''))
    }
    
    create(discountcode) {
        return this.axios.post('/discountcode', discountcode)
    }

    save(discountcode) {
        return this.axios.post('/discountcode/' + discountcode.id, discountcode)
    }

    getByOrder(orderId, orderSecret, code) {
        return this.axios.post('/order/' + orderId + '/' + orderSecret + '/discountcode', {code})
    }

    delete(discountcode) {
        return this.axios.delete('/discountcode/' + discountcode)
    }

}
