<template>
  <div>
    <b-row>
      <b-col v-if="$can('master_data_default_values_layout_view')" lg="4" sm="7" cols="12">
        <router-link :to="{name: 'DefaultCompanyLayout'}"  class="info-card center text-center card">{{ $t('default_company_layout') }}</router-link>
      </b-col>
      <b-col v-if="$can('master_data_default_values_fees_view')" lg="4" sm="7" cols="12">
        <router-link :to="{name: 'DefaultCompanyFees'}"  class="info-card center text-center card">{{ $t('default_company_fees') }}</router-link>
      </b-col>
      <b-col v-if="$can('master_data_default_values_permissions_view')" lg="4" sm="7" cols="12">
        <router-link :to="{name: 'DefaultCompanyPermissions'}"  class="info-card center text-center card">{{ $t('default_company_permissions') }}</router-link>
      </b-col>
      <b-col v-if="$can('master_data_default_values_ticket_template_view')" lg="4" sm="7" cols="12">
        <router-link :to="{name: 'DefaultCompanyTicketTemplate'}"  class="info-card center text-center card">{{ $t('default_company_ticket_template') }}</router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "DefaultCompanySettings",
  created() {
    document.title = this.$t('default_account_setup')
    if(this.$store.state.user) {
      if(!this.$can('master_data_default_values_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('master_data_default_values_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
