<template>
      <b-row class="flexing PosProduct Myyelement mb-3">
        <b-col cols="12"  class="myelement">
          <b-form-checkbox switch v-model="product.is_enabled" :ref="'p' + product.id + 'check-box'"  class="mySwitch" @input="$emit('input', product)">{{product.name}}</b-form-checkbox>
          <!-- <b-form-group :label="product.name" label-cols="6" style="width: 100%;"> -->
          <b-form-group  label-cols="2" class="myInput">
          <b-input-group style="height: 45px;" class="inputt">
            <b-form-input type="number" style="height: 45px; "
                          @input="enableProduct"
                          :min="0"
                          :step="1"
                          :max="parseInt(product.stock) === -1 ? 100000000000 :product.stock"
                          v-model.number="product.pos_stock" />
          <b-input-group-append>
              <b-input-group-text>{{ '/' + (parseInt(product.stock) === -1 ? $t('product_stock_unlimited_icon') : product.stock) }}</b-input-group-text>
              <b-form-checkbox class="my-auto ml-2" v-on:change="handleUnlimitedClicked" :disabled="product.stock !== -1" v-model="product.pos_stock" value="-1" :unchecked-value="1">{{$t('product_stock_unlimited_text')}}</b-form-checkbox>
          </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <div class="flexTop" v-if="sortable">
          <b-button variant="link" class="product-handle">
            <b-icon icon="arrows-move"></b-icon>
          </b-button>
        </div>
        </b-col>
         <!-- <b-col cols="2" lg="1" class="flexTop" v-if="sortable">
          <b-button variant="link" class="product-handle">
            <b-icon icon="arrows-move"></b-icon>
          </b-button>
        </b-col> -->
      </b-row>
</template>
<script>
export default {
  props: ['value', 'sortable'],
  data() {
    return {
      product: {}
    }
  },
  watch: {
    value() {
      this.product = this.value
    },
    product() {
      this.$emit('input', this.product)
    }
  },
  mounted() {
    this.product = this.value
  },
  methods:{
    handleUnlimitedClicked(value){
      this.product = Object.assign({}, this.product, {
        pos_stock: value
      })
      this.product.is_enabled = true;
    },
    enableProduct() {
      if(this.product.pos_stock !== 0){
        this.product.is_enabled = true;
        this.$refs['p' + this.product.id + 'check-box'].localChecked = true;
        this.$forceUpdate();
      }
      this.$emit('input', this.product)
    }
  }
}
</script>
<style scoped>
 
  .mySwitch{
    width: 55%;
  }
  .myInput{
    width: 45%;
  }
  .myelement{
    display: flex;
    align-items: center;
  }
.flexing{
  align-items: center;
}
.form-group{
  margin-bottom: 0px;
}
.input-group-text{
  font-size: 15px;
}

@media (max-width:992px) {
.inputt{
  margin: 0;
}
  .mySwitch{
    width: 100%;
    margin-bottom: 1rem;
    margin-top: .5rem;
  }
  .myInput{
    width: 100%;
  }
  .myelement{
    display: block;
    position: relative;
  }
  .myelement .flexTop{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
  }
  .Myyelement{
  padding: 10px 0px;
  width: 100%;
  border: 1px solid #ddd;
  margin: auto;
  background: #F5F5F5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 10px;
}
.Myyelement div{
  font-size: 13px;
}
}
</style>
<style>
 .myelement .col-form-label{
    display: none!important;
  }
</style>
