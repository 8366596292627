<template>
  <b-row>
    <b-col>
      <b-form-group :label="label">
        <b-input-group>
          <b-form-input
              :state="state"
              v-model="date"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
                v-model="date"
                button-only
                right
                start-weekday="1"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import moment from 'moment'
export default {
  props: ['label', 'value', 'disableTime', 'state'],
  data() {
    return {
      date: '',
      dateTime: null,
      debounceTimeout: null,
      loaded: false,
      valid: null
    }
  },
  watch: {
    date() {
      this.$emit('input', this.date)
    },
    value () {

      if(!this.value){
        return;
      }

      this.dateTime = moment(this.value)
      if(!this.loaded && this.dateTime.isValid()) {
        this.date = this.dateTime.format('YYYY-MM-DD')
        this.loaded = true
        return
      }
      if(this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        if(this.dateTime.isValid()){
          this.valid = null
          this.date = this.dateTime.format('YYYY-MM-DD')
        } else {
          this.valid = false
        }
      }, 1000)
    }
  },
  created() {
    if(this.value) {
      this.dateTime = moment(this.value)
      if (!this.loaded && this.dateTime.isValid()) {
        this.date = this.dateTime.format('YYYY-MM-DD')
        this.loaded = true
      }
    }
  }
}
</script>
