export default class BoxOfficeUser {

    constructor(axios) {
        this.axios = axios
    }

    login(email, password) {
        return this.axios.post('/box-office/login', {email, password})
    }

    me() {
        return this.axios.get('/box-office/user')
    }
}
