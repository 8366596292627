<template>
  <div>
    <b-card class="info-card">
      <b-card-body>
        <b-row v-if="invoice" class="Parent">
          <b-col><h3>{{ $t('administration_invoice_number', {number: invoice.invoice_number}) }}</h3></b-col>
          <b-col>
            <b-form-group v-if="$can('invoice_details_create_credit_invoice')" class="text-right container-distance">
              <b-button variant="new">{{ $t('invoice_details_create_credit_invoice') }}</b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "InvoiceDetails",
  data(){
    return {
      invoice: null,
    }
  },
  mounted() {
    this.invoice = {
      invoice_number: this.$route.params.number
    }
  }
}
</script>

<style scoped>

</style>
