<template>
  <b-container>
    <b-row>
      <b-col class="content-block cart-container text-center pr-5 pl-5">
        <div class="pt-5 pb-5">
          <b-icon icon="check-circle-fill" font-scale="6" variant="success"></b-icon>
        </div>
        <h1 class="title">{{ $t('order_thank_you', {order_number: order_number}) }}</h1>
        <p class="subtitle m-5">{{ $t('order_payment_pending') }}</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'

export default {
  components: {},
  data() {
    return {
      tickets: null,
      assignSelf: true,
      order_number: ''
    }
  },
  methods: {
    assignTickets() {
      let inviteRequests = this.tickets.filter((ticket) => {
        return ticket.invite
      }).map((ticket) => {
        return api.ticket().assign(ticket.id, ticket.secret, ticket.customer.invite_email)
      })

      if (inviteRequests.length === 0) {
        // no tickets to send invites for
        this.$router.push({
          name: 'AssignTickets',
          params: this.$route.params
        })
        return
      }
      Promise.all(inviteRequests).then(() => {
        this.$store.commit('success', 'tickets_assigned_email_sent')
        this.$router.push({
          name: 'AssignTickets',
          params: this.$route.params
        })
      }).catch((error) => {
        this.$store.commit('errors', error.response.body)
      })
    }
  },
  created() {
    this.$store.commit('clearCart')
    document.title = this.$t('order_view_order_details_title')
    if(this.$route.query.order_number) {
      this.order_number = this.$route.query.order_number;
    }
    if(!localStorage.getItem('ft_lang')) {
      api.pos().getByOrderIdAndSecret(this.$route.params.id, this.$route.params.secret).then(pos => {
        if (pos) {
          if(pos.language){
            this.$store.commit('setLanguage', pos.language)
          }
        }
      })
    }

    api.order().getTickets(this.$route.params.id, this.$route.params.secret).then(tickets => {
      this.tickets = tickets
    })
  }
}
</script>
<style scoped>

.product-details {
  padding: 0.5em;
}

.product-details h2 {
  font-size: 1em;
  line-height: 50px;
}

.product-details img {
  max-width: 50px;
  max-height: 50px;
}

@media only screen and (max-width: 360px) {
  .full-width-button{
    width:100%
  }
  .full-width-button:first-of-type{
    margin-bottom:1rem
  }
}
</style>
