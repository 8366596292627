<template>
<div>
  <div>
    <b-row>
      <b-col lg="12" md="12" sm="12" xl="6">
        <b-card >
          <b-card-title class="px-4">{{ $t('guest_list_add_attendees') }}</b-card-title>
          <b-card-body>
            <b-row class="px-4 small">
              <b-col class="mt-3">
                <span class="title">{{ $t('guest_list_event_name') }}</span>
                <b-form-group v-if="!event || evenEditMode" class="mt-2">
                  <v-select v-model="event" :options="events" label="name" @input="getProducts"></v-select>
                </b-form-group >
                <div v-if="event && !evenEditMode" class="mt-2"><span class="event-name">{{ event.name }} <b-icon @click="evenEditMode = true;event = null;" class="pointer-cursor ml-2 text-black-50" icon="pencil-fill" /></span></div>
              </b-col>
            </b-row>
            <b-row v-if="event" class="px-4 mt-2 mb-3">
              <b-col>
                <b-form-checkbox v-model="rememberEvent">{{ $t('guest_list_remember_event') }}</b-form-checkbox>
              </b-col>
            </b-row>

            <div v-if="event" class="py-3 px-4 small">
              <div class="customer-form">
                <span class="title">{{ $t('guest_list_customer') }}</span>
                <b-row class="mt-2">
                  <b-col sm="12" md="6" class="mb-2">
                    <b-form-input v-model="customer.first_name" @input="validateCustomerForm('first_name')" :state="validation.first_name.state" :placeholder="$t('customer_first_name')+'*'" ref="firstNameInp" />
                    <b-form-invalid-feedback :state="validation.first_name.state">
                      {{ $t(validation.first_name.message) }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col sm="12" md="6" class="mb-2">
                    <b-form-input v-model="customer.last_name" @input="validateCustomerForm('last_name')" :state="validation.last_name.state" :placeholder="$t('customer_last_name')+'*'" />
                    <b-form-invalid-feedback :state="validation.last_name.state">
                      {{ $t(validation.last_name.message) }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-input v-model="customer.email" @input="validateCustomerForm('email')" :state="validation.email.state" type="email" :placeholder="$t('email')+'*'" />
                    <b-form-invalid-feedback :state="validation.email.state">
                      {{ $t(validation.email.message) }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </div>

            </div>

            <div v-if="event" class="px-4 my-4">
              <span class="title">{{ $t('guest_list_select_products') }}</span>
              <b-row class="mt-4 border-bottom pb-2">
                <b-col sm="12" md="5" xl="6" class="font-weight-bold d-flex align-items-center sub-title">{{ $t('guest_list_ticket_type') }}</b-col>
                <b-col sm="12" md="3" xl="2" class="font-weight-bold text-right d-flex align-items-center sub-title justify-content-start justify-content-xl-end">{{ $t('guest_list_price') }}</b-col>
                <b-col sm="12" md="4" xl="4" class="font-weight-bold text-center d-flex align-items-center sub-title justify-content-end">{{ $t('guest_list_number') }}</b-col>
              </b-row>
              <div v-for="group in groups" :key="group.name">
                <label class="group-label d-block pl-3 pr-3 mb-0 font-weight-bold" v-if="group.name !== 'event_default_group'">{{ group.name }}</label>
                <b-row v-for="product in group.products" :key="product.id" class="py-2 border-bottom items">
                  <b-col sm="12" md="5" xl="6" class="d-flex align-items-center" :class="{'pl-5': group.name !== 'event_default_group'}">{{ product.name }}</b-col>
                  <b-col sm="12" md="3" xl="2" class="text-right d-flex align-items-center justify-content-start justify-content-xl-end">{{ product.price | currency }}</b-col>
                  <b-col sm="12" md="4" xl="4" class="text-center d-flex align-items-center justify-content-end">
                    <NumberInput
                        :min="0"
                        :max="product.current_stock"
                        v-model="product.amount"
                        v-if="product.current_stock !== 0"
                        :state="parseInt(product.amount) > product.current_stock ? false : null"/>
                    <span v-else class="text-danger d-block">{{ $t('pos_product_sold_out') }}</span>
                  </b-col>
                  <b-col cols="12" v-if="parseInt(product.amount) > product.current_stock" class="d-flex justify-content-end">
                    <span class="text-danger small" >{{ $t('pos_only_available_x', {amount: product.current_stock}) }}</span>
                  </b-col>
                </b-row>
              </div>
              <b-row class="py-4 d-block d-lg-flex">
                <b-col cols="12" class="text-md-right total">
                  <span class="font-weight-bold mr-4 mb-2 d-block d-md-inline">{{ $t('guest_list_total_worth') }} {{ total | currency }}</span> <span class="font-weight-bold">{{ $t('guest_list_total_items') }} {{ itemsCount }}</span>
                </b-col>
              </b-row>
              <b-row class="border-bottom"></b-row>

              <div class="mt-5">
                <span class="title">{{ $t('guest_list_what_next') }}</span>
                <div>
                  <b-button v-if="!isPersonalized" @click="updateTodo('send_all_tickets_to_order_owner')"
                            :class="{'btn-outline': toDoNext !== 'send_all_tickets_to_order_owner', 'btn-primary': toDoNext === 'send_all_tickets_to_order_owner'}"
                            class="mt-2 mr-3 btn-option">
                    {{ $t('guest_list_send_to_email', {email: customer.email}) }}
                  </b-button>
                  <b-button v-if="isPersonalized" @click="updateTodo('send_invite_to_claim_tickets')"
                            :class="{'btn-outline': toDoNext !== 'send_invite_to_claim_tickets', 'btn-primary': toDoNext === 'send_invite_to_claim_tickets'}"
                            class="mt-2 mr-3 btn-option">
                    {{ $t('guest_list_send_invitation_to_sign') }}
                  </b-button>
                  <b-button
                      @click="updateTodo('send_payment_link')"
                      :class="{'btn-outline': toDoNext !== 'send_payment_link', 'btn-primary': toDoNext === 'send_payment_link'}"
                      class="mt-2 btn-option">
                    {{ $t('guest_list_send_payment_request', {email: customer.email}) }}
                  </b-button>
                </div>
              </div>

              <div v-if="toDoNext !== 'send_payment_link'" class="mt-4">
                <span class="title">{{ $t('guest_list_select_payment') }}</span>
                <div>
                  <b-button
                      @click="payment = 'no_payment'"
                      :class="{'btn-outline': payment !== 'no_payment', 'btn-primary': payment === 'no_payment'}"
                      class="mr-3 mt-2 btn-option">{{ $t('guest_list_select_payment_free') }}</b-button>
                  <b-button
                      @click="payment = 'paid_cash'"
                      :class="{'btn-outline': payment !== 'paid_cash', 'btn-primary': payment === 'paid_cash'}"
                      class="btn-primary mt-2 btn-option">{{ $t('guest_list_select_payment_paid') }}</b-button>
                </div>
              </div>

              <b-form-group class="mt-5 float-right text-right">
                <b-button v-if="!loading && !isDone" @click="save" class="btn-primary" :disabled="itemsCount === 0 || moreThanStock || !customer.first_name || !customer.last_name || !customer.email">
                  {{ $t('guest_list_send') }}
                </b-button>
                <span v-if="!isDone && !itemsCount" class="text-danger d-block mt-2">{{ $t('guest_list_choose_tickets_to_send') }}</span>
                <span v-if="!isDone && (!customer.first_name || !customer.last_name || !customer.email)" class="text-danger d-block mt-2">{{ $t('guest_list_not_all_customer_details_filled') }}</span>
                <b-icon v-if="loading" icon="arrow-clockwise" animation="spin" font-scale="4"></b-icon>
                <span class="title d-block" v-if="loading"> {{ toDoNext === 'send_payment_link' ? $t('guest_list_payment_link_send') : $t('guest_list_order_ticket_generated') }}</span>
                <span class="title d-block" v-if="isDone">{{ toDoNext === 'send_payment_link' ? $t('guest_list_payment_link_send') : $t('guest_list_order_ticket_send_successfully') }}</span>
              </b-form-group>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
  <b-modal id="submit-order-success" hide-footer hide-header size="size-lg">
    <div class="py-5">
      <div class="py-4 text-center">
        <b-icon icon="check-circle" font-scale="7" style="color:#0b9f39"></b-icon>
      </div>
      <div class="text-center px-5">
        <h3 class="my-3 font-weight-bold title">{{ $t('guest_list_order_saved_successfully') }}</h3>
        <p>{{ $t('guest_list_order_saved_successfully_desc') }}</p>
      </div>
    </div>
  </b-modal>

</div>
</template>

<script>
import api from "@/api";
import {validateEmail} from "@/functions";
import NumberInput from '@/components/NumberInput.vue';

export default {
  name: "GuestListOverview",
  components: {NumberInput},
  data() {
    return {
      events: [],
      event: null,
      groups: [],
      populate: false,
      send_order_confirmation: false,
      step: 1,
      ticketsCustomerData: [],
      toDoNext: 'send_all_tickets_to_order_owner',
      payment: 'no_payment',
      customerFields: [],
      customerAnswers: {},
      loading: false,
      isDone: false,
      evenEditMode: false,
      customer: {
        first_name: null,
        last_name: null,
        email: null,
      },
      validation: {
        first_name: {
          state: null,
          message: null
        },
        last_name: {
          state: null,
          message: null
        },
        email: {
          state: null,
          message: null
        }
      },
      rememberEvent: false
    }
  },
  mounted() {
    if(this.$store.state.user) {
      if(!this.$can('add_attendees_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.event().statusLive().then(response => {
      this.events = response;
    })
  },
  methods: {
    getProducts(event) {
      this.groups = [];
      this.step = 1;
      this.ticketsCustomerData = [];
      this.toDoNext = 'send_all_tickets_to_order_owner';
      this.payment = 'no_payment';
      this.customerAnswers = {};
      this.evenEditMode = false;
      if(!event) {
        return;
      }
      api.guestList().getProductsByEvent(event.id).then(response => {
        this.groups = response;
      })
      api.event().getCustomerFields(event.id).then(customerFields => {
        this.customerFields = customerFields;

        setTimeout(() => {
          if(this.isPersonalized) {
            this.toDoNext = 'send_invite_to_claim_tickets';
          }
        }, 200)
      })
    },
    validateCustomerForm(input) {
      this.validation[input].state = null;
      if(!this.customer[input] || !this.customer[input].length) {
        this.validation[input].state = false;
        this.validation[input].message = 'field_is_required';
        return false;
      }
      if(input === 'email') {
        if(!this.validateEmail(this.customer.email)) {
          this.validation[input].state = false;
          this.validation[input].message = 'wrong_email';
          return false;
        }
      }
      return true;
    },
    createCustomerData() {
      this.ticketsCustomerData = [];
      this.groups.forEach(group => {
        group.products.forEach((product) => {
          for(let i=0; i < product.amount; i++) {
            this.ticketsCustomerData.push({
              product: product,
              customerAnswers: this.toDoNext === 'send_all_tickets_to_order_owner' ? this.customer : {},
              expanded: true
            })
          }
        })
      })
    },
    updateTodo(todo){
      this.toDoNext = todo;
      this.payment = 'no_payment';
    },
    save() {
      if(!this.validateCustomerForm('first_name')) {
        return;
      }
      if(!this.validateCustomerForm('last_name')) {
        return;
      }
      if(!this.validateCustomerForm('email')) {
        return;
      }
      this.createCustomerData();
      const products = this.ticketsCustomerData.map(row => {
        return {
          product_id: row.product.id,
          amount: 1,
          customer: {}
        };
      })
      this.loading = true;
      if(this.toDoNext === 'send_payment_link') {
        this.payment = 'payment_link';
      }
      api.guestList().placeOrder(this.event.id, {
        products,
        customer: this.customer,
        payment: this.payment,
        todo_next: this.toDoNext,
        populate_tickets_customer: this.populate,
        send_order_confirmation: this.send_order_confirmation
      }).then(() => {
        this.reset();
        this.$bvModal.show('submit-order-success');
        setTimeout(() => {
          this.$bvModal.hide('submit-order-success');
        }, 2000)
      }).catch(() => {
        this.loading = false;
      })
    },
    reset() {
      this.loading = false;
      this.isDone = false;
      this.groups = [];
      this.step = 1;
      this.ticketsCustomerData = [];
      this.toDoNext = 'send_all_tickets_to_order_owner';
      this.payment = 'no_payment';
      this.customerAnswers = {};
      this.customer = {
        first_name: null,
        last_name: null,
        email: null,
      };
      if(this.rememberEvent) {
        this.getProducts(this.event);
      }
      else {
        this.event = null;
      }
      this.$refs.firstNameInp.focus();
    },
    validateEmail
  },
  computed: {
    total() {
      let total = 0;
      this.groups.forEach(group => {
        group.products.forEach(product => {
          total += parseInt(product.amount) * product.price;
        })
      })
      return total;
    },
    itemsCount() {
      let amount = 0;
      this.groups.forEach(group => {
        group.products.forEach(product => {
          amount += parseInt(product.amount);
        })
      })
      return amount;
    },
    moreThanStock() {
      return this.groups.some(group => {
        return group.products.some(product => {
          return parseInt(product.amount) > product.current_stock;
        })
      })
    },
    isPersonalized() {
      let valid = true;
      this.customerFields.forEach(f => {
        if(f.field === 'first_name' || f.field === 'last_name' || f.field === 'email') {
          if(!f.ticket_ask || !f.ticket_mandatory) {
            valid = false;
          }
        }
      })
      return valid;
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('add_attendees_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h3.title{
  font-size: 17pt;
  color:#484848
}
span.title{
  font-size: 13pt;
}
.border-bottom:last-of-type{
  border-bottom: 1px solid transparent!important;
}
.items,.total,.b-form-spinbutton,.small{
  font-size: 14px;
}
.pointer-cursor {
  cursor: pointer;
}
.group-label {
  background-color: #EEEEEE;
  color: #808080!important;
  font-size: 15px!important;
  padding-top: 12px ;
  padding-bottom: 12px ;
  border-radius: 5px;
}
 .group-label span {
  background-color: #EEEEEE;
  color: #4E4D4D;
}
.card .card-title{
  font-size: 15px;
}
.form-group {
    margin-bottom: 2rem;
}
.event-name {
  font-size: 10pt;
}
.event-name svg {
  color: #000000 !important;
}

.sub-title{
  font-size:11pt
}

.btn-option{
  font-size:10pt
}

.btn-primary, .btn-primary:active, .btn-primary:focus {
  background-color: #193e58;
  border: 0 !important;
  box-shadow: unset !important;
&:hover {
   background-color: #102636;
 }
}

.product-stock::-webkit-outer-spin-button, .product-stock::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.product-stock[type=number] {
    -moz-appearance: textfield;
}

.btn-outline {
  background-color: #FFFFFF !important;
  border: 1px solid #6c757d !important;
  color: #6c757d !important;
&:hover {
   background-color: #6c757d !important;
   color: #FFFFFF !important;
 }
}
</style>
<style lang="scss">
.custom-switch{
  margin-left:5px
}
.vnis__button {
  background-color: #193E58 !important;
}
.vnis {
  width: 132px;
}

input.product-stock {
  height: 35px !important;
  width: 75px !important;
  text-align: center;
}

@media (min-width: 992px) {
  .modal-size-l {
    max-width: 660px !important;
  }
}

</style>

