<template>
  <b-container>
    <b-row v-if="pos && !saving">
      <b-col class="content-block">
        <product-list v-if="cart" :cross_sell="true"></product-list>
        <div class="text-right">
          <b-button variant="success" size="lg" class="mb-4" @click="processOrder()">
            {{ $t('order_continue') }}
          </b-button>
        </div>
      </b-col>
      <b-col cols="4" class="content-sidebar p-4">
        <cart></cart>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="text-center">
        <h1>{{ $t('order_loading') }}</h1>
        <b-spinner style="width: 10em; height: 10em;"></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'
import {mapState, mapGetters} from 'vuex'
import Cart from '@/components/Cart'
import ProductList from '@/components/ProductList'

export default {
  components: {
    Cart,
    ProductList
  },
  data() {
    return {
      saving: false
    }
  },
  methods: {
    processOrder() {
      this.saving = true
      let products = this.cart.products.filter(product => {
        return product.is_cross_sell
      }).map((product) => {
        return {product_id: product.id, amount: product.amount}
      })
      api.order().addCrossSell(this.order, products).then((order) => {
        this.$store.commit('setOrder', order)
        this.$router.push({
          name: 'OrderSelectPaymentMethod',
          params: this.$route.params
        })
      })
    },
  },
  computed: {
    ...mapState(['order', 'pos', 'cart', 'campaign']),
    ...mapGetters(['posHasCrossSell'])
  },
  created() {
    api.order().getBySecret(this.$route.params.id, this.$route.params.secret).then((order) => {
      this.$store.commit('setOrder', order)

      if(this.posHasCrossSell === false) {
        if(this.campaign) {
          this.$router.push({name: 'OrderSelectPaymentMethod', params: this.$route.params, query:{ft_campaign_name: this.campaign.slug}})
        }
        else {
          this.$router.push({name: 'OrderSelectPaymentMethod', params: this.$route.params})
        }
      }
    })
  }
}
</script>
