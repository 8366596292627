export default class CampaignApi {

  constructor(axios) {
    this.axios = axios
  }

  all(page = 1) {
    return this.axios.get('/campaigns?page=' + page)
  }

  create(name) {
    return this.axios.post('/campaigns', {name})
  }

  update(id, data) {
    return this.axios.post('/campaigns/' + id, data)
  }

  find(id) {
    return this.axios.get('/campaigns/' + id)
  }

  delete(id) {
    return this.axios.delete('/campaigns/' + id)
  }

  findByDomain(slug, event = null) {
    let domain = location.hostname === 'localhost' ?
      (location.hostname + ':' + location.port) : location.hostname
    return this.axios.post('/campaign/get', {domain, slug, event, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone})
  }

  createRule(id, reference) {
    return this.axios.post('/campaigns/' + id + '/rules', {reference})
  }

  deleteRule(id, rule_id) {
    return this.axios.delete('/campaigns/' + id + '/rules/' + rule_id)
  }

  updateRule(id, rule_id, data) {
    return this.axios.post('/campaigns/' + id + '/rules/' + rule_id, data)
  }
}