<template>
  <b-container v-if="ticket">
    <b-row>
      <b-col class="content-block">
        <customer-form :disabled="sending" ref="customer-form" v-if="fields && ticket" v-model="ticket.customer" level="ticket"
                       :fields="fields" :message="message" :is-claim="true" :order-customer="ticket.order.customer"></customer-form>
        <customer-information-form :disabled="sending" ref="customer-information-form" v-model="answers"
                                   :questions="questions"></customer-information-form>
        <b-form-group class="text-center">
          <b-spinner v-if="sending"></b-spinner>
          <b-btn v-else variant="success" @click="submit()" size="lg">{{ $t('ticket_claim') }}</b-btn>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'
import CustomerForm from '../components/CustomerForm'
import CustomerInformationForm from "@/components/CustomerInformationForm";

export default {
  components: {
    CustomerForm,
    CustomerInformationForm
  },
  data() {
    return {
      ticket: null,
      questions: [],
      answers: {},
      fields: null,
      sending: false,
      message: '',
      order_number: null,
      order_secret: null
    }
  },
  methods: {
    loadAnswers(answers) {
      console.log('Loading answers')
      answers.forEach(answer => {

        this.answers[answer.customer_information_id] = answer.answer
        console.log(this.answers)
      })
      if (answers.length === 0) {
        this.answers = {}
      }
    },
    submit() {

      let customerValid = this.$refs['customer-form'].isValid();
      let customerInfoValid = this.$refs['customer-information-form'].isValid();
      if (!customerValid || !customerInfoValid) {
        return
      }
      this.sending = true

      api.ticket().updateCustomer(this.$route.params.id, this.$route.params.secret, this.ticket.customer).then(ticket => {
        this.ticket = ticket

        api.customerInformation().submit(this.$route.params.id, this.$route.params.secret, this.answers).then(this.loadAnswers).catch(() => {
          this.sending = false
        })
      }).then(() => {
        api.ticket().claim(this.$route.params.id, this.$route.params.secret).then(() => {
          if (this.$route.query.change) {
            this.$router.push({
              name: 'TicketChanged'
            })
            return
          }
          this.$router.push({
            name: 'OrderThankYou',
            query: {
              order_number: this.order_number,
              order_secret: this.order_secret
            }
          })
        })
      }).catch(() => {
        this.sending = false
      })
    }
  },
  created() {
    api.ticket().getBySecret(this.$route.params.id, this.$route.params.secret).then(ticket => {
      if(ticket.order.order_number){
        this.order_number = ticket.order.order_number
      }
      if(ticket.order.secret){
        this.order_secret = ticket.order.secret
      }
      if(!ticket.customer){
        ticket.customer = {}
      }
      this.ticket = ticket
      if(ticket.invited_at && !ticket.claimed_at && ticket.order.event){
        this.message = this.$t('ticket_assigned_explenation', {customer_first_name: ticket.order.customer.first_name, event_name: ticket.order.event.name})
      }
      if(this.ticket.order.point_of_sale) {
        this.$store.commit('setPos', this.ticket.order.point_of_sale)
        // this.$store.commit('setLanguage', this.ticket.order.point_of_sale.language)
      }
    })
    api.customerInformation().getAnswersByTicket(this.$route.params.id, this.$route.params.secret).then(this.loadAnswers)
    api.customerInformation().getByTicket(this.$route.params.id, this.$route.params.secret).then((questions) => {
      this.questions = questions
    })
    api.customerInformation().getFieldsByTicket(this.$route.params.id, this.$route.params.secret).then((fields) => {
      this.fields = fields
    })
  }
}
</script>
