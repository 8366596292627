export default class cdnApi {

    constructor(axios) {
        this.axios = axios
    }

    signUpload(filename) {
        return this.axios.post('/cdn/upload', {filename})
    }

    deleteUpload(filename) {
        return this.axios.post('/cdn/delete', {filename})
    }
}
