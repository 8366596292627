<template>
  <div>
    <b-form-group v-if="$can('venue_create')" class=" text-right container-distance">
      <b-button  variant="new" @click="showVenueModal">{{ $t('new') }}</b-button>
    </b-form-group>
    <b-modal id="add-venue" :title="$t('venue_new_modal_title')">
      <venue-form v-if="venue" v-model="venue"></venue-form>

      <template #modal-footer>
        <b-button variant="primary" @click="createVenue()">{{ $t('create') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('venue_modal_edit', venue)" id="edit-venue">
      <venue-form v-if="venue" v-model="venue"></venue-form>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="saveVenue()" variant="success">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
<div class="tableBig">
 <b-table v-if="venuesData.length" class="table-container" :items="venuesData" :fields="fields">
      <template v-slot:cell(verified)="{item}">
        {{ item.verified ? $t('yes') : $t('no') }}
      </template>
       <template v-slot:cell(active)="{item}">
         {{ item.active ? $t('yes') : $t('no') }}
       </template>
      <template v-slot:cell(name)="{item}">
        <span v-if="item.name">{{ item.name }}</span>
        <span v-else>{{ $t('not_set') }}</span>
      </template>
      <template v-slot:cell(actions)="{item}">
        <b-button class="mr-3 mb-1" v-if="$can('venue_create')" variant="primary" @click="editVenue(item)">{{ $t('manage') }}</b-button>
        <b-icon v-if="$can('venue_delete')" @click="showDeleteModal(item)" icon="trash" font-scale="1.5" class="text-secondary btnDelete" :class="{cursor_pointer: item.orders_count === 0, disabled_danger: item.orders_count}"></b-icon>
      </template>
    </b-table>

  <pagination v-if="page_data" :limit="3" :data="page_data" @pagination-change-page="getVenuesData"></pagination>
  
    <b-alert v-else show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>

  <b-modal :title="$t('delete')" id="delete-venue" size="lg">
    <div>
      <b-row>
        <b-col>{{ $t('delete_entry') }}</b-col>
      </b-row>
    </div>
    <template #modal-footer="{cancel}">
      <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
      <b-button :disabled="loading" @click="deleteVenue" variant="danger">
        <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon> {{ $t('delete') }}
      </b-button>
    </template>
  </b-modal>
</div>
   

  </div>
</template>

<script>
import api from '../api'
import VenueForm from "../components/VenueForm.vue";
export default {
  components: {
    VenueForm,
  },
  name: 'VenuesOverview',
  data() {
    return {
      venue: {},
      venuesData: [],
      page_data: null,
      loading: false,
      fields: [
        {key: 'name', label: this.$t('venue_name')},
        {key: 'verified', label: this.$t('venue_verified')},
        {key: 'active', label: this.$t('venue_active')},
        {key: 'actions', label: this.$t('order_actions')},
      ]
    }
  },
  created() {
    document.title = this.$t('venues_overview')
    if(this.$store.state.user) {
      if(!this.$can('master_data_venues_overview')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.getVenuesData()
  },
  methods: {
    showVenueModal() {
      this.venue = {};
      this.venue.active = true
      this.venue.verified = true
      this.$bvModal.show('add-venue')
    },
    createVenue() {
      this.adding = true

      api.venues().create(this.venue).then(() => {
        this.$bvModal.hide('add-venue')
        this.getVenuesData()
      })
    },
    getVenuesData(page = 1) {
      api.venues().overview(page).then(venues => {
        this.venuesData = venues.data

        this.venuesData.forEach((value) => {
          if (value.verified === 1) {
            value.verified = true
          }
          if (value.active === 1) {
            value.active = true
          }
        });

        this.page_data = venues
      })
    },
    editVenue(venue) {
      this.venue = Object.assign({}, venue)
      this.$bvModal.show('edit-venue')
    },
    saveVenue() {
      api.venues().save(this.venue).then(() => {
        this.$bvModal.hide('edit-venue')
        this.getVenuesData()
        this.venue = {}
        this.$store.commit('success', 'venue_saved')
      })
    },
    showDeleteModal(venue) {
      this.venue = venue;
      this.$bvModal.show('delete-venue')
    },
    deleteVenue() {
      this.loading = true;
      api.venues().delete(this.venue.id).then(() => {
        this.$store.commit('success', 'venue_deleted');
        this.$bvModal.hide('delete-venue')
        this.venue = {};
        this.getVenuesData();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('master_data_venues_overview')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
    '$store.state.language': {
      handler() {
        this.fields = [
          {key: 'verified', label: this.$t('venue_verified')},
          {key: 'active', label: this.$t('venue_active')},
          {key: 'name', label: this.$t('venue_name')},
          {key: 'actions', label: this.$t('order_actions')},
        ];
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tableBig{
  overflow: auto;
}
.btnDelete {
  vertical-align:middle;
}
.cursor_pointer {
  cursor: pointer;
}
.disabled_danger {
  opacity: .4;
}
</style>
