<template>
  <b-container>
    <b-row>
      <b-col v-if="tickets" class="content-block cart-container">
        <template v-if="tickets.length > 0">
          <h2>{{ $t('assign_tickets_title') }}</h2>
          <p>{{ $t('assign_tickets_hint') }}</p>
          <b-form-group>
            <b-button size="lg" @click="assignTickets('self')" variant="success" class="mr-3 mb-3 full-width-button">
              {{ $t('assign_tickets_myself') }}
            </b-button>
            <!-- <b-button size="lg" class="full-width-button mb-3" @click="assignSelf = false">{{ $t('assign_tickets_by_invite') }}</b-button> -->
          </b-form-group>
        </template>
      </b-col>
      <b-col v-else class="text-center">
        <h1>{{ $t('order_loading') }}</h1>
        <b-spinner style="width: 10em; height: 10em;"></b-spinner>
      </b-col>
    </b-row>
    <b-row v-if="!assignSelf">
      <b-col class="content-block mt-3">
        <h1>{{ $t('assign_tickets_select') }}</h1>
        <div class="pl-2">
          <b-row v-for="ticket in tickets" :key="`ticket `+ ticket.id">
            <b-col lg="3">
              <b-row class="product-details">
                <b-col cols="4" v-if="ticket.order_product.product.image">
                  <b-img fluid :src="ticket.order_product.product.image"></b-img>
                </b-col>
                <b-col>
                  <h2>{{ ticket.order_product.product.name }}</h2>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="5" sm="6">
              <b-row class="pt-3 pb-3">
                <b-col class="text-right">{{ $t('assign_ticket_self') }}</b-col>
                <b-col cols="2" class="text-center">
                  <b-form-checkbox switch size="lg" v-model="ticket.invite"></b-form-checkbox>
                </b-col>
                <b-col>{{ $t('assign_ticket_sent') }}</b-col>
              </b-row>
            </b-col>
            <b-col sm="6" lg="4">
              <b-form-group v-if="ticket.invite">
                <b-form-input class="invite-email-input" :placeholder="$t('customer_email')" type="email"
                              v-model="ticket.customer.invite_email"
                              :state="validated ? errors.indexOf(ticket.id) === -1 : null"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="text-right">
          <b-button size="lg" variant="success" @click="assignTickets()">{{ $t('assign_tickets_continue') }}</b-button>
        </div>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import api from '../api'

export default {
  components: {},
  data() {
    return {
      tickets: null,
      assignSelf: true,
      errors: [],
      validated: false
    }
  },
  methods: {
    validateEmail( email ){
      if(email == null){ return null }
      return email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    },
    assignTickets(type='') {

      // Reset the invited at fields if they decide to switch to filling tickets themselves
      if(type === 'self'){
        this.tickets.map(ticket => {
          ticket.invite = false
          if(ticket.customer){
            ticket.customer.invite_email = null
          }
          return ticket
        })
      }

      this.validated = true
      this.errors = []
      this.tickets.forEach(ticket => {  
        if(ticket.invite){
          if( this.validateEmail(ticket.customer.invite_email) === null ){
            this.errors.push(ticket.id)
          }
        }
      })

      if(this.errors.length > 0){
        return
      }

      let inviteRequests = this.tickets.filter((ticket) => {
        return ticket.invite
      }).map((ticket) => {
        return api.ticket().assign(ticket.id, ticket.secret, ticket.customer.invite_email)
      })

      if (inviteRequests.length === 0) {
        // no tickets to send invites for
        this.$router.push({
          name: 'AssignTickets',
          params: this.$route.params
        })
        return
      }
      Promise.all(inviteRequests).then(() => {
        this.$store.commit('success', 'tickets_assigned_email_sent')
        this.$router.push({
          name: 'AssignTickets',
          params: this.$route.params
        })
      }).catch((error) => {
        this.$store.commit('errors', error.response.body)
      })
    }
  },
  created() {
    this.$store.commit('clearCart')
    document.title = this.$t('order_view_order_details_title')
    api.pos().getByOrderIdAndSecret(this.$route.params.id, this.$route.params.secret).then(pos => {
      if(pos){
        this.$store.commit('setPos', pos)

        if(pos.language && !localStorage.getItem('ft_lang')){
          this.$store.commit('setLanguage', pos.language)
        }
      }
    })

    api.order().getTickets(this.$route.params.id, this.$route.params.secret).then(tickets => {
      this.tickets = tickets.map(ticket => {
        if(!ticket.customer){
          ticket.customer = []
        }
        return ticket
      })
    })
  }
}
</script>
<style scoped>
h2{
  font-size:1.5rem;
  font-weight:bold
}
p{
  font-size:0.8rem
}
.product-details {
  padding: 0.5em;
}

.product-details h2 {
  font-size: 1em;
  line-height: 50px;
}

.product-details img {
  max-width: 50px;
  max-height: 50px;
}

@media only screen and (max-width: 360px) {
  .full-width-button{
    width:100%
  }
  .full-width-button:first-of-type{
    margin-bottom:1rem
  }
}
</style>
