export default class ScanOperationSessionApi {

    constructor(axios) {
        this.axios = axios
    }

    get(scanOperationId) {
        return this.axios.get('/scan-operation/' + scanOperationId + '/session')
    }

    create(scanOperationSession) {
        return this.axios.post('/scan-operation/' + scanOperationSession.scan_operation_id + '/session', scanOperationSession)
    }

    save(scanOperationSession) {
        return this.axios.post('/scan-operation/' + scanOperationSession.scan_operation_id + '/session/' + scanOperationSession.id, scanOperationSession)
    }

    delete(scanOperationId, scanOperationSessionId) {
        return this.axios.delete('/scan-operation/' + scanOperationId + '/session/' + scanOperationSessionId)
    }

    saveProduct(session_id, product_id) {
        return this.axios.post('/scan-operation/session/sync-product', {
            session_id,
            product_id
        })
    }

    removeProduct(session_id, product_id) {
        return this.axios.delete('/scan-operation/session/sync-product/' + session_id + '/' + product_id)
    }

    getSessionsProducts(slug) {
        return this.axios.get('/scan-operation/' + slug + '/sessions-products')
    }

}
