<template>
  <div>
    <b-row>
      <b-spinner v-if="!scan_operations"></b-spinner>
      <b-col v-else>
        <b-row>
          <b-col>
            <label class="d-inline-block">{{ $t('scan_operation_show_archived') }}</label>
            <b-form-checkbox class="d-inline-block ml-3" switch v-model="show_archived" :value="true" :unchecked-value="false" @change="getScanOperations()"></b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-group class="text-right container-distance">
              <b-button v-if="$can('scan_terminal_view')" class="mr-4" variant="new" @click="openScannerTerminal">{{ $t('scan_operation_terminal_button') }}</b-button>
              <b-button v-if="$can('scan_operation_create') && events" variant="new" :disabled="events.length === 0" v-b-modal.add-scan-operation>{{ $t('new') }}</b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <table v-if="scan_operations.length" class="table b-table" style="width:100%;">
          <thead>
            <tr>
              <th style="width: 25%; color:white;">{{ $t('scan_operation_event') }}</th>
              <th style="width: 25%; color:white;">{{ $t('scan_operation_status') }}</th>
              <th style="width: 25%; color:white;">{{ $t('scan_operation_users') }}</th>
              <th style="width: 25%; color:white;">{{ $t('scan_operation_actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="scan_operation in scan_operations" :key="`scan-operation` + scan_operation.name">
              <td>{{ scan_operation.name }}</td>
              <td><status-label :status="scan_operation.scan_operation.status"></status-label></td>
              <td>{{ scan_operation.scan_operation.scanners.length }}</td>
              <td>
                <b-btn v-if="$can('scan_operation_edit')" variant="primary" :to="{name:'ScanOperationManage', params:{id: scan_operation.scan_operation.slug}}">{{$t('scan_operation_manage')}}</b-btn>
              </td>
            </tr>
          </tbody>
        </table>
        <b-alert v-else show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>
      </b-col>
    </b-row>
    <b-modal id="add-scan-operation" :title="$t('scan_operation_add_modal_title')">
      <b-form-group :label="$t('select_event')">
        <v-select v-model="scan_operation.event_id" :options="events" label="name" :reduce="event => event.id"></v-select>
      </b-form-group>
      <p>{{ $t('scan_operation_create_hint') }}</p>
      <template #modal-footer>        
        <b-button :disabled="saving" variant="primary" @click="createScanOperation()">{{ $t('create') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import StatusLabel from "@/components/StatusLabel";

export default {
  components: {
    StatusLabel
  },
  data() {
    return {
      scan_operations: [],
      scan_operation: {},
      events: null,
      saving: false,
      show_archived: false,
    }
  },
  methods: {
    openScannerTerminal(){
      let routeData = this.$router.resolve({name: 'ScannerTerminalLogin'});
      window.open(routeData.href, '_blank');
    },
    createScanOperation() {
      this.saving = true;
      api.scanOperation().create(this.scan_operation).then(response => {
        this.getScanOperations();
        this.getEvents();
        this.$bvModal.hide('add-scan-operation')
        this.saving = false;
        if(this.$can('scan_operation_edit')) {
          this.$router.push({name: 'ScanOperationManage', params: {id: response.slug}})
        }
        else {
          this.scan_operation = {};
          this.$store.commit('success', this.$t('scan_operation_created'));
        }
      }).catch(() => {
        this.saving = false;
      })
    },
    getScanOperations() {
      api.scanOperation().get(this.show_archived).then(scanOperations => {
        this.scan_operations = scanOperations
      })
    },
    getEvents() {
      api.scanOperation().getAvailableEvents().then((events) => this.events = events);
    },
  },
  created() {
    if(this.$store.state.user) {
      if(!this.$can('scan_operation_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.getScanOperations()
    this.getEvents()
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('scan_operation_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>
