export default class companyApi {

    constructor(axios) {
        this.axios = axios
    }

    getByDomain(){

        let domain = location.hostname === 'localhost' ?
            (location.hostname + ':' + location.port) : location.hostname
        return this.axios.post('/company/get', {
            domain
        })
    }

    all() {
        return this.axios.get('/company')
    }

    create(company) {
        return this.axios.post('/company', company)
    }

    get(id) {
        return this.axios.get('/company/' + id)
    }

    getCompaniesName() {
        return this.axios.get('/company-all-names/')
    }

    update(id, company) {
        return this.axios.post('/company/' + id, company)
    }

    addUser(id, user) {
        return this.axios.post('/company/' + id + '/user', user)
    }

    updateLayout(id, layout) {
        return this.axios.post('/company/' + id + '/layout', layout)
    }

    signLogoUpload(id, filename) {
        return this.axios.post('/company/' + id + '/sign-logo-upload', {filename})
    }

    updatePaymentMetodFees(id, paymentMethodFees){
        return this.axios.post('/company/' + id + '/payment-method-fees', paymentMethodFees)
    }

    events(company_id, show_archived) {
        return this.axios.get('/company/' + company_id + '/events?show_archived=' + show_archived)
    }

    getAllPaymentMethods(){
      return this.axios.get('/payment-methods')
    }

    addNewPaymentMetodFees(id, newPaymentFee){
      return this.axios.post('/company/' + id + '/create-payment-method-fees', newPaymentFee)
    }

    addCompanyFee(company_id, data) {
        return this.axios.post('/company/' + company_id + '/company-fees', data)
    }

    updateCompanyFee(company_id, id, data) {
        return this.axios.post('/company/' + company_id + '/company-fees/' + id, data)
    }

    countries() {
        return this.axios.get('/countries');
    }

    signup(data) {
        return this.axios.post('/company/signup', data)
    }

    signupConfirmation(data) {
        return this.axios.post('/company/signup/confirm', data)
    }

}
