import axios from "axios";
import store from "@/store";
import BoxOfficeUser from "@/api/boxOfficeUser";
import BoxOfficeEvent from "@/api/boxOfficeEvent";
import BoxOfficePlaceOrder from "@/api/boxOfficePlaceOrder";

const HttpClient = axios.create({});

if (window.location.host === 'localhost:8080') {
    // Local development environment
    HttpClient.defaults.baseURL = 'http://api.fairtix.local'
} else if (window.location.host === 'frontend.staging.fairtix.io') {
    // Test environment Staging (staging-ft)
    HttpClient.defaults.baseURL = 'https://api.staging.fairtix.io'
} else {
    HttpClient.defaults.baseURL = 'https://api.fairtix.io'
}

HttpClient.interceptors.request.use((config) => {
    let token = localStorage.getItem('box_office_token');
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
}, (error) => {
    // Do something with request error
    document.location = '/'
    return Promise.reject(error)
})

HttpClient.interceptors.response.use((response) => {
    return response.data
}, (error) => {
    // Do something with response error
    if (error.response.status === 401) {
        store.commit('boxOfficeLogout')
    }
    if (error.response.status === 400) {
        store.commit('errors', error.response.data)
    }
    return Promise.reject(error)
})

const user = () => {
    return new BoxOfficeUser(HttpClient);
}

const event = () => {
    return new BoxOfficeEvent(HttpClient);
}

const boxOfficePlaceOrder = () => {
    return new BoxOfficePlaceOrder(HttpClient);
}

export default {
    user,
    event,
    boxOfficePlaceOrder
}
