var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.company)?_c('b-container',[_c('section',{staticClass:"event-section"},[_c('div',{staticClass:"container"},[(_vm.company.show_calendar)?_c('div',{staticClass:"main-wrapper bg-dark mb-5"},[_c('div',{staticClass:"calender-header"},[_c('button',{staticClass:"button arrow-left",on:{"click":function($event){return _vm.handleDown()}}}),_c('div',{staticClass:"month-name-wrap"},[_c('p',{staticStyle:{"font-weight":"700","text-align":"center","margin-bottom":"0px"}},[_vm._v(_vm._s(_vm.months[_vm.SELECTED_MONTH].fullname)+" - "+_vm._s(_vm.SELECTED_YEAR))])]),_c('button',{staticClass:"button arrow-right",on:{"click":function($event){return _vm.handleUp()}}})]),_c('div',{staticClass:"calendar-wrap"},[_vm._l((_vm.weekdays),function(w){return _c('div',{key:JSON.stringify(w) + Math.random(),staticClass:"week-days"},[_c('span',{staticClass:"mobile"},[_vm._v(_vm._s(w.name))]),_c('span',{staticClass:"desktop"},[_vm._v(_vm._s(w.fullname))])])}),_vm._l((_vm.monthStartingDays),function(i){return _c('div',{key:JSON.stringify(i) + Math.random(),staticClass:"next-month-day-item"},[_c('div',{staticClass:"prev-date-text-wrap"},[_c('p',{staticClass:"prev-date-text"},[_vm._v(" "+_vm._s(_vm.prevMonthTotalDays-(_vm.monthStartingDays-i)))])]),(_vm.events(i).length>0)?_c('div',{staticClass:"events-wrapper"},_vm._l((_vm.events(i,_vm.SELECTED_MONTH>0?_vm.SELECTED_MONTH-1:11)),function(e){return _c('div',{key:JSON.stringify(e) + Math.random(),class:(e.imgURL) ? 'event-wrap with-image' : 'event-wrap',style:({
                          backgroundImage: (e.imgURL) ? 'url(\'https://cdn.fairtix.io' + e.imgURL + '\')' : 'none',
                          backgroundColor: (!e.imgUrl) ? '#585c60' : 'transparent',
                          textShadow: '0 0 5px #000'
                        })},[(e.day===i && _vm.company.calendar_sold_ratio)?_c('div',{staticClass:"event-status",style:({backgroundColor: _vm.statusColor(e.status)})}):_vm._e(),(e.day===i)?_c('a',{staticClass:"event-text",attrs:{"href":`/p/` + e.url + (_vm.$route.query.ft_campaign_name ? ('/?ft_campaign_name='+_vm.$route.query.ft_campaign_name) : ''),"target":"_blank"}},[_vm._v(_vm._s(e.text))]):_vm._e()])}),0):_vm._e()])}),_vm._l((_vm.monthTotalDays),function(i){return _c('div',{key:JSON.stringify(i) + Math.random(),staticClass:"curr-month-day-item"},[_c('div',{staticClass:"curr-date-text-wrap"},[_c('p',{staticClass:"curr-date-text"},[_vm._v(_vm._s(i))])]),(_vm.events(i,_vm.SELECTED_MONTH).length > 0)?_c('div',{staticClass:"events-wrapper"},_vm._l((_vm.events(i,_vm.SELECTED_MONTH)),function(e){return _c('div',{key:JSON.stringify(e) + Math.random(),class:(e.imgURL) ? 'event-wrap with-image' : 'event-wrap',style:({
                          backgroundImage: (e.imgURL) ? 'url(\'https://cdn.fairtix.io' + e.imgURL + '\')' : 'none',
                          backgroundColor: (!e.imgUrl) ? '#585c60' : 'transparent',
                          textShadow: '0 0 5px #000'
                      })},[(e.day===i && _vm.company.calendar_sold_ratio)?_c('div',{staticClass:"event-status",style:({backgroundColor: _vm.statusColor(e.status)})}):_vm._e(),(e.day===i)?_c('a',{staticClass:"event-text",attrs:{"href":`/p/` + e.url + (_vm.$route.query.ft_campaign_name ? ('/?ft_campaign_name='+_vm.$route.query.ft_campaign_name) : ''),"target":"_blank"}},[_vm._v(" "+_vm._s(e.text)+" ")]):_vm._e()])}),0):_vm._e()])}),_vm._l((7-(_vm.monthStartingDays+_vm.monthTotalDays)%7),function(i){return _c('div',{key:JSON.stringify(i) + Math.random(),staticClass:"next-month-day-item",class:(_vm.monthStartingDays+_vm.monthTotalDays)%7>0?'':'hidden'},[_c('div',{staticClass:"prev-date-text-wrap"},[_c('p',{staticClass:"prev-date-text"},[_vm._v(_vm._s(i))])]),(_vm.events(i,_vm.SELECTED_MONTH<11?_vm.SELECTED_MONTH+1:0).length>0 && i===1)?_c('div',{staticClass:"events-wrapper"},_vm._l((_vm.events(i, _vm.SELECTED_MONTH<11?_vm.SELECTED_MONTH+1:0)),function(e){return _c('div',{key:JSON.stringify(e) + Math.random(),class:(e.imgURL) ? 'event-wrap with-image' : 'event-wrap',style:({
                          backgroundImage: (e.imgURL) ? 'url(\'https://cdn.fairtix.io' + e.imgURL + '\')' : 'none',
                          backgroundColor: (!e.imgUrl) ? '#585c60' : 'transparent',
                          textShadow: '0 0 5px #000'
                        })},[(e.day===i && _vm.company.calendar_sold_ratio)?_c('div',{staticClass:"event-status",style:({backgroundColor: _vm.statusColor(e.status)})}):_vm._e(),(e.day===i)?_c('a',{staticClass:"event-text",attrs:{"href":`/p/` + e.url + (_vm.$route.query.ft_campaign_name ? ('/?ft_campaign_name='+_vm.$route.query.ft_campaign_name) : ''),"target":"_blank"}},[_vm._v(_vm._s(e.text))]):_vm._e()])}),0):_vm._e()])})],2)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},_vm._l((_vm.pointsOfSale),function(pos){return _c('pos',{key:pos.id,attrs:{"pos":pos}})}),1)])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }