<template>
  <div class="number-input">
    <button @click="subtract">-</button>
    <input type="number" :value="value" @input="updateNumber($event.target.value)" :class="{'is-invalid': state === false}">
    <button @click="add">+</button>
  </div>
</template>

<script>
export default {
  name: "NumberInput",
  props: ['min', 'max', 'value', 'state'],
  methods: {
    add() {
      let value = this.value + 1;
      if(this.max !== null && value > this.max) {
        value = this.max;
      }
      this.$emit('input', value)
    },
    subtract() {
      let value = this.value - 1;
      if(this.min !== null && value < this.min) {
        value = this.min;
      }
      this.$emit('input', value)
    },
    updateNumber(value) {
      if(!value){
        value = 0;
      }
      value = parseInt(value);
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
.number-input {
  width: 132px;
}
.number-input button {
  -webkit-appearance: none;
  transition: background 0.5s ease;
  background: #193E58;
  border: 0;
  color: #fff;
  cursor: pointer;
  float: left;
  font-size: 20px;
  height: 40px;
  margin: 0;
  width: 40px;
}

.number-input input {
  -webkit-appearance: none !important;
  border: 1px solid #ebebeb;
  float: left;
  font-size: 16px;
  height: 40px;
  margin: 0;
  outline: none;
  text-align: center;
  width: calc(100% - 80px);
  overflow: visible;
}

.number-input input::-webkit-outer-spin-button, .number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.number-input input.is-invalid {
  border-color: #dc3545;
}
</style>