<template>
  <span></span>
</template>
<script>
export default {
  props: ['gtm_id'],
  data() {
    return {
    }
  },
  mounted() {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    dataLayer.push({
      'gtm.id': this.gtm_id,
      event: 'gtm.js',
    });

    dataLayer.push({
      event: 'virtualPageView',
      virtualPagePath : window.location.pathname,
      virtualPageTitle: document.title
    });
  }
}
</script>