export default class auditApi {

    constructor(axios) {
        this.axios = axios
    }

    get(subject, subjectId) {
        return this.axios.get('/audit/' + subject + '/' + subjectId)
    }

}
