<template>
    <span style="font-size: 18px">
        <b-badge v-if="status === 'test'" variant="secondary">{{ status }}</b-badge>
        <b-badge v-if="status === 'draft'" variant="warning">{{ status }}</b-badge>
        <b-badge v-if="status === 'live'" variant="success">{{ status }}</b-badge>
        <b-badge v-if="status === 'box-office'" variant="success">{{ status }}</b-badge>
        <b-badge v-if="status === 'sold-out'" variant="warning">{{ status }}</b-badge>
        <b-badge v-if="status === 'archived'">{{ status }}</b-badge>
        <b-badge v-if="status === 'online'" variant="success">{{ status }}</b-badge>
        <b-badge v-if="status === 'api'" variant="success">{{ status }}</b-badge>
        <b-badge v-if="status === 'paid'" variant="success">{{ status }}</b-badge>
        <b-badge v-if="status === 'pending'" variant="warning">{{ status }}</b-badge>
        <b-badge v-if="status === 'batch-ticket'" variant="warning">{{ status }}</b-badge>
        <b-badge v-if="status === 'payment-link'" variant="warning">{{ status }}</b-badge>
    </span>
</template>
<script>
export default {
  props: ['status']
}
</script>
