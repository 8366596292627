<template>
  <div>
    <b-row>
      <b-col cols="12" lg="2">
        <b-form-group>
          <b-form-input class="v-text-field" v-model="search" debounce="500"
                        :placeholder="$t('search')" @update="getDiscountcodes(1, $event)"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group v-if="$can('discountcode_create')" class=" text-right container-distance">
          <b-button  variant="new" @click="showDiscountModal">{{ $t('new') }}</b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <b-modal id="add-discountcode" :title="$t('discountcode_new_modal_title')">
      <b-form-group :label="$t('discountcode_code')">
        <b-form-input v-model="discountcode.code"></b-form-input>
      </b-form-group>
      <template #modal-footer>
        <p>{{ $t('discountcode_create_hint') }}</p>
        <b-button variant="primary" @click="createDiscountcode()">{{ $t('create') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('discountcode_modal_edit', discountcode)" id="edit-discountcode" size="lg">
      <discountcode-form v-if="discountcode" v-model="discountcode"></discountcode-form>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="saveDiscountcode()" variant="success">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
<div class="tableBig">
 <b-table v-if="discountcodes.length" class="table-container" :items="discountcodes" :fields="fields">
  <template v-slot:cell(is_active)="{item}">
        <b-badge :variant="item.is_active ? 'success' : 'warning'">{{ item.is_active ? $t('yes') : $t('no') }}</b-badge>
      </template>
      <template v-slot:cell(ends_at)="{item}">
        <span v-if="item.ends_at">{{ item.ends_at | date_time }}</span>
        <span v-else>{{ $t('not_set') }}</span>
      </template>
      <template v-slot:cell(starts_at)="{item}">
        <span v-if="item.starts_at">{{ item.starts_at | date_time }}</span>
        <span v-else>{{ $t('not_set') }}</span>
      </template>
      <template v-slot:cell(actions)="{item}">
        <b-button class="mr-3 mb-1" v-if="$can('discountcode_edit')" variant="primary" @click="editDiscountcode(item)">{{ $t('manage') }}</b-button>
        <b-icon v-if="$can('discountcode_delete') && item.orders_count === 0" @click="showDeleteModal(item)" icon="trash" font-scale="1.5" class="text-secondary btnDelete" :class="{cursor_pointer: item.orders_count === 0, disabled_danger: item.orders_count}"></b-icon>
        <b-button v-if="item.orders_count > 0" :to="{name:'OrderOverview', query:{discountcode: item.id}}">{{ $t('discountcode_overview_show_orders') }}</b-button>
      </template>
    </b-table>

  <pagination v-if="page_data" :limit="3" :data="page_data" @pagination-change-page="getDiscountcodes($event, search)"></pagination>

    <b-alert v-else show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>

    <b-modal :title="$t('delete')" id="delete-discountcode" size="lg">
    <div>
      <b-row>
        <b-col>{{ $t('delete_entry') }}</b-col>
      </b-row>
    </div>
    <template #modal-footer="{cancel}">
      <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
      <b-button :disabled="loading" @click="deleteDiscountcode" variant="danger">
        <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon> {{ $t('delete') }}
      </b-button>
    </template>
  </b-modal>
</div>


  </div>
</template>

<script>
import api from '../api'
import DiscountcodeForm from '@/components/DiscountcodeForm';

export default {
  components: {
    DiscountcodeForm
  },
  name: 'Discountcodes',
  data() {
    return {
      discountcode: {},
      discountcodes: [],
      page_data: null,
      loading: false,
      fields: [
        {key: 'is_active', label: this.$t('discountcode_is_active')},
        {key: 'code', label: this.$t('discountcode_code')},
        {key: 'type', label: this.$t('discountcode_type')},
        {key: 'amount', label: this.$t('discountcode_amount')},
        {key: 'stock', label: this.$t('discountcode_stock')},
        {key: 'orders_count', label: this.$t('discountcode_times_used')},
        {key: 'tickets_count', label: this.$t('discountcode_tickets_times_used')},
        {key: 'starts_at', label: this.$t('discountcode_starts_at')},
        {key: 'ends_at', label: this.$t('discountcode_ends_at')},
        {key: 'actions', label: this.$t('order_actions')},
      ],
      search: null,
    }
  },
  created() {
    document.title = this.$t('backend_title_discountcodes')
    if(this.$store.state.user) {
      if(!this.$can('discountcode_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.getDiscountcodes()
  },
  methods: {
    showDiscountModal() {
      this.discountcode = {};
      this.$bvModal.show('add-discountcode')
    },
    createDiscountcode() {
      this.adding = true

      api.discountcode().create(this.discountcode).then((discountcode) => {
        this.$bvModal.hide('add-discountcode')
        this.getDiscountcodes()
        if(this.$can('discountcode_edit')) {
          this.editDiscountcode(discountcode)
        }
      })
    },
    getDiscountcodes(page = 1, search) {
      api.discountcode().all(page, search).then(discountcodesdata => {
        this.discountcodes = discountcodesdata.data
        discountcodesdata.data.forEach((value) => {
          if (value.is_active == 1) {
            value.is_active = true
          }
        });
        this.page_data = discountcodesdata
      })
    },
    editDiscountcode(discountcode) {
      if(discountcode.events === null) {
        discountcode.events = [];
      }
      if(discountcode.products === null) {
        discountcode.products = [];
      }

      this.discountcode = Object.assign({}, discountcode)
      this.$bvModal.show('edit-discountcode')
    },
    saveDiscountcode() {
      api.discountcode().save(this.discountcode).then(() => {
        this.$bvModal.hide('edit-discountcode')
        this.getDiscountcodes()
        this.discountcode = {}
        this.$store.commit('success', 'discountcode_saved')
      })
    },
    showDeleteModal(discountcode) {
      if(discountcode.orders_count) {
        return;
      }
      this.discountcode = discountcode;
      this.$bvModal.show('delete-discountcode')
    },
    deleteDiscountcode() {
      this.loading = true;
      api.discountcode().delete(this.discountcode.id).then(() => {
        this.$store.commit('success', 'discountcode_deleted');
        this.$bvModal.hide('delete-discountcode')
        this.discountcode = {};
        this.getDiscountcodes();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('discountcode_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
    '$store.state.language': {
      handler() {
        this.fields = [
          {key: 'is_active', label: this.$t('discountcode_is_active')},
          {key: 'code', label: this.$t('discountcode_code')},
          {key: 'type', label: this.$t('discountcode_type')},
          {key: 'amount', label: this.$t('discountcode_amount')},
          {key: 'stock', label: this.$t('discountcode_stock')},
          {key: 'starts_at', label: this.$t('discountcode_starts_at')},
          {key: 'ends_at', label: this.$t('discountcode_ends_at')},
          {key: 'actions', label: this.$t('order_actions')},
        ];
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tableBig{
  overflow: auto;
}
.btnDelete {
  vertical-align:middle;
}
.cursor_pointer {
  cursor: pointer;
}
.disabled_danger {
  opacity: .4;
}
</style>