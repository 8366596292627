export function groupBy(collection, iteratee) {
    return reduce(collection, (result, value, key) => {
        key = iteratee(value)
        if (Object.prototype.hasOwnProperty.call(result, key)) {
            result[key].push(value)
        } else {
            baseAssignValue(result, key, [value])
        }
        return result
    }, {})
}

export function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function setLocalStorage(key, value) {
    localStorage.setItem( key, value )
}

export function getLocalStorage(key) {
    return localStorage.getItem( key )
}

export function getEnvironment(){
    let isLocalhost = window.location.href.includes('localhost:8080');
    return isLocalhost ? 'local' : 'prod';
}

export function validateSlug( value ){
    return /^[a-z0-9-$-: _@&.+!*'()]+$/i.test(value) && !/\s/.test(value);
}

export function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
}

export function validateEmail( email ){
    if(email == null){ return null }
    return email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
}

export function getOrderStatusPageUrl(orderId, orderSecret){
    let url = '';
    if(getEnvironment() === 'local'){
        url = 'http://localhost:8080/';
    }else{
        url = 'https://order.fairtix.io/';
    }
    return url + 'order/' + orderId + '/' + orderSecret + '/status';
}

export function baseAssignValue(object, key, value) {
    if (key === '__proto__') {
        Object.defineProperty(object, key, {
            'configurable': true,
            'enumerable': true,
            'value': value,
            'writable': true
        })
    } else {
        object[key] = value
    }
}

export function reduce(collection, iteratee, accumulator) {
    const initAccum = arguments.length < 3
    return arrayReduce(collection, iteratee, accumulator, initAccum, baseEach)
}

export function arrayReduce(array, iteratee, accumulator, initAccum) {
    let index = -1
    const length = array == null ? 0 : array.length

    if (initAccum && length) {
        accumulator = array[++index]
    }
    while (++index < length) {
        accumulator = iteratee(accumulator, array[index], index, array)
    }
    return accumulator
}

export function baseEach(collection, iteratee) {
    if (collection == null) {
        return collection
    }

    const length = collection.length
    const iterable = Object(collection)
    let index = -1

    while (++index < length) {
        if (iteratee(iterable[index], index, iterable) === false) {
            break
        }
    }
    return collection
}

export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function applyCampaignRule(campaign, name, price) {
    if(campaign) {
        price = parseFloat(price);
        for(const r of campaign.rules) {
            let apply = true;
            for (const set of r.sets) {
                if(set.field === 'price') {
                    if(set.operation === 'less') {
                        if(!(price < parseFloat(set.value))) {
                            apply = false;
                            break;
                        }
                    }
                    else if(set.operation === 'greater') {
                        if(!(price > parseFloat(set.value))) {
                            apply = false;
                            break;
                        }
                    }
                    else if(set.operation === 'equal') {
                        if(price !== parseFloat(set.value)) {
                            apply = false;
                            break;
                        }
                    }
                    else {
                        apply = false;
                        break;
                    }
                }
                else if(set.field === 'name') {
                    if(set.operation === 'equal') {
                        if(name.toLowerCase() !== set.value.toLowerCase()) {
                            apply = false;
                            break;
                        }
                    }
                    else if(set.operation === 'contains') {
                        if(!name.toLowerCase().includes(set.value.toLowerCase())) {
                            apply = false;
                            break;
                        }
                    }
                    else {
                        apply = false;
                        break;
                    }
                }
            }
            if(apply) {
                if(r.discount_type === 'percentage') {
                    price = price - (price * (parseFloat(r.discount_value)/100));
                }
                else if(r.discount_type === 'fixed') {
                    price = price - parseFloat(r.discount_value);
                }
                price = parseFloat( price.toFixed(2))
            }
        }
    }
    return price;
}
export function applyCampaignHideRule(campaign, name, price) {
    if(campaign && campaign.hiderules.length) {
        price = parseFloat(price);
        for (const set of campaign.hiderules) {
            if(set.field === 'price') {
                if(set.operation === 'less') {
                    if(price < parseFloat(set.value)) {
                        return true;
                    }
                }
                else if(set.operation === 'greater') {
                    if(price > parseFloat(set.value)) {
                        return true;
                    }
                }
                else if(set.operation === 'equal') {
                    if(price === parseFloat(set.value)) {
                        return true;
                    }
                }
                else {
                    return false;
                }
            }
            else if(set.field === 'name') {
                if(set.operation === 'equal') {
                    if(name.toLowerCase() === set.value.toLowerCase()) {
                        return true;
                    }
                }
                else if(set.operation === 'contains') {
                    if(name.toLowerCase().includes(set.value.toLowerCase())) {
                        return true;
                    }
                }
                else {
                    return false;
                }
            }
        }
    }
    return false;
}

export function randomStr(length = 10) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function validatePassword(value, isEdit = false) {
    const passwordValidation = [];
    if(isEdit && value.length === 0) {
        return [];
    }
    if(value.length < 8) {
        passwordValidation.push('password_validation_8_chars');
    }
    if(!/[a-z]/.test(value)) {
        passwordValidation.push('password_validation_lowercase');
    }
    if(!/[A-Z]/.test(value)) {
        passwordValidation.push('password_validation_capital');
    }
    if(!/\d/.test(value)) {
        passwordValidation.push('password_validation_number');
    }
    if(!/[!@#$%^&*(),.?":{}|<>=+_-]/.test(value)) {
        passwordValidation.push('password_validation_special_char');
    }

    return passwordValidation;
}