<template>
  <div>
    <div :id="id"></div>
    <div class="d-none" :id="id+'iv'"></div>
    <p v-if="description" class="mt-2">{{ description }}</p>
    <b-button class="btn btn-orange-border mt-2" @click="downloadQr">{{ $t('download') }}</b-button>
  </div>
</template>

<script>
import QRCode from 'davidshimjs-qrcodejs'
import {randomStr} from "@/functions";

export default {
  name: "QrCode",
  props: ['text', 'width', 'height', 'colorDark', 'colorLight', 'description'],
  mounted() {
   this.generateQR();
  },
  methods: {
    generateQR() {
      document.getElementById(this.id).innerHTML = '';
      document.getElementById(this.id+'iv').innerHTML = '';
      new QRCode(document.getElementById(this.id), {
        text: this.text,
        width: this.width ?? 128,
        height: this.height ?? 128,
        colorDark: this.colorDark ?? "#000000",
        colorLight: this.colorLight ?? "#ffffff",
        correctLevel: QRCode.CorrectLevel.H
      });
      new QRCode(document.getElementById(this.id+'iv'), {
        text: this.text,
        width: 400,
        height: 400,
        colorDark: this.colorDark ?? "#000000",
        colorLight: this.colorLight ?? "#ffffff",
        correctLevel: QRCode.CorrectLevel.H
      });
    },
    downloadQr() {
      let img = document.querySelector('#' + this.id + 'iv img').src;
      const a = document.createElement('a');
      a.href = img;
      a.download = `${this.id}.png`;

      a.click();
    }
  },
  computed: {
    id() {
      return 'qr-' + randomStr();
    }
  },
  watch: {
    text() {
      this.generateQR();
    }
  }
}
</script>