export default class customerInformationApi {

    constructor(axios) {
        this.axios = axios
    }

    get(eventId) {
        return this.axios.get('/event/' + eventId + '/customer-information')
    }

    save(eventId, customerInformation) {
        return this.axios.post('/event/' + eventId + '/customer-information/' + customerInformation.id, customerInformation)
    }

    saveDefault(eventId, arrCustomerInformation) {
        return this.axios.post('/event/' + eventId + '/customer-information-default/', arrCustomerInformation)
    }

    create(eventId, customerInformation) {
        return this.axios.post('/event/' + eventId + '/customer-information', customerInformation)
    }

    getByTicket(ticketId, ticketSecret) {
        return this.axios.get('/ticket/' + ticketId + '/' + ticketSecret + '/customer-information')
    }

    getAnswersByTicket(ticketId, ticketSecret) {
        return this.axios.get('/ticket/' + ticketId + '/' + ticketSecret + '/customer-information/answers')
    }

    getByOrder(orderId, orderSecret) {
        return this.axios.get('/order/' + orderId + '/' + orderSecret + '/customer-information')
    }

    getFieldsByOrder(orderId, orderSecret) {
        return this.axios.get('/order/' + orderId + '/' + orderSecret + '/customer-fields')
    }

    getFieldsByTicket(ticketId, ticketSecret) {
        return this.axios.get('/ticket/' + ticketId + '/' + ticketSecret + '/customer-fields')
    }

    submit(ticketId, ticketSecret, answers) {
        return this.axios.post('/ticket/' + ticketId + '/' + ticketSecret + '/customer-information', {answers})
    }

    submitByOrder(orderId, orderSecret, answers) {
        return this.axios.post('/order/' + orderId + '/' + orderSecret + '/customer-information', {answers})
    }

    getCustomerFields(eventId) {
        return this.axios.get('/event/' + eventId + '/customer-fields')
    }

    saveCustomerFields(eventId, fields) {
        return this.axios.post('/event/' + eventId + '/customer-fields', {
            fields
        })
    }

    delete(eventId, questionId) {
        return this.axios.delete('/event/' + eventId + '/customer-information/' + questionId)
    }
}
