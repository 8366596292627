<template>
  <div class="scan-operation-page">
    <b-row>
      <b-col cols="12">
        <div class="content-main-container container-distance">
          <div class="title-container">{{ $t("scan_operation_session_configure_scanners") }}</div>
          <div class="content-container">
              <div v-for="(scanner, index) in scanners" :key="index">
                <b-row>
                  <b-col cols="4"></b-col>
                  <b-col cols="4">
                    <label>{{$t('sessions')}}</label>
                    <b-row>
                      <b-col cols="12">
                        <v-select v-model="scanner.session" :options="sessions" label="name"></v-select>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <label>{{$t('scan_operation_products')}}</label>
                    <b-row>
                      <b-col cols="10">
                        <v-select v-model="scanner.product" :options="scanner.session ? scanner.session.products : []" label="name">
                          <template v-slot:option="option">
                           {{ option.product_group && option.product_group.type !== 'default' ? option.product_group.name + ' - ' : '' }}{{option.name}}
                          </template>
                        </v-select>
                      </b-col>
                      <b-col cols="2">
                        <b-button :disabled="loading || !(scanner.product && scanner.session)" @click="addSession(index)"  variant="primary" class="float-right"><b-icon icon="plus"></b-icon></b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col cols="4">
                    <h4>{{ scanner.name }}</h4>
                  </b-col>
                  <b-col cols="4">
                    <b-table :items="scanner.sessionProducts" :fields="['name']" thead-class="d-none" bordered borderless>
                      <template v-slot:cell(name)="{item}" >
                        {{ item.session.name }} ( {{ formatSessionDate(item.session.starts_at, item.session.ends_at) }})
                      </template>
                    </b-table>
                  </b-col>
                  <b-col cols="4">
                    <b-table :items="scanner.sessionProducts" :fields="['name', 'actions']" thead-class="d-none" bordered borderless>
                      <template v-slot:cell(name)="{item}" >
                        {{ item.product && item.product.product_group && item.product.product_group.type !== 'default' ? item.product.product_group.name + ' - ' : '' }}
                        {{ item.product ? item.product.name : '' }}
                      </template>
                      <template v-slot:cell(actions)="{item}" >
                        <div class="d-flex justify-content-end" >
                          <b-btn :disabled="loading" @click="handleDeleteClick(index, item)" pill variant="outline-secondary"><b-icon icon="x"></b-icon></b-btn>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <hr>
              </div>

              <b-row>
                <b-col cols="12">
                  <b-button @click="$router.push({name: 'ScanOperationSummary', params: {id: $route.params.id}})" class="mb-3 ml-3 btn-new float-right">{{ $t('next') }}</b-button>
                  <b-button @click="$router.push({name: 'ScanOperationProductsConfigure', params: {id: $route.params.id}})" class="mb-3 btn-new float-right">{{ $t('previous') }}</b-button>
                </b-col>
              </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal :title="$t('scan_operation_session_modal_delete')" id="delete-session" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('delete_entry') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button :disabled="loading" @click="removeSession()" variant="danger">{{ $t('delete') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import moment from 'moment';

export default {
  data() {
    return {
      scanOperation: null,
      products: [],
      sessions: [],
      scanners: [],
      loading: false,

      selectedIndex: null,
      selectedItem: null,
    };
  },
  methods: {
    addSession(index) {
      const isDuplicate = this.scanners[index].sessionProducts.filter(sessionProduct => {
        return (sessionProduct.session.id === this.scanners[index].session.id && sessionProduct.product.id === this.scanners[index].product.id)
      }).length
      if(isDuplicate) {
        this.$store.commit('errors', ['scan_operation_session_configure_scanners_duplicated'])
        return;
      }
      this.loading = true;
      api.scanOperationPrivileges().create({
        scanner_id: this.scanners[index].id,
        session_id: this.scanners[index].session.id,
        product_id: this.scanners[index].product.id,
      }).then(() => {
        this.scanners[index].sessionProducts.push({
          session: this.scanners[index].session,
          product: this.scanners[index].product,
        })

        this.scanners[index].session = null;
        this.scanners[index].product = null;
        this.$store.commit('success', 'scan_operation_session_product_saved')
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    handleDeleteClick(index, item) {
      this.selectedIndex = index;
      this.selectedItem = item;
      this.$bvModal.show('delete-session')
    },
    removeSession() {
      const index = this.selectedIndex;
      const item = this.selectedItem;

      this.loading = true;
      api.scanOperationPrivileges().delete({
        scanner_id: this.scanners[index].id,
        session_id: item.session.id,
        product_id: item.product.id,
      }).then(() => {
        this.$bvModal.hide('delete-session')
        this.loading = false;
        this.scanners[index].sessionProducts = this.scanners[index].sessionProducts.filter(sessionProduct => {
          return !(sessionProduct.session.id === item.session.id && sessionProduct.product.id === item.product.id);
        })
        this.$store.commit('success', 'scan_operation_session_product_deleted')
        this.selectedIndex = null;
        this.selectedItem = null;
      }).catch((error) => {
        console.log(error, 'error')
        this.loading = false;
      })
    },
    formatSessionDate(start, end) {
      return moment(start).format('DD-MM-YYYY HH:mm') + ' - ' + moment(end).format('DD-MM-YYYY HH:mm');
    }
  },
  mounted() {
    api.scanOperation().find(this.$route.params.id).then((response)=>{
      this.scanOperation = response;
      this.scanners = this.scanOperation.scanners;
      this.scanners = this.scanners.map(scanner => {
        const tmpScanner = Object.assign({session: null, product: null, sessionProducts: []}, scanner);
        scanner.privileges.forEach(privelege => {
          tmpScanner.sessionProducts.push({
            product: privelege.product,
            session: privelege.session,
          })
        })
        return tmpScanner;
      });
      this.sessions = response.sessions;
    });
  },
  created(){
    document.title = this.$t('backend_title_scan_operation_scanner_configuration')
    if(this.$store.state.user) {
      if (!this.$can('scan_operation_edit') && !this.$can('scan_operation_create')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if (!this.$can('scan_operation_edit') && !this.$can('scan_operation_create')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
};
</script>

<style scoped>
.scan-operation-page tr,.scan-operation-page td {
  height: 61px;
}
</style>
