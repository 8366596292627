<template>
  <div>
    <b-form-group v-if="$can('master_data_company_links_create')" class=" text-right container-distance">
      <b-button variant="new" @click="showCompanyLinkModal">{{ $t('new') }}</b-button>
    </b-form-group>
    <b-modal id="add-company-link" :title="$t('company_link_new_modal_title')">
      <div>
        <b-row>
          <b-col>
            <b-form-group :label="$t('company_master_name')">
              <b-select v-model="companyLink.company_master_id" text-field="name" value-field="id" :options="companies"></b-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('company_sub_name')">
              <b-select v-model="companyLink.company_sub_id" text-field="name" value-field="id" :options="companies"></b-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('location_name')">
              <b-select v-model="companyLink.location_id" text-field="name" value-field="id" :options="venues"></b-select>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="createCompanyLink()">{{ $t('create') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('company_link_modal_edit', companyLink)" id="edit-company-link">
      <div>
        <b-row>
          <b-col>
            <b-form-group :label="$t('company_master_name')">
              <b-select v-model="companyLink.company_master_id" text-field="name" value-field="id" :options="companies"></b-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('company_sub_name')">
              <b-select v-model="companyLink.company_sub_id" text-field="name" value-field="id" :options="companies"></b-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('location_name')">
              <b-select v-model="companyLink.location_id" text-field="name" value-field="id" :options="venues"></b-select>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="saveCompanyLink()" variant="success">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
<div class="tableBig">
 <b-table v-if="companyLinks.length" class="table-container" :items="companyLinks" :fields="fields">
      <template v-slot:cell(company_master_id)="{item}">
        {{ item.company_master_id }}
      </template>
      <template v-slot:cell(company_sub_id)="{item}">
        {{ item.company_sub_id }}
      </template>
      <template v-slot:cell(location_id)="{item}">
        <span v-if="item.location_id">{{ item.location_id }}</span>
        <span v-else>{{ $t('not_set') }}</span>
      </template>
      <template v-slot:cell(actions)="{item}">
        <b-button class="mr-3 mb-1" v-if="$can('master_data_company_links_edit')" variant="primary" @click="editCompanyLink(item)">{{ $t('manage') }}</b-button>
        <b-icon v-if="$can('master_data_company_links_delete')" @click="showDeleteModal(item)" icon="trash" font-scale="1.5" class="text-secondary btnDelete" :class="{cursor_pointer: item.orders_count === 0, disabled_danger: item.orders_count}"></b-icon>
      </template>
    </b-table>

  <pagination v-if="page_data" :limit="3" :data="page_data" @pagination-change-page="getCompanyLinksData"></pagination>
  
    <b-alert v-else show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>

  <b-modal :title="$t('delete')" id="delete-company-link" size="lg">
    <div>
      <b-row>
        <b-col>{{ $t('delete_entry') }}</b-col>
      </b-row>
    </div>
    <template #modal-footer="{cancel}">
      <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
      <b-button :disabled="loading" @click="deleteCompanyLink" variant="danger">
        <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon> {{ $t('delete') }}
      </b-button>
    </template>
  </b-modal>
</div>
   

  </div>
</template>

<script>
import api from '../api'
export default {
  components: {
  },
  name: 'CompanyLinksOverview',
  data() {
    return {
      companyLink: {},
      companyLinks: [],
      venues: [],
      companies: [],
      page_data: null,
      loading: false,
      fields: [
        {key: 'company_master.name', label: this.$t('company_master_name')},
        {key: 'company_sub.name', label: this.$t('company_sub_name')},
        {key: 'location.name', label: this.$t('location_id')},
        {key: 'actions', label: this.$t('order_actions')},
      ]
    }
  },
  created() {
    document.title = this.$t('company_links_overview')
    if(this.$store.state.user) {
      if(!this.$can('master_data_company_links_overview')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.getCompanyLinksData()
    this.getVenues()
    this.getCompanies()
  },
  methods: {
    showCompanyLinkModal() {
       this.companyLink = {};
       this.$bvModal.show('add-company-link')
    },
    createCompanyLink() {
      this.adding = true

      if (this.companyLink.company_master_id === this.companyLink.company_sub_id) {
        this.$store.commit('errors', [this.$t('company_link_master_sub_not_equal')])
        return
      }

      api.companyLinks().create(this.companyLink).then(() => {
        this.$bvModal.hide('add-company-link')
        this.getCompanyLinksData()
      })
    },
    getCompanyLinksData(page = 1) {
      api.companyLinks().overview(page).then(companyLinks => {
        this.companyLinks = companyLinks.data
        this.page_data = companyLinks
      })
    },
    getVenues() {
      api.venues().getVenuesNames().then(venues => {
        this.venues = venues
      })
    },
    getCompanies() {
      api.company().getCompaniesName().then(companies => {
        this.companies = companies
      })
    },
    editCompanyLink(companyLink) {
      this.companyLink = Object.assign({}, companyLink)
      this.$bvModal.show('edit-company-link')
    },
    saveCompanyLink() {
      if (this.companyLink.company_master_id === this.companyLink.company_sub_id) {
        this.$store.commit('errors', [this.$t('company_link_master_sub_not_equal')])
        return
      }

      api.companyLinks().save(this.companyLink).then(() => {
        this.$bvModal.hide('edit-company-link')
        this.getCompanyLinksData()
        this.companyLink = {}
        this.$store.commit('success', 'company_link_saved')
      })
    },
    showDeleteModal(companyLink) {
      this.companyLink = companyLink;
      this.$bvModal.show('delete-company-link')
    },
    deleteCompanyLink() {
      this.loading = true;
      api.companyLinks().delete(this.companyLink.id).then(() => {
        this.$store.commit('success', 'company_link_deleted');
        this.$bvModal.hide('delete-company-link')
        this.companyLink = {};
        this.getCompanyLinksData();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('master_data_company_links_overview')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
    '$store.state.language': {
      handler() {
        this.fields = [
          {key: 'company_master.name', label: this.$t('company_master_id')},
          {key: 'company_sub.name', label: this.$t('company_sub_id')},
          {key: 'location.name', label: this.$t('location_id')},
          {key: 'actions', label: this.$t('order_actions')},
        ];
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tableBig{
  overflow: auto;
}
.btnDelete {
  vertical-align:middle;
}
.cursor_pointer {
  cursor: pointer;
}
.disabled_danger {
  opacity: .4;
}
</style>
