<template>
  <div>

    <b-row class="Parent">
      <b-col cols="12" lg="3">
        <b-form-group v-if="$can('invoice_overview_search')">
          <b-form-input class="v-text-field" v-model="search" debounce="750"
                        placeholder="Search"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="invoices.length">
      <b-col class="tableBig">
        <b-table class="table-container" :items="invoices" :fields="[ {key: 'company', label: $t('company_name')},{key: 'invoice_number', label: $t('administration_invoice_number', {number: ''})},{key: 'created_at', label: $t('order_created_at')},{key: 'status', label: $t('event_status')},{key: 'total', label: $t('cart_total')},{key: 'actions', label: $t('order_actions')}]">
          <template v-slot:cell(company)="{item}">
            {{ item.company.name }}
          </template>
          <template v-slot:cell(created_at)="{item}">
            {{ item.created_at | date_time}}
          </template>
          <template v-slot:cell(status)="{item}">
            <status-label :status="item.status"></status-label>
          </template>
          <template v-slot:cell(total)="{item}">
            {{ item.total | currency }}
          </template>
          <template v-slot:cell(actions)="{item}">
            <b-button @click="downloadFile(item.invoice_number, 'invoice')" v-if="item.url" class="mr-3 mb-1">{{ $t('company_invoice_overview_invoice') }}</b-button>
            <b-button @click="downloadFile(item.invoice_number, 'payout_summary')" v-if="item.payout_summary_url">{{ $t('company_invoice_overview_payout_summary') }}</b-button>

            <b-button @click="generateInvoice(item.invoice_number)" v-if="!item.url" class="mr-3 mb-1">{{ $t('invoice_overview_btn_generate_invoice') }}</b-button>
            <b-button @click="generatePayoutSummary(item.invoice_number)" v-if="!item.payout_summary_url" class="mr-3 mb-1">{{ $t('invoice_overview_btn_generate_payout_summary') }}</b-button>

            <b-button v-if="$can('invoice_details_view')" class="mr-2 ml-3 btnEvent" variant="row-edit" :to="{name:'InvoiceDetails', params:{number: item.invoice_number}}">{{ $t('manage') }}</b-button>
          </template>
        </b-table>
        <pagination v-if="page_data" :limit="3" :data="page_data" @pagination-change-page="getInvoices"></pagination>
      </b-col>
    </b-row>

    <b-alert v-else show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>

  </div>
</template>

<script>
import api from '../api'
import StatusLabel from "@/components/StatusLabel";

export default {
  components: {
    StatusLabel
  },
  name: 'InvoiceOverview',
  data() {
    return {
      invoices: {},
      search: '',
      page_data: null,
    }
  },
  created() {
    document.title = this.$t('backend_title_invoices_overview')
    if(this.$store.state.user) {
      if(!this.$can('invoice_overview_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.getInvoices();
  },
  methods: {
    generatePayoutSummary(invoiceNumber){
      api.invoice().generatePayoutSummary(invoiceNumber)
      this.$store.commit('success', 'invoice_overview_file_generated')
    },
    generateInvoice(invoiceNumber){
      api.invoice().generateInvoice(invoiceNumber)
      this.$store.commit('success', 'invoice_overview_file_generated')
    },
    downloadFile(invoice_number, type){
      api.invoice().downloadFile(invoice_number, type).then((response) => {
        api.invoice().getFileName(invoice_number, type).then((filename) => {
          try {
            let url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (error) {
            console.error('Error downloading PDF:', error);
          }  
        })
      });
    },
    getInvoices(page = 1) {
      if (!page) {
        page = 1
      }
      api.invoice().getAll(page).then(invoices => {
        this.page_data = invoices
        this.invoices = invoices.data
      })
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('invoice_overview_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
    search() {
      if (this.search.length === 0) {
        this.getInvoices(1)
        return
      }
      api.invoice().searchInvoice(this.search).then((invoices) => {
        this.page_data = invoices
        this.invoices = invoices.data
      })
    },
  }
}
</script>

<style>

</style>
