<template>
  <b-container>
    <b-row>
      <b-col class="content-block cart-container text-center pr-5 pl-5">
        
        <gtm-page-view v-if="gtm_id" :gtm_id="gtm_id"></gtm-page-view>
        <gtm-purchase v-if="gtm_id" :gtm_id="gtm_id" :total_price="total_price" :items="items" :order_secret="order_secret"></gtm-purchase>

        <div class="pt-4 pb-4">
          <b-icon icon="check-circle-fill" font-scale="4" style="color:#000"></b-icon>
        </div>
        <h1 class="title">{{ $t('order_thank_you', {order_number: order_number}) }}</h1>
        <p class="subtitle m-2">{{ $t('order_thank_you_text') }}</p>
        <a :href="order_status_page_url" v-if="order_status_page_url">
          <b-button variant="success" class="mt-4 mb-5" id="btn-ticket-download">{{ $t('order_thank_you_btn_ticket_download') }}</b-button>
        </a>

        <b-row v-if="upcoming_events && upcoming_events.length > 0" class="mt-4 mb-3">
          <b-col>
            <p class="sub-title">{{ $t('order_thank_you_upcoming_events') }}</p>
            <b-row class="custom-gutter mt-2">
              <b-col v-for="event in upcoming_events" :key="event.id" :style="{ backgroundImage: `url(${event.image_url})` }" class="event-col mb-4">
                <div :class="(event.image_url) ? 'overlay' : 'overlay dark'"></div>
                <div class="content">
                  <div class="event-details">
                    <div class="event-name">{{ event.name }}</div>
                    <div class="event-date">{{ event.starts_at | date_written }}</div>
                  </div>
                  <a class="event-button" :href="getEventLink(event)" variant="success">{{ $t('order_thank_you_btn_event_details') }}</a>
                </div>
              </b-col>
            </b-row>
            
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'
import GtmPageView from '@/components/GtmPageView'
import GtmPurchase from '@/components/GtmPurchase'
import {groupBy, getOrderStatusPageUrl} from "@/functions";

export default {
  components: {
    GtmPurchase,
    GtmPageView
  },
  data() {
    return {
      order_number: '',
      gtm_id: null,
      total_price: null,
      items: [],
      order_secret: null,
      order_status_page_url: '',
      upcoming_events: null,
      order: null
    }
  },
  created(){
    if(document.body.contains(document.getElementById('company-header'))){
      document.getElementById("company-header").scrollIntoView();
    }
    if(this.$route.query.order_number) {
      this.order_number = this.$route.query.order_number; 
    }
    if(this.$route.query.order_number && this.$route.query.order_secret){
      api.order().getByOrdernumberSecret(this.$route.query.order_number, this.$route.query.order_secret).then(order => {
        this.order = order;
        if(order && order.id && order.secret && order.company.domain){
          this.order_status_page_url = getOrderStatusPageUrl(order.id, order.secret)
        }
        if(order && order.event && order.event.gtm_id){

          // Create the GTM scripts
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer',order.event.gtm_id);

          let gtm_noscript = document.createElement('noscript');
          let gtm_iframe = document.createElement('iframe');
          gtm_iframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=' + order.event.gtm_id);
          gtm_iframe.setAttribute('height', '0');
          gtm_iframe.setAttribute('width', '0');
          gtm_iframe.setAttribute('style', 'display:none;visibility:hidden');
          gtm_noscript.appendChild(gtm_iframe);
          document.body.appendChild(gtm_noscript);

          const products = groupBy(order.order_products, (product) => {
            return product.product.name;
          });

          let items = [];

          for(const x in products) {
            const p = products[x][0];
            items.push({
              item_id: p.product.id,
              item_name: p.product.name,
              item_category: order.event.name,
              item_category2: 'Tickets',
              item_category3: p.product.product_group.name === 'event_default_group' ? '' : p.product.product_group.name,
              price: p.price,
              quantity: products[x].length
            });
          }

          this.order_secret = this.$route.query.order_secret
          this.total_price = order.total
          this.items = items;
          this.gtm_id = order.event.gtm_id
        }

        if(order && order.company && order.company.order_thank_you_show_events){
          api.event().getUpcomingEvents(order.company.id, order.event.id).then(events => {
            this.upcoming_events = events;
          })
        }

      })
    }
    document.title = this.$t('order_thank_you_title')
  },
  methods:{
    getEventLink(event){
      let posLink = ''
      event.points_of_sale.some(pos => {
        if(pos.status === 'live' && pos.show_in_overview){
          posLink = pos.url
          return true
        }
        return false
      })
      return 'https://' + this.order.company.domain + '/p/' + posLink + '?utm_source=fairtix&utm_medium=ticketshop&utm_campaign=order_thank_you_page_event_display'
    }
  
  }
}
</script>
<style scoped>
h1.title {
  font-size: 2em;
}

p.subtitle {
  font-size: 1.4em;
}

#btn-ticket-download {
  font-size: 1.4em;
  border-radius:50px;
  padding:12px 35px
}

.sub-title{
  margin-top:60px;
  font-size:2em
}

.custom-gutter {
  display: flex;
  flex-wrap: wrap;
  margin: -30px;
}

.event-col{
  position: relative;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 20px;
  border-radius: 5px;
  height:230px;
  display:flex;
  flex-direction: column;
  margin: 10px;
}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 7px;
}
.overlay.dark{
  background-color: rgba(0, 0, 0, 0.75);
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.event-details {
  flex: 0 1 auto;
}

.event-name{
  color:#FFF;
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
}

.event-date{
  color:#FFF;
  font-size: 1em;
  text-align: left;
}

.event-col a{
  color:#FFF;
  background-color:#28A644;
  font-weight: bold;
  border-radius:100px;
  font-size:1.1em;
  padding:8px 40px;
  align-self: flex-start;
  display:inline-block;
}

</style>
