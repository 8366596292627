<template>
  <div>
    <b-row>
      <b-col cols="12" sm="9">
        <h1 class="title text-left">{{ $t('layout_settings') }}</h1>
      </b-col>
      <b-col cols="12" sm="3" class=" text-left text-sm-right">
        <b-form-group>
          <b-button v-if="$can('my_company_layout_edit')" variant="primary" @click="save()">{{ $t('save') }}</b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="pb-3">
      <b-col cols="12" md="" lg="4" class="mb-4 mb-lg-0">
        <b-card :title="$t('layout_header')" >
          <b-card-text>
            <b-form-group :label="$t('layout_logo')">
              <input type="file" id="file" accept="image/*" ref="logo" v-on:change="onLogoChange()" :disabled="!$can('my_company_layout_edit')"/>
            </b-form-group>
            <b-form-group v-if="layout.logo" :label="$t('company_layout_logo_url')">
              <b-form-input v-model="layout.logo_url"></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('layout_header_backgroundColor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.header.backgroundColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.header.backgroundColor"></div>
            </b-form-group>
            <div class="p-3" :style="layout.header">
              <b-img fluid :src="layout.logo" v-if="layout.logo" />
              <a v-if="layout.logo && $can('my_company_layout_edit')" href="javascript:" @click="layout.logo = null" class="d-block font-14">
                {{ $t('my_company_layout_remove_image') }}
              </a>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col  cols="12" lg="4"  class="mb-4 mb-lg-0">
        <b-card :title="$t('layout_colors')">
          <b-card-text>
            <b-form-group :label="$t('layout_primary_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.primary.color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.primary.color"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_secondary_backgroundColor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.secondary.backgroundColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.secondary.backgroundColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_secondary_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.secondary.color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.secondary.color"></div>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12" lg="4"  class="mb-4 mb-lg-0">
        <b-card :title="$t('layout_info_blocks')">
          <b-card-text>
            <b-form-group :label="$t('layout_info_blocks_titleColor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.info_blocks.titleColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.info_blocks.titleColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_info_blocks_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.info_blocks.color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.info_blocks.color"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_info_blocks_backgroundColor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.info_blocks.backgroundColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.info_blocks.backgroundColor"></div>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="pb-3">
      <b-col cols="12" lg="4"  class="mb-4 mb-lg-0">
        <b-card :title="$t('layout_top_bar')">
          <b-card-text>
            <b-form-group :label="$t('layout_top_bar_title_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.top_bar.titleColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.top_bar.titleColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_top_bar_date_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.top_bar.dateColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.top_bar.dateColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_stepper_hint_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.stepper.hintColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.stepper.hintColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_stepper_backgroundColor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.stepper.backgroundColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.stepper.backgroundColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_stepper_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.stepper.color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.stepper.color"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_stepper_done_backgroundColor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.stepper.doneBackgroundColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.stepper.doneBackgroundColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_stepper_done_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.stepper.doneColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.stepper.doneColor"></div>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col  cols="12" lg="4"  class="mb-4 mb-lg-0" >
        <b-card :title="$t('layout_cart')">
          <b-card-text>
            <b-form-group :label="$t('layout_cart_backgroundcolor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart.backgroundColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart.backgroundColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_cart_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart.color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart.color"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_cart_product_group_backgroundColor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart.productGroupBackgroundColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart.productGroupBackgroundColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_cart_product_group_textColor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart.productGroupTextColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart.productGroupTextColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_cart_product_title_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart.productTitleColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart.productTitleColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_cart_product_subtext_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart.productSubtextColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart.productSubtextColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_cart_product_price_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart.productPriceColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart.productPriceColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_cart_button_backgroundColor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart.buttonBackgroundColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart.buttonBackgroundColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_cart_button_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart.buttonColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart.buttonColor"></div>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12" lg="4"  class="mb-4 mb-lg-0">
        <b-card :title="$t('layout_cart_sidebar')">
          <b-card-text>
            <b-form-group :label="$t('layout_cart_sidebar_backgroundColor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart_sidebar.backgroundColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart_sidebar.backgroundColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_cart_sidebar_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart_sidebar.color"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart_sidebar.color"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_cart_sidebar_button_backgroundColor')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart_sidebar.buttonBackgroundColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart_sidebar.buttonBackgroundColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_cart_sidebar_button_color')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.cart_sidebar.buttonColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.cart_sidebar.buttonColor"></div>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="last-bar">
       <b-col cols="12" lg="3" class="mb-4 mb-lg-0">
        <b-card :title="$t('layout_countdown')">
          <b-card-text>
            <b-form-group :label="$t('layout_countdown_card_top')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.countdown.cardTopColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.countdown.cardTopColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_countdown_card_bottom')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.countdown.cardBottomColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.countdown.cardBottomColor"></div>
            </b-form-group>
            <b-form-group :label="$t('layout_countdown_card_text')">
              <color-picker v-if="$can('my_company_layout_edit')" v-model="layout.countdown.cardTextColor"></color-picker>
              <div v-else :style="'width: 32px; height: 32px; background-color: ' + layout.countdown.cardTextColor"></div>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'
import axios from 'axios'
import {validateUrl} from "@/functions";

export default {
  data() {
    return {
      working: false,
      company: {},
      editUser: {},
      layout: {
        logo: null,
        secondary: {},
        info_blocks: {},
        top_bar: {},
        stepper: {},
        cart_sidebar: {},
        cart:{
          product:{}
        },
        header: {},
        primary: {},
        countdown: {
          cardTopColor: {},
          cardBottomColor: {},
          cardTextColor: {}
        }
      }
    }
  },
  created() {
    if(this.$store.state.user) {
      if(!this.$can('my_company_layout_view') && !this.$can('admin_company_details_edit')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.loadCompany()
  },
  methods: {
    onLogoChange() {
      api.company().signLogoUpload(this.companyId, this.$refs['logo'].files[0].name).then((response) => {
        // Upload the file
        axios.create().put(response.upload_url, this.$refs['logo'].files[0], {
          headers: {
            'Content-Type': this.$refs['logo'].files[0].type
          }
        }).catch((e) => {
          // Upload failed...
          console.error(e)
        }).then(() => {
          // upload done, create the video in the database
          this.layout.logo = response.target_url
        })
      })
    },
    loadCompany() {
      if (!this.user) {
        setTimeout(this.loadCompany, 100)
        return
      }
      api.company().get(this.companyId).then(company => {
        this.company = company
        if (this.company.layout) {
          if(Array.isArray(this.company.layout.header)) {
            this.company.layout.header = {}
          }
          if(Array.isArray(this.company.layout.primary)) {
            this.company.layout.primary = {}
          }
          if(Array.isArray(this.company.layout.secondary)) {
            this.company.layout.secondary = {}
          }
          if(Array.isArray(this.company.layout.info_blocks)) {
            this.company.layout.info_blocks = {}
          }
          if(Array.isArray(this.company.layout.top_bar)) {
            this.company.layout.top_bar = {}
          }
          if(Array.isArray(this.company.layout.stepper)) {
            this.company.layout.stepper = {}
          }
          if(Array.isArray(this.company.layout.cart_sidebar)) {
            this.company.layout.cart_sidebar = {}
          }
          if(Array.isArray(this.company.layout.cart)) {
            this.company.layout.cart = {}
          }
          if(Array.isArray(this.company.layout.countdown)) {
            this.company.layout.countdown = {}
          }
          this.layout = Object.assign(this.layout, this.company.layout)
        }
      })
    },
    save() {
      if (this.layout.logo_url) {
        if (!validateUrl(this.layout.logo_url)) {
          this.$store.commit('errors', [this.$t('pos_invalid_url')]);
          return;
        }
      }
      api.company().updateLayout(this.companyId, this.layout).then(() => {
        this.loadCompany()
        this.$store.commit('success', 'layout_updated');
      })
    }
  },
  computed: {
    companyId() {
      return this.user.role === 'admin' ? this.$route.params.id : 'mine'
    },
    ...mapState(['user'])
  },
  watch:{
    '$store.state.user': {
      handler() {
        if(!this.$can('my_company_layout_view') && !this.$can('admin_company_details_edit')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.last-bar{
  margin-bottom:190px !important
}
.one-colorpicker {
  border: solid 1px silver;
}
@media (max-width:992px) {
  .title{
  font-size: 25px;
  text-align: center;
}
.card-text{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.card-text > fieldset{
  width: 50%;
  margin-bottom: 20px;
}
}
@media (max-width:576px) {
.card-text{
  display: block;
}
.card-text > fieldset{
  width: 100%;
  margin-bottom: 20px;
}
}
</style>
