<template>
  <div id="dashboard">
    <b-row>
      <b-col v-for="event in currentEvents" cols="12"  lg="6" sm="6" xl="4" :key="'c' + event.id">
        <div class="event-statistics">
          <div class="event-statistics-title px-4 py-3 bg-blue">
            <b-row>
              <b-col class="text-white event-name" :title="event.name">
                {{ $t('dashboard_events_scans') }} {{ event.name }}
              </b-col>
              <b-col cols="2" class="text-right">
                <b-icon v-if="$can('dashboard_view')" @click="getEventSoldTickets(event)" icon="pie-chart-fill" class="cursor-pointer text-white"></b-icon>
              </b-col>
            </b-row>
          </div>
          <div class="event-statistics-content active-event text-center">
            <vue-ellipse-progress
                :progress="event.percentage"
                :color="(event.percentage === 100 ? '#4FD166' : '#1B3E58')"
                :fontColor="(event.percentage === 100 ? '#4FD166' : '#1B3E58')"
                :size="130"
                thickness="3%"
                emptyThickness="3%"
            >
              <span class="spinner-body" :style="'color:' + (event.percentage === 100 ? '#4FD166' : '#1B3E58')">{{ event.scanned + event.box_offices }}</span>
            </vue-ellipse-progress>
            <span class="d-block mt-2 tickets-sold">{{ $t('dashboard_total_visitors_inside') }}</span>
          </div>
          <div class="event-statistics-stats scans pb-3">
            <b-row>
              <b-col class="text-center">
                <span class="d-block font-weight-bold">{{ event.scanned }}</span>
                <span class="d-block">{{ $t('dashboard_scanned') }}</span>
              </b-col>
              <b-col class="text-center">
                <span class="d-block font-weight-bold">{{ event.total_tickets - event.scanned }}</span>
                <span class="d-block">{{ $t('dashboard_left_to_scan') }}</span>
              </b-col>
              <b-col class="text-center">
                <span class="d-block font-weight-bold">{{ event.box_offices }}</span>
                <span class="d-block">{{ $t('dashboard_box_office_entries') }}</span>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col v-for="event in events" cols="12" xl="4" lg="6" sm="6" :key="event.id">
        <div class="event-statistics">
          <div class="event-statistics-title">
            <b-row>
              <b-col class="event-name" :title="event.name">
                <span v-if="$store.state.user.company_id === 1" class="d-block font-weight-bold">{{ event.company.name }}</span>
                {{ event.name }}
              </b-col>
              <b-col cols="2" class="text-right">
                <b-icon v-if="$can('dashboard_view')" @click="getEventSoldTickets(event)" icon="pie-chart-fill" class="cursor-pointer"></b-icon>
              </b-col>
            </b-row>
          </div>
          <div class="event-statistics-content text-center">
            <vue-ellipse-progress
                :progress="event.percentage"
                :color="(event.percentage === 100 ? '#4FD166' : '#1B3E58')"
                :fontColor="(event.percentage === 100 ? '#4FD166' : '#1B3E58')"
                :size="130"
                thickness="3%"
                emptyThickness="3%"
            >
              <span class="spinner-body" :style="'color:' + (event.percentage === 100 ? '#4FD166' : '#1B3E58')">{{ event.total_sold }}</span>
            </vue-ellipse-progress>
            <span class="d-block mt-2 tickets-sold">{{ $t('dashboard_events_tickets_sold', {'guest_list_number': event.total_guest_list}) }}</span>
            <span class="d-block total-revenue">  {{ $t('dashboard_events_total_revenue') }} {{ event.total_revenue | currency }}</span>
          </div>
          <div class="event-statistics-stats sales pb-3">
            <b-row>
              <b-col class="text-center">
                <span class="d-block font-weight-bold">{{ event.sold_today.toLocaleString() }}</span>
                <span class="d-block">{{ $t('dashboard_events_today') }}<br>{{ $t('dashboard_events_ticket_sold') }}</span>
              </b-col>
              <b-col class="text-center last-today">
                <span class="d-block font-weight-bold">{{ event.guest_list_today.toLocaleString() }}</span>
                <span class="d-block">{{ $t('dashboard_events_ticket_guest_list') }}</span>
              </b-col>
              <b-col class="text-center">
                <span class="d-block font-weight-bold">{{ event.sold_yesterday.toLocaleString() }}</span>
                <span class="d-block">{{ $t('dashboard_events_yesterday') }}<br>{{ $t('dashboard_events_ticket_sold') }}</span>
              </b-col>
              <b-col class="text-center">
                <span class="d-block font-weight-bold">{{ event.guest_list_yesterday.toLocaleString() }}</span>
                <span class="d-block">{{ $t('dashboard_events_ticket_guest_list') }}</span>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-modal @hidden="closeModal" id="dashboard-stat-modal" :title="$t('dashboard_sold_tickets', {event: selectedEvent ? selectedEvent.name : ''})" ok-only>
      <b-row class="d-flex justify-content-start">
        <b-col cols="2" class="d-flex justify-content-end">
          <BounceLoader color="#f0b52d" :size="60" sizeUnit="px"></BounceLoader>
        </b-col>
        <b-col cols="10">
          <span class="d-block" style="font-size:12pt">{{ $t('dashboard_modal_active_carts') }}</span>
          <span class="d-block" style="font-size:22pt">{{ activeCarts }}</span>
        </b-col>
      </b-row>
      
      <hr>
      
      <div v-if="sold_tickets">
        <div v-for="(tickets, group) in sold_tickets" :key="group" class="mb-3">
          <div :class="{'bg-light':true,'p-3':group !== 'event_default_group'}">{{ group !== 'event_default_group' ? group : '' }}</div>
          <b-row v-for="ticket in tickets" :key="ticket.name" class="border-bottom py-3 w-100 m-0 ticket-row">
            <b-col cols="12" sm="12" lg="5"  class="mb-3 mb-md-0">{{ ticket.name }}</b-col>
            <b-col cols="6" sm="6" lg="3" >
              <b-progress :value="Math.ceil((ticket.sold/ticket.stock) * 100)"></b-progress>
            </b-col>
            <b-col v-if="ticket.stock === -1" cols="3" sm="3" lg="2" >{{ ticket.sold }}</b-col>
            <b-col v-else cols="3" sm="3" lg="2" >{{ ticket.stock ? (Math.ceil((ticket.sold / ticket.stock) * 100)) : 0 }}%</b-col>
            <b-col v-if="$can('reports_sales_by_tickettype_view')" cols="3" sm="3" lg="2" class="text-right"><router-link :to="{name: 'ReportSalesPerTickettype', query: {event: selectedEvent.id, ticket: ticket.product_id}}">{{ ticket.sold }}/{{ ticket.stock >=0 ? ticket.stock : '&infin;' }}</router-link></b-col>
            <b-col v-else cols="3" sm="3" lg="2" class="text-right">{{ ticket.sold }}/{{ ticket.stock >=0 ? ticket.stock : '&infin;' }}</b-col>
          </b-row>
        </div>
      </div>
      <div v-else class="text-center">
        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
      </div>
    </b-modal>
  </div>
</template>
<script>
import api from "@/api";
import moment from "moment";
import { VueEllipseProgress } from "vue-ellipse-progress";
import { BounceLoader } from '@saeris/vue-spinners'
export default {
  name: 'Dashboard',
  data() {
    return {
      events: [],
      currentEvents: [],
      sold_tickets: null,
      selectedEvent: null,
      activeCarts: 0,
      activeCartInterval: null
    }
  },
  created(){
    document.title = this.$t('backend_title_dashboard')
  },
  mounted() {
    api.event().getDashboardStatistics({
      timezone: moment.tz.guess()
    }).then(response => {
      this.events = response.map(event => {
        return Object.assign(event, {
          percentage: event.total_stocks ? Math.round((event.total_sold/event.total_stocks)*100) : 0,
        })
      });
    })
    api.event().getDashboardCurrentEventStatistics({
      timezone: moment.tz.guess()
    }).then(response => {
      this.currentEvents = response.map(event => {
        return Object.assign(event, {
          percentage: event.total_tickets ? Math.round(((event.scanned)/event.total_tickets)*100) : 0,
        })
      });
    })
  },
  methods: {
    closeModal(){
      clearInterval(this.activeCartInterval)
    },
    getEventActiveCarts(event){
      api.event().getActiveCarts(event.id).then(response => {
        this.activeCarts = response
      }) 
    },
    getEventSoldTickets(event) {
      this.getEventActiveCarts(event)
      this.activeCartInterval = setInterval(() => {
        this.getEventActiveCarts(event)
      }, 10000)
      this.selectedEvent = event;
      this.sold_tickets = null;
      this.$bvModal.show('dashboard-stat-modal')
      api.event().getEventSoldTickets(event.id).then(response => {
        this.sold_tickets = response
      })
    }
  },
  components: {
    VueEllipseProgress,
    BounceLoader
  }
}
</script>
<style @scoped lang="scss">
.bg-blue {
  background-color: #1B3E58 !important;
}
.event-statistics {
  border: solid 1px #efefef;
  background: #fff;
  box-shadow: 0 3px 6px #52575d19;
  border-radius: 6px;
  margin-bottom: 2em;

  .spinner-body {
    font-size: 2rem
  }

  .event-statistics-title{
    border-bottom:2px solid #F5F5F5;
    padding:1em;
    color:#666666;
    font-size:13pt;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius:6px 6px 0 0;
  }

  .event-statistics-content{
    padding:2em;

    .tickets-sold, .total-revenue{
      color:#B3B3B3;
      font-size:11pt
    }
  }

  .event-statistics-content.active-event{
    padding:3em 2em
  }

  .last-today{
    border-right:2px solid rgb(243, 242, 242);
  }

  .event-statistics-stats{
    padding:15px 1em 0;
    border-top:2px solid rgb(243, 242, 242);

    .row{
      div.col span:first-of-type{
        color:#808080;
        font-size: 15px;
      }
      div.col span:last-of-type{
        color:#B3B3B3;
        font-size:10pt
      }
      div{
        color:#b3b3b3;
        font-size:13px
      }

      div.indicator{
        width:20%;
        text-align:left
      }
      div.today{
        width:40%;
        color:#808080;
        font-size:15px;
        font-weight: bold;
      }
      div.today.title{
        font-size:13px
      }
      div.yesterday{
        width:40%;
      }
    }
  }
}
#dashboard-stat-modal .row div{
  font-size: 14px;
}
.cursor-pointer {
  cursor: pointer;
}
.modal-footer button{
  font-size: 14px;
  padding: 6px 30px;
}
.ticket-row:last-child {
  border-bottom: none !important;
}
.progress-bar {
  background-image: linear-gradient(0.25turn, #d2ecff, #008ffb) !important;
  border-radius: 50px;
  height: 6px !important;
}
.progress {
  background-color: unset;
  margin-top: 7px;
}

.event-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.period-badge {
  background-color: #4b667c;
  color: #FFFFFF !important;
  border-radius: 10px;
  padding: 5px 2px;
  display: block;
  text-align: center;
}

.row-border div.row {
  border-bottom: 2px solid #F5F5F5;
  padding: 10px 0;
  text-align: center;

  &:last-child {
    border-bottom: 0;
  }

  & span {
    padding: 3px;
  }
}

.table-title {
  font-size: 11px !important;
}

@media screen and (max-width: 420px) {
  .event-statistics {
    .event-statistics-stats.sales div.row div.col.last-today {
      border-right:0px;
    }
    .event-statistics-stats.sales div.row div.col{
      flex: 0 0 50%;
      max-width: 50%;
    }
    .event-statistics-stats.sales div.row div.col:nth-of-type(3n),.event-statistics-stats.sales div.row div.col:nth-of-type(4n){
      padding-top:10px
    }
  }
}

</style>
