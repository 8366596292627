<template>
  <div>
    <h1>Fairtix User Terms</h1>
    <p>Last updated: 24-04-2024</p>
    <p><strong>Article 1 -	Definitions</strong></p>
    <p>1.1.	In addition to terms defined elsewhere in these terms of use the following terms will have the meaning set out below:</p>
    <p>(a)	Customer: each natural person or entity that accesses or uses the Ticketshop;</p>
    <p>(b)	Customer Data: all information and any personal data provided by Customer to Fairtix;</p>
    <p>(c)	Event: an event organised by the Organiser, including but not limited to performances of an artistic nature, music, theatre and musical performances, concerts and exhibitions, all in the broadest sense of the word;</p>
    <p>(d)	Fairtix: Upways B.V., having its registered office and principal place of business at Spoorstraat 153, 6591 GT Gennep, registered with the Chamber of Commerce under number 77888286, also acting under the tradename ‘Fairtix’;</p>
    <p>(e)	Foundation: the Pay.nl Foundation ‘Derdengelden’, with offices at Kopersteden 10, 7547 TK Enschede. The Pay.nl Foundation ‘Derdengelden’ receives and manages the funds received on behalf of Fairtix. The Pay.nl Foundation ‘Derdengelden’ is affiliated as a third-party funds entity with Tintel B.V. and is therefore involved by the Dutch Central Bank in its supervision;</p>
    <p>(f)	Organiser: the natural or entity who organises an Event and is responsible for it and with whom the Customer enters into the Ticket Agreement;</p>
    <p>(g)	Parties:Fairtix and the Customer, each to be referred to separately as Party.</p>
    <p>(h)	Services Agreement: the agreement between Customer and Fairtix that governs the use of the Ticketshop and the provision of intermediary services for the purchase of a Ticket and/or merchandise;</p>
    <p>(i)	Ticket(s): the admission ticket to an Event, which may include related items such as merchandise or consumptions, organised by or on behalf of the Organiser that is sold to the Customer through the Ticketshop;</p>
    <p>(j)	Ticket Agreement: the agreement between Customer and the Organiser with respect to the purchase of a Ticket;</p>
    <p>(k)	Ticketshop: the online system developed by Fairtix for the purchase, processing and settlement of the purchased Tickets;</p>
    <p>(l)	User Terms; the terms and conditions of Fairtix for the provision of intermediary services and the use of the Ticketshop.</p>
    <p><strong>Article 2 -	Applicability of terms</strong></p>
    <p>2.1.	By using the Ticketshop and the intermediary services provided by Fairtix, the Customer enters into the Services Agreement. These User Terms apply to and form part of the Services Agreement.</p>
    <p>2.2.	When buying a Ticket, Customer agrees to enter into a Ticket Agreement. The general terms and conditions of the Organiser of the Event shall be applicable to the Ticket Agreement between the Customer and the Organiser. Admission to an Event is at all times subject to any terms, conditions or rules of the Organiser and the venue operator. </p>
    <p>2.3.	The Ticket Agreement between the Customer and the Organiser is concluded after Fairtix received the (authorization of the) payment and Customer received a confirmation. The payment confirmation in itself does not constitute a completed order. A completed order or purchase is only concluded after the Customer has received a written confirmation from or on behalf of the Organiser. </p>
    <p>2.4.	Unless explicitly agreed otherwise, these User Terms shall take precedence over any other communication, offer, or agreement (oral or in writing) between the Parties relating to the subject matter hereof.</p>
    <p>2.5.	The invalidity, illegality or unenforceability of any of the provisions in these User Terms in any jurisdiction shall not affect the validity, legality and enforceability of the remaining provisions of these User Terms. Any invalid, illegal or unenforceable provision will be replaced by a valid, legal or enforceable provision that most closely matches the intent of the original provision. </p>
    <p><strong>Article 3 -	Use of the Ticketshop</strong></p>
    <p>3.1.	The access to and use of the Ticketshop are subject to the usage restrictions specified in these User Terms or otherwise published by Fairtix. Customer will not violate any law, contract, intellectual property rights or other third party right while accessing or using the Ticketshop. Without limitation to the above, Customer will not:</p>
    <p>(a)	offer any information, data or content through the Ticketshop that may infringe any law or regulation; </p>
    <p>(b)	use the Ticketshop for purposes other than permitted by these User Terms, and only to the extent permitted by any applicable law or regulation, nor shall engage in, encourage or promote any activity that violates these User Terms;</p>
    <p>(c)	check, scan or test the vulnerability of the Ticketshop or any other related system or network, or act in breach of any security or authentication;</p>
    <p>(d)	block, disable or otherwise affect any advertising, advertisement banner window, links to other sites and services, or other features that constitute an integral part of the Ticketshop; </p>
    <p>(e)	gain or attempt to gain unauthorized access to any (computer) system, server, network or hardware of Fairtix, other users or any other third party;</p>
    <p>(f)	use the Ticketshop in any manner that could interfere with, disrupt, negatively affect or inhibit other users from fully accessing and using the Ticketshop, or that could damage, disable, overburden or impair the functioning of the Ticketshop in any manner;</p>
    <p>(g)	use scripts, (partially) automated programs or other methods to gain priority on purchases in the Ticketshop.</p>
    <p>3.2.	Fairtix shall take reasonable technical and organizational measures to ensure the security of the Ticketshop but does not guarantee the security or availability of Customer Data.</p>
    <p>3.3.	Customer shall provide accurate, current and complete information when purchasing a Ticket. </p>
    <p><strong>Article 4 -	Ticket Agreement with Organiser</strong></p>
    <p>4.1.	Admission to an Event is at all times subject to any terms, conditions or rules of the Organiser and the venue operator. Customer is responsible to take notice of these terms, conditions or rules and any updates about the Event. Customer should verify himself/herself if the Event will be postponed, cancelled or changed in any other way.</p>
    <p>4.2.	In case of an official refund request by Customer, Fairtix will contact the Organiser for approval of the refund, unless otherwise agreed between Parties. If the request for reimbursement is fully or partly approved by the Organiser, the portion of the Ticket fee and associated costs will be reimbursed by the Organiser to Fairtix and by Fairtix to the Customer.</p>
    <p><strong>Article 5 -	Prices and Payment</strong></p>
    <p>5.1.	All Ticket prices shown in the Ticketshop include the service charges and are including VAT. The service charges will be clearly stated in the order process or will be included in the total price for the Ticket and other products. Depending on the order type, service charges may be charged per product or per order. </p>
    <p>5.2.	These service charges cover, among other things, the costs of administration, transaction and maintenance, the costs of the management of access controls, (mandatory) memberships of music venues, the provision of customer service and, if applicable, the costs of shipping physical Tickets and/or other products. </p>
    <p>5.3.	The Customer can pay online via the offered payment methods. Based on the selected payment method when purchasing a Ticket, Fairtix may charge transaction costs on behalf of the Organiser.</p>
    <p>5.4.	The Ticket price and/or price of other products may vary from the price printed on the Ticket, as a result of additional costs such as costs for reservations, payments or delivery. </p>
    <p>5.5.	All orders are paid for in Euro’s and the statutory VAT rates apply, unless otherwise specified in the Ticketshop. </p>
    <p>5.6.	When placing an order, the Customer agrees to pay the total amount due to Fairtix - which collects these payments on behalf of the Organiser -  or the Customer gives Fairtix permission to debit the relevant amount from the account indicated by the Customer.</p>
    <p>5.7.	After completing the order and once Fairtix received the (authorization of the) payment in full, the Customer will receive an order confirmation including a payment confirmation and a downloadable Ticket per email. </p>
    <p>5.8.	By default, Tickets are delivered by e-mail. In the event the Customer has selected an alternative delivery method when placing an order, additional costs will be charged and shall be shown on the order as part of the total amount.</p>
    <p><strong>Article 6 -	Liability and Warranties</strong></p>
    <p>6.1.	Although Fairtix takes measures to ensure a safe and proper functioning Ticketshop, Fairtix cannot guarantee the accuracy of the Ticketshop or the content of the Ticketshop. The Ticketshop is provided "as is" with any faults or failings and without any representation, warranty or guarantee whatsoever, express or implied, including without limitation any implied warranty of accuracy, completeness, quality, fitness for a particular purpose or non-infringement. Fairtix does not guarantee that the Ticketshop or the information is free of errors, defects, malware and viruses or that the Ticketshop or information is correct, up to date and accurate. </p>
    <p>6.2.	Fairtix is not liable for the consequences of (any) inaccuracies, such correct, complete, up to date and accurate information, with respect to the Events or the Ticketshop, nor shall it be liable for any damages resulting from the use or inability to use of the Ticketshop, unless such damage is the result of any willful misconduct or from gross negligence on the part of Fairtix.</p>
    <p>6.3.	The Ticketshop may provide links to external internet sites. Fairtix shall not be liable for the use or the content of sites that link to the Ticketshop, have embedded the Ticketshop or which are linked from it.</p>
    <p>6.4.	The Ticket Agreement is concluded and executed by the Organiser. Fairtix, in its role as intermediary, is not liable for the performance by the Organiser, for any form of damage and/or reimbursement resulting from the failure of the Organiser to fulfil its obligations to the Customer. Fairtix is not liable for any damage the Customer may suffer as a result of change of an Event, such as relocation, postponement or cancellation.</p>
    <p>6.5.	Fairtix accepts no liability for loss or damage to Tickets suffered by the Customer for any reason whatsoever, insofar as the Organiser (or Fairtix) cannot reasonably be held responsible for this. From the moment the Ticket is issued to the Customer, the Customer bears the risk of loss, theft, damage or misuse.</p>
    <p>6.6.	The limitations and exclusions of liability shall not apply to liability that may not be excluded or limited under mandatory applicable law.</p>
    <p><strong>Article 7 -	Customer Data</strong></p>
    <p>7.1.	Fairtix’ Cookie Policy and Privacy Statement apply to the collection and processing of Customer’s personal data in the context of its use of the Ticketshop. Fairtix’ Cookie Policy and Privacy Statement do not apply to any collection and processing of Customer’s personal data on or through third party external sites, including the website of the Organiser. </p>
    <p>7.2.	Customer is responsible for the legality, reliability, integrity and accuracy of the Customer Data. </p>
    <p><strong>Article 8 -	Intellectual Property</strong></p>
    <p>8.1.	All intellectual property rights in or related to the Ticketshop or the documentation provided remain vested in Fairtix or its licensors. </p>
    <p>8.2.	Nothing in these User Terms shall be construed as conferring any right or license to any patent, trademark, copyright or other proprietary rights of Fairtix or any third party. Any licenses granted (if any) may be revoked by Fairtix at any time. Fairtix' licensors are the intended third party beneficiaries of the agreement to which these User Terms apply and have the express right to rely upon and directly enforce the terms set forth herein.</p>
    <p><strong>Article 9 -	Suspension and Termination</strong></p>
    <p>9.1.	Fairtix is entitled to (temporarily) suspend or limit the use thereof, and/or terminate, with immediate effect and without any notice, in case Customer does not comply with these User Terms or any reasonable instructions from Fairtix with respect to Customer’s use of the Ticketshop and without giving rise to any right on compensation.</p>
    <p>9.2.	The Services Agreement commences when the order is placed in the Ticketshop (which is independent of the moment at which the order is accepted by Organiser) and shall terminate once the intermediary services are provided by Fairtix, unless the Services Agreement is terminated prior to that moment by either party in accordance with applicable law.</p>
    <p><strong>Article 10 -	Applicable Law and Jurisdiction</strong></p>
    <p>10.1.	These User Terms, the Services Agreement and any other agreements concluded with Fairtix, any dispute and any non-contractual obligations arising out of or in connection with it shall be governed by Dutch law, without regard to its choice of law rules. </p>
    <p>10.2.	All disputes that may arise in connection with the agreement with Fairtix or User Terms shall be submitted to the competent court of the Central Netherlands, location Utrecht, the Netherlands, unless the User informs Fairtix within one (1) month after Fairtix has invoked this clause, that is prefers settlement of the dispute by the competent court according to the applicable law.</p>
    <p><strong>Article 11 -	Additional Terms</strong></p>
    <p>11.1.	Fairtix may assign its rights and obligations under these User Terms. These User Terms will inure to the benefit of Fairtix’ successors and assigns. Customer may not assign or otherwise transfer by operation of law or otherwise this Agreement or any rights or obligations herein.</p>
    <p>11.2.	Fairtix may change these User Terms from time to time and will post the changed terms and conditions on the website. The Customer is advised to regularly check whether the User Terms have changed.</p>
  </div>
</template>

<script>
/* eslint @intlify/vue-i18n/no-raw-text: 'off' */
export default {
  data() {
    return {
    }
  }
}
</script>
