import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/pages/Login'
import Signup from '@/pages/Signup'
import Dashboard from '@/pages/Dashboard'
import Companies from '@/pages/Companies'
import ManageCompany from '@/pages/ManageCompany'
import ManageCompanyLayout from '@/pages/ManageCompanyLayout'
import Events from '@/pages/Events'
import ManageEvent from '@/pages/ManageEvent'
import EventProducts from '@/pages/EventProducts'
import EventsOverview from '@/pages/EventsOverview'
import ViewPointOfSale from "@/pages/ViewPointOfSale";
import EventPointsOfSale from "@/pages/EventPointsOfSale";
import EventTimeslots from "@/pages/EventTimeslots";
import EventCustomerInformation from "@/pages/EventCustomerInformation";
import Discountcodes from "@/pages/Discountcodes";
import OrderOverview from "@/pages/OrderOverview";
import OrderDetails from "@/pages/OrderDetails";
import ScannerOverview from '@/pages/ScannerOverview.vue'
import ScanOperationOverview from '@/pages/ScanOperationOverview.vue'
import ScanOperationManage from '@/pages/ScanOperationManage.vue'
import ClaimTicket from '@/pages/ClaimTicket'
import TicketClaimed from '@/pages/TicketClaimed'
import EventVisitorList from '@/pages/EventVisitorList'
import OrderEnterPersonalDetails from "@/pages/OrderEnterPersonalDetails";
import OrderSelectTimeslot from "@/pages/OrderSelectTimeslot";
import OrderSelectPaymentMethod from "@/pages/OrderSelectPaymentMethod";
import OrderAdditionalProducts from "@/pages/OrderAdditionalProducts";
import OrderStatus from "@/pages/OrderStatus";
import CustomerOrderDetails from "@/pages/CustomerOrderDetails";
import CustomerOrderPending from "@/pages/CustomerOrderPending";
import AssignTickets from "@/pages/AssignTickets";
import ReportsOverview from "@/pages/ReportsOverview";
import CampaignDetails from "@/pages/CampaignDetails";
import CampaignsOverview from "@/pages/CampaignsOverview";
import OrderThankYou from "@/pages/OrderThankYou";
import TicketTemplates from "@/pages/TicketTemplates";
import EditTicketTemplate from "@/pages/EditTicketTemplate";
import NotFound from '@/pages/NotFound'
import TermsConditions from '@/pages/TermsConditions'
import TicketChanged from "@/pages/TicketChanged";
import StockReport from '@/pages/StockReport'
import ScanOperationProductsConfigure from "@/pages/ScanOperationProductsConfigure";
import ScanOperationScannersConfigure from "@/pages/ScanOperationScannersConfigure";
import ScanOperationSummary from "@/pages/ScanOperationSummary";
import BoxOfficeLogin from "@/pages/BoxOfficeLogin";
import BoxOfficeEvents from "@/pages/BoxOfficeEvents";
import BoxOfficeEventBoxOffices from "@/pages/BoxOfficeEventBoxOffices";
import BoxOfficePlaceOrder from "@/pages/BoxOfficePlaceOrder";
import BoxOfficeOrderHistory from "@/pages/BoxOfficeOrderHistory";
import CustomerSupportOverview from "@/pages/CustomerSupportOverview";
import ScannerTerminalLogin from "@/pages/ScannerTerminalLogin";
import ScannerTerminalScan from "@/pages/ScannerTerminalScan";
import GuestListOverview from "@/pages/GuestListOverview";
import ReportSalesPerTickettype from "@/pages/ReportSalesPerTickettype";
import UserResetPassword from "@/pages/UserResetPassword";
import store from "@/store";
import MasterDataOverview from "@/pages/MasterDataOverview";
import PermissionRoles from "@/pages/PermissionRoles";
import AccessDenied from "@/pages/AccessDenied";
import AdministrationOverview from "@/pages/AdministrationOverview";
import InvoiceOverview from "@/pages/InvoiceOverview";
import InvoiceDetails from "@/pages/InvoiceDetails";
import AdministrationEventFeesOverview from "@/pages/AdministrationEventFeesOverview";
import ScannerTerminalEvents from "@/pages/ScannerTerminalEvents.vue";
import DefaultCompanySettings from "@/pages/DefaultCompanySettings";
import DefaultCompanyLayout from "@/pages/DefaultCompanyLayout";
import DefaultCompanyFees from "@/pages/DefaultCompanyFees";
import DefaultCompanyPermissions from "@/pages/DefaultCompanyPermissions";
import DefaultCompanyTicketTemplate from "@/pages/DefaultCompanyTicketTemplate";
import EventMarketing from "@/pages/EventMarketing.vue";
import BatchesOverview from "@/pages/BatchesOverview.vue";
import BatchDetails from "@/pages/BatchDetails";
import SignupConfirmation from "@/pages/SignupConfirmation.vue";
import AutoInvoiceSettings from "@/pages/AutoInvoiceSettings.vue";
import CompanyInvoiceOverview from "@/pages/CompanyInvoiceOverview.vue";
import ExtensionsOverview from "@/pages/ExtensionsOverview.vue";
import MailchimpSettings from "@/pages/MailchimpSettings.vue";
import VenuesOverview from "@/pages/VenuesOverview.vue";
import CompanyLinksOverview from "@/pages/CompanyLinksOverview.vue";
import TicketLost from "../pages/TicketLost";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Index',
            component: EventsOverview
        },
        {
            path: '/p/:url',
            name: 'ViewPointOfSale',
            component: ViewPointOfSale
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/signup',
            name: 'Signup',
            component: Signup
        },
        {
            path: '/signup/confirmation',
            name: 'SignupConfirmation',
            component: SignupConfirmation
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard
        },
        {
            path: '/company',
            name: 'Companies',
            component: Companies
        },
        {
            path: '/company/:id',
            name: 'ManageCompany',
            component: ManageCompany
        },
        {
            path: '/company/:id/layout',
            name: 'ManageCompanyLayout',
            component: ManageCompanyLayout
        },
        {
            path: '/company/:id/invoices',
            name: 'CompanyInvoiceOverview',
            component: CompanyInvoiceOverview
        },
        {
            path: '/event',
            name: 'Events',
            component: Events
        },
        {
            path: '/event/:id',
            name: 'ManageEvent',
            component: ManageEvent
        },
        {
            path: '/event/:id/products',
            name: 'EventProducts',
            component: EventProducts
        },
        {
            path: '/event/:id/pointsofsale',
            name: 'EventPointsOfSale',
            component: EventPointsOfSale
        },
        {
          path: '/event/:id/marketing',
          name: 'EventMarketing',
          component: EventMarketing
        },
        {
            path: '/event/:id/visitor-list',
            name: 'EventVisitorList',
            component: EventVisitorList
        },
        {
            path: '/order/:id/:secret/personal',
            name: 'OrderEnterPersonalDetails',
            component: OrderEnterPersonalDetails
        },
        {
            path: '/order/:id/:secret/timeslot',
            name: 'OrderSelectTimeslot',
            component: OrderSelectTimeslot
        },
        {
            path: '/order/:id/:secret/products',
            name: 'OrderAdditionalProducts',
            component: OrderAdditionalProducts
        },
        {
            path: '/order/:id/:secret/payment',
            name: 'OrderSelectPaymentMethod',
            component: OrderSelectPaymentMethod
        },
        {
            path: '/order/:id/:secret/overview',
            name: 'CustomerOrderOverview',
            component: CustomerOrderDetails
        },
        {
            path: '/order/:id/:secret/pending',
            name: 'CustomerOrderPending',
            component: CustomerOrderPending
        },
        {
            path: '/order/:id/:secret/assign',
            name: 'AssignTickets',
            component: AssignTickets
        },
        {
            path: '/order/thanks',
            name: 'OrderThankYou',
            component: OrderThankYou
        },
        {
            path: '/order/:id/:secret/status',
            name: 'OrderStatus',
            component: OrderStatus
        },
        {
            path: '/event/:id/timeslots',
            name: 'EventTimeslots',
            component: EventTimeslots
        },
        {
            path: '/event/:id/customer-information',
            name: 'EventCustomerInformation',
            component: EventCustomerInformation
        },
        {
            path: '/discountcodes',
            name: 'Discountcodes',
            component: Discountcodes
        },
        {
            path: '/order-overview',
            name: 'OrderOverview',
            component: OrderOverview
        },
        {
            path: '/order-details/:order_number',
            name: 'OrderDetails',
            component: OrderDetails
        },
        {
          path:'/scan-operation',
          name:'ScanOperationOverview',
          component:ScanOperationOverview
        },
        {
          path:'/scan-operation/manage/:id',
          name:'ScanOperationManage',
          component:ScanOperationManage
        },
        {
          path:'/scan-operation/manage/:id/products',
          name:'ScanOperationProductsConfigure',
          component:ScanOperationProductsConfigure
        },
        {
          path:'/scan-operation/manage/:id/scanners',
          name:'ScanOperationScannersConfigure',
          component:ScanOperationScannersConfigure
        },
        {
          path:'/scan-operation/manage/:id/summary',
          name:'ScanOperationSummary',
          component:ScanOperationSummary
        },
        {
            path: '/scan',
            name: 'ScannerOverview',
            component: ScannerOverview
        },
        {
            path: '/ticket/:id/:secret/claim',
            name: 'ClaimTicket',
            component: ClaimTicket
        },
        {
            path: '/ticket/claimed',
            name: 'TicketClaimed',
            component: TicketClaimed
        },
        {
            path: '/ticket-templates',
            name: 'TicketTemplates',
            component: TicketTemplates
        },
        {
            path: '/reports',
            name: 'ReportsOverview',
            component: ReportsOverview
        },
        {
            path: '/ticket-templates/:id',
            name: 'EditTicketTemplate',
            component: EditTicketTemplate
        },
        {
            path: '/campaigns',
            name: 'CampaignsOverview',
            component: CampaignsOverview
        },
        {
            path: '/campaigns/:id',
            name: 'CampaignDetails',
            component: CampaignDetails
        },
        {
            path: '/404',
            name: 'NotFound',
            component: NotFound
        },
        {
            path: '/terms-conditions',
            name: 'TermsConditions',
            component: TermsConditions
        },
        {
            path: '/ticked-changed',
            name: 'TicketChanged',
            component: TicketChanged
        },
        {
            path: '/stock-report',
            name: 'StockReport',
            component: StockReport
        },
        {
            path: '/customer-support',
            name: 'CustomerSupportOverview',
            component: CustomerSupportOverview
        },
        // manual scanner routes
        {
          path: '/scanner-terminal/login',
          name: 'ScannerTerminalLogin',
          component: ScannerTerminalLogin
        },
        {
          path: '/scanner-terminal/events',
          name: 'ScannerTerminalEvents',
          component: ScannerTerminalEvents
        },
        {
          path: '/scanner-terminal/:event/scan',
          name: 'ScannerTerminalScan',
          component: ScannerTerminalScan
        },
        // box office routes
        {
            path: '/box-office/login',
            name: 'BoxOfficeLogin',
            component: BoxOfficeLogin
        },
        {
            path: '/box-office/events',
            name: 'BoxOfficeEvents',
            component: BoxOfficeEvents
        },
        {
            path: '/box-office/event/:event/box-offices',
            name: 'BoxOfficeEventBoxOffices',
            component: BoxOfficeEventBoxOffices
        },
        {
            path: '/box-office/event/:event/box-offices/:boxOffice/place-order',
            name: 'BoxOfficePlaceOrder',
            component: BoxOfficePlaceOrder
        },
        {
          path: '/box-office/event/:event/box-offices/:boxOffice/order-history',
          name: 'BoxOfficeOrderHistory',
          component: BoxOfficeOrderHistory
        },
        {
            path: '/guests-list',
            name: 'GuestListOverview',
            component: GuestListOverview
        },
        {
            path: '/reports/sales-by-tickettype',
            name: 'ReportSalesPerTickettype',
            component: ReportSalesPerTickettype
        },
        {
            path: '/reset-password',
            name: 'UserResetPassword',
            component: UserResetPassword
        },
        {
            path: '/master-data',
            name: 'MasterDataOverview',
            component: MasterDataOverview
        },
        {
            path: '/master-data/permission-roles',
            name: 'PermissionRoles',
            component: PermissionRoles
        },
        {
            path: '/403',
            name: 'AccessDenied',
            component: AccessDenied
        },
        {
          path: '/administration',
          name: 'AdministrationOverview',
          component: AdministrationOverview
        },
        {
          path: '/invoice-overview',
          name: 'InvoiceOverview',
          component: InvoiceOverview
        },
        {
          path: '/invoice/:number',
          name: 'InvoiceDetails',
          component: InvoiceDetails
        },
        {
            path: '/administration/events-fees',
            name: 'AdministrationEventFeesOverview',
            component: AdministrationEventFeesOverview
        },
        {
            path: '/master-data/default-account-settings',
            name: 'DefaultCompanySettings',
            component: DefaultCompanySettings
        },
        {
            path: '/master-data/default-account-settings/layout',
            name: 'DefaultCompanyLayout',
            component: DefaultCompanyLayout
        },
        {
            path: '/master-data/default-account-settings/fees',
            name: 'DefaultCompanyFees',
            component: DefaultCompanyFees
        },
        {
            path: '/master-data/default-account-settings/permissions',
            name: 'DefaultCompanyPermissions',
            component: DefaultCompanyPermissions
        },
        {
          path: '/master-data/default-account-settings/ticket-layout',
          name: 'DefaultCompanyTicketTemplate',
          component: DefaultCompanyTicketTemplate
        },
        {
          path: '/master-data/auto-invoice-settings',
          name: 'AutoInvoiceSettings',
          component: AutoInvoiceSettings
        },
        {
            path: '/master-data/venues-overview',
            name: 'VenuesOverview',
            component: VenuesOverview
        },
        {
            path: '/master-data/company-links-overview',
            name: 'CompanyLinksOverview',
            component: CompanyLinksOverview
        },
        {
          path: '/batches',
          name: 'BatchesOverview',
          component: BatchesOverview
        },
        {
            path: '/batches/:id',
            name: 'BatchDetails',
            component: BatchDetails
        },
        {
          path: '/extensions',
          name: 'ExtensionsOverview',
          component: ExtensionsOverview
        },
        {
          path: '/extensions/mailchimp',
          name: 'MailchimpSettings',
          component: MailchimpSettings
        },
        {
          path: '/ticket-lost',
          name: 'TicketLost',
          component: TicketLost
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    if(!['ViewPointOfSale','Login','ScannerTerminalLogin','BoxOfficeLogin','UserResetPassword', 'Signup', 'SignupConfirmation', 'TicketLost'].includes(to.name)) {
        if(store.state.user && store.state.user.company.signup_agreed_metadata === null) {
            if(!to.path.startsWith('/box-office') && !to.path.startsWith('/scanner-terminal')) {
                next('/signup/confirmation');
                return {name: 'SignupConfirmation'};
            }
        }
        if(store.state.user && store.state.user.to_reset_password) {
            next('/reset-password');
            return {name: 'UserResetPassword'};
        }
        else {
            next();
        }
    }
    else {
        next();
    }
})

export default router;
