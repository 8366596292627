<template>
  <b-container>
    <router-link v-if="$store.state.pos" :to="{name: 'ViewPointOfSale', params: {url: $store.state.pos.url}, query: backQuery}" class="text-black-50 mb-2 d-block" style="margin-left: -15px;"><b-icon icon="arrow-left"></b-icon>{{ $t('back') }}</router-link>
    <b-row v-if="order && pos && !saving">
      
      <gtm-page-view v-if="order && order.event && order.event.gtm_id" :gtm_id="order.event.gtm_id"></gtm-page-view>

      <b-col v-if="order" class="content-block cart-container">
        <customer-form v-if="loadingDone" ref="form" level="order" v-model="customer" :fields="customerFields"></customer-form>
        <h2 v-if="questions.length">{{ $t('customer_form_custom_questions') }}</h2>
        <customer-information-form ref="customer-information-form" v-model="answers"
                                   :questions="questions"></customer-information-form>
        <b-row>
          <b-col lg="8">
            <span v-if="pos.terms_conditions_url && pos.terms_conditions_url !== ''" class="terms-conditions-text" v-html="$t('cart_terms_conditions2', {url: 'https://' + pos.company.domain + '/terms-conditions', url2: pos.terms_conditions_url, name: pos.company.name})"></span>
            <span v-else class="terms-conditions-text" v-html="$t('cart_terms_conditions', {url: 'https://' + pos.company.domain + '/terms-conditions'})"></span>
          </b-col>
          <b-col class="text-right" lg="4">
            <b-button variant="success" size="lg" class="mb-4" @click="processOrder()">
              {{ $t('order_continue') }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4">
        <div class="content-sidebar">
          <cart></cart>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="text-center">
        <h1>{{ $t('order_loading') }}</h1>
        <b-spinner style="width: 10em; height: 10em;"></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'
import Cart from '@/components/Cart'
import CustomerForm from '@/components/CustomerForm'
import {deepClone, getLocalStorage, setLocalStorage, getCookie} from "@/functions";
import CustomerInformationForm from "@/components/CustomerInformationForm.vue";
import GtmPageView from "@/components/GtmPageView.vue";

export default {
  components: {
    CustomerInformationForm,
    Cart,
    CustomerForm,
    GtmPageView
  },
  data() {
    return {
      saving: false,
      customerFields: [],
      customer: {}, 
      customQuestions: [],
      answers: {},
      loadingDone: false,
      cookieCategories: null
    }
  },
  methods: {
    processOrder() {
      if(this.order.payment_status === 'paid' || this.order.payment_status === 'pending') {
        this.$router.push({name: 'ViewPointOfSale', params: {url: this.pos.url}});
        this.$store.commit('errors', [this.$t('order_already_paid')]);
        return;
      }

      this.$store.commit('errors', [])

      let customerValid = this.$refs['form'].isValid();
      let customerInformationValid = this.$refs['customer-information-form'].isValid();

      if (!customerValid || !customerInformationValid) {
        return
      }

      this.saving = true
      let order = Object.assign(this.order, {customer: this.customer})

      api.order().save(order).then((order) => {
        this.$store.commit('setOrder', order)

        const cookieCategories = getCookie("ft_cookieCategories");
        if (cookieCategories) {
          let tmpCookieCategories = JSON.parse(cookieCategories);
          if(tmpCookieCategories.local_information){
            setLocalStorage('booker_first_name', this.customer.first_name);
            setLocalStorage('booker_last_name', this.customer.last_name);
            setLocalStorage('booker_email', this.customer.email);
          }
        }

        if (this.customQuestions.length > 0) {
          api.customerInformation().submitByOrder(this.order.id, this.order.secret, this.answers).then(() => {})
        }
        if(this.campaign) {
          this.$router.push({name: 'OrderSelectTimeslot', params: this.$route.params, query: {ft_campaign_name: this.campaign.slug}})
        }
        else {
          this.$router.push({name: 'OrderSelectTimeslot', params: this.$route.params})
        }
      }).catch((error) => {
        this.saving = false
        if(error?.response?.data[0]?.code === 'order_already_paid') {
          this.$router.push({name: 'ViewPointOfSale', params: {url: this.pos.url}});
          return;
        }
        this.$store.commit('errors', ['error_try_again'])
      })
    }
  },
  computed: {
    questions() {
      const products = deepClone(this.order.order_products).map(orderProduct => {
        return orderProduct.product_id;
      })
      return this.customQuestions.filter(question => {
        if(question.where_to_show === 'order') {
          if(question.show_at_specific_prod) {
            return question.products.some(product => {
              return products.includes(product.product_id);
            })
          }
          return true;
        }
        return false;
      });
    },
    backQuery() {
      if(this.campaign) {
        return {ft_campaign_name: this.campaign.slug, back: ''};
      }
      return {back: ''};
    },
    ...mapState(['order', 'pos', 'cart', 'campaign'])
  },
  created() {
    if(document.body.contains(document.getElementById('company-header'))){
      document.getElementById("company-header").scrollIntoView();
    }
    document.title = this.$t('pos_personal_details_title')
     
    let apiCallOne = api.customerInformation().getFieldsByOrder(this.$route.params.id, this.$route.params.secret).then((customerFields) => {
      this.customerFields = customerFields
    })
    api.customerInformation().getByOrder(this.$route.params.id, this.$route.params.secret).then((customQuestions) => {
      this.customQuestions = customQuestions
    })
    
    let apiCallTwo = api.order().getBySecret(this.$route.params.id, this.$route.params.secret).then((order) => {
      if (order.customer) {
        if(order.customer.address) {
          order.customer = Object.assign(order.customer, {...order.customer.address});
        }
        this.customer = order.customer
      } else {
        this.customer = {}
      }
      this.$store.commit('setOrder', order)
    })

    Promise.all([apiCallOne, apiCallTwo]).then(() => {
      this.customerFields.forEach((field) => {
        if( typeof this.customer[field.field] === 'undefined' && getLocalStorage('booker_' + field.field) !== null) {
          this.customer[field.field] = getLocalStorage('booker_' + field.field);
          console.log('deze moeten we gewoon doen: ' + getLocalStorage('booker_' + field.field))
        }
      })
      this.loadingDone = true;
    })
  }
}
</script>

<style scoped>
.terms-conditions-text{
  font-size:10pt
}
</style>
