<template>
  <div>

    <b-row class="Parent">
      <b-col cols="12" lg="3">
        <b-form-group>
          {{ $t('invoice_number') }}
          <b-form-input class="v-text-field" v-model="search" debounce="750"
                        :placeholder="$t('invoice_number')"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="3">
        <b-form-group>
          {{ $t('report_event') }}
          <b-select @change="selectEvent" v-model="filter_event" :options="events"></b-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="invoices.length">
      <b-col class="tableBig">
        <b-table class="table-container" :items="invoices" :fields="[ {key: 'invoice_number', label: $t('administration_invoice_number', {number: ''})},{key: 'event_name', label: $t('event_name')},{key: 'time_period', label: $t('company_invoice_overview_time_period')},{key: 'created_at', label: $t('order_created_at')},{key: 'total', label: $t('cart_total')},{key: 'total_payout', label: $t('company_invoice_overview_total_payout')},{key: 'clearing_ref', label: $t('company_invoice_overview_clearing_ref')},{key: 'actions', label: $t('company_invoice_downloads')}]">
          <template v-slot:cell(created_at)="{item}">
            {{ item.created_at | date_time}}
          </template>
           <template v-slot:cell(event_name)="{item}">
            {{ item.event ? item.event.name : '' }}
          </template>
          <template v-slot:cell(time_period)="{item}">
            {{ item.period_starts_at | date_time }} - {{ item.period_ends_at | date_time }}
          </template>
          <template v-slot:cell(total)="{item}">
            {{ item.total | currency }}
          </template>
          <template v-slot:cell(total_payout)="{item}">
            {{ item.total_payout | currency }}
          </template>
          <template v-slot:cell(clearing_ref)="{item}">
            {{ item.clearing_ref }}
          </template>
          <template v-slot:cell(actions)="{item}">
            <b-button @click="downloadFile(item.invoice_number, 'invoice')" v-if="item.url" class="mr-3 mb-1">{{ $t('company_invoice_overview_invoice') }}</b-button>
            <b-button @click="downloadFile(item.invoice_number, 'payout_summary')" v-if="item.payout_summary_url">{{ $t('company_invoice_overview_payout_summary') }}</b-button>
          </template>
        </b-table>
        <pagination v-if="page_data" :limit="3" :data="page_data" @pagination-change-page="getInvoices"></pagination>
      </b-col>
    </b-row>

    <b-alert v-else show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>

  </div>
</template>

<script>
import api from '../api'

export default {
  name: 'InvoiceOverview',
  data() {
    return {
      invoices: {},
      search: '',
      page_data: null,
      events: [],
      filter_event: null
    }
  },
  created() {
    document.title = this.$t('backend_title_invoices_overview')
    if(this.$store.state.user) {
      if(!this.$can('company_invoice_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.getInvoices();
    this.getEvents();
  },
  methods: {
    downloadFile(invoice_number, type){
      api.invoice().downloadFile(invoice_number, type).then((response) => {
        let filename = 'Invoice ' + invoice_number + '.pdf';
        if(type === 'payout_summary'){
          filename = 'Payout summary ' + invoice_number + '.pdf';
        }
        try {
          let url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading PDF:', error);
        }
      });
    },
    selectEvent(){
      this.getInvoicesWithFilters();
    },
    getEvents(){
      api.event().all(1).then((events) => {
        this.events = events.map((event) => {
          return {
            text: event.name,
            value: event.id
          }
        })

        this.events = [{text: this.$t('all_events'), value: ''}, ...this.events]
      })
    },
    getInvoices(page = 1) {
      if (!page) {
        page = 1
      }
      api.invoice().getAllByCompany(page).then(invoices => {
        this.page_data = invoices
        this.invoices = invoices.data
      })
    },
    getInvoicesWithFilters(page = 1) {
      if (!page) {
        page = 1
      }

      if(this.search === ''){
        this.search = null
      }
      if(this.filter_event === ''){
        this.filter_event = null
      }

      api.invoice().searchInvoiceByCompany(this.search, this.filter_event).then((invoices) => {
        this.page_data = invoices
        this.invoices = invoices.data
      })
    },
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('company_invoice_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
    search() {
      if (!this.search && this.search.length === 0) {
        this.getInvoices(1)
        return
      }
      this.getInvoicesWithFilters()
    },
  }
}
</script>

<style>

</style>
