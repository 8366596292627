<template>
  <div v-if="customer">
    <b-row v-if="!hideTitle">
      <b-col>
        <b-row>
          <b-col sm="7" cols="12">
            <h2 v-if="isClaim && !isTicketFieldsAsked">{{ $t('customer_form_click_on_claim_button') }}</h2>
            <h2 v-else>{{ $t('customer_form_title') }}</h2>
          </b-col>
          <b-col v-if="isClaim && isTicketFieldsAsked && orderCustomer" sm="5" cols="12" id="copy-data-link">
            <a @click="copyOrderCustomer" href="javascript:">{{ $t('ticket_claim_copy_order_data') }}</a>
          </b-col>
        </b-row>
        <span v-if="message">{{ message }}<br><br></span>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="customerFields.indexOf('company_name') !== -1">
        <b-form-group
            :label="(mandatoryFields.indexOf('company_name') !== -1) ? $t('customer_company_name') + '*' : $t('customer_company_name')">
          <b-form-input :disabled="disabled" v-model="customer.company_name"
                        @change="reValidate()"
                        :state="validated ? errors.indexOf('company_name') === -1 : null"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="customerFields.indexOf('first_name') !== -1">
        <b-form-group
            :label="(mandatoryFields.indexOf('first_name') !== -1) ? $t('customer_first_name') + '*' : $t('customer_first_name')">
          <b-form-input :disabled="disabled" v-model="customer.first_name"
                        @change="reValidate()"
                        :state="validated ? errors.indexOf('first_name') === -1 : null"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col v-if="customerFields.indexOf('last_name') !== -1">
        <b-form-group
            :label="(mandatoryFields.indexOf('last_name') !== -1) ? $t('customer_last_name') + '*' : $t('customer_last_name')">
          <b-form-input :disabled="disabled" v-model="customer.last_name"
                        @change="reValidate()"
                        :state="validated ? errors.indexOf('last_name') === -1 : null"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>


    <b-row>
      <b-col v-if="customerFields.indexOf('address') !== -1">
        <b-form-group v-if="customerFields.indexOf('address') !== -1"
                  :label="(mandatoryFields.indexOf('address') !== -1) ? $t('customer_address') + '*' : $t('customer_address')">
          <b-form-input :disabled="disabled" v-model="customer.address"
                        @change="reValidate()"
                        :state="validated ? errors.indexOf('address') === -1 : null"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col v-if="customerFields.indexOf('address') !== -1">
        <b-form-group v-if="customerFields.indexOf('address') !== -1"
                  :label="(mandatoryFields.indexOf('address') !== -1) ? $t('customer_house_nr') + '*' : $t('customer_house_nr')">
          <b-form-input :disabled="disabled" v-model="customer.house_nr"
                        @change="reValidate()"
                        :state="validated ? errors.indexOf('address') === -1 : null"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>


    
    <b-row>
      <b-col v-if="customerFields.indexOf('zip') !== -1">
        <b-form-group :label="(mandatoryFields.indexOf('zip') !== -1) ? $t('customer_zip') + '*' : $t('customer_zip')">
          <b-form-input :disabled="disabled" v-model="customer.zip"
                        @change="reValidate()"
                        :state="validated ? errors.indexOf('zip') === -1 : null"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col v-if="customerFields.indexOf('city') !== -1">
        <b-form-group
            :label="(mandatoryFields.indexOf('city') !== -1) ? $t('customer_city') + '*' : $t('customer_city')">
          <b-form-input :disabled="disabled" v-model="customer.city"
                        @change="reValidate()"
                        :state="validated ? errors.indexOf('city') === -1 : null"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="customerFields.indexOf('phone') !== -1">
        <b-form-group
            :label="(mandatoryFields.indexOf('phone') !== -1) ? $t('customer_phone') + '*' : $t('customer_phone')">
          <b-form-input :disabled="disabled" v-model="customer.phone"
                        @change="reValidate()"
                        :state="validated ? errors.indexOf('phone') === -1 : null"></b-form-input>
        </b-form-group>
      </b-col>
       <b-col v-if="customerFields.indexOf('country') !== -1">
        <b-form-group
            :label="(mandatoryFields.indexOf('country') !== -1) ? $t('customer_country') + '*' : $t('customer_country')">
          <b-form-select text-field="label" value-field="id" :options="countries" :disabled="disabled"
                         v-model="customer.country"
                         @change="reValidate()"
                         :state="validated ? errors.indexOf('country') === -1 : null">
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="customerFields.indexOf('gender') !== -1">
        <b-form-group
            :label="(mandatoryFields.indexOf('gender') !== -1) ? $t('customer_gender') + '*' : $t('customer_gender')">
          <b-form-radio-group :options="genders"
                              :disabled="disabled" v-model="customer.gender"
                              @change="reValidate()"
                              :state="validated ? errors.indexOf('gender') === -1 : null">
            <b-form-invalid-feedback :state="validated ? errors.indexOf('gender') === -1 : null">
              {{ $t('errors_please_select') }}
            </b-form-invalid-feedback>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col v-if="customerFields.indexOf('dob') !== -1">
        <b-form-group
            :label="(mandatoryFields.indexOf('dob') !== -1) ? $t('customer_dob') + '* (' + $t('date_format') + ')' : $t('customer_dob') + ' (' + $t('date_format') + ')'">
          <b-form-select class="dob-select day" text-field="label" value-field="id" :options="days" :disabled="disabled"
                        v-model="tmp_dob.day"
                        @change="reValidate()"
                        :state="validated ? errors.indexOf('dob') === -1 : null">
          </b-form-select>
          <b-form-select class="dob-select month" text-field="label" value-field="id" :options="months" :disabled="disabled"
                        v-model="tmp_dob.month"
                        @change="reValidate()"
                        :state="validated ? errors.indexOf('dob') === -1 : null">
          </b-form-select>
          <b-form-select class="dob-select year" text-field="label" value-field="id" :options="years" :disabled="disabled"
                        v-model="tmp_dob.year"
                        @change="reValidate()"
                        :state="validated ? errors.indexOf('dob') === -1 : null">
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="customerFields.indexOf('email') !== -1">
      <b-col sm="12" md="6">
        <b-form-group :label="$t('customer_email') + (mandatoryFields.indexOf('email') !== -1 ? '*' : '')">
          <b-form-input :disabled="disabled" v-model="customer.email"
                        @change="checkEmail()"
                        :state="validated ? errors.indexOf('email') === -1 : null"></b-form-input>
          <p v-if="emailSuggestion" class="mt-2">
            {{ $t('customer_email_suggestion', emailSuggestion) }}
            <b-button variant="success" size="xs" class="p-1"
                      @click="customer.email = emailSuggestion.full;emailSuggestion = null">
              {{ $t('yes') }}
            </b-button>
            <b-button variant="danger" size="xs" class="p-1 ml-2" @click="emailSuggestion = null">
              {{ $t('no') }}
            </b-button>
          </p>
        </b-form-group>
      </b-col>
      <b-col v-if="customerFields.indexOf('email_confirmation') !== -1" md="6" sm="12">
        <b-form-group :label="$t('email_confirmation') + (mandatoryFields.indexOf('email_confirmation') !== -1 ? '*' : '')">
          <b-form-input :disabled="disabled" v-model="customer.email_confirmation"
                        @change="checkEmail()"
                        @paste="onConfirmationPaste"
                        autocomplete="off"
                        :state="validated ? errors.indexOf('email_confirmation') === -1 : null"></b-form-input>
          <b-form-invalid-feedback v-if="email_confirmation_error">
            {{ $t('customer_form_email_confirmation_not_match') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="customerFields.indexOf('optin') !== -1">
        <b-form-group>
          <b-form-checkbox v-if="fields && fields[0] && fields[0].company_id == 33" :disabled="disabled" v-model="customer.optin">{{ $t('customer_optin_iams') }}
          </b-form-checkbox>
          <b-form-checkbox v-if="fields && fields[0] && fields[0].company_id != 33" :disabled="disabled" v-model="customer.optin">{{ $t('customer_optin') }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    
  </div>
</template>
<script>
import mailcheck from 'mailcheck'
import moment from 'moment'

export default {
  props: ['value', 'fields', 'level', 'disabled', 'message', 'isClaim', 'orderCustomer', 'hideTitle'],
  data() {
    return {
      errors: [],
      days: [],
      months: [],
      years: [],
      validated: false,
      countries: [
        {id: 'DE', label: this.$t('country_de')},
        {id: 'NL', label: this.$t('country_nl')},
        {id: 'ES', label: this.$t('country_es')},
        {id: 'BE', label: this.$t('country_be')},
      ],
      genders: [
        {value: 'm', text: this.$t('customer_gender_male')},
        {value: 'f', text: this.$t('customer_gender_female')},
        {value: 'o', text: this.$t('customer_gender_other')}
      ],
      customer: {},
      emailSuggestion: null,
      tmp_dob: {
        day: null,
        month: null,
        year: null
      },
      email_confirmation_error: false
    }
  },
  created(){
    for(let i=1;i<=31;i++){
      let a = i
      if(a.toString().length == 1){ a = '0' + a }
      this.days.push(a)
    }
    for(let i=1;i<=12;i++){
      let a = i
      if(a.toString().length == 1){ a = '0' + a }
      this.months.push(a)
    }
    for(let i=new Date().getFullYear();i>=new Date().getFullYear()-130;i--){
      this.years.push(i)
    }
  },
  methods: {
    formatDob(){
      if( this.customer && this.customer.dob ){
        let date = this.customer.dob.split(' ')[0].split('-')
        if(date){
          this.tmp_dob = {
            day: date[2],
            month: date[1],
            year: date[0]
          }
        }
      }
    },
    checkEmail() {
      this.customer.email = this.customer.email.trim()
      this.reValidate()
      mailcheck.run({
        email: this.customer.email,
        suggested: (suggestion) => {
          this.emailSuggestion = suggestion
        }
      })
    },
    validateDob(){
      if(!this.tmp_dob.day || !this.tmp_dob.month || !this.tmp_dob.year){
        this.errors.push('dob')
      }
      if(this.tmp_dob.day && this.tmp_dob.month && this.tmp_dob.year){
        this.customer.dob = this.tmp_dob.year + '-' + this.tmp_dob.month + '-' + this.tmp_dob.day
      }
      const date = `${this.tmp_dob.year}-${this.tmp_dob.month}-${this.tmp_dob.day}`;

      if(moment(moment().format('YYYY-MM-DD')).diff(date) <= 0) {
        this.errors.push('dob')
      }
    },
    validateEmail( email ){
      if(email == null){ return null }
      return email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    },
    validateCity(isMandatory) {
      if(!this.customer.city && isMandatory) {
        return null;
      }
      if(!this.customer.city){
        return
      }
      this.customer.city = this.customer.city[0].toUpperCase() + this.customer.city.slice(1).toLowerCase();
      return this.customer.city.match(/^[\p{So}\p{Mn}\p{P}\p{Z}À-ÿa-zA-Z]+$/u)
    },
    validate() {
      this.validated = true
      this.errors = []
      this.email_confirmation_error = false;
      this.fields.forEach(customerField => {
        let f = customerField.field

        if(this.customer[f]){
          this.customer[f] = typeof this.customer[f] === 'string' ? this.customer[f].trim() : this.customer[f]
        }

        if( f === 'dob' && this.tmp_dob.day || this.tmp_dob.month || this.tmp_dob.year ){
          this.validateDob()
        }

        if(f === 'city') {
          if (this.validateCity(customerField.order_mandatory && this.level === 'order') === null) {
            this.errors.push(f);
          }

          if (this.validateCity(customerField.ticket_mandatory && this.level === 'ticket') === null) {
            this.errors.push(f);
          }
        }

        if (!customerField.order_mandatory && this.level === 'order') {
          return
        }
        if (!customerField.ticket_mandatory && this.level === 'ticket') {
          return
        }
        
        if(f === 'dob'){
          this.validateDob()
        } else if (!this.customer[f]) {
          this.errors.push(f)
        } else {
          if (this.customer[f].length === 0) {
            this.errors.push(f)
          }
          else if (f === 'email') {
            if( this.validateEmail(this.customer[f]) === null){
              this.errors.push(f)
            }
          }
          else if(f === 'email_confirmation') {
            if( this.validateEmail(this.customer[f]) === null){
              this.errors.push(f)
            }
            if(this.customer.email && this.customer.email !== this.customer.email_confirmation) {
              this.email_confirmation_error = true;
              this.errors.push(f);
            }
          }
        }

      })
    },
    reValidate() {
      if (this.validated && !this.isValid) {
        this.validate()
      }
    },
    reset() {
      this.errors = []
      this.validated = false
    },
    isValid() {
      this.validate()
      const date = `${this.tmp_dob.year}-${this.tmp_dob.month}-${this.tmp_dob.day}`;
      
      if( this.tmp_dob.year && this.tmp_dob.month && this.tmp_dob.day && moment(moment().format('YYYY-MM-DD')).diff(date) <= 0) {
        this.$store.commit('errors', [this.$t('birth_date_must_be_past')])
        return
      }
      return this.validated && this.errors.length === 0
    },
    copyOrderCustomer() {
      if(!this.isClaim || !this.orderCustomer) {
        return;
      }
      for(const field of this.fields) {
        if(field.ticket_ask) {
          if(this.orderCustomer[field.field]) {
            this.customer[field.field] = this.orderCustomer[field.field]
          }
          else if(this.orderCustomer.address && this.orderCustomer.address[field.field]) {
            this.customer[field.field] = this.orderCustomer.address[field.field];
          }
          if(field.field === 'email') {
            this.checkEmail();
          }
        }
      }
      this.$forceUpdate();
    },
    onConfirmationPaste(e) {
      e.preventDefault();
    }
  },
  computed: {
    customerFields() {
      if (!this.fields) {
        return []
      }
      return this.fields.filter(customerField => {
        if (this.level === 'order') {
          return customerField.order_ask
        }
        if (this.level === 'ticket') {
          return customerField.ticket_ask
        }
        return false
      }).map((customerField) => {
        return customerField.field
      })
    },
    mandatoryFields() {
      if (!this.fields) {
        return []
      }
      return this.fields.filter(mandatoryField => {
        if (this.level === 'order' && mandatoryField.order_ask) {
          return mandatoryField.order_mandatory
        }
        if (this.level === 'ticket' && mandatoryField.ticket_ask) {
          return mandatoryField.ticket_mandatory
        }
        return false
      }).map((mandatoryField) => {
        return mandatoryField.field
      })
    },
    isTicketFieldsAsked() {
      return this.fields.some(f => f.ticket_ask);
    }
  },
  watch: {
    customer() {
      this.$emit('input', this.customer)
    },
    value() {
      this.customer = this.value
      this.formatDob()
    }
  },
  mounted() {
    this.customer = this.value
    this.formatDob()
  }
}
</script>
<style scoped lang="scss">
  h2{
    font-size:1.5rem;
    font-weight:bold;
    margin-bottom:20px
  }
  #copy-data-link{
    text-align:right
  }
  .dob-select{
    float:left
  }
  .dob-select.day, .dob-select.month{
    width:80px;
    margin-right:10px
  }
  .dob-select.year{
    width:100px
  }
  @media screen and (max-width: 768px) {
    .dob-select.day, .dob-select.month{
      width:47%
    }
    .dob-select.month{
      margin-right:0px
    }
    .dob-select.year{
      width:100%
    }
    .dob-select.day, .dob-select.month{
      margin-bottom:10px
    }
  }
  @media screen and (max-width: 578px) {
    #copy-data-link{
      text-align:left;
      margin-bottom:15px
    }
  }
  @media screen and (max-width: 420px) {
    .dob-select.day, .dob-select.month{
      width:100%;
      margin-right:0
    }
  }
</style>
