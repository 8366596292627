<template>
  <div class="pos-products-list">
    <div v-for="group in groups" :key="group.name">
      <h2 class="group-title mt-3" v-if="group.type != 'default'">{{ group.name }}</h2>
      <b-row v-for="product in group.products.filter(p => p.show && p.showDependency)" :key="`product` + product.id" class="product-row mt-3" :class="{'product-row-campaign': product.price !== product.discount_price}">
        <template v-if="product.product_type_id !== donation_product_id">
          <b-col class="product-desc-col" cols="7">
            <b-row>
              <b-col v-if="product.image_url" lg="3" sm="12">
                <b-img fluid :src="product.image_url"></b-img>
              </b-col>
              <b-col class="product-details" lg="9" sm="12">
                <h4>{{ product.name }}</h4>
                <p style="white-space: pre;white-space: normal" >{{ product.description }}</p>
                <p style="font-style:italic;white-space: pre;white-space: normal" >{{ getSaleText(product) }}</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="5" cols="5" class="pricing-col">
            <b-row>
              <b-col lg="6" class="text-right">
                <div v-if="!product.out_of_stock">
                  <p v-if="product.price === product.discount_price" class="price">{{ product.price | currency }}</p>
                  <p v-else class="price">
                    <span class="d-block old-price">{{ product.price | currency }}</span>
                    {{ product.discount_price | currency }}
                  </p>
                </div>
              </b-col>
              <b-col v-if="!product.is_disabled" lg="6" class="text-right">
                <b-select class="price-select" v-if="!product.out_of_stock" @change="updateCart()" :options="product.options"
                          v-model="amounts[product.id]"></b-select>
                <b-alert v-else :show="true" variant="danger" class="text-center">{{ $t('pos_product_sold_out') }}</b-alert>
              </b-col>
              <b-col v-else>
                <b-alert :show="true" variant="danger" class="text-center">{{ $t('pos_product_expired') }}</b-alert>
              </b-col>
            </b-row>
          </b-col>
        </template>
        <template v-else >
          <div class="donation px-0 py-4">
            <b-col lg="3" class="product-details flex-vertical">
              <h4>{{ product.name }}</h4>
              <p>{{ product.description }}</p>
            </b-col>
            <b-col lg="6" md="6" class="flex-horizontal donation-buttons text-right">
              <b-button pill variant="primary" :disabled="amounts[product.id] === 5" @click="amounts[product.id] = 5;updateCart()" class="ml-2" >{{5 | currency }}</b-button>
              <b-button pill variant="primary" :disabled="amounts[product.id] === 10" @click="amounts[product.id] = 10;updateCart()" class="ml-2" >{{10 | currency }}</b-button>
              <b-button pill variant="primary" :disabled="amounts[product.id] === 15" @click="amounts[product.id] = 15;updateCart()" class="ml-2" >{{15 | currency }}</b-button>
            </b-col>
            <b-col lg="3" md="3" class="flex-horizontal">
              <b-input-group prepend="€ " >
                <b-form-input v-model="amounts[product.id]" class="text-right" @change="updateCart()" ></b-form-input>
              </b-input-group>
            </b-col>
          </div>
        </template>
      </b-row>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import moment from "moment";
import {applyCampaignHideRule, applyCampaignRule, deepClone} from "@/functions";
export default {
  props: ['cross_sell'],
  data() {
    return {
      amounts: {},
      refreshGroups: 0,
    }
  },
  watch: {
    cart(){
      console.log('Cart changed')
      this.syncCart()
    }
  },
  methods: {
    setProductStock(product) {
      let showDependency = true;
      if(product.parents.length) {
        showDependency = false;
        product.parents.forEach(pp => {
          if(this.amounts[pp.parent_id] && this.amounts[pp.parent_id] > 0) {
            showDependency = true;
          }
        })
      }

      if(!showDependency) {
        this.amounts[product.id] = 0;
      }

      product = Object.assign(product, {
        discount_price: applyCampaignRule(this.campaign, product.name, deepClone(product).price),
        show: !applyCampaignHideRule(this.campaign, product.name, deepClone(product).price),
        showDependency
      })

      this.pos.pos_product_groups.filter(posProductGroup => {
        return posProductGroup.product_group
      }).forEach(posProductGroup => {
        posProductGroup.product_group.products.forEach(ppp => {
          ppp.additions.forEach(ad => {
            if(ad.child_id === product.id) {
              product.show = false;
            }
          })
        })
      })

      if(product.additions.length) {
        product.additions.forEach(pp => {
          if(this.amounts[pp.parent_id] !== undefined && this.amounts[pp.parent_id] !== null) {
            this.amounts[pp.child_id] = this.amounts[pp.parent_id];
          }
        })
      }

      let posProduct = this.pos.products.find(p => {
        return p.product_id === product.id
      })

      if (!posProduct || posProduct.out_of_stock) {
        console.error('Unable to get stock for product', product.id)
        product.out_of_stock = true
        return product
      }

      if (posProduct) {
        product.current_stock = posProduct.current_stock
        product.sort = posProduct.sort

        let max = 0
        if (product.max_buy && product.max_buy > 0) {
          max = product.max_buy
        }

        if (product.current_stock < max) {
          max = product.current_stock
        }

        if (max === 0) {
          product.out_of_stock = true
          return product
        }

        product.options = []
        if(product.min_buy > 0){
          product.options.push(0)
        }
        let limit = product.current_stock < product.max_buy ? product.current_stock : product.max_buy;
        let step = 1;
        if(product.sale_sets) {
          step = product.sale_sets;
        }

        if(product.limit_max_amount) {
          let maxAmount = 0;
          this.pos.products.forEach(p => {
            const pp =this.amounts[ p.product.id];
            if (p.product.id !== product.id && pp > maxAmount && !p.product.limit_max_amount) {
              maxAmount = pp;
            }
          })
          limit = maxAmount;

          if(this.amounts[product.id] !== undefined || !this.amounts[product.id]) {
            if(this.amounts[product.id] > limit) {
              this.amounts[product.id] = limit;
            }
          }
        }

        for (let i = product.min_buy; i <= limit; i = i + step) {
          product.options.push(i)
        }
      }
      let canSell = true;

      if(product.sale_starts_at) {
        canSell = moment().isAfter(moment(product.sale_starts_at));
      }

      if(product.sale_ends_at) {
        canSell = canSell && moment().isBefore(moment(product.sale_ends_at));
      }
      product.is_disabled = false;
      if(!canSell && product.sale_show_after_expire) {
        if( moment().isAfter(moment(product.sale_ends_at)) ) {
          product.is_disabled = true;
          product.amount = 0;
        }
      }
      return product
    },
    getProduct(id) {
      let posProduct = this.pos.products.find(posProduct => {
        return posProduct.product_id === parseInt(id)
      })
      if (!posProduct) {
        console.error('Unable to find pos product')
        return null
      }

      return posProduct.product
    },
    updateCart() {
      this.refreshGroups++;
      this.$forceUpdate();
      setTimeout(() => {
        let products = Object.keys(this.amounts).filter(id => {
          // filter out the products that have 0 selected
          return this.amounts[id] > 0
        }).map(id => {
          let product = this.getProduct(id)
          if (!product) {
            console.error('Unable to find product from cart in POS')
            return {amount: 0}
          }
          if (product.product_type_id === this.donation_product_id){
            product.price = this.amounts[id]
            product.amount = 1
          }else{
            product.amount = this.amounts[id]
          }
          if(product.min_buy > this.amounts[id]) {
            setTimeout(() => {
              this.amounts[id] = product.min_buy;
              this.$forceUpdate();
            }, 500)
            product.amount = product.min_buy;
          }
          return product
        })
        this.$store.commit('setCartProducts', products)
        this.$forceUpdate()
      }, 200)
    },
    syncCart() {
      if(this.cart.products.length === 0) {
        this.amounts = {}
        return
      }
      this.cart.products.forEach(product => {
        if (product.product_type_id === this.donation_product_id){
          this.amounts[product.id] = product.price
        }else{
          this.amounts[product.id] = product.amount
        }
      })
    },
    getSaleText(product) {
      if(!product.sale_ends_at) {
        return '';
      }

      const ends = moment(product.sale_ends_at);

      if(!product.sale_starts_at &&  moment().isBefore(ends)) {
        const duration = moment.duration(ends.diff(moment()));
        if(duration.months()) {
          if(duration.days()) {
            if(duration.hours()) {
              return this.$t('pos_sale_ends_in_months_days_and_hours', {
                months: duration.months(),
                days: duration.days(),
                hours: duration.hours()
              });
            }
            else {
              return this.$t('pos_sale_ends_in_months_days', {
                months: duration.months(),
                days: duration.days()
              });
            }
          }
          else {
            return this.$('pos_sale_ends_in_months', {months: duration.months()})
          }
        }
        if(duration.days() === 0 && duration.hours() === 0) {
          if(duration.minutes()) {
            return this.$t('pos_sale_ends_in_minutes', {minutes: duration.minutes()});
          }
        }
        if(duration.days() === 0) {
          return this.$t('pos_sale_ends_in_hours', {hours: duration.hours()});
        }
        return this.$t('pos_sale_ends_in_days_and_hours', {days: duration.days(), hours: duration.hours()});
      }

      const starts = moment(product.sale_starts_at);

      if(moment().isBefore(starts)) {
        return this.$t('product_sale_starts_at') + ' ' + starts.format('DD-MM-YYYY HH:mm');
      }

      if(moment().isAfter(starts) && moment().isBefore(ends)) {
        const duration = moment.duration(ends.diff(moment()));
        if(duration.months()) {
          if(duration.days()) {
            if(duration.hours()) {
              return this.$t('pos_sale_ends_in_months_days_and_hours', {
                months: duration.months(),
                days: duration.days(),
                hours: duration.hours()
              });
            }
            else {
              return this.$t('pos_sale_ends_in_months_days', {
                months: duration.months(),
                days: duration.days()
              });
            }
          }
          else {
            return this.$t('pos_sale_ends_in_months', {months: duration.months()})
          }
        }
        if(duration.days() === 0 && duration.hours() === 0) {
          if(duration.minutes()) {
            return this.$t('pos_sale_ends_in_minutes', {minutes: duration.minutes()});
          }
        }
        if(duration.days() === 0) {
          return this.$t('pos_sale_ends_in_hours', {hours: duration.hours()});
        }
        return this.$t('pos_sale_ends_in_days_and_hours', {days: duration.days(), hours: duration.hours()});
      }

      if(moment().isAfter(ends)) {
        return this.$t('pos_sale_ended_at') + ' ' + ends.format('DD-MM-YYYY HH:mm');
      }
    }
  },
  computed: {
    groups() {
      // Flatten the data from the pos response
      console.log(this.refreshGroups); // this for force update groups when product selected
      return this.pos.pos_product_groups.filter(posProductGroup => {
        return posProductGroup.product_group
      }).map((posProductGroup) => {
        return posProductGroup.product_group
      }).map(productGroup => {
        // Filter out the additional products
        productGroup.products = productGroup.products.filter((product) => {
          if (this.cross_sell) {
            return product.is_cross_sell
          }
          return !product.is_cross_sell
        })
        return productGroup
      }).map((productGroup) => {
        //filter out non enabled pos
        let exists = productGroup.products.filter((product)=>{
          let x = this.pos.products.filter((posProduct)=> {
            return product.id === posProduct.product_id && !posProduct.product.is_disabled
          })
          return x.length > 0
        }).map(this.setProductStock);
        const expired = productGroup.products.filter((product)=>{
          let x = this.pos.products.filter((posProduct)=> {
            return product.id === posProduct.product_id && posProduct.product.is_disabled && (posProduct.product.sale_ends_at !== null || posProduct.product.out_of_stock)
          })
          return x.length > 0
        }).map(this.setProductStock);
        // Set the stock details for the grouped products
        productGroup.products = exists.sort((a,b) => a.sort - b.sort).concat(expired)
        return productGroup
      })
      .filter((productGroup) => {
        // Filter out empty categories
        return productGroup.products.length > 0
      })
    },
    ...mapState(['pos', 'cart','donation_product_id', 'campaign'])
  },
  created() {
    this.syncCart()
    if(this.campaign && this.campaign.highlight_discount && this.campaign.highlight_style) {
      let css = '';
      if(this.campaign.highlight_style.border_color) {
        css += 'box-shadow: 2px 2px 10px -9px ' + this.campaign.highlight_style.border_color + ';';
      }
      if(this.campaign.highlight_style.background_color) {
       css += 'background: linear-gradient(217deg, ' + this.campaign.highlight_style.background_color + ', #FFF);';
      }
      css += 'margin: 0;'
      css += 'padding: 15px 0 5px 0;'
      css += 'border-radius: 5px;'
      const style = '.pos-products-list .product-row-campaign {' + css + '}'
      document.querySelector('head').innerHTML += '<style id="product-row-campaign">' + style + '</style>'
    }
  },
}
</script>
<style scoped>
.content-block.cart-container .product-details p{
  font-size:0.8rem
}
.wrapText{
  white-space: normal!important;
}
.price {
  font-size: 1.2em;
  line-height: 50px;
}

.group-title {
  background: #efefef;
  color: gray;
  padding: 0.5em;
  font-size: 1.4em;
  text-transform: uppercase;
}

.product-desc-col{
  margin-top:auto;
  margin-bottom: auto;
}
.product-details h4 {
  font-size: 1.2em;
  margin-bottom:2px
}
.product-details p, .content-block.cart-container .product-details p{
   white-space: normal!important;
  }
.donation{
  border: 1px solid lightgrey;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  display:flex;
  margin:0 2%;
}

.donation-buttons{
  justify-content: end;
  align-items: center;
}

.flex-vertical{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex-horizontal{
  display:flex;
  flex-direction: row;
  align-items: center;
}
.pricing-col div.row div.alert{
  text-align:center
}

@media screen and (max-width: 994px) {
  .product-row{
    margin-bottom:25px
  }
  .product-row p.price{
    margin-bottom:0
  }
  .price-select{
    width:120px
  }
}

@media screen and (max-width: 768px) {
  .donation{
    flex-direction: column;
  }
  .donation-buttons{
    margin-bottom:15px
  }
}

@media screen and (max-width: 480px) {
  h2.group-title{
    font-size:13pt
  }
  .product-details h4{
    font-size:15pt
  }
  .product-details p{
    font-size:11pt
  }
  .product-row p.price{
    font-size:15pt
  }
}

@media screen and (max-width: 360px) {
  div.product-row{
    flex-direction: column;
    margin-bottom:30px
  }
  .product-row div.product-desc-col, .product-row div.pricing-col, .price-select{
    width:100%;
    max-width:100%
  }
  .product-row div.pricing-col div.row{
    flex-direction: row-reverse;
  }
  .product-details p{
    margin-bottom:10px
  }
}
.old-price {
    text-decoration: line-through;
    padding: 0;
    margin: 0;
    line-height: initial;
}
</style>
