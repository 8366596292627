export default class ScannerTerminalUser {

  constructor(axios) {
      this.axios = axios
  }

  login(username, password) {
      return this.axios.post('/scanner-terminal/login', {username, password})
  }

  me() {
      return this.axios.get('/scanner-terminal/user')
  }
}
