<template>
  <b-container>
    <b-row>
      <b-col>
        <component :is="'TermsConditions' + (language ? language.toUpperCase() : 'EN')"></component>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint @intlify/vue-i18n/no-raw-text: 'off' */
import TermsConditionsEN from "@/pages/TermsConditionsEN";
import TermsConditionsDE from "@/pages/TermsConditionsDE";
import TermsConditionsES from "@/pages/TermsConditionsES";
import TermsConditionsNL from "@/pages/TermsConditionsNL";
import {mapState} from "vuex";

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['language'])
  },
  components: {
    TermsConditionsEN,
    TermsConditionsDE,
    TermsConditionsES,
    TermsConditionsNL,
  }
}
</script>
