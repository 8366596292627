<template>
  <div>

    <b-row>
      <b-col cols="12" >
        <div class="content-main-container container-distance">
          <div class="title-container">{{ $t("scanner_manage_title") }}</div>
          <div class="content-container">
            <b-row>
              <b-col cols="6">
                <b-row>
                  <b-col>
                    <label for="scanner-name">{{$t('scanner_name')}}</label>
                  </b-col>
                </b-row>
                <b-row class="align-items-center justify-content-between">
                  <b-col cols="10">
                    <b-form-input id="scanner-name" v-model="scanner.name" :placeholder="$t('scanner_name')"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-button class="float-right" :disabled="!scanner.name || loading" variant="primary" size="lg" @click="handleAddScannerClick"><b-icon icon="plus"></b-icon></b-button>
                  </b-col>
                </b-row>

                <b-row class="mt-3" v-if="scanners.length > 0">
                  <b-col cols="12">
                    <b-table :items="scanners" :fields="['name', 'actions']" thead-class="d-none" bordered borderless>
                      <template v-slot:cell(name)="{item}" >
                        {{ item.name }}
                      </template>
                      <template v-slot:cell(actions)="{item}" >
                        <div class="d-flex justify-content-end" >
                          <b-btn class="mr-4 btn-new p-2" variant="secondary" @click="showQR(item)" ><b-icon icon="eye"></b-icon></b-btn>
                          <b-btn class="mr-4" variant="primary" @click="handleEditScannerClick(item)">{{$t('manage')}} </b-btn>
                          <b-btn :disabled="loading" pill variant="outline-secondary" class="border-0" @click="handleDeleteScannerClick(item.id)"><b-icon icon="x"></b-icon></b-btn>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <br>
            <b-row class="mt-3">
              <b-col cols="3">
                <b>{{ $t("scan_test_mode") }}</b>
                <p>{{ $t("scan_test_mode_hint") }}</p>
              </b-col>
              <b-col cols="1">
                <b-form-checkbox
                    class="float-left ml-3"
                    switch
                    v-model="status"
                    :unchecked-value="false"
                   
                ></b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <b>{{ $t("scan_enable_outgoing") }}</b>
                <p>{{ $t("scan_enable_outgoing_hint") }}</p>
              </b-col>
              <b-col cols="1">
                <b-form-checkbox
                    class="float-left ml-3"
                    switch
                    v-model="outgoing"
                    :unchecked-value="false"
                   
                ></b-form-checkbox>
              </b-col>
            </b-row>
            <br>
            <b-row  class="align-items-center">
              <b-col cols="3">
                <b-form-group :label="$t('scan_sessions')">
                  <b-form-input v-model="session.name" :placeholder="$t('session_name')"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <date-time-picker v-model="session.starts_at" :label="$t('scan_start_date')"></date-time-picker>

              </b-col>
              <b-col cols="4">
                <date-time-picker v-model="session.ends_at" :label="$t('scan_end_date')"></date-time-picker>
              </b-col>
              <b-col cols="1">
                <b-button :disabled="!session.name || !session.starts_at || !session.ends_at || loading" style="margin-top:10px" variant="primary" size="lg" @click="handleAddSessionClick"><b-icon icon="plus"></b-icon></b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table :items="sessions" :fields="['name', 'starts_at', 'actions']" thead-class="d-none" bordered borderless>
                  <template v-slot:cell(name)="{item}" >
                    {{ item.name }}
                  </template>
                  <template v-slot:cell(starts_at)="{item}" >
                    {{ formatSessionDate(item.starts_at, item.ends_at) }}
                  </template>
                  <template v-slot:cell(actions)="{item}" >
                    <div class="d-flex justify-content-end" >
                      <b-btn class="mr-4" variant="primary" @click="handleEditSessionClick(item)">{{$t('manage')}} </b-btn>
                      <b-btn :disabled="loading" pill variant="outline-secondary" class="border-0" @click="handleDeleteSessionClick(item.id)"><b-icon icon="x"></b-icon></b-btn>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row v-if="$can('scan_operation_edit') || $can('scan_operation_create')">
              <b-col cols="12">
                <b-button @click="$router.push({name: 'ScanOperationProductsConfigure', params: {id: $route.params.id}})" class="mb-3 ml-3 btn-new float-right">{{ $t('next') }}</b-button>
                <b-button :disabled="loading" @click="save" class="mb-3 bg-success btn-new float-right">{{ $t('save') }}</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal id="scanner-qr" :title="qrTitle">
      <div class="text-center" v-if="qrCode">
        <h2>{{ qrCode.name }}</h2>
        <qrcode-vue :size="300" level="H" :value="qrCode"></qrcode-vue>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="closeQRModal">{{ $t('close') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('scanner_modal_edit')" id="edit-scanner" size="md">
      <div v-if="scanner">
        <b-row>
          <b-col>
            <b-form-group :label="$t('scanner_name')">
              <b-form-input v-model="scanner.name" required :placeholder="$t('session_name')"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('scanner_username')">
              <b-form-input v-model="scanner.username" readonly></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('scanner_password')">
              <b-form-input v-model="scanner.password" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('scanner_description')">
              <b-form-input v-model="scanner.description" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <b-button :disabled="loading" variant="secondary" @click="handleSaveScannerClick">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
    <b-modal :title="$t('scanner_modal_delete')" id="delete-scanner" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('delete_entry') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button :disabled="loading" @click="deleteScanner()" variant="danger">{{ $t('delete') }}</b-button>
      </template>
    </b-modal>
    <b-modal :title="$t('scan_operation_session_modal_edit')" id="edit-session" size="md" @cancel="clearSessionInputs" @hide="clearSessionInputs">
      <div v-if="scanner">
        <b-row>
          <b-col>
            <b-form-group :label="$t('scan_operation_session_name')">
              <b-form-input v-model="session.name" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <date-time-picker v-model="session.starts_at" :label="$t('scan_start_date')"></date-time-picker>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <date-time-picker v-model="session.ends_at" :label="$t('scan_end_date')"></date-time-picker>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <b-button :disabled="loading" variant="secondary" @click="handleSaveSessionClick">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
    <b-modal :title="$t('scan_operation_session_modal_delete')" id="delete-session" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('delete_entry') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button :disabled="loading" @click="deleteSession()" variant="danger">{{ $t('delete') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import DateTimePicker from "@/components/DateTimePicker";
import QrcodeVue from "qrcode.vue";
import moment from 'moment';

export default {
  components: {
    DateTimePicker,QrcodeVue
  },
  data() {
    return {
      scanners: [],
      scanner: {
        name:''
      },
      qrCode: null,
      qrTitle: '',
      selected_scanner_id: null,
      selected_session_id: null,
      sessions: [],
      session: {
        name: null,
        starts_at: null,
        ends_at: null,
      },
      status: false,
      outgoing: false,
      loading: false,
      scanOperation: null
    };
  },
  methods: {
    handleAddScannerClick () {
      this.loading = true;
      api.scanner().create(this.$route.params.id,this.scanner).then((response)=>{
        this.scanners.push(Object.assign({},response));
        this.scanner = {name:''}
        this.handleEditScannerClick(response)
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    handleDeleteScannerClick(scannerId){
      this.$bvModal.show('delete-scanner')
      this.selected_scanner_id = scannerId
    },
    deleteScanner() {
      this.$bvModal.hide('delete-scanner')
      this.loading = true;
      api.scanner().delete(this.selected_scanner_id).then(()=>{
        this.scanners = this.scanners.filter((scanner)=>scanner.id !== this.selected_scanner_id)
        this.selected_scanner_id = null
        this.$store.commit('success', 'scanner_has_been_deleted')
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    handleEditScannerClick(scanner) {
      this.scanner = Object.assign({}, scanner)
      this.$bvModal.show('edit-scanner')
    },
    handleSaveScannerClick(){
      this.loading = true;
      api.scanner().save(this.scanner.id,this.scanner).then((response)=>{
        this.scanners = this.scanners.filter((scanner)=>scanner.id !== response.id)
        this.scanners.push(Object.assign({},response));
        this.scanner = {};
        this.$bvModal.hide('edit-scanner')
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    closeQRModal(){
      this.qrCode = null
      this.$bvModal.hide('scanner-qr')
    },
    showQR(scanner){
      this.qrTitle = scanner.name
      this.qrCode = JSON.stringify({
        name: scanner.name,
        login: scanner.username,
        password: scanner.password
      })
      this.$bvModal.show('scanner-qr')
    },
    handleEditSessionClick(session) {
      this.session = Object.assign({}, session)
      this.$bvModal.show('edit-session')
    },
    handleAddSessionClick(){
      this.loading = true;
      api.scanOperationSession().create(Object.assign({scan_operation_id: this.$route.params.id}, this.session)).then((response)=>{
        this.sessions.push(Object.assign({},response));
        this.clearSessionInputs();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    handleSaveSessionClick(){
      this.loading = true;
      api.scanOperationSession().save(Object.assign({scan_operation_id: this.$route.params.id}, this.session)).then((response)=>{
        this.sessions.map(session => {
          if(session.id === response.id) {
            session = Object.assign(session, response);
          }
          return session;
        });
        this.clearSessionInputs();
        this.$bvModal.hide('edit-session')
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    handleDeleteSessionClick(sessionId){
      this.$bvModal.show('delete-session')
      this.selected_session_id = sessionId
    },
    deleteSession() {
      this.$bvModal.hide('delete-session')
      this.loading = true;
      api.scanOperationSession().delete(this.$route.params.id, this.selected_session_id).then(()=>{
        this.sessions = this.sessions.filter(session => session.id !== this.selected_session_id)
        this.selected_session_id = null
        this.$store.commit('success', 'session_has_been_deleted')
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    save() {
      this.loading = true;
      api.scanOperation().save({
        id: this.$route.params.id,
        status: this.status,
        outgoing: this.outgoing,
      }).then((response)=>{
        this.scanOperation = response;
        this.$store.commit('success', 'scan_operation_updated')
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    clearSessionInputs() {
      this.session = {
        name: null,
        starts_at: null,
        ends_at: null,
      }
    },
    formatSessionDate(start, end) {
      return moment(start).format('DD-MM-YYYY HH:mm') + ' - ' + moment(end).format('DD-MM-YYYY HH:mm');
    }
  },
  created() {
    document.title = this.$t('backend_title_scan_operation_manage')
    if(this.$store.state.user) {
      if (!this.$can('scan_operation_edit')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.scanner().get(this.$route.params.id).then((response)=>{
      this.scanners = response;
    });
    api.scanOperationSession().get(this.$route.params.id).then((response)=>{
      this.sessions = response;
    });
    api.scanOperation().find(this.$route.params.id).then((response)=>{
      this.status = response.status === 'test';
      this.outgoing = !!response.outgoing_enabled;
      this.scanOperation = response;
    });
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('scan_operation_edit')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
};
</script>
