<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group :label="$t('discountcode_code')">
          <b-form-input v-if="discountcode" v-model="discountcode.code"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="$t('discountcode_stock')">
          <b-form-input v-if="discountcode" v-model="discountcode.stock"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
        <b-col>
          <b-form-group :label="$t('discountcode_type')">
            <b-select v-if="discountcode" v-model="discountcode.type" :options="[
              {value:'percentage', text: $t('discountcode_type_percentage')},
              {value:'value', text: $t('discountcode_type_value')}
            ]"></b-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('discountcode_amount')">
            <b-form-input v-if="discountcode" type="number" v-model="discountcode.amount" step="0.01"></b-form-input>
          </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group>
                <b-form-checkbox v-if="discountcode" v-model="discountcode.is_active">{{ $t('discountcode_is_active') }}
                </b-form-checkbox>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group :label="$t('discountcode_apply_type')">
          <b-form-select v-model="discountcode.apply_type" :options="[{text: $t('discountcode_apply_type_order'), value: 'order'}, {text: $t('discountcode_apply_type_ticket'), value: 'ticket'}]"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <date-time-picker v-if="discountcode" v-model="discountcode.starts_at" :label="$t('discountcode_starts_at')" :optional="true"></date-time-picker>
        <date-time-picker v-if="discountcode" v-model="discountcode.ends_at" :label="$t('discountcode_ends_at')" :optional="true"></date-time-picker>
      </b-col>
    </b-row>

    <b-row v-if="discountcode">
      <b-col v-if="discountcode.events">
        <b-form-group :label="$t('discountcode_allowed_events')">
          <b-dropdown id="dropdown-Checkbox" :text="discountcode.events.length ? (discountcode.events.length + ' ' + $t('permission_events_selected')) : $t('permission_all_events')" ref="dropdown" class="w-100">
            <b-form-group class="allowed-events-container">
              <b-form-input v-model="searchEvents" :placeholder="$t('search')" size="sm" class="mb-2"></b-form-input>
              <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="discountcode.events"
                  name="flavour-2"
                  @change="getProducts"
              >
                <b-form-checkbox v-for="ev in events.filter(e => searchEvents ? e.name.toLowerCase().includes(searchEvents.toLowerCase()) : true)"
                                 :key="ev.id"
                                 :value="ev.id" class="w-100">{{ ev.name }}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-dropdown>
        </b-form-group>

        <b-form-group v-if="discountcode.events && discountcode.events.length > 0" :label="$t('discountcode_allowed_products')">
          <b-form-group v-for="eventProduct in eventProducts" :key="eventProduct.id" :label="eventProduct.name">
            <b-row>
              <b-col>
                <b-form-checkbox-group
                  v-model="discountcode.products"
                  :options="eventProduct.products"
                  value-field="id"
                  text-field="name"
                ></b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form-group>

      </b-col>
    </b-row>

  </div>
</template>

<script>
import DateTimePicker from './DateTimePicker'
import api from "@/api";
export default {
  components: {
    DateTimePicker
  },
  props: ['value'],
  data() {
    return {
      discountcode: {},
      events: [],
      products: [],
      searchEvents: null,
      eventProducts: []
    }
  },
  watch: {
    discountcode() {
      this.$emit('input', this.discountcode)
    },
    value() {
      this.discountcode = this.value
    }
  },
  mounted() {
    this.discountcode = this.value;
    api.event().all(true).then(response => {
      this.events = response;
      this.getProducts();
    });
  },
  methods: {
    getProducts() {
      if(this.discountcode.events.length > 0) {

        this.eventProducts = [];

        this.discountcode.events.forEach(eventId => {
          api.event().products(eventId).then(response => {   

            let tmpEvent = {
              id: eventId,
              name: this.events.find(e => e.id === eventId).name,
              products: []
            }
            this.eventProducts.push(tmpEvent);

            response.forEach(product => {
              let index = this.eventProducts.findIndex(product => product.id === eventId);
              this.eventProducts[index].products.push(product);
            });            

          });
        });
      }
    }
  }
}
</script>
