<template>
  <div>
    <b-row>
      <b-col v-if="$can('invoice_overview_view')" lg="3" sm="6" cols="12">
        <router-link :to="{name: 'InvoiceOverview'}"  class="info-card center text-center card">{{ $t('administration_overview_invoice') }}</router-link>
      </b-col>
      <b-col v-if="$can('administration_overrule_fees_per_event_view')" lg="3" sm="6" cols="12">
        <router-link :to="{name: 'AdministrationEventFeesOverview'}"  class="info-card center text-center card">{{ $t('administration_event_fees') }}</router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "AdministrationOverview",
  created() {
    document.title = this.$t('nav_administration')
    if(this.$store.state.user) {
      if(!this.$can('master_data_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('admin_administration_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
