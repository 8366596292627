export default class scanerApi {

    constructor(axios) {
        this.axios = axios
    }

    get(scanOperationId) {
        return this.axios.get('/scan-operation/' + scanOperationId + '/scanners')
    }

    create(scanOperationId,scanner) {
        return this.axios.post('/scan-operation/'  + scanOperationId + '/scanner', scanner)
    }

    save(scannerId,scanner) {
        return this.axios.post('/scan-operation/scanner/edit/' + scannerId, scanner)
    }

    delete(scannerId) {
        return this.axios.delete('/scan-operation/scanner/delete/' + scannerId)
    }

}
