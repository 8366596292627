<template>
  <div class="body" id="body403">
    <svg xmlns="http://www.w3.org/2000/svg" id="robot-error" viewBox="0 0 260 118.9">
      <defs>
        <clipPath id="white-clip"><circle id="white-eye" fill="#cacaca" cx="130" cy="65" r="20" /> </clipPath>
        <text id="text-s" class="error-text" y="106"> 403 </text>
      </defs>
      <path class="alarm" fill="#e62326" d="M120.9 19.6V9.1c0-5 4.1-9.1 9.1-9.1h0c5 0 9.1 4.1 9.1 9.1v10.6" />
      <use xlink:href="#text-s" x="-0.5px" y="-1px" fill="black"></use>
      <use xlink:href="#text-s" fill="#1A3E58"></use>
      <g id="robot">
        <g id="eye-wrap">
          <use xlink:href="#white-eye"></use>
          <circle id="eyef" class="eye" clip-path="url(#white-clip)" fill="#000" stroke="#F0B52D" stroke-width="2" stroke-miterlimit="10" cx="130" cy="65" r="11" />
          <ellipse id="white-eye" fill="#1A3E58" cx="130" cy="40" rx="18" ry="12" />
        </g>
        <circle class="lightblue" cx="105" cy="32" r="2.5" id="tornillo" />
        <use xlink:href="#tornillo" x="50"></use>
        <use xlink:href="#tornillo" x="50" y="60"></use>
        <use xlink:href="#tornillo" y="60"></use>
      </g>
    </svg>
    <h1>{{ $t('not_have_permission') }}</h1>
    <h2><router-link class="back-to-home" to="/dashboard">Back to home</router-link></h2>
  </div>

</template>

<script>
export default {
  name: "AccessDenied",
  mounted() {
    var root = document.getElementById('body403');
    var eyef = document.getElementById('eyef');
    var cx = document.getElementById("eyef").getAttribute("cx");
    var cy = document.getElementById("eyef").getAttribute("cy");

    document.addEventListener("mousemove", evt => {
      let x = evt.clientX / innerWidth;
      let y = evt.clientY / innerHeight;

      root.style.setProperty("--mouse-x", x);
      root.style.setProperty("--mouse-y", y);

      cx = 115 + 30 * x;
      cy = 50 + 30 * y;
      eyef.setAttribute("cx", cx);
      eyef.setAttribute("cy", cy);

    });

    document.addEventListener("touchmove", touchHandler => {
      let x = touchHandler.touches[0].clientX / innerWidth;
      let y = touchHandler.touches[0].clientY / innerHeight;

      root.style.setProperty("--mouse-x", x);
      root.style.setProperty("--mouse-y", y);
    });
  }
}
</script>

<style scoped lang="scss">
.body {
  color: white;
  font-family: "Bungee", cursive !important;
  margin-top: 100px;
  text-align: center;
}
#text-s {
  font-family: "Bungee", cursive !important;
}
a {
  color: #F0B52D;
  text-decoration: none;
}
a:hover {
  color: #000000;
}
svg {
  width: 50vw;
}
.lightblue {
  fill: #F0B52D;
}
.eye {
  cx: calc(115px + 30px * var(--mouse-x));
  cy: calc(50px + 30px * var(--mouse-y));
}
#eye-wrap {
  overflow: hidden;
}
.error-text {
  font-size: 120px;
}
.alarm {
  animation: alarmOn 0.5s infinite;
}

@keyframes alarmOn {
  to {
    fill: darkred;
  }
}
</style>
