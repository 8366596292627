<template>
  <div class="login">
    <b-row>
      <b-col cols="12" lg="12">
        <b-row class="flexing">
          <b-col cols="12" md="6" class="ml-0">
            <b-button v-if="$can('my_company_layout_view') && isUserCompany" variant="new" :to="{name: 'ManageCompanyLayout'}" class="py-3 mb-3 mr-3">{{ $t('company_layout') }}</b-button>
            <b-button variant="new" v-if="$can('company_invoice_view') && isUserCompany" :to="{name: 'CompanyInvoiceOverview'}" class="py-3 mb-3">{{ $t('company_invoice_overview') }}</b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-if="user && $can('admin_company_payment_costs_view') || $can('admin_company_payment_costs_create')">
            <b-col cols="12" md="12" class="ml-0 pl-0">
              <b-button v-if="$can('admin_company_payment_costs_create')" variant="new" @click="showAddPaymentCostsModal">{{ $t('admin_company_add_payment_method') }}</b-button>
            </b-col>

            <span v-if="user && $can('admin_company_payment_costs_view')">{{ $t('manage_company_without_vat') }}</span>

            <div class="tableBig">
              <b-table class="table-container" :items="paymentMethodHistory" :fields="[{key: 'payment_method', label: $t('admin_company_payment_method')},{key: 'payment_method_fees.fee_percentage', label: $t('admin_company_payment_method_fee_percentage')},{key: 'payment_method_fees.fee_fixed_amount', label: $t('admin_company_payment_method_fee_fixed')},{key: 'starts_at', label: $t('event_starts_at')}]">
                <template v-slot:cell(payment_method_fees.fee_percentage)="{item}">
                  {{ item.fee_percentage }}%
                </template>
                <template v-slot:cell(payment_method_fees.fee_fixed_amount)="{item}">
                  {{ item.fee_fixed_amount | currency }}
                </template>
                <template v-slot:cell(starts_at)="{item}">
                  {{ item.starts_at | date_time }}
                </template>
              </b-table>
            </div>
          </b-col>
          <b-col v-if="user && $can('admin_company_fee_view')">
            <b-button v-if="$can('admin_company_fee_add')" variant="new" @click="showAddCompanyFeeModal" class="mb-4">{{ $t('admin_company_add_company_fee') }}</b-button>
            <div v-else style="height: 48px;" class="mb-4"></div>
            <div class="tableBig">
              <b-table class="table-container" :items="company.fees" :fields="[{key: 'fee_type', label: $t('admin_company_fee_type')},{key: 'fee', label: $t('admin_company_fee')},{key: 'starts_at', label: $t('admin_company_fee_start_at')},{key: 'actions', label: $t('order_actions')}]">
                <template v-slot:cell(starts_at)="{item}">
                  {{ item.starts_at | date_time }}
                </template>
                <template v-slot:cell(fee)="{item}">
                  <span v-if="item.fee_type === 'free-tickets-free-percentage'">{{ item.fee + '%' }}</span>
                  <span v-if="item.fee_type !== 'free-tickets-free-percentage'">{{ item.fee | currency }}</span>
                </template>
                <template v-slot:cell(actions)="{item}">
                  <b-button variant="primary" v-if="$can('admin_company_fee_edit')" @click="showEditCompanyFeeModal(item)" class="mr-2">{{ $t('edit') }}</b-button>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
        <div class="tableBig">
        <b-row>
          <b-col v-if="($can('my_company_settings_view') && isUserCompany) || $can('my_company_settings_edit') || $can('admin_company_details_view')" xs="12" sm="12" xl="4">
            <div v-if="($can('my_company_user_create') && isUserCompany) || $can('admin_company_details_edit')" class="mb-3 px-4 py-3" style="height: 60px;"></div>
            <div v-if="$can('admin_company_details_view')" class="content-main-container mt-0 container-distance">
              <div class="title-container">{{ $t('company_details') }}</div>
              <div class="content-container">

                <div v-if="user">
                  <b-form-group :label="$t('company_name')">
                    <b-form-input v-model="company.name" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_subdomain')">
                    <b-form-input v-model="company.domain" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_contact')">
                    <b-form-input v-model="company.contact" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_phone')">
                    <b-form-input v-model="company.phone" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_address')">
                    <b-form-input v-model="company.address" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-row>
                    <b-col>
                      <b-form-group :label="$t('company_zip')">
                        <b-form-input v-model="company.zip" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group :label="$t('company_city')">
                        <b-form-input v-model="company.city" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group :label="$t('company_kvk')">
                        <b-form-input v-model="company.kvk" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                      </b-form-group>
                      <b-form-group>
                        <b-form-checkbox v-model="company.show_kvk_on_pos" :disabled="!$can('admin_company_details_edit')">
                          {{ $t('company_show_kvk_on_pos') }}
                        </b-form-checkbox>
                      </b-form-group> 
                    </b-col>
                    <b-col>
                      <b-form-group :label="$t('company_vat')">
                        <b-form-input v-model="company.vat" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group :label="$t('company_invoice_prefix')">
                    <b-form-input v-model="company.invoice_prefix" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_vat_percentage')">
                    <b-form-input v-model="company.vat_percentage" type="number" step="1" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                   <b-form-group :label="$t('company_paynl_merchant_id')">
                    <b-form-input v-model="company.paynl_merchant_id" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_paynl_token_code')">
                    <b-form-input v-model="company.paynl_token_code" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_paynl_api_token')">
                    <b-form-input v-model="company.paynl_api_token" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_paynl_service_id')">
                    <b-form-input v-model="company.paynl_service_id" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_country')">
                    <b-select v-model="company.country" :options="['nl', 'es', 'de', 'en', 'be']" :disabled="!$can('admin_company_details_edit')"></b-select>
                  </b-form-group>
                  <b-form-group :label="$t('company_email_customer_questions')">
                    <b-form-input v-model="company.email_customer_questions" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_email_invoice')">
                    <b-form-input v-model="company.email_invoice" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_paynl_account_type')">
                    <b-select v-model="company.paynl_account_type" :options="['not-chosen', 'submerchant', 'payout-merchant']" :disabled="!$can('admin_company_details_edit')"></b-select>
                  </b-form-group>
                  <b-form-group :label="$t('company_payout_iban')">
                    <b-form-input v-model="company.payout_iban" :disabled="!$can('admin_company_details_edit')"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('company_whitelabel_layout')">
                    <b-select v-model="company.whitelabel_layout" :options="['fairtix', 'baron']" :disabled="!$can('admin_company_details_edit')"></b-select>
                  </b-form-group>
                </div>

                <b-form-group v-if="user && $can('admin_company_details_edit')">
                  <b-button @click="save()">{{ $t('save') }}</b-button>
                </b-form-group>
              </div>
            </div>

            <div  v-if="isUserCompany && ($can('my_company_settings_view') || $can('my_company_settings_edit'))" class="content-main-container mt-0 container-distance">
              <div class="title-container">{{ $t('company_settings') }}</div>
              <div class="content-container">
                <b-form-group>
                  <b-form-checkbox v-model="company.show_calendar" :disabled="!$can('my_company_settings_edit')">{{ $t('company_show_calendar') }}</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox v-model="company.calendar_sold_ratio" :disabled="!$can('my_company_settings_edit')">{{ $t('company_calendar_sold_ratio') }}</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox v-model="company.order_notification_enabled" :disabled="!$can('my_company_settings_edit')">{{ $t('company_send_order_notifications') }}</b-form-checkbox>
                </b-form-group>
                <b-form-group v-if="company.order_notification_enabled" :label="$t('company_send_order_notifications_email')">
                  <b-form-input v-model="company.order_notification_email" type="email" :disabled="!$can('my_company_settings_edit')"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox v-model="company.tickets_sold_out_notification_enabled" :disabled="!$can('my_company_settings_edit')">{{ $t('company_tickets_sold_out_notification') }}</b-form-checkbox>
                </b-form-group>
                <b-form-group v-if="company.tickets_sold_out_notification_enabled" :label="$t('company_send_order_notifications_email')">
                  <b-form-input v-model="company.tickets_sold_out_notification_email" type="email" :disabled="!$can('my_company_settings_edit')"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox v-model="company.order_thank_you_show_events" :disabled="!$can('my_company_settings_edit')">{{ $t('company_order_thank_you_show_events') }}</b-form-checkbox>
                </b-form-group>
                <b-form-group v-if="user && $can('my_company_settings_edit')">
                  <b-button @click="save()">{{ $t('save') }}</b-button>
                </b-form-group>
              </div>
            </div>
          </b-col>
          <b-col>
            <b-form-group v-if="($can('my_company_user_create') && isUserCompany) || $can('admin_company_details_edit')" class="text-right container-distance">
              <b-button variant="new" @click="showAddUserModal">{{ $t('user_create') }}</b-button>
            </b-form-group>
            <div v-else class="mb-3 float-right px-4 py-3" style="height: 56px;"></div>
            <b-table v-if="$can('admin_company_users_view') || ($can('my_company_users_view') && isUserCompany)" class="table-container" :items="company.users" :fields="[{key: 'name', label: $t('user_name')},{key: 'email', label: $t('user_email')},{key: 'role', label: $t('user_role')},{key: 'actions', label: $t('order_actions')}]">
              <template v-slot:cell(role)="{item}">
                {{ $t( 'user_role_' + item.role) }}
              </template>
              <template v-slot:cell(actions)="{item}">
                <!-- <b-button-group v-if="user.id !== item.id"> -->
                  <b-button variant="primary" v-if="(user.role === 'admin' || user.role === 'company_admin' || (user.role === 'user' && item.role === 'user')) && (user.id === 1 && item.id === 1 || (user.id !== item.id && item.id !== 1)) && (($can('my_company_user_edit') && isUserCompany) || $can('admin_company_users_edit'))" @click="showEditModal(item)" class="mr-2">{{ $t('manage') }}</b-button>
                  <b-button variant="danger" v-if="(user.role === 'admin' || user.role === 'company_admin' || (user.role === 'user' && item.role === 'user')) &&  (user.id !== item.id && item.id !== 1) && (($can('my_company_user_delete') && isUserCompany) || $can('admin_company_users_delete'))" @click="showDeleteModal(item)">{{ $t('delete') }}</b-button>
                  <b-button variant="info" v-if="(user.id !== item.id && item.id !== 1) && $can('admin_company_user_impersonate')" @click="impersonate(item.id)">
                    {{ $t('company_user_impersonate') }}
                  </b-button>
                <!-- </b-button-group> -->
              </template>
            </b-table>

            <div class="content-main-container mt-0 container-distance" v-if="user && $can('admin_automatic_invoicing_view')">
              <div class="title-container">{{ $t('my_company_invoice_title') }}</div>
              <div class="content-container">   
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox v-model="company.automatic_invoicing" :disabled="!$can('admin_company_automatic_invoicing_edit')">
                        {{ $t('my_company_invoice_automatic_invoicing') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox v-model="company.automatic_invoicing_test_mode" :disabled="!$can('admin_company_automatic_invoicing_edit')">
                        {{ $t('my_company_invoice_automatic_invoicing_test_mode') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox v-model="company.invoice_anti_dating" :disabled="!$can('admin_company_automatic_invoicing_edit')">
                        {{ $t('my_company_invoice_anti_dating') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox v-model="company.invoice_vat_shifted" :disabled="!$can('admin_company_automatic_invoicing_edit')">
                        {{ $t('my_company_invoice_vat_shifted') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox v-model="company.invoice_generate_payout_summary" :disabled="!$can('admin_company_automatic_invoicing_edit')">
                        {{ $t('my_company_invoice_generate_payout_summary') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group v-if="company.invoice_generate_payout_summary">
                      <b-form-checkbox v-model="company.invoice_include_payout_summary" :disabled="!$can('admin_company_automatic_invoicing_edit')">
                        {{ $t('my_company_invoice_include_payout_summary') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="company.invoice_generate_payout_summary">
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox v-model="company.invoice_generate_detailed_explanation" :disabled="!$can('admin_company_automatic_invoicing_edit')">
                        {{ $t('my_company_invoice_generate_detailed_explanation') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group v-if="company.invoice_generate_detailed_explanation">
                      <b-form-checkbox v-model="company.invoice_include_detailed_explanation" :disabled="!$can('admin_company_automatic_invoicing_edit')">
                        {{ $t('my_company_invoice_include_detailed_explanation') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox v-model="company.invoice_generate_fairtix_invoice" :disabled="!$can('admin_company_automatic_invoicing_edit')">
                        {{ $t('my_company_invoice_generate_fairtix_invoice') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group v-if="company.invoice_generate_fairtix_invoice">
                      <b-form-checkbox v-model="company.invoice_include_fairtix_invoice" :disabled="!$can('admin_company_automatic_invoicing_edit')">
                        {{ $t('my_company_invoice_include_fairtix_invoice') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox v-model="company.invoice_set_off_invoices" :disabled="!$can('admin_company_automatic_invoicing_edit')">
                        {{ $t('my_company_invoice_set_off_invoices') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('my_company_invoice_language')">
                      <b-select v-model="company.invoice_language" :options="['nl', 'en', 'es', 'de']" :disabled="!$can('admin_company_automatic_invoicing_edit')"></b-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('my_company_invoice_payout_frequency')">
                      <b-select v-model="company.invoice_payout_frequency" :options="['daily', 'weekly', 'monthly', 'after-event']" :disabled="!$can('admin_company_automatic_invoicing_edit')"></b-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('my_company_invoice_payout_delay')" v-if="company.invoice_payout_frequency === 'after-event'">
                      <b-form-input type="number" v-model="company.invoice_payout_delay" :disabled="!$can('admin_company_automatic_invoicing_edit')"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('my_company_invoice_max_payment_period')">
                      <b-form-input type="number" v-model="company.invoice_max_payment_period" :disabled="!$can('admin_company_automatic_invoicing_edit')"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('my_company_invoice_receive_method')">
                      <b-select v-model="company.invoice_receive_method" :options="['mail']" :disabled="!$can('admin_company_automatic_invoicing_edit')"></b-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-form-group v-if="user && $can('admin_company_automatic_invoicing_edit')">
                  <b-button @click="save()">{{ $t('save') }}</b-button>
                </b-form-group>
              </div>
            </div>
            <div class="content-main-container container-distance" v-if="user && $can('admin_company_permission_view')">
              <div class="title-container">{{ $t('company_permissions') }}</div>
              <div class="content-container">
                <div v-for="(group, index) in companyPermissions" :key="index">
                  <label class="group-label px-2 d-block mb-3 mb-1">
                    <b-form-checkbox @change="changePermissionsCheckbox(group, $event)" class="font-weight-bold py-1" :disabled="!$can('admin_company_permission_edit')">{{ index }}</b-form-checkbox>
                  </label>
                  <b-row>
                      <b-col cols="6" v-for="permission in group" :key="permission.id">
                        <b-form-group class="px-3">
                          <b-form-checkbox v-model="permission.selected" :disabled="!$can('admin_company_permission_edit')">{{ $t('permission_' + permission.slug) }}</b-form-checkbox>
                        </b-form-group>
                      </b-col>
                  </b-row>
                </div>

                <b-button v-if="$can('admin_company_permission_edit')" @click="savePermissions()">{{ $t('save') }}</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        
        </div>
        <b-modal id="add-user" @close="editUser = null" :title="$t('user_create_modal_title', company)" size="lg">
          <div v-if="editUser">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('company_user_settings_tab')" active>
                <b-form-group :label="$t('user_name')">
                  <b-form-input v-model="editUser.name"></b-form-input>
                </b-form-group>
                <b-form-group :label="$t('user_email')">
                  <b-form-input v-model="editUser.email" :state="state.email" aria-describedby="user-email" @input="validateEmail($event)"></b-form-input>
                  <b-form-invalid-feedback v-if="state.email === false" id="user-email">
                    {{ $t('email_is_required') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group :label="$t('user_password')">
                  <b-form-input @input="validatePassword($event)" v-model="editUser.password"></b-form-input>
                  <div v-if="passwordValidation.length">
                    <span class="d-block text-danger small" v-for="er in passwordValidation" :key="er">{{ $t(er) }}</span>
                  </div>
                </b-form-group>
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('user_language')">
                      <b-select v-model="editUser.language" :options="['nl', 'es', 'de', 'en']"></b-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('user_app_login_duration')">
                      <b-form-input v-model="editUser.app_login_duration" type="number" min="1"></b-form-input>
                      <div v-if="editUser.app_login_duration < 1">
                        <span class="d-block text-danger small">{{ $t('edit_user_modal_app_login_duration_error') }}</span>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group :label="$t('user_box_office')" v-if="$can('my_company_user_box_office_edit')">
                  <b-form-checkbox switch v-model="editUser.box_office_user" :value="true" :unchecked-value="false"></b-form-checkbox>
                </b-form-group>
                <b-form-group :label="$t('company_user_reset_password_checkbox')">
                  <b-form-checkbox switch v-model="editUser.to_reset_password" :value="true" :unchecked-value="false"></b-form-checkbox>
                </b-form-group>
              </b-tab>
              <b-tab :title="$t('company_permissions_tab')" v-if="$can('my_company_user_permissions_view')">
                <b-form-group :label="$t('user_allowed_events')">
                  <b-dropdown id="dropdown-Checkbox" :text="editUser.allowed_events.length ? (editUser.allowed_events.length + ' ' + $t('permission_events_selected')) : $t('permission_all_events')" ref="dropdown" class="w-100">
                    <b-form-group class="allowed-events-container">
                      <b-form-input v-model="searchEvents" :placeholder="$t('search')" size="sm" class="mb-2"></b-form-input>
                      <b-form-checkbox-group
                          id="checkbox-group-2"
                          v-model="editUser.allowed_events"
                          name="flavour-2"
                      >
                        <b-form-checkbox v-for="ev in events.filter(e => searchEvents ? e.name.toLowerCase().includes(searchEvents.toLowerCase()) : true)"
                                         :key="ev.id"
                                         :value="ev.id" :disabled="!$can('my_company_user_permissions_edit')" class="w-100">{{ ev.name }}</b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-dropdown>
                </b-form-group>
                <hr>
                <b-form-group :label="$t('my_company_allowed_events_days')">
                  <b-form-checkbox-group
                      v-model="editUser.events_days"
                      :options="days"
                  ></b-form-checkbox-group>
                </b-form-group>
                <div v-if="editUser.permissions && Object.keys(editUser.permissions).length">
                  <b-form-group v-if="$can('my_company_user_permissions_edit') && roles.length" :label="$t('permission_role_templates')">
                    <b-form-select @input="roleSelected($event)" :options="roles" value-field="id" text-field="name"></b-form-select>
                  </b-form-group>
                  <hr>
                  <div v-for="(group, index) in editUser.permissions" :key="index">
                    <label class="group-label px-2 d-block mt-3 mb-1 mb-0">
                      <b-form-checkbox @change="changePermissionsCheckbox(group, $event)" class="font-weight-bold py-1" :disabled="!$can('my_company_user_permissions_edit')">{{ index }}</b-form-checkbox>
                    </label>
                    <b-row>
                      <b-col cols="6" v-for="permission in group" :key="permission.id">
                        <b-form-group class="px-3">
                          <b-form-checkbox v-model="permission.selected" :disabled="!$can('my_company_user_permissions_edit')">{{ $t('permission_' + permission.slug) }}</b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <b-alert v-else show variant="warning">{{ $t('permission_no_company_privileges') }}</b-alert>
              </b-tab>
            </b-tabs>
          </div>

          <template #modal-footer>
            <b-button @click="addUser()" :disabled="passwordValidation.length  > 0 || state.email === false || (editUser && !editUser.email)">{{ $t('create') }}</b-button>
          </template>
        </b-modal>

        <b-modal id="add-company-payment-method" v-if="$can('admin_company_payment_costs_create')" @close="editPaymentFees = null" :title="$t('admin_company_add_payment_method', company)" size="lg">
         
          <b-form-group :label="$t('admin_company_payment_method')">
            <b-select v-model="newPaymentFee.payment_method" :options="payment_methods" value-field="id" text-field="name"></b-select>
          </b-form-group>
          {{ $t('admin_company_payment_method_fee_percentage') }}
          <b-input-group style="height:45px">
            <template #prepend>
              <b-input-group-text  style="height:45px">%</b-input-group-text>
            </template>
            <b-form-input  style="height:45px"
                type="number"
                v-model="newPaymentFee.fee_percentage"
                :step="0.01"
                :min="0"
            ></b-form-input>
          </b-input-group >
          <br>
          {{ $t('admin_company_payment_method_fee_fixed') }} ( {{ $t('manage_company_without_vat') }} )
          <b-input-group style="height:45px">
            <template #prepend>
              <b-input-group-text  style="height:45px">{{ $t('currency') }}</b-input-group-text>
            </template>
            <b-form-input  style="height:45px"
                type="number"
                v-model="newPaymentFee.fee_fixed_amount"
                :step="0.01"
                :min="0"
            ></b-form-input>
          </b-input-group >
          <br>
          <date-time-picker v-model="newPaymentFee.starts_at" :label="$t('admin_company_payment_method_start_at')"></date-time-picker>

          <template #modal-footer>
            <b-button @click="addPaymentMethodFees()">{{ $t('add') }}</b-button>
          </template>
        </b-modal>

        <b-modal id="add-company-fee-modal" @close="companyFee = null" :title="$t('admin_company_add_company_fee')">

         <div v-if="companyFee">
           <b-form-group :label="$t('admin_company_fee_type')">
             <b-select v-model="companyFee.fee_type" :options="companyFees"></b-select>
           </b-form-group>

           {{ $t('admin_company_fee') }}
           <b-input-group style="height:45px">
             <template #prepend>
               <b-input-group-text style="height:45px">{{ companyFee.fee_type === 'free-tickets-free-percentage' ? '%' : $t('currency') }}</b-input-group-text>
             </template>
             <b-form-input  style="height:45px"
                            type="number"
                            v-model="companyFee.fee"
                            :step="0.01"
                            :min="0"
             ></b-form-input>
           </b-input-group >
           <br>
           <date-time-picker v-model="companyFee.starts_at" :label="$t('admin_company_fee_start_at')"></date-time-picker>
         </div>

          <template #modal-footer>
            <b-button :disabled="addingLoading || !companyFee || !companyFee.fee_type || !companyFee.fee || !companyFee.starts_at" @click="saveCompanyFee">
              <b-icon v-if="addingLoading" icon="three-dots" animation="cylon" class="mr-2"></b-icon> {{ (companyFee && companyFee.id) ? $t('update') : $t('add') }}
            </b-button>
          </template>
        </b-modal>

        <b-modal id="edit-user" @hide="editUser = null" @close="editUser = null" :title="$t('edit_user_modal_title')" size="lg">
          <div v-if="editUser">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('company_user_settings_tab')" active>
                <b-form-group :label="$t('user_name')">
                  <b-form-input v-model="editUser.name"></b-form-input>
                </b-form-group>
                <b-form-group :label="$t('user_email')">
                  <b-form-input v-model="editUser.email" :state="state.email" aria-describedby="user-email" @input="validateEmail($event)"></b-form-input>
                  <b-form-invalid-feedback v-if="state.email === false" id="user-email">
                    {{ $t('email_is_required') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group :label="$t('user_password')"
                              :description="$t('user_password_hint')">
                  <b-form-input @input="validatePassword($event, true)" v-model="editUser.password"></b-form-input>
                  <div v-if="passwordValidation.length">
                    <span class="d-block text-danger small" v-for="er in passwordValidation" :key="er">{{ $t(er) }}</span>
                  </div>
                </b-form-group>
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('user_language')">
                      <b-select v-model="editUser.language" :options="['nl', 'es', 'de', 'en']"></b-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('user_app_login_duration')">
                      <b-form-input v-model="editUser.app_login_duration" type="number" min="1"></b-form-input>
                      <div v-if="editUser.app_login_duration < 1">
                        <span class="d-block text-danger small">{{ $t('edit_user_modal_app_login_duration_error') }}</span>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group v-if="$can('my_company_user_box_office_edit')" :label="$t('user_box_office')">
                  <b-form-checkbox switch v-model="editUser.box_office_user" :value="true" :unchecked-value="false"></b-form-checkbox>
                </b-form-group>
                <b-form-group :label="$t('company_user_reset_password_checkbox')">
                  <b-form-checkbox switch v-model="editUser.to_reset_password" :value="true" :unchecked-value="false"></b-form-checkbox>
                </b-form-group>
              </b-tab>
              <b-tab :title="$t('company_permissions_tab')" v-if="$can('my_company_user_permissions_view')">
                <b-form-group :label="$t('user_allowed_events')">
                  <b-dropdown id="dropdown-Checkbox" :text="editUser.allowed_events.length ? (editUser.allowed_events.length + ' ' + $t('permission_events_selected')) : $t('permission_all_events')" ref="dropdown" class="w-100">
                    <b-form-group class="allowed-events-container">
                      <b-form-input v-model="searchEvents" :placeholder="$t('search')" size="sm" class="mb-2"></b-form-input>
                      <b-form-checkbox-group
                          id="checkbox-group-2"
                          v-model="editUser.allowed_events"
                          name="flavour-2"
                      >
                        <b-form-checkbox v-for="ev in events.filter(e => searchEvents ? e.name.toLowerCase().includes(searchEvents.toLowerCase()) : true)"
                                         :key="ev.id"
                                         :value="ev.id" :disabled="!$can('my_company_user_permissions_edit')" class="w-100">{{ ev.name }}</b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-dropdown>
                </b-form-group>
                <hr>
                <b-form-group :label="$t('my_company_allowed_events_days')">
                  <b-form-checkbox-group
                      v-model="editUser.events_days"
                      :options="days"
                  ></b-form-checkbox-group>
                </b-form-group>
                <div v-if="editUser.permissions && Object.keys(editUser.permissions).length">
                  <b-form-group :label="$t('permission_role_templates')" v-if="$can('my_company_user_permissions_edit') && roles.length">
                    <b-form-select @input="roleSelected($event)" :options="roles" value-field="id" text-field="name"></b-form-select>
                  </b-form-group>
                  <hr>
                  <div v-for="(group, index) in editUser.permissions" :key="index">
                    <label class="group-label px-2 d-block mt-3 mb-1 mb-0">
                      <b-form-checkbox @change="changePermissionsCheckbox(group, $event)" class="font-weight-bold py-1" :disabled="!$can('my_company_user_permissions_edit')">{{ index }}</b-form-checkbox>
                    </label>
                    <b-row>
                      <b-col cols="6" v-for="permission in group" :key="permission.id">
                        <b-form-group class="px-3">
                          <b-form-checkbox v-model="permission.selected" :disabled="!$can('my_company_user_permissions_edit')">{{ $t('permission_' + permission.slug) }}</b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <b-alert v-else show variant="warning">{{ $t('permission_no_company_privileges') }}</b-alert>
              </b-tab>
            </b-tabs>
          </div>
          <template #modal-footer>
            <b-button @click="updateUser()" :disabled="passwordValidation.length > 0 || state.email === false || (editUser && !editUser.email)">{{ $t('save') }}</b-button>
          </template>
        </b-modal>

      </b-col>
    </b-row>
    <b-modal :title="$t('delete')" id="delete-user" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('delete_entry') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button :disabled="loading" @click="deleteUser" variant="danger">{{ $t('delete') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'
import {groupBy, validateEmail, validatePassword} from "@/functions";
import DateTimePicker from "@/components/DateTimePicker";

export default {
  name: 'ManageCompany',
  components: {
    DateTimePicker
  },
  data() {
    return {
      working: false,
      company: {},
      logo: null,
      editUser: null,
      paymentMethods: [],
      loading: false,
      selectedUser: null,
      permissions: [],
      companyPermissions: [],
      roles: [],
      role: null,
      events: [],
      searchEvents: null,
      payment_method_fees: null,
      newPaymentFee: {
        payment_method: null,
        fee_percentage: 0,
        fee_fixed_amount: 0,
        starts_at: null
      },
      payment_methods: [],
      paymentMethodHistory: [],
      companyFees: [
        {value: 'ticket-fee', text: this.$t('admin_company_company_fee_ticket_fee')},
        {value: 'box-office-ticket-fee', text: this.$t('admin_company_company_fee_box_office_ticket_fee')},
        {value: 'refund-fee', text: this.$t('admin_company_company_fee_refund_fee')},
        {value: 'free-tickets-free-percentage', text: this.$t('admin_company_company_fee_free_tickets_free_percentage')},
        {value: 'free-ticketfee', text: this.$t('admin_company_company_fee_free_ticketfee')},
      ],
      companyFee: null,
      addingLoading: false,
      days: [
        this.$t('day_mon'),
        this.$t('day_tue'),
        this.$t('day_wed'),
        this.$t('day_thu'),
        this.$t('day_fri'),
        this.$t('day_sat'),
        this.$t('day_sun')
      ],
      passwordValidation: [],
      state: {
        email: null
      }
    }
  },
  created() {
    document.title = this.$t('backend_title_manage_company')
    if(this.$store.state.user) {
      if(!this.$can('my_company_view') && !this.$can('admin_company_details_page_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.loadCompany()
    api.permission().getRoles().then(response => {
      this.roles = response.filter(r => {
        if(this.companyId !== 'fairtix') {
          return !r.admin_only && r.online;
        }
        return r.online;
      });
    })
  },
  methods: {
    loadCompany() {
      if (!this.user) {
        setTimeout(this.loadCompany, 100)
        return
      }
      api.company().get(this.companyId).then(company => {
        company.show_kvk_on_pos = (company.show_kvk_on_pos === 1) ? true : false
        company.automatic_invoicing = (company.automatic_invoicing === 1) ? true : false
        company.automatic_invoicing_test_mode = (company.automatic_invoicing_test_mode === 1) ? true : false
        company.invoice_anti_dating = (company.invoice_anti_dating === 1) ? true : false
        company.invoice_vat_shifted = (company.invoice_vat_shifted === 1) ? true : false
        company.invoice_generate_payout_summary = (company.invoice_generate_payout_summary === 1) ? true : false
        company.invoice_include_payout_summary = (company.invoice_include_payout_summary === 1) ? true : false
        company.invoice_generate_detailed_explanation = (company.invoice_generate_detailed_explanation === 1) ? true : false
        company.invoice_include_detailed_explanation = (company.invoice_include_detailed_explanation === 1) ? true : false
        company.invoice_generate_fairtix_invoice = (company.invoice_generate_fairtix_invoice === 1) ? true : false
        company.invoice_include_fairtix_invoice = (company.invoice_include_fairtix_invoice === 1) ? true : false
        company.invoice_set_off_invoices = (company.invoice_set_off_invoices === 1) ? true : false
        company.users = company.users.map(user => {
          user.allowed_events = user.allowed_events.map(ae => ae.id);
          return user;
        })
        this.company = company
        this.permissions = JSON.parse(JSON.stringify(company.permissions));
        if(this.companyId !== 'mine') {
            api.permission().getPermissions().then(permissions => {
                const tmpArray = permissions.filter(p => {
                    if(this.companyId !== 'fairtix') {
                        return !p.admin_only;
                    }
                    return true;
                }).map(permission => {
                    const isSelected = !!company.permissions.filter(rp => rp.id === permission.id).length;
                    return Object.assign(permission, {selected: isSelected})
                });

                this.companyPermissions = this.getSortedPermissions(tmpArray)
            })
        }
        this.getPaymentMethodFees()
        api.company().events(company.id, false).then(response => {
          this.events = response;
        })
      })
    },
    getPaymentMethodFees(){
      api.order().paymentMethods(this.company.id).then(paymentMethods => {
        this.paymentMethodHistory = []
        paymentMethods.forEach(el => {
          if(el.payment_method_fees){
            el.payment_method_fees.forEach(element => {
              let tmpObject = {
                starts_at: element.starts_at,
                payment_method: el.name,
                fee_percentage: element.fee_percentage,
                fee_fixed_amount: element.fee_fixed_amount
              }
              this.paymentMethodHistory.push(tmpObject)
            })
          }
        })
      })
    },
    getSortedPermissions(permissions) {
      const tempPermissions = groupBy(permissions, p => p.category);
      for (const group in tempPermissions) {
        tempPermissions[group] = tempPermissions[group].sort((a, b)=>{
          return a.sort - b.sort;
        })
      }

      return tempPermissions;
    },
    showEditModal(user) {
      this.passwordValidation = [];
      this.state.email = null;
      const tmpArray = JSON.parse(JSON.stringify(this.permissions)).map(permission => {
        const isSelected = !!user.permissions.filter(rp => rp.id === permission.id).length;
        return Object.assign(permission, {selected: isSelected})
      });

      this.editUser = JSON.parse(JSON.stringify(user));
      this.editUser.permissions = this.getSortedPermissions(tmpArray);
      this.searchEvents = null;
      this.$bvModal.show('edit-user')
    },
    addPaymentMethodFees(){
      if( this.$can('admin_company_payment_costs_create') ){
        api.company().addNewPaymentMetodFees(this.company.id, this.newPaymentFee).then(() => {
          this.getPaymentMethodFees()
          this.$store.commit('success', 'admin_company_payment_method_added');
          this.$bvModal.hide('add-company-payment-method')
        })
      }
    },
    updateUser() {
      const permissions = [];
      if(this.$can('my_company_user_permissions_edit')) {
        for (const group in this.editUser.permissions) {
          for (const permission of this.editUser.permissions[group]) {
            if (permission.selected) {
              permissions.push(permission.id);
            }
          }
        }
      }
      const user = JSON.parse(JSON.stringify(this.editUser));
      user.permissions = permissions;
      if (this.$can('admin_company_details_edit')) {
        api.user().adminUpdate(user).then(() => {
          this.$store.commit('success', 'user_updated');
          this.$bvModal.hide('edit-user')
          this.editUser = null
          this.loadCompany()
        })
        return
      }

      api.user().update(this.companyId === 'mine' ? 'mine' : this.company.id, user).then(() => {
        this.$store.commit('success', 'user_updated');
        this.$bvModal.hide('edit-user')
        this.editUser = null
        this.loadCompany()
      })
    },
    showDeleteModal(user) {
      this.selectedUser = user;
      this.$bvModal.show('delete-user')
    },
    deleteUser() {
      this.loading = true;
      api.user().delete(this.company.id, this.selectedUser.id).then(() => {
        let index = this.company.users.indexOf(this.selectedUser)
        this.company.users.splice(index, 1)
        this.$bvModal.hide('delete-user')
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    impersonate(id) {
      api.user().loginAs(id).then((token) => {
        this.$store.commit('loginAs', token)
        this.$router.push({
          name: 'Dashboard'
        })
      })
    },
    showAddUserModal() {
      const tmpArray = JSON.parse(JSON.stringify(this.permissions)).map(permission => {
        return Object.assign(permission, {selected: true})
      });
      this.passwordValidation = [];
      this.state.email = null;
      this.editUser = {
        name: null,
        email: null,
        password: null,
        language: null,
        role: null,
        box_office_user: false,
        to_reset_password: false,
        permissions: this.getSortedPermissions(tmpArray),
        allowed_events: [],
        events_days: []
      }
      this.searchEvents = null;
      this.$bvModal.show('add-user')
    },
    showAddPaymentCostsModal() {
      api.company().getAllPaymentMethods().then((paymentMethods) => {
        this.payment_methods = paymentMethods
      })
      this.$bvModal.show('add-company-payment-method')
    },
    addUser() {
      const permissions = [];
      if(this.$can('my_company_user_permissions_edit')) {
        for (const group in this.editUser.permissions) {
          for (const permission of this.editUser.permissions[group]) {
            if (permission.selected) {
              permissions.push(permission.id);
            }
          }
        }
      }
      const user = JSON.parse(JSON.stringify(this.editUser));
      user.permissions = permissions;
      api.company().addUser(this.companyId === 'mine' ? 'mine' : this.company.id, user).then((user) => {
        user.allowed_events = user.allowed_events.map(ae => ae.id);
        this.company.users.push(user)
        this.$store.commit('success', 'user_added');
        this.$bvModal.hide('add-user')
        this.editUser = null
      })
    },
    save() {
      api.company().update(this.company.id, this.company).then(() => {
        this.loadCompany()
        this.$store.commit('success', 'company_updated');
      })
    },
    savePermissions() {
      const permissions = [];

      for (const group in this.companyPermissions) {
        for (const permission of this.companyPermissions[group]) {
          if (permission.selected) {
            permissions.push(permission.id);
          }
        }
      }
      this.loading = true;
      api.permission().syncCompanyPermissions(this.company.id, permissions).then(() => {
        this.loading = false;
        this.$store.commit('success', 'company_updated');
        this.loadCompany();
      }).catch(() => {
        this.loading = false;
      })
    },
    roleSelected(role) {
      if(!role) {
        return;
      }
      role = this.roles.find(r => r.id === parseInt(role))
      for (const group in this.editUser.permissions) {
        for (const permission of this.editUser.permissions[group]) {
          permission.selected = !!role.permissions.filter(rp => rp.id === permission.id).length
        }
      }
    },
    changePermissionsCheckbox(permissions, event) {
      for(const permission of permissions) {
        permission.selected = event;
      }
    },
    showAddCompanyFeeModal() {
      this.companyFee = {
        fee_type: null,
        starts_at: null,
        fee: null
      };
      this.$bvModal.show('add-company-fee-modal')
    },
    showEditCompanyFeeModal(companyFee) {
      this.companyFee = JSON.parse(JSON.stringify(companyFee));
      this.$bvModal.show('add-company-fee-modal')
    },
    saveCompanyFee() {
      const request = this.companyFee.id ?
          api.company().updateCompanyFee(this.company.id, this.companyFee.id, this.companyFee) :
          api.company().addCompanyFee(this.company.id, this.companyFee);

      this.addingLoading = true;
      request.then(() => {
        this.$bvModal.hide('add-company-fee-modal');
        this.$store.commit('success', this.companyFee.id ? 'admin_company_company_fee_updated' : 'admin_company_company_fee_added');
        this.addingLoading = false;
        this.loadCompany();
      }).catch(() => {
        this.addingLoading = false;
      })
    },
    validatePassword(value, isEdit = false) {
      this.passwordValidation = validatePassword(value, isEdit)
    },
    validateEmail(email) {
      this.state.email = null
      if(!validateEmail(email)) {
        this.state.email = false
      }
    },
  },
  computed: {
    companyId() {
      return this.$can('admin_company_overview_view') ? this.$route.params.id : 'mine'
    },
    isUserCompany() {
      return this.user.company.unique_id === this.$route.params.id;
    },
    ...mapState(['user'])
  },
  watch:{
    '$store.state.user': {
      handler() {
        if(!this.$can('my_company_view') && !this.$can('admin_company_details_page_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width:992px) {
  .flexing{
    align-items: center;
    margin-bottom: 20px;
  }
  .flexing > div{
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 10px;
  }
  .flexing .container-distance{
    margin-bottom: 0px!important;
  }
  .flexing >div a,.flexing >div fieldset ,.flexing >div fieldset div button{
    width: 100%;
  }
}
.group-label {
  background-color: #EEEEEE !important;
  color: #000!important;
  font-size: 15px!important;
  padding-top: 12px ;
  padding-bottom: 12px ;
}
.group-label span {
  background-color: #EEEEEE;
  color: #4E4D4D;
}
.allowed-events-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height:250px;
   
}
</style>
