<template>
  <b-row>
    <b-col cols="12" lg="6">
      <b-form-group :label="label ? (label + ` date`) : null">
        <b-input-group>
          <b-form-input
              v-model="date"
              type="text"
              :placeholder="datePlaceholder ? datePlaceholder : 'YYYY-MM-DD'"
              autocomplete="off"
              :state="valid"
              :disabled="!!disabled"
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
                v-model="date"
                button-only
                right
                locale="nl"
                :min="min"
                start-weekday="1"
                :disabled="!!disabled"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col  cols="12" lg="6">
      <b-form-group :label="label ? (label + ` time`) : null">
        <b-input-group>
          <b-form-input
              v-model="time"
              type="text"
              :placeholder="timePlaceholder ? timePlaceholder : 'UU:MM'"
              :state="valid"
              :disabled="!!disabled"
          ></b-form-input>
          <b-input-group-append>
            <b-form-timepicker
                v-model="time"
                button-only
                :show-seconds="false"
                right
                locale="nl"
                :disabled="!!disabled"
            ></b-form-timepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import moment from 'moment-timezone'

moment.tz.setDefault('Europe/Amsterdam');
export default {
  props: ['label', 'value', 'min', 'disabled', 'optional', 'datePlaceholder', 'timePlaceholder'],
  data() {
    return {
      date: '',
      time: '',
      dateTime: null,
      debounceTimeout: null,
      loaded: false,
      valid: null
    }
  },
  methods: {
    updateValue(){
      if (this.value === null) {
        this.date = '';
        this.time = '';
        this.dateTime = null;
        this.debounceTimeout = null;
        this.valid = false;
        this.loaded = false;
        if(this.optional) {
          this.valid = null;
        }
        return
      }

      this.dateTime = moment(new Date(this.value))

      if (!this.loaded && this.dateTime.isValid()) {
        this.date = this.dateTime.format('YYYY-MM-DD')
        this.time = this.dateTime.format('HH:mm')
        this.loaded = true
        return
      }

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        if (this.dateTime.isValid()) {
          this.valid = null
          this.date = this.dateTime.format('YYYY-MM-DD')
          this.time = this.dateTime.format('HH:mm')
        } else {
          this.valid = false
        }
        if(this.optional) {
          this.valid = null;
        }
      }, 1000)
    }
  },
  mounted() {
    this.updateValue()
  },
  watch: {
    date(value) {
      if(value) {
        if(!this.time) {
          this.time = '00:00';
        }
        this.$emit('input', moment(this.date + ' ' + this.time).tz('UTC').format())
        this.updateValue()
      }
    },
    time(value) {
      if(value) {
        this.$emit('input', moment(this.date + ' ' + this.time).tz('UTC').format())
        this.updateValue()
      }
    },
    value() {
      this.updateValue()
    }
  }
}
</script>
