<template>
  <div>
    <b-row>
      <b-spinner v-if="!scanners || !entrances"></b-spinner>
      <b-col v-else>
        <b-form-group class="text-right container-distance">
          <b-button variant="new" v-b-modal.add-scanner>{{ $t('new') }}</b-button>
        </b-form-group>
        <b-modal id="add-scanner" :title="$t('scanner_add_modal_title')">
          <b-form-group :label="$t('scanner_name')">
            <b-form-input v-model="scanner.name"></b-form-input>
          </b-form-group>
          <template #modal-footer>
            <p>{{ $t('scanner_create_hint') }}</p>
            <b-button variant="primary" @click="createScanner()">{{ $t('create') }}</b-button>
          </template>
        </b-modal>
        <b-table :items="scanners" :fields="['name', 'entrance', 'login', 'password', 'actions']">
          <template v-slot:cell(entrance)="{item}">
            <span v-if="item.entrance">{{ item.entrance.name }}</span>
            <span v-else>{{ $t('scanner_entrances_all') }}</span>
          </template>
          <template v-slot:cell(actions)="{item}">
            <b-btn variant="info" @click="showLoginQR(item)" class="mr-3">{{ $t('scanner_show_login_qr') }}</b-btn>
            <b-btn variant="primary" @click="editScanner(item)">{{ $t('scanner_edit') }}</b-btn>
          </template>
        </b-table>
        <b-modal id="scanner-qr" :title="qrTitle">
          <div class="text-center" v-if="qrCode">
            <h2>{{ qrCode.name }}</h2>
            <qrcode-vue :size="300" level="H" :value="qrCode"></qrcode-vue>
          </div>
          <template #modal-footer>
            <b-button variant="primary" @click="closeQRModal">{{ $t('close') }}</b-button>
          </template>
        </b-modal>
        <b-modal id="scanner-edit" :title="$t('scanner_edit')" @hidden="scanner = {}">
          <b-form-group :label="$t('scanner_name')">
            <b-form-input v-model="scanner.name"></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('scanner_entrance')">
            <b-select v-model="scanner.entrance_id" :options="entrances" text-field="name" value-field="id"></b-select>
          </b-form-group>
          <template #modal-footer>
            <b-button variant="primary" @click="saveScanner">{{ $t('save') }}</b-button>
          </template>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '../api'
import QrcodeVue from 'qrcode.vue'
export default {
  components: {
    QrcodeVue
  },
  data() {
    return {
      scanners: null,
      scanner: {},
      qrCode: null,
      qrTitle: '',
      entrances: []
    }
  },
  methods: {
    createScanner(){
      api.scanner().create(this.scanner).then((scanner) => {
        this.scanners.push(scanner)
        this.scanner = {}
        this.$bvModal.hide('add-scanner')
      })
    },
    closeQRModal(){
      this.qrCode = null
      this.$bvModal.hide('scanner-qr')
    },
    showLoginQR(scanner){
      this.qrTitle = scanner.name
      this.qrCode = JSON.stringify({
        name: scanner.name,
        login: scanner.login,
        password: scanner.password
      })
      this.$bvModal.show('scanner-qr')
    },
    editScanner(scanner){
      this.scanner = Object.assign({}, scanner)
      this.$bvModal.show('scanner-edit')
    },
    saveScanner(){
      api.scanner().save(this.scanner).then(() => {
        this.getScanners()
        this.scanner = {}
        this.$store.commit('success', 'scanner_updated')
        this.$bvModal.hide('scanner-edit')
      })
    },
    getScanners(){
      api.scanner().get().then(scanners => {
        this.scanners = scanners
      })
    }
  },
  created() {
    this.getScanners()
    api.entrance().get().then(entrances => {
      this.entrances = [{
        id: 0,
        name: this.$t('scanner_entrances_all')
      }]
      this.entrances = this.entrances.concat(entrances)
    })
  }
}
</script>
