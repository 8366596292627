export default class mailApi {

    constructor(axios) {
        this.axios = axios
    }

    loadTemplate( template, orderId ){
        return this.axios.get('/mail/get-template/' + template + '/' + orderId )
    }

    sendMail( template, mail, orderId ){
        return this.axios.post('/mail/send/' + template + '/' + orderId, mail)
    }

}