<template>
  
  <div v-if="showModal" class="cookie-container">
    <div class="cookie-modal">  
      <div class="cookie-content" v-if="!showPreferences">
        <h2>{{ $t('cookie_banner_title') }}</h2>
        <br>
        <span>
          {{ $t('cookie_banner_text') }}
        </span>
        <br><br>
        <a class="link-change-preferences" @click="togglePreferences()">{{ $t('cookie_banner_change_preference') }}</a>
        <br><br>
        <button class="btn-accept" @click="acceptSelectedCookies()">{{ $t('btn_accept_all') }}</button>
      </div>
      <div v-else class="cookie-content">
        <h3>{{ $t('cookie_banner_personalize') }}</h3>
        <div class="preference-checkboxes mt-4">
          <b-row class="mb-3">
            <b-col class="text-left">{{ $t('cookie_banner_preference_local') }}</b-col>
            <b-col class="text-right"><b-form-checkbox v-model="selectedCategories.local_information" /></b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col class="text-left">{{ $t('cookie_banner_preference_analytics') }}</b-col>
            <b-col class="text-right"><b-form-checkbox v-model="selectedCategories.analytics" /></b-col>
          </b-row>
          <b-row>
            <b-col class="text-left">{{ $t('cookie_banner_preference_marketing') }}</b-col>
            <b-col class="text-right"><b-form-checkbox v-model="selectedCategories.marketing" /></b-col>
          </b-row>
        </div>
        <div class="actions mt-4">
          <button @click="acceptSelectedCookies()" class="btn-second">{{ $t('btn_accept_all') }}</button>
          <button @click="acceptSelectedCookies()" class="btn-accept">{{ $t('btn_accept_selected') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCookie, setCookie} from "@/functions";

export default {
  data() {
    return {
      showModal: true,
      showPreferences: false,
      selectedCategories: {
        analytics: true,
        marketing: true,
        local_information: true
      }
    };
  },
  methods: {
    gtag(){
      window.dataLayer.push(arguments);
    },
    togglePreferences(){
      this.showPreferences=true
    },
    toggleCategory(category) {
      if (this.selectedCategories.includes(category)) {
        this.selectedCategories = this.selectedCategories.filter(
          (item) => item !== category
        );
      } else {
        this.selectedCategories.push(category);
      }
    },
    acceptSelectedCookies() {
      this.showModal = false;
      setCookie(
        "ft_cookieCategories",
        JSON.stringify(this.selectedCategories),
        365
      );

      // Set up default consent settings
      var consentSettings = {
        'ad_storage': (this.selectedCategories.marketing) ? 'granted' : 'denied',
        'ad_personalization': (this.selectedCategories.marketing) ? 'granted' : 'denied',
        'ad_user_data': (this.selectedCategories.marketing) ? 'granted' : 'denied',
        'analytics_storage': (this.selectedCategories.analytics) ? 'granted' : 'denied',
      };

      // Push the gtm.init_consent event with default consent settings
      this.gtag('consent', 'update', consentSettings);
    }
  },
  mounted() {
    const storedCategories = getCookie("ft_cookieCategories");
    if (storedCategories) {
      this.showModal = false;
      this.selectedCategories = JSON.parse(storedCategories);
    }
  }
};
</script>

<style scoped>
.cookie-container{
  background-color:rgba(0,0,0,0.7);
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:300;
}
.cookie-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  max-width: 500px;
  text-align: center;
  z-index: 400;
}
.cookie-content {
  min-width: 460px;
  margin-top: 10px;
}
.cookie-content span {
  margin-right: 10px;
}
.cookie-content button {
  margin: 5px;
  cursor: pointer;
}
.preference-checkboxes {
  margin-top: 10px;
}
.preference-checkboxes label {
  display: block;
  margin-bottom: 5px;
}
.actions {
  margin-top: 10px;
}
.preference-checkboxes input[type="checkbox"] {
  margin-right: 5px;
}
.btn-accept{
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 8px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius:55px
}
.btn-second{
  background-color: grey;
  border: none;
  color: white;
  padding: 8px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius:55px
}
.link-change-preferences{
  cursor:pointer;
  margin:15px 0
}

@media only screen and (max-width: 990px) {
  .cookie-modal {
    width: 94%;
  }
}

@media only screen and (max-width: 576px) {
  .cookie-content{
    min-width:100%
  }
}

</style>