<template>
  <div>
    <div>
    <div  class="d-flex  flex-wrap">
      <div class="bigDiv px-0 pr-lg-3  pl-lg-0 order-2 order-lg-1 mb-3">
        <b-row class="printBlock">
          <b-col cols="7" sm="8">
            <b-form-input class="v-text-field" v-model="search" debounce="750"
                          placeholder="Search"></b-form-input>
          </b-col>
          <b-col cols="5" sm="4">
            <b-form-group class="text-right container-distance mb-0">
              <b-button variant="new" class="py-3 mb-1" @click="print()">{{ $t('print') }}</b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="tableBig">
        <b-table class="table-container" :items="visitors" :fields="headers">
        </b-table>
        </div>
        <pagination v-if="page_data" :data="page_data" @pagination-change-page="getVisitorList"></pagination>
      </div>
      <div class="SmallDiv px-0 pl-lg-3 pr-lg-0 order-1 order-lg-2 mb-4 mb-lg-1" >
        <event-nav v-if="event && event.name" :event="event"></event-nav>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
import EventNav from "@/components/EventNav";

export default {
  name: 'EventVisitorList',
  components: {
    EventNav
  },
  data() {
    return {
      search: '',
      event: null,
      page_data: null,
      visitors: null,
      headers: ['first_name', 'last_name', 'email', 'product_name', 'timeslot_start', 'timeslot_end']
    }
  },
  methods: {
    print() {
      let printWindow = window.open()
      printWindow.document.open('text/html')

      printWindow.document.write('<style>td{border-top:solid 1px lightgrey;color:#808080}td,th{font-family:Roboto}tr th:first-of-type, tr td:first-of-type{padding-left:10px}</style>')

      let table = '<table width="100%" cellspacing="0" cellpadding="0"><thead>'
      table += '<tr style="background-color:#193e58;height:30px">'
      this.headers.forEach(header => {
        table += '<th style="color:#FFF;text-align:left">' + this.$t(header) + '</th>'
      })
      table += '</tr>'


      this.visitors.forEach(visitor => {
        table += '<tr height="30">'
        this.headers.forEach(header => {
          if (visitor[header] === null) {
            table += '<td></td>'
          } else {
            table += '<td>' + visitor[header] + '</td>'
          }
        })
        table += '</tr>'

      })

      table += '</table>'
      printWindow.document.write(table)

      printWindow.document.close()
      printWindow.focus()
      printWindow.print()
      printWindow.close()
    },
    getVisitorList(page = 1) {
      api.event().getVisitorList(this.$route.params.id, page).then(visitors => {
        this.page_data = visitors
        this.visitors = visitors.data
      })
    }
  },
  watch: {
    search() {
      if (this.search === '') {
        this.getVisitorList()
        return
      }

      api.event().searchVisitorList(this.$route.params.id, this.search).then(visitors => {
        this.visitors = visitors.data
        this.page_data = visitors
      })
    },
    '$store.state.user': {
      handler() {
        if(!this.$can('event_visitor_list_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  },
  created() {
    document.title = this.$t('backend_title_events_visitor_list')
    if(this.$store.state.user) {
      if(!this.$can('event_visitor_list_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.event().get(this.$route.params.id).then(event => {
      this.event = event
    })

    this.getVisitorList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.v-text-field {
  width: 300px;
  min-width: 100%;
  max-width: 100%;
}
.printBlock{
  margin-bottom: .5rem;
  align-items: center;
}
.button-row-included {
  margin-top: 87px !important
}
@media (max-width:992px) {
  .v-text-field {
  width: 250px;
}
}
</style>
