<template>
  <b-modal :title="$t('order_detail_modal_refund_order_title', {order: order ? order.order_number : ''})"
           id="refund-order" size="lg">
    <div v-if="order">
    <!--  -->
     <b-row class="mb-2">
        <b-col cols="8" class="d-flex align-items-center blackColor mediamSize">{{ $t('order_refund_choose_which_products_to_refund') }}</b-col>
        <b-col cols="4"  class="text-right">
          <b-button @click="selectAll(!isSelectAll)" class="SmallSize selectAll px-3 px-md-4 py-1 text-white" variant="warning">{{ isSelectAll ? $t('unselect_all') : $t('select_all')}}</b-button>
        </b-col>
      </b-row>
      <div v-for="group in groups" :key="group.name" class="mt-1">
        <div class="mt-2 mb-2 title blackColor" v-if="group.name !== 'event_default_group'">{{ group.name }}</div>
        <div class="px-2 px-md-3 SmallSize">
          <b-row v-for="product in group.products" :key="product.id" class="mb-2">
            <b-col cols="6">
              <b-form-checkbox v-if="product.refunded_at === null" v-model="product.checked" @change="calculateRefundTotal()">
                <span>{{ product.product.name }}</span>
              </b-form-checkbox>
              <span v-else class="pl-1 text-line-through">{{ product.product.name }}</span>
            </b-col>
            <b-col cols="3">
              <span v-if="product.ticket && product.ticket.customer">
                <span v-if="product.ticket.customer.first_name" class="mr-1">{{ product.ticket.customer.first_name  }}</span>
                <span v-if="product.ticket.customer.last_name" class="mr-1">{{ product.ticket.customer.last_name  }}</span>
              </span>
            </b-col>
            <b-col cols="3" class="text-right">
              <span v-if="product.refunded_at === null">{{ product.price | currency }}</span>
              <span v-else class="text-uppercase">{{ $t('order_status_refunded') }}</span>
            </b-col>
          </b-row>
        </div>
      </div>
       <b-row class="mt-4 mb-2 ">
         <b-col>
           <div class="SmallSize">
             <b-form-checkbox v-model="includeFees" @change="calculateRefundTotal">
               <span>{{ $t('order_refund_include_ticket_fees', {total: $options.filters.currency(totalFees)}) }}</span>
             </b-form-checkbox>
           </div>
         </b-col>
         <b-col>
           <div v-if="order.payment_fee" class="SmallSize">
             <span v-if="isPaymentFeesRefunded" class="pl-1 text-line-through mr-3">{{ $t('order_refund_include_payment_fees', {total: $options.filters.currency(order.payment_fee)}) }}</span>
             <span v-if="isPaymentFeesRefunded" class="text-uppercase">{{ $t('order_status_refunded') }}</span>
             <b-form-checkbox v-if="!isPaymentFeesRefunded" v-model="includePaymentFees" @change="calculateRefundTotal">
               <span>{{ $t('order_refund_include_payment_fees', {total: $options.filters.currency(order.payment_fee)}) }}</span>
             </b-form-checkbox>
           </div>
         </b-col>
       </b-row>
       <b-row class="mt-4 mb-0 mediamSize">
        <b-col class="blackColor">{{ $t('order_refund_total_amount') }} <span class="pl-3 blackColor">{{ refundTotal | currency }}</span></b-col>
      </b-row>
       <b-row v-if="refundTotal && ((refundTotal + refundedTotal) > order.total)" class="mb-2 SmallSize">
        <b-col class="blackColor text-danger">{{ $t('order_refund_total_bigger_paid') }} ({{ order.total | currency }})</b-col>
      </b-row>
      <b-row>
        <b-col class="SmallSize mt-4">
          <b-form-group>
            <b-checkbox class="name-checkbox" v-model="orderRefundSendMail">
            <span>{{
                $t('order_detail_modal_send_mail')
              }}</span>
            </b-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <!-- <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button> -->
      <b-button class="px-4 px-md-5 SmallSize" @click="refundProducts()"
                :disabled="refundTotal <= 0 || (refundTotal + refundedTotal) > order.total || loading" variant="warning">
        <b-icon v-if="loading" icon="three-dots" animation="cylon" class="mr-2"></b-icon> {{ $t('order_detail_refund_order') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {groupBy} from "@/functions";
import api from "@/api";

export default {
  name: "OrderDetailsRefund",
  props: ['order'],
  data() {
    return {
      orderRefundSendMail: true,
      groups: [],
      includeFees: false,
      includePaymentFees: false,
      refundTotal: 0,
      refundedTotal: 0,
      totalFees: 0,
      loading: false,
      isPaymentFeesRefunded: false,
      isSelectAll: false
    }
  },
  mounted() {
    this.loadOrder();
  },
  methods: {
    loadOrder() {
      if(this.order) {
        this.refundTotal = 0;
        this.refundedTotal = 0;
        this.totalFees = 0;
        this.orderRefundSendMail = true;
        this.includeFees = false;
        this.includePaymentFees = false;
        this.isPaymentFeesRefunded = false;
        this.isSelectAll = false;
        this.groups = [];
        if(this.order.point_of_sale.pos_product_groups.length) {
          this.groups = this.order.point_of_sale.pos_product_groups.filter(posGroup => posGroup.product_group).map(group => {
            return {
              name: group.product_group.name,
              products: this.order.order_products.filter(orderProduct => {
                return orderProduct.product.group_id === group.product_group_id;
              }).map(product => {
                return Object.assign(product, {checked: false});
              })
            };
          }).filter(group => group.products.length)
        }
        else {
          const groups = groupBy(this.order.order_products, (product) => {
            return product.product.product_group.name;
          });
          for (const group in groups) {
            const g = {
              name: group,
              products: []
            };
            for (const product of groups[group]) {
              g.products.push(Object.assign(product, {checked: false}));
            }
            this.groups.push(g);
          }
        }
        this.isPaymentFeesRefunded = this.order.refunds.filter(refund => refund.type === 'payment-fee').length
        const refunds = this.order.refunds.filter(r => r.type !== 'refund-fee');
        for(const refund of refunds) {
          this.refundedTotal+= refund.amount;
        }
        this.calculateRefundTotal();
      }
    },
    refundProducts() {
      const products = [];
      for (const group of this.groups) {
        for (const product of group.products) {
          if(product.checked && product.refunded_at === null) {
            products.push({
              order_product_id: product.id,
              price: product.price
            });
          }
        }
      }
      const body = {
        total: this.refundTotal,
        products,
        includeFees: this.includeFees,
        includePaymentFees: this.includePaymentFees,
        orderRefundSendMail: this.orderRefundSendMail
      }
      this.loading = true;
      api.order().refund(this.order.id, body).then(() => {
        this.$emit('refunded', true)
        this.$bvModal.hide('refund-order')
        this.loading = false;
        this.$store.commit('success', 'order_detail_order_refunded')
        this.loadOrder();
      }).catch(() => {
        this.$emit('refunded', true)
        this.loading = false;
      })
    },
    selectAll(status) {
      for (let i=0; i< this.groups.length;i++) {
        for(let j=0; j < this.groups[i].products.length; j++ ) {
          if(this.groups[i].products[j].refunded_at === null) {
            this.groups[i].products[j].checked = status;
            this.isSelectAll = status;
          }
        }
      }
      this.includeFees = status;
      if(!this.isPaymentFeesRefunded) {
        this.includePaymentFees = status;
      }
      this.$forceUpdate();
      this.calculateRefundTotal();
    },
    calculateRefundTotal() {
      this.calculateTotalFees();
      let total = 0;
      for (const group of this.groups) {
        for(const product of group.products) {
          if(product.checked && product.refunded_at === null) {
            total += product.price;
          }
        }
      }
      if(this.includeFees) {
        total += this.totalFees;
      }
      if(this.includePaymentFees) {
        total += this.order.payment_fee;
      }
      this.refundTotal = parseFloat(total.toFixed(2));
    },
    calculateTotalFees() {
      let total = 0;
      for (const group of this.groups) {
        for(const product of group.products) {
          if(product.checked && product.refunded_at === null) {
            total += product.service_fee;
          }
        }
      }

      this.totalFees = total;
    }
  },
}
</script>

<style scoped>
.text-line-through {
  text-decoration: line-through;
}
.title{
  background: rgb(231, 231, 231);
  padding: 10px ;
  border-radius: 2px;
}
.blackColor{
  color: rgb(32, 31, 31);
  font-weight: 500;
}
.linethrough{
    text-decoration: line-through;
}
</style>
<style>

#refund-order h5{
font-size: 20px;
}
.SmallSize{
  font-size: 15px;
}
@media (max-width:992px) {
  #refund-order h5{
font-size: 17px;
}
  .mediamSize{
    font-size: 14px;
  }
  .SmallSize{
  font-size: 14px;
}
.custom-checkbox .custom-control-label::before{
  margin-top: -2px;
}
}
@media (max-width:576px) {
  .selectAll{
  font-size: 13px;
}
  .SmallSize{
  font-size: 12px;
}
}
@media (max-width:300px) {
    #refund-order h5{
font-size: 15px;
}
 .mediamSize{
    font-size: 12px;
  }
  .selectAll{
  font-size: 12px;
  padding:2px 5px !important;
}
  .SmallSize{
  font-size: 10px;
}
}
</style>
