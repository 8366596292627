<template>
  <b-container>
    <router-link v-if="order" :to="{name: (hasTimeslots ? 'OrderSelectTimeslot': 'OrderEnterPersonalDetails'), params: {id: order.id, secret: order.secret}, query: backQuery}" class="text-black-50 mb-2 d-block" style="margin-left: -15px;">
      <b-icon icon="arrow-left"></b-icon>{{ $t('back') }}
    </router-link>
    <b-row v-if="$route.query.payment_retry">
      <b-col>
        <b-alert variant="danger" show>{{ $t('payment_failed') }}</b-alert>
      </b-col>
    </b-row>
    <b-row v-if="order && pos && !saving">
      
      <gtm-page-view v-if="order && order.event && order.event.gtm_id" :gtm_id="order.event.gtm_id"></gtm-page-view>

      <b-col v-if="order && cartTotal > 0" class="content-block cart-container">
        <h3>{{ $t('order_payment_method') }}</h3>
        <b-row v-for="paymentMethod in paymentMethods" :key="`pay` + paymentMethod.id" @click="selectRow('pay'+paymentMethod.id)" :class="'pay'+paymentMethod.id === selectedOption ? 'active-option' : ''">
          <b-col class="payment-method-row">
            <b-form-group>
              <b-form-radio @change="selectPaymentMethod()" v-model="paymentMethodId"
                            :value="paymentMethod.id">
                <span class="pm-img">
                  <b-img :src="require('@/assets/payment-methods/' + paymentMethod.image_active)"></b-img>
                </span>
                {{ paymentMethod.payment_method.name }}
                <span v-if="paymentMethod.fee_fixed">{{ paymentMethod.fee_fixed | currency }}</span>
                <span v-if="paymentMethod.fee_percentage">{{ ' +' + paymentMethod.fee_percentage + '%' }}</span>
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button v-if="selectedOption" variant="success" size="lg" class="mb-4" @click="processOrder()">
            {{ $t('order_continue') }}
          </b-button>
        </div>
      </b-col>
      <b-col v-else class="content-block cart-container">
        <h3>{{ $t('pos_free_order_btn') }}</h3>
        <b-button variant="success" size="lg" class="free-order-btn" @click="processOrder()">
          {{ $t('order_continue') }}
        </b-button>
      </b-col>
      <b-col lg="4">
        <div  class="content-sidebar">
          <cart></cart>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="saving">
      <b-col class="text-center">
        <h1>{{ $t('payment_loading') }}</h1>
        <b-spinner style="width: 10em; height: 10em;"></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'
import Cart from '@/components/Cart'
import moment from 'moment'
import GtmPageView from '@/components/GtmPageView.vue'


export default {
  components: {
    Cart,
    GtmPageView
  },
  data() {
    return {
      paymentMethodId: null,
      saving: false,
      selectedOption: null
    }
  },
  methods: {
    selectRow(key){
      this.selectedOption = key
    },
    selectPaymentMethod() {
      let paymentMethod = this.pos.payment_methods.find((posPaymentMethod) => {
        return posPaymentMethod.id === this.paymentMethodId
      })
      this.$store.commit('setCartPaymentMethod', paymentMethod)
    },
    processOrder() {
      this.saving = true
      let order = Object.assign(this.order, {point_of_sale_payment_method_id: this.paymentMethodId})
      order.customer = null;
      if(order.payment_status === 'paid' || order.payment_status === 'pending') {
        this.$router.push({name: 'ViewPointOfSale', params: {url: this.pos.url}});
        this.$store.commit('errors', [this.$t('order_already_paid')]);
        return;
      }
      api.order().save(order).then((order) => {
        this.$store.commit('setOrder', order)
        api.order().pay(this.order).then((url) => {
          document.location = url
        }).catch(() => {
          this.$store.commit('errors', ['error_select_payment_method'])
          this.saving = false
        })
      }).catch((error) => {
        if(error?.response?.data[0]?.code === 'order_already_paid') {
          this.$router.push({name: 'ViewPointOfSale', params: {url: this.pos.url}});
        }
        else {
          this.$store.commit('errors', ['error_select_payment_method'])
        }
        this.saving = false
      })
    }
  },
  computed: {
    paymentMethods(){
      return this.pos.payment_methods.filter((pm) => {
        // Only return the active payment methods & show_pos for the POS
        if(!pm.online && !pm.show_pos){
          return false
        }
        // Hide STC Transfer in case the event is within 3 days
        if(pm.payment_method.adapter !== 'App\\PaymentAdapters\\PayNLSTC') {
          return true
        }
        let daysLeft = moment(this.event.starts_at).diff(moment(), 'days')
        let dayOfWeek = moment(this.event.starts_at).format('E')
        let daysNeeded = 3;
        // add 2 more days if the event is in the weekend
        if (dayOfWeek >= 6) {
          daysNeeded += 2
        }
        if(daysLeft > daysNeeded){
          return true
        }
        return false
      }).map((pm) => {
        let img = pm.payment_method.adapter.split('\\').pop().toLowerCase()
        pm.image = img  + '-grey.svg'
        pm.image_active = img + '.svg'
        return pm
      })
    },
    hasTimeslots() {
      if (!this.event) {
        return false
      }
      return this.event.timeslots.length > 0 || (this.event.opening_hours && this.event.opening_hours.length > 0)
    },
    backQuery() {
      if(this.campaign) {
        return {ft_campaign_name: this.campaign.slug, back: ''};
      }
      return {back: ''};
    },
    ...mapState(['cart', 'order', 'pos', 'event', 'campaign', 'cartTotal'])
  },
  created() {
    if(document.body.contains(document.getElementById('company-header'))){
      document.getElementById("company-header").scrollIntoView();
    }
    document.title = this.$t('pos_payment_method_title')
    api.order().getBySecret(this.$route.params.id, this.$route.params.secret).then((order) => {
      if(order && order.is_cancelled){
        this.$store.commit('errors', ['order_cancelled'])
        this.$router.push({name: 'ViewPointOfSale', params: {url: order.point_of_sale.url}});
        return
      }
      this.$store.commit('setOrder', order)
    })
  }
}
</script>
<style scoped>
  h3{
    font-size:1.5rem;
    font-weight:bold;
    margin-bottom:20px
  }
  .payment-method-row /deep/ .custom-control{
    padding-left:0;
  }
  .payment-method-row /deep/ .custom-radio .custom-control-label::before{
    display:none
  }
  .payment-method-row /deep/ label.custom-control-label{
    width:100%;
    padding:15px 0 15px 20px;
    border:1px solid lightgrey;
    border-radius:5px;
    cursor:pointer
  }

  .pm-img {
    width: 40px;
    display: inline-block;
  }
  .pm-img > img{
    max-width: 30px;
    max-height: 24px;
    display: inline-block;
  }

  .free-order-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
</style>
