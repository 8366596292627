<template>
  <div>
    <b-row>
      <b-col v-if="$can('master_data_permissions_roles_view')" lg="3" sm="6" cols="12">
        <router-link :to="{name: 'PermissionRoles'}"  class="info-card center text-center card">{{ $t('roles_permission_roles') }}</router-link>
      </b-col>
      <b-col v-if="$can('master_data_default_values_view')" lg="4" sm="7" cols="12">
        <router-link :to="{name: 'DefaultCompanySettings'}"  class="info-card center text-center card">{{ $t('default_account_setup') }}</router-link>
      </b-col>
      <b-col v-if="$can('master_data_auto_invoice_settings_view')" lg="4" sm="7" cols="12">
        <router-link :to="{name: 'AutoInvoiceSettings'}"  class="info-card center text-center card">{{ $t('master_data_auto_invoice_settings') }}</router-link>
      </b-col>
      <b-col v-if="$can('master_data_venues_overview')" lg="3" sm="6" cols="12">
        <router-link :to="{name: 'VenuesOverview'}"  class="info-card center text-center card">{{ $t('venues_overview') }}</router-link>
      </b-col>
      <b-col v-if="$can('master_data_company_links_overview')" lg="4" sm="7" cols="12">
        <router-link :to="{name: 'CompanyLinksOverview'}"  class="info-card center text-center card">{{ $t('company_links_overview') }}</router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "MasterDataOverview",
  created() {
    document.title = this.$t('nav_master_data')
    if(this.$store.state.user) {
      if(!this.$can('master_data_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('master_data_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
