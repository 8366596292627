<template>
<b-container fluid>
  <b-row class="mt-3">
    <b-col>
      <label class="p-1 pl-3 default-container-layout">{{ $t('box_office_choose_box_office_title', {event_name: event ? event.name : ''}) }}</label>
    </b-col>
  </b-row>

  <b-row class="mt-4" v-if="boxOffices.length">
    <b-col class="text-center mb-3" sm="12" md="6" v-for="boxOffice in boxOffices" :key="boxOffice.id">
      <b-button class="pt-4 pb-4 default-container-layout"
                      :to="{name: 'BoxOfficePlaceOrder', params:{event: $route.params.event, boxOffice: boxOffice.url}}">
              {{ boxOffice.name }}
            </b-button>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import api from '../api/boxOfficeApi'
export default {
  name: "BoxOfficeEventBoxOffices",
  data() {
    return {
      event: null,
      boxOffices: []
    }
  },
  mounted() {
    api.event().get(this.$route.params.event).then(event => {
      this.event = event;
    })

    api.event().boxOffices(this.$route.params.event).then(boxOffice => {
      this.boxOffices = boxOffice;
    })
  }
}
</script>

<style scoped>
.default-container-layout {
  width: 100%;
  color: #808080;
  font-weight: bold;
  font-size: 18px;
  border: none;
  background-color:#FFF;
  border:0px
}
</style>
