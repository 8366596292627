export default class userApi {

    constructor(axios) {
        this.axios = axios
    }

    login(email, password) {
        return this.axios.post('/user/login', {email, password})
    }

    me() {
        return this.axios.get('/user/me')
    }

    adminUpdate(user) {
        return this.axios.post('/user/' + user.id, user)
    }

    update(companyId, user) {
        return this.axios.post('/company/' + companyId + '/user/' + user.id, user)
    }

    loginAs(id) {
        return this.axios.post('/user/impersonate/' + id)
    }

    delete(companyId, userId) {
        return this.axios.delete('/company/' + companyId + '/user/' + userId)
    }

    updateLanguage(language) {
        return this.axios.post('/user/change-language', {language})
    }

    resetPassword(password, passwordConfirmation) {
        return this.axios.post('/user/reset-password', {
            password,
            password_confirmation: passwordConfirmation
        })
    }
}
