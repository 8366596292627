<template>
  <b-container fluid id="app">
    <b-row no-gutters>
      <b-col>
        <div class="nav-wrap">
          <b-row style="height: 45px;">
            <b-col style="line-height:45px">
              <router-link v-if="isPlaceOrder" :to="{name: 'BoxOfficeOrderHistory', params:{event: $route.params.event, boxOffice: $route.params.boxOffice}}">
                <svg fill="#ffffff" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C201.7 512 151.2 495 109.7 466.1C95.2 455.1 91.64 436 101.8 421.5C111.9 407 131.8 403.5 146.3 413.6C177.4 435.3 215.2 448 256 448C362 448 448 362 448 256C448 149.1 362 64 256 64C202.1 64 155 85.46 120.2 120.2L151 151C166.1 166.1 155.4 192 134.1 192H24C10.75 192 0 181.3 0 168V57.94C0 36.56 25.85 25.85 40.97 40.97L74.98 74.98C121.3 28.69 185.3 0 255.1 0L256 0zM256 128C269.3 128 280 138.7 280 152V246.1L344.1 311C354.3 320.4 354.3 335.6 344.1 344.1C335.6 354.3 320.4 354.3 311 344.1L239 272.1C234.5 268.5 232 262.4 232 256V152C232 138.7 242.7 128 256 128V128z"/></svg>
              </router-link>
              <router-link v-if="isOrderHistory" :to="{name: 'BoxOfficePlaceOrder', params:{event: $route.params.event, boxOffice: $route.params.boxOffice}}">
                <svg fill="#ffffff" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C201.7 512 151.2 495 109.7 466.1C95.2 455.1 91.64 436 101.8 421.5C111.9 407 131.8 403.5 146.3 413.6C177.4 435.3 215.2 448 256 448C362 448 448 362 448 256C448 149.1 362 64 256 64C202.1 64 155 85.46 120.2 120.2L151 151C166.1 166.1 155.4 192 134.1 192H24C10.75 192 0 181.3 0 168V57.94C0 36.56 25.85 25.85 40.97 40.97L74.98 74.98C121.3 28.69 185.3 0 255.1 0L256 0zM256 128C269.3 128 280 138.7 280 152V246.1L344.1 311C354.3 320.4 354.3 335.6 344.1 344.1C335.6 354.3 320.4 354.3 311 344.1L239 272.1C234.5 268.5 232 262.4 232 256V152C232 138.7 242.7 128 256 128V128z"/></svg>
              </router-link>
            </b-col>
            <b-col style="text-align:center;line-height:45px">
                <b-img height="35px" :src="require('../assets/logo-light.png')"></b-img>
            </b-col>
            <b-col style="line-height:45px">
              <a v-if="isPlaceOrder" @click="$root.$emit('bv::show::modal', 'place-order-setting', $event.target)" href="javascript:" style="float: right;">
                <svg fill="#ffffff" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M331.8 224.1c28.29 0 54.88 10.99 74.86 30.97l19.59 19.59c40.01-17.74 71.25-53.3 81.62-96.65c5.725-23.92 5.34-47.08 .2148-68.4c-2.613-10.88-16.43-14.51-24.34-6.604l-68.9 68.9h-75.6V97.2l68.9-68.9c7.912-7.912 4.275-21.73-6.604-24.34c-21.32-5.125-44.48-5.51-68.4 .2148c-55.3 13.23-98.39 60.22-107.2 116.4C224.5 128.9 224.2 137 224.3 145l82.78 82.86C315.2 225.1 323.5 224.1 331.8 224.1zM384 278.6c-23.16-23.16-57.57-27.57-85.39-13.9L191.1 158L191.1 95.99l-127.1-95.99L0 63.1l96 127.1l62.04 .0077l106.7 106.6c-13.67 27.82-9.251 62.23 13.91 85.39l117 117.1c14.62 14.5 38.21 14.5 52.71-.0016l52.75-52.75c14.5-14.5 14.5-38.08-.0016-52.71L384 278.6zM227.9 307L168.7 247.9l-148.9 148.9c-26.37 26.37-26.37 69.08 0 95.45C32.96 505.4 50.21 512 67.5 512s34.54-6.592 47.72-19.78l119.1-119.1C225.5 352.3 222.6 329.4 227.9 307zM64 472c-13.25 0-24-10.75-24-24c0-13.26 10.75-24 24-24S88 434.7 88 448C88 461.3 77.25 472 64 472z"/></svg>
              </a>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col id="content">
        <router-view></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: 'App',
  methods: {
    logout() {
      this.$store.commit('boxOfficeLogout')
    },
    dismissSuccess() {
      this.$store.commit('success', false)
    },
    ...mapActions(['loadBoxOfficeUser'])
  },
  created() {
    this.loadBoxOfficeUser()
  },
  computed: {
    isPlaceOrder() {
      return this.$route.name === 'BoxOfficePlaceOrder'
    },
    isOrderHistory(){
      return this.$route.name === 'BoxOfficeOrderHistory'
    }
  }
}
</script>

<style lang="scss" scoped>

$shadow-color: #52575D19;
$blue-color: #193E58;
$orange-color: #F0B52D;
$text-color: #4D4D4D;
$element-height: 50px;
$container-distance: 2rem;
$default-border-radius: 6px;
$button-border-radius: 6px;
$element-border-color: #F5F5F5;
$row-hover-color: rgba(240, 181, 45, 0.06);
$label-text-color: #4D4D4D;
$label-text-size: 16px;
$label-font-weight: 400;
$field-border-radius: 4px;
$field-height: 50px;
$field-border-thickness: 2px;
$field-border-color: #EEEEEE;
$field-text-color: #4D4D4D;
$field-text-size: 13px;

p, h1, h2, h3, h4, div, span {
  color: $text-color;
}

#app {
  padding: 0;
}
body {
  background-color: #F5F5F5 !important;;
}
#content {
  height: auto;
  min-height: 90vh;
  padding: 1rem;
  background-color: #F5F5F5
}
ul.pagination{
  justify-content: center;
  margin-top:30px
}
ul.pagination .page-item.active .page-link{
  background-color:$blue-color;
  border-color:$blue-color
}
ul.pagination .page-link{
  color:#808080
}

.vertical-align{
  display:flex;
  align-self: center;
}

.container-distance {
  margin-bottom: $container-distance !important;
}

.btn.btn-save {
  background-color: $blue-color;
  color: #FFF;
  border-radius: $button-border-radius;
  padding: 5px 40px;
  font-weight: bold
}

.btn.btn-new {
  background-color: #193E58;
  border-radius: $button-border-radius;
  color: #FFF;
  padding: 15px 20px;
  font-weight: bold
}

.btn.btn-orange{
  background-color:$orange-color;
  border-radius: $button-border-radius;
  color:#FFF;
  border:0px
}
.btn.btn-orange-border{
  background-color:#FFF;
  padding:5px 20px;
  font-size:13px;
  font-weight:bold;
  border:2px solid $orange-color;
  border-radius: $button-border-radius;
  color:#808080
}

.btn.btn-row-edit {
  background-color: #FFF;
  border-radius: $button-border-radius;
  border: 2px solid #F5F5F5;
  color: #808080;
  padding: 5px 20px;
  font-size: 13px;
  font-weight: bold
}

.btn.btn-row-edit:hover {
  border-color: $orange-color;
  background-color: $orange-color;
  color: #FFF
}

.modal-header {
  background-color: $blue-color;

  .modal-title, .close {
    color: #FFF
  }
}

.button-row-included {
  margin-top: 73px
}

.table th, .table td {
  vertical-align: middle !important;
}

.col-form-label {
  font-size: $label-text-size;
  color: $label-text-color;
  font-weight: $label-font-weight;
}

input.form-control, select.form-control, select.custom-select {
  border-radius: $field-border-radius;
  border: $field-border-thickness solid $field-border-color;
  height: $field-height;
  color: $field-text-color;
  font-size: $field-text-size;
}

textarea.form-control {
  border: $field-border-thickness solid $field-border-color;
  color: $field-text-color;
  font-size: $field-text-size;
}

#dropdown-right  {
  width: 50px;
  background-color: #fff;
}
#dropdown-right .dropdown-toggle {
  background-color: #fff;
}
#dropdown-right ul {
  min-width: 36px;
}
.lang-flag, .selected-lang {
  border-radius: 50%;
  width: 36px;
}
.lang-flag {
  border: 3px solid #fff;
}
.selected-lang {
  border: 3px solid #193E58;
}
#dropdown-right a {
  padding: 0.25rem 0.9rem;
}

.content-main-container {

  .title-container {
    background-color: $blue-color;
    color: #FFF;
    border-radius: $default-border-radius $default-border-radius 0 0;
    padding: 10.5px 25px;
    font-weight: bold
  }

  .content-container {
    background-color: #FFF;
    padding: 25px;
    border-radius: 0 0 $default-border-radius $default-border-radius
  }

}

.card {
  border: none !important;

  .card-body {
    padding: 0 !important;
  }

  .card-title {
    background-color: $blue-color;
    color: white;
    font-weight: bold;
    padding: 10px;
    font-size: 1rem;
    border-top-right-radius: $default-border-radius;
    border-top-left-radius: $default-border-radius;
  }

  .card-text {
    padding: 10px;
  }

}


table.b-table {

  thead {
    background-color: $blue-color;

    th {
      border-bottom: 0px;
      padding: 10px 0.75rem
    }

    tr th:first-of-type {
      padding-left: 25px
    }

    tr th:first-child {
      border-top-left-radius: $default-border-radius;
    }

    tr th:last-child {
      border-top-right-radius: $default-border-radius;
    }

    tr th div {
      color: #FFF
    }
  }

  tbody {
    tr {
      background-color: #FFF;
      border-bottom: 1px solid $element-border-color;
    }

    tr:hover {
      background-color: $row-hover-color;

      .btn.btn-row-edit {
        background-color: $orange-color;
        color: #FFF;
        border-color: $orange-color
      }
    }

    tr td:first-of-type {
      padding-left: 25px
    }

    tr:last-of-type {
      border-bottom: 0
    }

    tr td:first-child {
      border-bottom-left-radius: $default-border-radius;
    }

    tr td:last-child {
      border-bottom-right-radius: $default-border-radius;
    }
  }

}

#app-top-bar {
  padding: 5px 30px;
  margin-bottom: $container-distance;

  #app-welcome-message {
    font-size: 18px;
  }

  #app-top-bar-right-col {
    display: flex;
    justify-content: flex-end;


    #app-username {
      align-self: center
    }

    #app-user-nav a:focus, #app-user-nav li:focus {
      border: 0
    }
  }
}

.default-container-layout {
  background-color: #FFF !important;
  border-radius: 6px !important;
  box-shadow: 0px 3px 6px $shadow-color !important;
}
.nav-wrap {
  background: #193E58;
  padding: 0 $container-distance;
}
#nav-wrap {
  background: #193E58;

  .nav-item {
    display: block;
    width: 100%;
    border-bottom: 1px solid #567184
  }

  .nav-link {
    color: white;
    display: block;
    width: 100%;
    height: 70px;
    line-height: 70px;
    padding: 0 20px
  }

  .nav-link img {
    margin-top: -5px;
    margin-right: 15px
  }

  .active {
    background: white;
    color: #193E58;
  }
}

$theme-colors: (
    "primary": #F0B52D
);
// Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap';
// BootstrapVue and its default variables
@import '../../node_modules/bootstrap-vue/src/index.scss';

* {
  font-family: Roboto, sans-serif;
}


@media only screen and (max-width: 600px) {
  #nav-wrap {
    position: absolute;
    width: 230px;
    height: 100vh
  }
}


</style>
