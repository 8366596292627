export default class pointsOfSaleApi {

    constructor(axios) {
        this.axios = axios
    }

    create(eventId, pos) {
        return this.axios.post('/event/' + eventId + '/pos', pos)
    }

    get(eventId) {
        return this.axios.get('/event/' + eventId + '/pos')
    }

    save(eventId, pos) {
        return this.axios.post('/event/' + eventId + '/pos/' + pos.id, pos)
    }

    getByUrl(url) {
        return this.axios.post('/pos/url', {url})
    }

    getByOrderIdAndSecret(id, secret){
        return this.axios.post('/pos/order', {id, secret})
    }

    getLive(companyId) {
        return this.axios.get('/pos/live/' + companyId)
    }

    delete(eventId, posId) {
        return this.axios.delete('/event/' + eventId + '/pos/' + posId)
    }
}
