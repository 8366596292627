<template>
  <div id="ticket-lost">
    <b-row align-v="center">
      <div id="ticket-lost-wrap" class="mt-5">
        <img src="https://fairtix.io/images/logo-fairtix.png" alt="Fairtix" style="max-width: 120px; margin: auto; display: block;"/>

        <div class="my-4">
          <b-dropdown id="dropdown-right " :text="languageLabel"  class="m-md-2 languages">
            <template v-slot:button-content>
              <div>
                <b-img class="selected-lang" :src="require('./../assets/icons/'+language+'.png')"/>
                <p class="mb-0">{{language.toUpperCase()}}</p>
              </div>
            </template>
            <b-dropdown-item @click="changeLanguage('nl')"><b-img class="lang-flag" :src="require('./../assets/icons/nl.png')"/><p class="mb-0 ml-2">{{ $t('lang_nl') }}</p></b-dropdown-item>
            <b-dropdown-item @click="changeLanguage('de')"><b-img class="lang-flag" :src="require('./../assets/icons/de.png')"/><p class="mb-0 ml-2">{{ $t('lang_de') }}</p></b-dropdown-item>
            <b-dropdown-item @click="changeLanguage('en')"><b-img class="lang-flag" :src="require('./../assets/icons/en.png')"/><p class="mb-0 ml-2">{{ $t('lang_en') }}</p></b-dropdown-item>
            <b-dropdown-item @click="changeLanguage('es')"><b-img class="lang-flag" :src="require('./../assets/icons/es.png')"/><p class="mb-0 ml-2">{{ $t('lang_es') }}</p></b-dropdown-item>
          </b-dropdown>
        </div>

        <div v-if="!orders" class="lost-search-body">
          <h3 class="tl-title my-4">{{ $t('ticket_lost_title') }}</h3>

          <p class="tl-description">{{ $t('ticket_lost_description') }}</p>

          <div>
            <b-form-group :label="$t('email') +'*'">
              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
                </b-input-group-prepend>
                <b-form-input v-model="email" type="email" :placeholder="$t('email') +'*'"></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group class="tl-date-picker" :label="$t('report_event_day') +'*'">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <svg class="cursor-pointer" @click="openDatePicket" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/></svg>
                </b-input-group-prepend>
                <date-picker
                    ref="dpicker"
                    :bootstrap-styling="true"
                    v-model="date"
                    :language="locale"
                    :typeable="true"
                    :use-utc="true"
                    placeholder="dd-mm-yyyy*"
                    format="dd-MM-yyyy"></date-picker>
              </b-input-group>
            </b-form-group>

            <vue-recaptcha v-if="recaptchaSiteKey"
                           ref="recaptcha"
                           :sitekey="recaptchaSiteKey"
                           @verify="verifyMethod"
                           @expired="expired"
                           :language="language || 'en'"
                           class="my-4 g-recaptcha"></vue-recaptcha>
            <b-alert v-if="error !== null" show variant="danger">{{ $t(error) }}</b-alert>

            <b-button @click="search" block class="float-right lost-ticket-btn mt-2" variant="primary" :disabled="isDisabled">{{ $t('ticket_lost_search_tickets') }}</b-button>
          </div>
        </div>
        <div v-else-if="orders">
          <b-alert show variant="success">{{ $t('ticket_lost_your_orders_description') }}</b-alert>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex";
import { VueRecaptcha } from 'vue-recaptcha';
import api from "../api";
import {getOrderStatusPageUrl, validateEmail} from "../functions";
import moment from "moment";
import DatePicker from '@sum.cumo/vue-datepicker'
import '@sum.cumo/vue-datepicker/dist/Datepicker.css'
import { en, es, nl, de } from '@sum.cumo/vue-datepicker/dist/locale/index.esm'

export default {
  name: "TicketLost",
  data() {
    return {
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      email: null,
      date: null,
      isDisabled: true,
      recaptcha: null,
      orders: null,
      error: null
    }
  },
  mounted() {
    document.title = this.$t('ticket_lost_title')
    document.body.style.backgroundColor = '#f3f3f4';
  },
  beforeDestroy() {
    document.body.style.backgroundColor = '#ffffff';
  },
  methods: {
    changeLanguage(lang) {
      this.$store.commit('setLanguage', lang)
      setTimeout(() => {
        this.$refs.recaptcha.reset();
      }, 500)
    },
    verifyMethod(e) {
      this.recaptcha = e;
      this.isDisabled = false;
    },
    expired() {
      this.isDisabled = false;
    },
    search() {
      this.error = null;
      if(!validateEmail(this.email)) {
        this.error = 'wrong_email';
        return
      }
      const date = moment(this.date, 'DD-MM-YYYY');
      if(!date.isValid()) {
        this.error = 'wrong_date';
        return
      }
      this.isDisabled = true;
      api.ticket().searchLost(this.email, moment(this.date, 'DD-MM-YYYY').format('YYYY-MM-DD'), this.recaptcha).then((orders) => {
        this.orders = orders;
        if(orders === 0) {
          this.error = 'ticket_lost_no_orders';
        }
        this.$refs.recaptcha.reset();
        this.isDisabled = true;
      });
    },
    getOrderStatusPageUrl(id, secret) {
      return getOrderStatusPageUrl(id, secret);
    },
    openDatePicket(e) {
      e.preventDefault();
      setTimeout(() => {
        this.$refs.dpicker.open();
      }, 50)
    }
  },
  computed: {
    languageLabel(){
      return this.$t('language_' + this.language)
    },
    locale() {
      if(this.language === 'es') {
        return es;
      }
      if(this.language === 'nl') {
        return nl;
      }
      if(this.language === 'de') {
        return de;
      }
      return en;
    },
    ...mapState(['language']),
  },
  components: {
    VueRecaptcha,
    DatePicker
  }
}
</script>

<style scoped lang="scss">

.g-recaptcha{
  width:100%;
  transform: scale(1.15);
  transform-origin: 0 0;
}

#ticket-lost {
  background-color: #f3f3f4;
  height: 99vh;
  padding: 0;
  margin: 0;
}
#ticket-lost-wrap {
  background: #ffffff;
  padding: 50px;
  text-align: center;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;
  width:450px;
  margin: auto;

  .btn-primary {
    color: #FFFFFF;
    font-size: large;
    font-weight: bold;
  }

  legend {
    text-align: left;
    color: #193d5b;
  }

  img {
    max-width: 100%;
  }

  .input-group-text {
    border: 2px solid #EEEEEE;
    border-right: 0;
    background-color: #FFFFFF;
  }

  input.form-control {
    border-left: 0;
  }

  input.form-control:focus {
    border-color: #EEEEEE;
    border-width: 2px;
    box-shadow: none;
  }

}

#ticket-lost-wrap {
  margin: auto;
}

@media only screen and (max-width: 480px) {
  #ticket-lost-wrap {
    width: 90%;
  }
  .g-recaptcha{
    transform:scale(1.05);
  }
}

@media only screen and (max-width: 468px) {
  .g-recaptcha{
    transform:scale(1);
  }
}

@media only screen and (max-width: 450px) {
  .g-recaptcha{
    transform:scale(0.95);
  }
}

@media only screen and (max-width: 430px) {
  .g-recaptcha{
    transform:scale(0.90);
  }
}

@media only screen and (max-width: 410px) {
  .g-recaptcha{
    transform:scale(0.85);
  }
}

@media only screen and (max-width: 410px) {
  .g-recaptcha{
    transform:scale(0.80);
  }
}

@media only screen and (max-width: 390px) {
  .g-recaptcha{
    transform:scale(0.75);
  }
}

@media only screen and (max-width: 370px) {
  .g-recaptcha{
    transform:scale(0.70);
  }
}

@media only screen and (max-width: 350px) {
  .g-recaptcha{
    transform:scale(0.65);
  }
}

@media only screen and (max-width: 330px) {
  .g-recaptcha{
    transform:scale(0.60);
  }
}

@media only screen and (max-width: 310px) {
  .g-recaptcha{
    transform:scale(0.55);
  }
}

@media only screen and (max-width: 290px) {
  .g-recaptcha{
    transform:scale(0.50);
  }
}

@media only screen and (max-width: 270px) {
  .g-recaptcha{
    transform:scale(0.45);
  }
}

.languages {
  border: 1px solid #999;
  border-radius: 30px;
}
.languages .dropdown-toggle{
  position: relative;
  border-radius: 20px;
}
.languages .dropdown-toggle::after{
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translateY(-10%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px;
  border-color: #000 transparent transparent transparent;
}
.languages .btn-secondary{
  background-color: #fff!important;
  width: 150px;
}
.languages .btn-secondary > div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.languages .btn-secondary > div img{
  margin: 0 10px;
}

.tl-title {
  color: #193d5b;
  font-weight: bold;
}

.tl-description {
  color: #a2a2a2 !important;
  font-size: large;
}

.tl-date-picker .b-form-datepicker button.dropdown-toggle {
  background-color: #FFFFFF;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  border: 2px solid #EEEEEE;
  border-right: 0;
  border-radius: 0.25rem;
}

.tl-event-name {
  color: #000000;
  font-weight: bold;
  font-size: large;
}
.tl-order-number {
  color: #000000;
  font-weight: bold;
  font-size: medium;
}

.tl-order {
  border-bottom: 1px solid #a2a2a2;

  &:last-child {
    border-bottom: 0;
  }
}

.lost-ticket-btn {
  height: 50px;
}
</style>

<style lang="scss">
#ticket-lost-wrap .tl-date-picker {
  .input-group-prepend {

  }

  input {
    border: 0;
    color: #808080;
    font-size: 13px;
    height: 50px;
    font-weight: 400;
    line-height: 1.5;
  }

  input:focus-visible {
    border: 0;
  }

  .vdp-datepicker {
    border-radius: 4px;
    border: 2px solid #EEEEEE;
    border-left: 0;
    color: #808080;
    font-size: 13px;

    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }
}

#ticket-lost-wrap legend {
  text-align: left;
  color: #193d5b;
}
</style>