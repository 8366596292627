<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>{{ $t('ticket_claimed') }}</h1>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {}
  }
}
</script>
