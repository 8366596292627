export default class eventApi {

    constructor(axios) {
        this.axios = axios
    }

    all(show_archived) {
        return this.axios.get('/event?show_archived=' + show_archived)
    }

    live() {
        return this.axios.get('/event/active')
    }

    statusLive() {
        return this.axios.get('/event?only_live=true')
    }

    get(id) {
        return this.axios.get('/event/' + id)
    }

    delete(id) {
        return this.axios.delete('/event/' + id)
    }

    create(event) {
        return this.axios.post('/event', event)
    }

    save(event) {
        return this.axios.post('/event/' + event.id, event)
    }

    products(id) {
        return this.axios.get('/event/' + id + '/product')
    }

    productsDependencies(eventId) {
        return this.axios.get('/event/' + eventId + '/product/dependencies')
    }

    posDependencies() {
        return this.axios.get('/event/pos/dependencies')
    }

    getLiveEvents(companyId) {
        return this.axios.get('/company/event/' + companyId + '/live')
    }

    getLiveEvent(companyId, eventId) {
        return this.axios.get('/company/event/' + companyId + '/live/' + eventId)
    }

    getUpcomingEvents(companyId, currentEventId) {
        return this.axios.get('/company/event/' + companyId + '/upcoming/' + currentEventId)
    }

    getVisitorList(id, page) {
        return this.axios.get('/event/' + id + '/visitor-list?page=' + page)
    }

    searchVisitorList(id, search_term){
        return this.axios.get('/event/' + id + '/visitor-list/search', {params:{event_id:id,search_term:search_term}})
    }

    getEventTypes(){
        return this.axios.get('/event-types')
    }

    getDashboardStatistics(data) {
        return this.axios.get('/event/dashboard', {
            params: data
        })
    }

    getDashboardCurrentEventStatistics(data) {
        return this.axios.get('/event/dashboard/current-events', {
            params: data
        })
    }

    getEventSoldTickets(event) {
        return this.axios.get('/event/dashboard/' + event + '/sold-tickets')
    }

    duplicate(id, data) {
        return this.axios.post('/event/' + id + '/duplicate', data)
    }

    getCustomerFields(event_id) {
        return this.axios.get('/event/' + event_id + '/customer-fields')
    }

    getLiveEventsWithProducts(company_id) {
        return this.axios.get('/event-with-products?order_company_id=' + company_id)
    }

    getLiveEventsWithPos() {
        return this.axios.get('/event-with-pos')
    }

    getActiveCarts(event_id){
      return this.axios.get('/event/' + event_id + '/active-carts')
    }

    updateMarketing(event_id, data) {
        return this.axios.post('/event/' + event_id + '/marketing', data)
    }

}
