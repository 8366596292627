<template>
  <div>
    <h1>{{ $t('companies_title') }}</h1>
    <b-form-group>
      <b-button v-if="$can('admin_company_add_company')" variant="primary"  v-b-modal.add-company>{{ $t('companies_create') }}</b-button>
    </b-form-group>

    <b-modal id="add-company" title="Add new company">
      <b-form-group :label="$t('company_name')">
        <b-form-input v-model="company.name" :state="state.name" aria-describedby="company-name-feedback"></b-form-input>
        <b-form-invalid-feedback id="company-name-feedback">{{ $t('company_name_is_required') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('company_subdomain')">
        <b-form-input v-model="company.domain" :state="state.domain" aria-describedby="company-domain-feedback"></b-form-input>
        <b-form-invalid-feedback id="company-domain-feedback">{{ $t('company_domain_is_required') }}</b-form-invalid-feedback>
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group :label="$t('company_contact')">
            <b-form-input v-model="company.contact" :state="state.contact" aria-describedby="company-contact-feedback"></b-form-input>
            <b-form-invalid-feedback id="company-contact-feedback">{{ $t('company_contact_is_required') }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('company_phone')">
            <b-form-input v-model="company.phone"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group :label="$t('company_address')">
        <b-form-input v-model="company.address"></b-form-input>
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group :label="$t('company_zip')">
            <b-form-input v-model="company.zip"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('company_city')">
            <b-form-input v-model="company.city"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('company_country')">
            <b-select v-model="company.country" :state="state.country" text-field="name" value-field="code" :options="countries"></b-select>
          </b-form-group>
        </b-col>  
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('company_kvk')">
            <b-form-input v-model="company.kvk"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('company_vat')">
            <b-form-input v-model="company.vat"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click="createCompany()">{{ $t('create') }}</b-button>
      </template>
    </b-modal>
    <div class="tableBig">
    <b-table :items="companies" :fields="['id',{key: 'name', label: $t('company_name')},{key: 'actions', label: $t('order_actions')}]">
      <template v-slot:cell(actions)="{item}">
        <b-spinner v-if="adding"></b-spinner>
        <b-button v-else-if="$can('admin_company_details_page_view')" variant="primary" :to="{name:'ManageCompany', params:{id: item.unique_id}}">{{ $t('manage') }}</b-button>
      </template>
    </b-table>
    </div>
  </div>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      companies: null,
      company: {},
      adding: false,
      logo: null,
      state: {
        name: null,
        domain: null,
        contact: null,
      },
      countries: []
    }
  },
  computed: {
    ...mapState(['user'])
  },
  created() {
    if(this.$store.state.user) {
      if(!this.$can('admin_company_overview_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.company().all().then(companies => {
      this.companies = companies
    })
    api.company().countries().then(countries => {
      this.countries = [{code: null, name: this.$t('company_country'), disabled: true}].concat(countries)
    })
  },
  methods: {
    createCompany() {
      this.state.name = null;
      this.state.domain = null;
      this.state.contact = null;
      this.state.country = null;

      let valid = true;

      if(!this.company.name) {
        this.state.name = false;
        valid = false;
      }

      if(!this.company.domain) {
        this.state.domain = false;
        valid = false;
      }
      if(!this.company.contact) {
        this.state.contact = false;
        valid = false;
      }
      if(!this.company.country) {
        this.state.country = false;
        valid = false;
      }

      if(!valid) {
        return;
      }
      this.adding = true
      
      api.company().create(this.company).then((company) => {
        this.$router.push({
          name: 'ManageCompany',
          params: {
            id: company.unique_id
          }
        })
      }).catch((errors) => {
        console.error(errors)
        this.adding = false
      })
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('admin_company_overview_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width:500px) {
  h1{
    font-size: 35px!important;
  }

}
</style>
