<template>
  <div id="company-page">
    <div v-if="company">
      <div id="company-wrapper">
        <div id="company-header">
          <b-container >
            <b-row>
              <b-col lg="3" sm="8" cols="5" id="logo-container">
                <a :href="company.layout.logo_url" v-if="company.layout.logo && company.layout.logo_url" target="_blank">
                  <b-img :src="company.layout.logo" :alt="company.name" fluid/>
                </a>
                <b-img v-else-if="company.layout.logo" :src="company.layout.logo" :alt="company.name" fluid/>
              </b-col>
              <b-col lg="9" sm="4" cols="7" class="d-flex justify-content-end align-items-center">
                <b-dropdown id="dropdown-right " :text="languageLabel"  class="m-md-2 languages">
                  <template v-slot:button-content>
                    <div>
                      <b-img class="selected-lang" :src="require('./../assets/icons/'+language+'.png')"/>
                      <p class="mb-0">{{language.toUpperCase()}}</p>
                    </div>
                  </template>
                  <b-dropdown-item @click="changeLanguage('nl')"><b-img class="lang-flag" :src="require('./../assets/icons/nl.png')"/><p class="mb-0 ml-2">{{ $t('lang_nl') }}</p></b-dropdown-item>
                  <b-dropdown-item @click="changeLanguage('de')"><b-img class="lang-flag" :src="require('./../assets/icons/de.png')"/><p class="mb-0 ml-2">{{ $t('lang_de') }}</p></b-dropdown-item>
                  <b-dropdown-item @click="changeLanguage('en')"><b-img class="lang-flag" :src="require('./../assets/icons/en.png')"/><p class="mb-0 ml-2">{{ $t('lang_en') }}</p></b-dropdown-item>
                  <b-dropdown-item @click="changeLanguage('es')"><b-img class="lang-flag" :src="require('./../assets/icons/es.png')"/><p class="mb-0 ml-2">{{ $t('lang_es') }}</p></b-dropdown-item>
                  <b-dropdown-item @click="changeLanguage('fr')"><b-img class="lang-flag" :src="require('./../assets/icons/fr.png')"/><p class="mb-0 ml-2">{{ $t('lang_fr') }}</p></b-dropdown-item>
                  <b-dropdown-item @click="changeLanguage('pt')"><b-img class="lang-flag" :src="require('./../assets/icons/pt.png')"/><p class="mb-0 ml-2">{{ $t('lang_pt') }}</p></b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div id="company-nav" v-if="event">
          <b-container>
            <b-alert v-if="this.pos && this.pos.status === 'test'" variant="warning" show class="text-center">
              {{ $t('pos_in_test_mode') }}
            </b-alert>

            <b-row>
              <b-col sm="12" lg="6" class="pl-0">
                <h1 class="event-title">{{ event.name }}</h1>
                <p class="event-date mb-1" v-if="event.starts_at">{{ event.starts_at | date_full }} - {{ event.ends_at | date_full }}</p>
                <p class="event-venue" v-if="event.venue_id">{{ event.venue.name }}, {{ event.venue.address }}, {{ event.venue.city }}, {{ event.venue.zip }}, {{ event.venue.country_code }}</p>
              </b-col>
              <b-col v-if="!countdown" sm="12" lg="6" class="stepper-wrapper pr-0">
                <b-row class="stepper" align-h="end">
                  <b-col :cols="stepperCols" :sm="stepperCols" :class="step.done ? 'step step-done' : 'step step-todo'"
                         v-for="(step, i) in steps"
                         :key="step.route">
                    <div class="ball">{{ i + 1 }}</div>
                    <div class="label">{{ step.label }}</div>
                  </b-col>
                </b-row>
                <div class="stepper-line"></div>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div id="company-content">
          <b-row>
            <b-col class="mt-5" v-if="countdown">
              <FlipCountdown :deadline="countdown"></FlipCountdown>
            </b-col>
            <b-col v-else>
              <router-view></router-view>
            </b-col>
          </b-row>
        </div>
        <b-container v-if="!countdown" class="footer-info-blocks">
          <b-row lg="6">
            <b-col class="info-card">
              <h3>{{ $t('shop_questions_event') }}</h3>
              <p>
                {{ $t('shop_questions_event_hint') }}
              </p>
              <p class="mb-0">
                <b-icon icon="phone"></b-icon>
                {{ company.phone }}
                <br>
                <b-icon icon="envelope"></b-icon>
                {{ company.email_customer_questions }}
              </p>
              <p v-if="company.show_kvk_on_pos">
                <b-icon icon="file-earmark-text"></b-icon>
                {{ $t('shop_chamber_of_commerce_number') }}: {{ company.kvk }}
              </p>
            </b-col>
            <b-col lg="6" class="info-card info-block">
              <h3>{{ $t('shop_questions_tickets') }}</h3>
              <p>
                {{ $t('shop_questions_tickets_hint') }}
              </p>
              <p>
                <b-icon icon="phone"></b-icon>
                +31 (0) 85 0603 589
                <br>
                <b-icon icon="envelope"></b-icon>
                support@fairtix.io
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div id="company-footer">
        <b-container>
          <b-row>
            <b-col class="first">
              <img src="https://fairtix.io/images/logo-fairtix.png" alt="Fairtix" style="max-width: 120px;"/>
            </b-col>
            <b-col>
              <p><strong>Fairtix</strong></p>
              <p>{{ $t('shop_chamber_of_commerce_number') }}: {{ $t('fairtix_location_coc') }}</p>
              <p>{{ $t('shop_vat') }}: {{ $t('fairtix_location_vat') }}</p>
            </b-col>
            <b-col>
              <p><strong>{{ $t('address') }}</strong></p>
              <p>{{ $t('fairtix_location_street') }}</p>
              <p>{{ $t('fairtix_location_zip') }} {{ $t('fairtix_location_city') }}</p>
              <p>{{ $t('country_nl') }}</p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <b-row v-else>
      <b-col class="text-center">
        <b-spinner style="width: 10em; height: 10em;"></b-spinner>
      </b-col>
    </b-row>
    <CookieBanner></CookieBanner>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import api from "@/api";
import FlipCountdown from 'vue2-flip-countdown';
import CookieBanner from "@/components/CookieBanner";
import moment from "moment/moment";
import {getCookie} from "@/functions";

export default {
  name: 'App',
  data() {
    return {
      show: false,
      countdown: null,
      countdownInterval: null,
      selectedCategories: {
        analytics: false,
        marketing: false,
        local_information: false
      }
    }
  },
  methods: {
    gtag(){
      window.dataLayer.push(arguments);
    },
    changeLanguage(lang) {
      this.$store.commit('setLanguage', lang)
    },
    loadCampaign() {
      if(this.$route.query.ft_campaign_name) {
        api.campaign().findByDomain(this.$route.query.ft_campaign_name).then(response => {
          if(response.campaign) {
            this.$store.commit('setCampaign', response.campaign);
          }
        })
      }
    },
    ...mapActions(['loadCompany','loadProductTypeDonation'])
  },
  computed: {
    languageLabel(){
      return this.$t('language_' + this.language)
    },
    hasTimeslots() {
      if (!this.event) {
        return false
      }
      return this.event.timeslots.length > 0 || (this.event.opening_hours && this.event.opening_hours.length > 0)
    },
    stepperCols() {
      if (!this.steps) {
        return 12
      }
      return Math.floor(12 / this.steps.length)
    },
    steps() {
      let steps = [
        {label: this.$t('shop_tickets'), route: 'ViewPointOfSale', done: true},
        {label: this.$t('shop_personal_details'), route: 'OrderEnterPersonalDetails', done: false},
      ]

      if (this.hasTimeslots) {
        steps.push({label: this.$t('shop_timeslot'), route: 'OrderSelectTimeslot', done: false})
      }

      if (this.posHasCrossSell) {
        steps.push({label: this.$t('shop_additional_products'), route: 'OrderAdditionalProducts', done: false})
      }

      steps.push({label: this.$t('shop_pay'), route: 'OrderSelectPaymentMethod', done: false})

      let done = true
      steps.forEach((step, index) => {
        steps[index].done = done
        if (this.$route.name === step.route) {
          done = false
        }
      })
      return steps
    },
    ...mapState(['company', 'event', 'pos', 'language','donation_product_id']),
    ...mapGetters(['posHasCrossSell'])
  },
  watch: {
    company() {

      if (!this.company.layout) {
        this.company.layout = {
          header: {
            backgroundColor: '#efefef'
          },
          secondary: {
            backgroundColor: '#000',
            color: '#fff'
          },
          primary: {
            color: '#E6A510'
          },
          footer: {
            backgroundColor: '#efefef',
            links: [
              {text: 'Fairtix.io', url: 'https://fairtix.io'}
            ]
          }
        }
      }

      // apply custom styling
      let customCss = document.getElementById('company-css')
      if (customCss) {
        customCss.remove()
      }
      let layout = this.company.layout
      let css = ``

      if(layout.countdown && layout.countdown.cardTopColor && layout.countdown.cardTopColor !== 'undefined' ){
        css += `div.flip-clock b.flip-card__top, div.flip-clock b.flip-card__back-bottom { background:${layout.countdown.cardTopColor}; }`
      }
      if(layout.countdown && layout.countdown.cardBottomColor && layout.countdown.cardBottomColor !== 'undefined' ){
        css += `div.flip-clock b.flip-card__bottom { background:${layout.countdown.cardBottomColor}; }`
      }
      if(layout.countdown && layout.countdown.cardTextColor && layout.countdown.cardTextColor !== 'undefined' ){
        css += `div.flip-clock b.flip-card__top, div.flip-clock b.flip-card__bottom, div.flip-clock b.flip-card__back-bottom, div.flip-clock b.flip-card__back::before, div.flip-clock b.flip-card__back::after { color:${layout.countdown.cardTextColor}; }`
      }

      if(layout.header.backgroundColor && layout.header.backgroundColor !== 'undefined' ){
        css += `#company-header { background:${layout.header.backgroundColor}; }`
      }
      if(layout.secondary.backgroundColor && layout.secondary.backgroundColor !== 'undefined' ){
        css += `#company-wrapper {background:${layout.secondary.backgroundColor} !important; }`
      }

      if(layout.header.backgroundColor && layout.header.backgroundColor !== 'undefined' ){
        css += `#dropdown-right, #dropdown-right .dropdown-toggle { background-color:${layout.header.backgroundColor}; } `
      }

      if(layout.primary.color && layout.primary.color !== 'undefined' ){
        css += `h1, h2, h3 { color:${layout.primary.color}; }`
      }
      if(layout.top_bar.titleColor && layout.top_bar.titleColor !== 'undefined' ){
        css += `h1.event-title { color:${layout.top_bar.titleColor}; }`
      }
      if(layout.top_bar.dateColor && layout.top_bar.dateColor !== 'undefined'){
        css += `p.event-date,p.event-venue{ color:${layout.top_bar.dateColor} !important; }`
      }

      if(layout.stepper.doneBackgroundColor && layout.stepper.doneBackgroundColor !== 'undefined' ){
        css += `.stepper .step-done .ball { background: ${layout.stepper.doneBackgroundColor};line-height:3.2em !important }`
        css += `.stepper .step-todo .ball { border: 3px solid ${layout.stepper.doneBackgroundColor};line-height:2.9em !important }`
      }
      if(layout.stepper.doneColor && layout.stepper.doneColor !== 'undefined' ){
        css += `.stepper .step-done .ball { color: ${layout.stepper.doneColor}; }`
      }
      if(layout.stepper.doneBackgroundColor && layout.stepper.doneBackgroundColor !== 'undefined' ){
        css += `.stepper-line { background: ${layout.stepper.doneBackgroundColor};  }`
      }
      if(layout.stepper.color && layout.stepper.color !== 'undefined' ){
        css += `.stepper .step-todo .ball { color: ${layout.stepper.color};  }`
      }
      if(layout.stepper.backgroundColor && layout.stepper.backgroundColor !== 'undefined' ){
        css += `.stepper .step-todo .ball { background: ${layout.stepper.backgroundColor}; }`
      }
      if(layout.stepper.hintColor && layout.stepper.hintColor !== 'undefined' ){
        css += `.stepper .step-done div.label, .stepper .step-todo div.label{ color:${layout.stepper.hintColor}; }`
      }

      if(layout.cart_sidebar.backgroundColor && layout.cart_sidebar.backgroundColor !== 'undefined' ){
        css += `.content-sidebar { background: ${layout.cart_sidebar.backgroundColor}; padding: 1em; margin-right: -1em;  height: 100%}`
      }
      css += `.content-sidebar input { color: inherit !important; }`
      if(layout.cart_sidebar.color && layout.cart_sidebar.color !== 'undefined' ){
        css += `.content-sidebar h1 { color: ${layout.cart_sidebar.color} !important; }`
        css += `table tr:first-of-type td{ border-top:1px solid #FFF; }`
        css += `table tr:last-of-type td{ border-top:1px solid #FFF; }`
      }
      if(layout.cart_sidebar.color && layout.cart_sidebar.color !== 'undefined' ){
        css += `.content-sidebar h3 { color: ${layout.cart_sidebar.color} !important; }`
      }
      if(layout.cart_sidebar.color && layout.cart_sidebar.color !== 'undefined' ){
        css += `.content-sidebar td, .content-sidebar td span, .content-sidebar th { color: ${layout.cart_sidebar.color} !important; }`
      }
      if(layout.cart_sidebar.color && layout.cart_sidebar.color !== 'undefined' ){
        css += `.content-sidebar p { color: ${layout.cart_sidebar.color} !important; }`
      }
      css += `.content-sidebar .btn { background-color: ${layout.cart_sidebar.buttonBackgroundColor} !important;color: ${layout.cart_sidebar.buttonColor} !important; }`
      css += `.content-sidebar .btn.assign-tickets-button { background-color: ${layout.cart_sidebar.backgroundColor} !important;color:${layout.cart_sidebar.color} !important }`

      css += `.row.active-option .payment-method-row label.custom-control-label{border:1px solid ${layout.primary.color} !important;background-color:${layout.primary.color}10}`

      if(layout.cart.backgroundColor && layout.cart.backgroundColor !== 'undefined' ){
        css += `.content-block.cart-container { background: ${layout.cart.backgroundColor} } `
      }
      if(layout.cart.color && layout.cart.color !== 'undefined' ){
        css += `.content-block.cart-container p, .content-block.cart-container .col-form-label { color: ${layout.cart.color} !important } `
      }

      if(layout.cart.productGroupBackgroundColor && layout.cart.productGroupBackgroundColor !== 'undefined' ){
        css += `.group-title { background-color: ${layout.cart.productGroupBackgroundColor} !important }`
      }
      if(layout.cart.productGroupTextColor && layout.cart.productGroupTextColor !== 'undefined' ){
        css += `h2.group-title { color: ${layout.cart.productGroupTextColor} !important }`
      }

      if(layout.cart.productTitleColor && layout.cart.productTitleColor !== 'undefined' ){
        css += `.product-details h4, .content-block.cart-container h2, .content-block.cart-container h3 { color: ${layout.cart.productTitleColor} }`
      }
      if(layout.cart.productSubtextColor && layout.cart.productSubtextColor !== 'undefined' ){
        css += `.product-details p, .content-block.cart-container .product-details p { color: ${layout.cart.productSubtextColor} !important }`
      }
      if(layout.cart.productPriceColor && layout.cart.productPriceColor !== 'undefined' ){
        css += `.product-details price { color: ${layout.cart.productPriceColor} !important }`
      }

      css += `.content-block.cart-container .btn.btn-success{ background: ${layout.cart.buttonBackgroundColor}; color: ${layout.cart.buttonColor} }`

      if(layout.info_blocks.backgroundColor && layout.info_blocks.backgroundColor !== 'undefined' ){
        css += `.footer-info-blocks .info-card { background: ${layout.info_blocks.backgroundColor} }`
      }
      if(layout.info_blocks.titleColor && layout.info_blocks.titleColor !== 'undefined' ){
        css += `.footer-info-blocks .info-card h3 { color: ${layout.info_blocks.titleColor} }`
      }
      if(layout.info_blocks.color && layout.info_blocks.color !== 'undefined' ){
        css += `.footer-info-blocks .info-card p { color: ${layout.info_blocks.color} }`
      }

      // Needed but not generated CSS
      css += `.content-block{ background: #fff; padding: 1em; box-shadow: 0px 0px 10px 1px #C9C9C9; }`
      css += `.row:first-child .content-block {}`
      css += `h1 { font-size: 1.8em }`
      css += `h2 { font-size: 1.6em }`
      css += `h3 { font-size: 1.4em }`

      document.querySelector('head').innerHTML += '<style id="company-css">' + css + '</style>'

    },
    pos(){
      // Check if we have to add GTM code
      if(this.pos && this.pos.event && this.pos.event.gtm_id){
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer',this.pos.event.gtm_id);

        let gtm_noscript = document.createElement('noscript');
        let gtm_iframe = document.createElement('iframe');
        gtm_iframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=' + this.pos.event.gtm_id);
        gtm_iframe.setAttribute('height', '0');
        gtm_iframe.setAttribute('width', '0');
        gtm_iframe.setAttribute('style', 'display:none;visibility:hidden');
        gtm_noscript.appendChild(gtm_iframe);
        document.body.appendChild(gtm_noscript);

        // Initialize Google Tag Manager
        this.gtag('js', new Date());
        this.gtag('config', this.pos.event.gtm_id);
      }
    }
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    const storedCategories = getCookie("ft_cookieCategories");
    if (storedCategories) {
      this.selectedCategories = JSON.parse(storedCategories);
      this.gtag('consent', 'default', {
        'ad_storage': (this.selectedCategories.marketing) ? 'granted' : 'denied',
        'ad_personalization': (this.selectedCategories.marketing) ? 'granted' : 'denied',
        'ad_user_data': (this.selectedCategories.marketing) ? 'granted' : 'denied',
        'analytics_storage': (this.selectedCategories.analytics) ? 'granted' : 'denied'
      })
    } else {
      this.gtag('consent', 'default', {
        'ad_storage': 'denied',
        'ad_personalization': 'denied',
        'ad_user_data': 'denied',
        'analytics_storage': 'denied',
      })
    }
  },
  created() {
    this.loadCompany()
    this.loadProductTypeDonation()
    this.loadCampaign();
    this.countdownInterval = setInterval(() => {
      if(this.pos && !this.pos.countdown) {
        this.countdown = null;
        clearInterval(this.countdownInterval)
      }
      if( this.pos && this.pos.countdown && moment(this.pos.countdown).isAfter(moment())) {
        this.countdown = moment(this.pos.countdown).format('YYYY-MM-DD HH:mm:ss');
      }
      else {
        clearInterval(this.countdownInterval)
        this.countdown = null;
      }
    }, 1300)
  },
  components: {
    FlipCountdown,
    CookieBanner
  },
  destroyed() {
    clearInterval(this.countdownInterval)
  }
}
</script>
<style>
.selected-lang{
  border:0px
}
.dropdown-toggle::after{
  margin-bottom:-4px
}
.btn{
  border:0px
}
#company-page {
  min-height: 100%;
}
#company-wrapper {
  overflow-y: scroll;
  background: #e5e5e5;
  overflow-x: hidden;
}
#company-header {
  padding: 1em 0;
  box-shadow: 0px 1px 1px #efefef;
  margin-bottom: 1em;
}
#logo-container{
  display:flex;
  align-items: center;
}
#company-content {
  margin-top: 1em;
}
h1.event-title{
  font-weight:bold;
  font-size:1.6rem
}
p.event-date, p.event-venue{
  font-size:0.95rem
}
#company-footer {
  border-top:1px solid #ececec;
  padding:30px 0px;
  background-color:#FFF !important
}
#company-footer p{
  margin-bottom:0;
  color:#808080;
  font-size:0.8rem
}
#company-footer div.col{
  padding:10px 0 10px 30px;
  border-left:1px solid #e0e0e0
}
#company-footer div.col.first{
  border-left:0px;
  text-align:center;
  padding: 1.5em 0 0;
}
.info-card {
  border: solid 1px #efefef;
  padding: 2em;
  margin-top: 1em;
  background: #fff;
  box-shadow: 0px 0px 10px 1px #C9C9C9;
}
.info-card h3{
  font-size:1.3rem;
  font-weight:bold
}
.info-card p{
  font-size:0.8rem
}
.info-card p svg{
  font-size:1rem
}
.footer-info-blocks.container{
  margin-bottom:50px
}
.footer-info-blocks .info-card{
  border:0px
}
.info-card:nth-child(1) {
  margin-right: 0.5em;
}
.info-card:nth-child(2) {
  margin-left: 0.5em;
}
.stepper-wrapper {
  height: 90px;
  position:relative
}
.stepper {
  margin-bottom: 50px;
}
.stepper .step {
  text-align: center;

}
.stepper .step .label {
  font-size: 0.8em;
  text-align: center;
  margin-top: 0.5em;
  display: inline-block;
  width: 100%;
  height: 2em;
}
.stepper .step .ball {
  z-index: 1;
  border-radius: 100%;
  width: 3em;
  height: 3em;
  line-height: 3em;
  margin: 0 1em;
  text-align: center;
  position: relative;
  display: inline-block;
}
.stepper-line {
  height: 4px;
  position:absolute;
  width:98%;
  z-index: 0;
  top:24px;
  right:0
}

@media only screen and (max-width: 992px) {
  .content-sidebar {
    margin-left: 0em;
    margin-top: 1em;
  }
  .info-card:nth-child(1) {
    margin-top: 2em;
    margin-right: 0em;
  }
  .info-card:nth-child(2) {
    margin-bottom: 3em;
    margin-left: 0em;
  }
}
.languages {
  border: 1px solid #999;
  border-radius: 30px;
}
.languages .dropdown-toggle{
  position: relative;
  border-radius: 20px;
}
.languages .dropdown-toggle::after{
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translateY(-10%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px;
  border-color: #000 transparent transparent transparent;
}
.languages .btn-secondary{
  background-color: #fff!important;
  width: 150px;
}
.languages .btn-secondary > div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.languages .btn-secondary > div img{
  margin: 0 10px;
}
.lang-flag, .selected-lang{
  width: 30px;
}
.speak{
  font-size: 11px;
  color: #fff!important;
  margin-right: 5px;
}
.dropdown-item{
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 770px) {
  #company-footer div.col.first img{
    max-width:100px !important
  }
}

@media only screen and (max-width: 576px) {
  .container{
    max-width:94%
  }
  #company-footer div.col{
    width:100%;
    border-left:0px;
    padding:0px;
    margin-bottom:15px
  }
  #company-footer div.row{
    flex-direction: column;
  }
  #company-footer div.col.first{
    text-align: left;
  }
}

@media only screen and (max-width: 337px) {
  .content-sidebar {
    margin-left: -1em;
    margin-top: 1em;
  }
  .info-card {
    margin-top: 0em;
  }
}

</style>
