export default class ticketApi {

    constructor(axios) {
        this.axios = axios
    }

    generate(productOrderId) {
        return this.axios.post('/ticket/generate/' + productOrderId)
    }

    assign(id, secret, email) {
        return this.axios.post('/ticket/' + id + '/' + secret + '/assign', {email})
    }

    remind(id, secret) {
        return this.axios.post('/ticket/' + id + '/' + secret + '/remind')
    }

    getBySecret(id, secret) {
        return this.axios.get('/ticket/' + id + '/' + secret)
    }

    updateCustomer(id, secret, customer) {
        return this.axios.post('/ticket/' + id + '/' + secret + '/customer', customer)
    }

    claim(id, secret) {
        return this.axios.post('/ticket/' + id + '/' + secret + '/claim')
    }

    getTemplates() {
        return this.axios.get('/ticket-templates');
    }

    getTemplatesOverview(page) {
        return this.axios.get('/ticket-templates/overview?page=' + page);
    }

    createTemplate(name) {
        return this.axios.post('/ticket-templates', {name});
    }

    duplicateTemplate(id, name) {
        return this.axios.post('/ticket-templates/' + id + '/duplicate', {name});
    }

    getTemplate(id) {
        return this.axios.get('/ticket-templates/' + id);
    }

    getDefaultTemplate(){
      return this.axios.get('/default-ticket-template');
    }

    previewTemplate(template, data) {
        return this.axios.post('/ticket-templates/' + template.id + '/preview', {data})
    }

    saveTemplate(template) {
        return this.axios.post('/ticket-templates/' + template.id, template)
    }

    cancel(ticketId) {
        return this.axios.post('/ticket/cancel/' + ticketId )
    }

    uploadDefaultTicketLayout(filename){
      return this.axios.post('/cdn/upload', {filename, type: 'default-company'})
    }

    searchLost(email, date, recaptcha) {
        return this.axios.post('/tickets/search-lost', {email, date, recaptcha})
    }

    deleteTemplate(id) {
        return this.axios.delete('/ticket-templates/' + id);
    }

}
