<template>
  <b-container>

    <b-row v-if="errors[0] !== undefined && errors[0] === 'event_not_live'">
      <b-col class="content-block text-center">
        <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 463.43" style="max-width: 100px" class="mt-4 mb-4">
          <path d="M189.46 44.02c34.26-58.66 99.16-58.77 133.24.12l.97 1.81 175.27 304.4c33.71 56.4-1.2 113.76-66.17 112.96v.12H73.53c-.9 0-1.78-.04-2.66-.11-58.34-.79-86.64-54.22-61.9-106.84.39-.85.82-1.67 1.28-2.46l-.04-.03 179.3-309.94-.05-.03zm50.32 302.4c4.26-4.13 9.35-6.19 14.45-6.56 3.4-.24 6.8.29 9.94 1.48 3.13 1.19 6.01 3.03 8.39 5.41 6.92 6.91 8.72 17.38 4.64 26.16-2.69 5.8-7.08 9.7-12.11 11.78-3.03 1.27-6.3 1.84-9.56 1.76-3.27-.08-6.49-.82-9.41-2.18-5.02-2.33-9.3-6.43-11.7-12.2-2.65-6.36-2.27-12.96.63-19.15 1.15-2.46 2.75-4.81 4.73-6.5zm33.86-47.07c-.8 19.91-34.51 19.93-35.28-.01-3.41-34.1-12.13-110.53-11.85-142.58.28-9.87 8.47-15.72 18.94-17.95 3.23-.69 6.78-1.03 10.35-1.02 3.6.01 7.16.36 10.39 1.05 10.82 2.3 19.31 8.39 19.31 18.45l-.05 1-11.81 141.06z" fill="#FFA500"/>
        </svg>
        
        <h1>{{ $t('event_passed') }}</h1>
        <p>{{ $t('pos_will_automatically_redirect', {seconds: passedRedirectTimer}) }}</p>
        <b-button id="btn-pos-redirect" class="mt-2 mb-4" variant="success" @click="$router.push({name: 'Index'})">{{ $t('pos_redirect_now') }}</b-button>
      </b-col>
    </b-row>

    <b-row v-if="pos">
      <b-col class="nopadding" lg="9" sm="12">
        <img v-if="event.banner_url" :src="event.banner_url" alt="" style="width:100%; max-width: 855px;" class="mb-2 pos-banner">
      </b-col>
    </b-row>
    <b-row v-if="pos">
      
      <gtm-page-view v-if="event && event.gtm_id" :gtm_id="event.gmt_id"></gtm-page-view>

      <img v-if="event.show_image_in_pos" id="pos-image" class="mb-3 d-md-flex d-lg-none" :src="event.image_url">

      <b-col v-if="show" class="content-block cart-container" lg="9" sm="12">
        <h2>{{ $t('view_pos_cart_title') }}</h2>
        <p v-if="event.description_location === 'top'" v-html="event.description"></p>
        <product-list></product-list>
        <b-form-group align="end">
          <b-button @click="order()" :disabled="cart.products.length === 0 || (event.age_confirmation_checkbox && !age_confirmation_checkbox)" size="lg" variant="success"
                    class="mt-4 mb-3 regular-order-button"
                    v-if="!processing">
            {{ this.$route.query.back !== undefined && this.$store.state.order ? $t('next') : $t('cart_order') }}
          </b-button>
          <b-spinner v-else></b-spinner>
          <br/>
          <b-row>
            <b-col sm="12" md="6"></b-col>
            <b-col sm="12" md="6">
              <div v-if="event.minimum_age && event.age_confirmation_checkbox">
                <b-checkbox v-model="age_confirmation_checkbox" class="pos-age-confirmation">{{ $t('pos_age_confirmation', event) }}</b-checkbox>
              </div>
              <div class="text-right hint-container" v-else-if="event.minimum_age">
                <span class="hint">{{ $t('view_pos_minimum_age', event) }}</span>
              </div>
            </b-col>
          </b-row>
        </b-form-group>
        <p v-if="event.description_location === 'bottom'" v-html="event.description"></p>
      </b-col>
      <b-col v-else class="content-block cart-container" lg="9" sm="12">
        <div class="loading-logo text-center">
          <b-img :src="require('./../assets/fairtix.gif')"/>
        </div>
      </b-col>
      <b-col sm="12" lg="3" class="sidebar-container">
        <div class="content-sidebar">
          <template v-if="cart && cart.products.length > 0">
            <cart></cart>
            
            <b-button @click="order()" :disabled="cart.products.length === 0 || (event.age_confirmation_checkbox && !age_confirmation_checkbox)" size="lg" variant="success"
                      class="mt-4 mb-3 sidebar-order-button"
                      v-if="!processing && cart && cart.products && cart.products[0].company_id == 33">
              {{ this.$route.query.back !== undefined && this.$store.state.order ? $t('next') : $t('cart_order') }}
            </b-button>
            <br>

            <span class="clickable-text clear-cart" @click="$store.commit('clearCart')">
              {{ $t('cart_clear') }}
            </span>
          </template>
          <b-alert v-else show>
            {{ $t('cart_empty') }}
          </b-alert>
        </div>
      </b-col>
    </b-row>
    <b-row v-else-if="errors.length === 0">
      <b-col class="text-center">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'
import Cart from '@/components/Cart'
import ProductList from '@/components/ProductList'
import GtmPageView from '@/components/GtmPageView'

export default {
  name: 'ViewPointOfSale',
  components: {
    Cart,
    ProductList,
    GtmPageView
  },
  data() {
    return {
      amounts: {},
      processing: false,
      error: null,
      passedRedirectTimer: 4,
      show: false,
      age_confirmation_checkbox: false,
      gtm_id: null,
      redirectInterval: null
    }
  },
  methods: {
    order() {
      const products = this.cart.products.map((product) => {
        let manual_price = false
        if(product.product_type_id === this.donation_product_id){
          manual_price = true
        }
        return {
          product_id: product.id,
          amount: product.amount,
          price: product.price,
          manual_price: manual_price,
          is_disabled: product.is_disabled,
        }
      }).filter(p => !p.is_disabled);

      if(this.$route.query.back !== undefined && this.$store.state.order) {
        this.processing = true;
        api.order().updateProducts(this.$store.state.order,products).then(order => {
          if(this.campaign) {
            this.$router.push({name: 'OrderEnterPersonalDetails', params: {id: order.id, secret: order.secret}, query: {ft_campaign_name: this.campaign.slug}})
          }
          else {
            this.$router.push({name: 'OrderEnterPersonalDetails', params: {id: order.id, secret: order.secret}})
          }
        })
        return;
      }
      this.processing = true
      const campaign = this.$route.query.ft_campaign_name
      let source = this.$route.query.utm_campaign
      api.order().create(this.pos.id, products, campaign, this.cart.campaignDiscount, source).then((order) => {
        if(this.campaign) {
          this.$router.push({name: 'OrderEnterPersonalDetails', params: {id: order.id, secret: order.secret}, query: {ft_campaign_name: this.campaign.slug}})
        }
        else {
          this.$router.push({name: 'OrderEnterPersonalDetails', params: {id: order.id, secret: order.secret}})
        }
      }).catch(() => {
        this.processing = false
        // this.$store.commit('errors', [error.response.error.error])
      })
    }
  },
  computed: {
    ...mapState(['cart', 'errors', 'pos', 'event', 'donation_product_id', 'campaign'])
  },
  created() {
    if(this.$route.query.back !== undefined && this.$store.state.order) {
      this.show = true;
      return;
    }
    setTimeout(() => {
      this.show = true;
    }, 1300)
    api.pos().getByUrl(this.$route.params.url).then((pos) => {
      if(this.$route.query.ft_campaign_name) {
        api.campaign().findByDomain(this.$route.query.ft_campaign_name, pos.event.id).then(response => {
          if(response.campaign) {
            this.$store.commit('setCampaign', response.campaign);
          }
        })
      }
      this.$store.commit('setPos', pos)
      this.$store.commit('setLanguage', pos.language)

      document.title = this.$t('view_pos_title', {'event_name':this.pos.event.name});
      document.querySelector('head').innerHTML += '<meta name="robots" content="noindex">'

      // And we can check against the current stock in the backend
      this.cart.products.forEach(product => {
        this.amounts[product.id] = product.amount
      })

      // apply custom styling
      let customCss = document.getElementById('custom-css')
      if (customCss) {
        customCss.remove()
      }
      document.querySelector('head').innerHTML += '<style id="custom-css">' + pos.custom_css + '</style>'
    }).catch((error) => {
      if (error.response.data.length > 0 && error.response.data[0] === 'pos_not_found') {
        this.$router.push({
          name: 'NotFound'
        })
        return
      }
      if(error.response.data.length && error.response.data[0].code === 'event_not_live') {
        this.redirectInterval = setInterval(() => {
          if(this.passedRedirectTimer) {
            this.passedRedirectTimer--;
          }
        }, 1000)
        setTimeout(() => {
          this.$router.push({name: 'Index'})
        }, 4000)
      }
      else {
        this.$store.commit('errors', error.response.data)
      }
    })
  }
}
</script>
<style scoped>
#pos-image{
  width:100%;
  max-height:250px;
  object-fit:cover
}

.loading-logo {
  height: 100%;
  min-height: 200px;
  position: relative;
}
.loading-logo img {
  height: 200px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.content-block.cart-container p{
  font-size:0.9rem
}
.content-block.cart-container h2{
  font-weight:bold;
  font-size:1.5rem
}

.price {
  font-size: 1.5em;
  line-height: 50px;
}

.group-title {
  background: #efefef;
  color: gray;
  padding: 0.5em;
  font-size: 1.2em;
  text-transform: uppercase;
}

.product-details {
  padding: 0.5em;
}

.product-details h3 {
  font-size: 1em;
}

.hint-container {
  text-align: center;
}

.hint {
  font-size: 0.8em
}

.content-block.cart-container /deep/ select.custom-select{
  font-size:16px !important
}

#btn-pos-redirect{
  font-size: 1.4em;
  border-radius:50px;
  padding:12px 35px
}

.nopadding{
  padding:0px
}

.clickable-text{
  cursor:pointer;
  text-decoration:underline;
  font-size:14px
}

.sidebar-order-button{
  display:block
}

@media only screen and (max-width: 990px) {
  .sidebar-order-button{
    display:none
  }
  .sidebar-container{
    padding-left:0px
  }
  #pos-image{
    max-height:none
  }
}
</style>

<style>
.pos-age-confirmation .custom-control-label {
  font-size: 14px !important;
}
@media screen and (max-width: 990px){
  .pos-banner {
    display: none;
  }
}
</style>
