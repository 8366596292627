export default class PermissionApi {
    constructor(axios) {
        this.axios = axios
    }

    createRole(data) {
        return this.axios.post('/roles', data);
    }

    updateRole(id, data) {
        return this.axios.post('/roles/' + id, data);
    }

    deleteRole(id) {
        return this.axios.delete('/roles/' + id);
    }

    getRoles() {
        return this.axios.get('/roles');
    }

    getPermissions() {
        return this.axios.get('/permissions');
    }

    syncCompanyPermissions(company_id, permissions) {
        return this.axios.post('/company/' + company_id + '/permissions', {
            permissions
        })
    }
}
