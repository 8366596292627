<template>
  <div>
    <div class="content-main-container container-distance">
      <div class="title-container">{{ $t('company_permissions') }}</div>
      <div class="content-container">
        <div v-for="(group, index) in companyPermissions" :key="index">
          <label class="group-label px-2 d-block mb-3 mb-1">
            <b-form-checkbox @change="changePermissionsCheckbox(group, $event)" class="font-weight-bold py-1" :disabled="!$can('admin_company_permission_edit')">{{ index }}</b-form-checkbox>
          </label>
          <b-row>
            <b-col cols="6" v-for="permission in group" :key="permission.id">
              <b-form-group class="px-3">
                <b-form-checkbox v-model="permission.selected">{{ $t('permission_' + permission.slug) }}</b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <b-button @click="savePermissions()" :disabled="loading">
          <b-icon v-if="loading" icon="three-dots" animation="cylon" class="mr-2"></b-icon> {{ $t('save') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import {groupBy} from "@/functions";

export default {
  name: "DefaultCompanyPermissions",
  data() {
    return {
      working: false,
      loading: false,
      company: {},
      editUser: {},
      footerLink: {},
      paymentMethodHistory: [],
      companyFee:null,
      editPaymentFees:null,
      newPaymentFee:[],
      companyPermissions:[],
    }
  },
  methods:{
    savePermissions() {
      const permissions = [];

      for (const group in this.companyPermissions) {
        for (const permission of this.companyPermissions[group]) {
          if (permission.selected) {
            permissions.push(permission.id);
          }
        }
      }

      this.loading = true;
      api.defaultValues().save('default-permissions', permissions).then(() => {
        this.loading = false;
        this.$store.commit('success', 'company_updated');
      }).catch(() => {
        this.loading = false;
      })
    },
    changePermissionsCheckbox(permissions, event) {
      for(const permission of permissions) {
        permission.selected = event;
      }
    },
    getSortedPermissions(permissions) {
      const tempPermissions = groupBy(permissions, p => p.category);
      for (const group in tempPermissions) {
        tempPermissions[group] = tempPermissions[group].sort((a, b)=>{
          return a.sort - b.sort;
        })
      }

      return tempPermissions;
    },
    getPermissions(){
      Promise.all([
        api.permission().getPermissions(),
        api.defaultValues().getByKey('default-permissions')
      ]).then(async([permissions,selectedPermissions]) => {
        selectedPermissions = selectedPermissions.value ?? [];
        const tmpArray = permissions.filter(p => {
          return !p.admin_only;
        }).map(permission => {
          const isSelected = !!selectedPermissions.filter(rp => rp === permission.id).length;
          return Object.assign(permission, {selected: isSelected})
        });

        this.companyPermissions = this.getSortedPermissions(tmpArray)
      })
    }
  },
  created() {
    document.title = this.$t('backend_title_default_company_permissions')
    if(this.$store.state.user) {
      if(!this.$can('master_data_default_values_fees_edit')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }

    this.getPermissions();
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('master_data_default_values_fees_edit')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style scoped>
.group-label {
  background-color: #EEEEEE !important;
  color: #000!important;
  font-size: 15px!important;
  padding-top: 12px ;
  padding-bottom: 12px ;
}
.group-label span {
  background-color: #EEEEEE;
  color: #4E4D4D;
}
</style>
