export default class CustomerSupportApi {
    constructor(axios) {
        this.axios = axios
    }

    orders(filters) {
        return this.axios.post('/customer-support', {
            filters
        })
    }

    dependencies() {
        return this.axios.get('/customer-support/dependencies')
    }
}
