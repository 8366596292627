import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMoment from 'vue-moment'
import VueMeta from 'vue-meta'
import i18n from './i18n'
import moment from 'moment-timezone'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import VueApexCharts from 'vue-apexcharts'
import {ColorPicker, ColorPanel} from 'one-colorpicker'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { VueSpinners } from '@saeris/vue-spinners'

moment.tz.setDefault('Europe/Amsterdam');

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMoment)
Vue.use(VueSidebarMenu)
Vue.use(VueApexCharts)
Vue.use(ColorPanel)
Vue.use(ColorPicker)
Vue.use(VueSpinners)

Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('apexchart', VueApexCharts)
Vue.component('v-select', vSelect)


Vue.filter('currency', function(value){
  return '€ ' + parseFloat(value).toLocaleString('nl-NL', {minimumFractionDigits: 2})
})

Vue.filter('time_format', function(value){
  return moment(value).format('HH:mm')
})

Vue.filter('date_time', function(value){
  return moment(value).format('DD-MM-YYYY HH:mm')
})

Vue.filter('date_written', function(value){
  return moment(value).format('ddd D MMMM YYYY')
})

Vue.filter('date_full', function(value){
  return moment(value).format('dddd D MMMM YYYY HH:mm')
})

Vue.mixin({
  methods: {
    $can(action) {
      if(!this.$store.state.user) {
        return false;
      }

      return this.$store.state.user?.permissions.includes(action)
    }
  }
})

Vue.use(VueMeta)

new Vue({
  render: h => h(App),
  router,
  i18n,
  store
}).$mount('#app')
