<template>
  <div>
    <b-row>
      <b-col lg="3" sm="6" cols="12">
      
        <b-form-checkbox switch v-model="automatic_invoice_enabled" class="mySwitch">
          {{ $t('auto_invoice_settings_general_stop') }}
        </b-form-checkbox>

        <br>

        <b-button @click="save()" variant="success">{{ $t('save') }}</b-button>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '../api'

export default {
  name: "AutoInvoiceSettings",
  data(){
    return {
      automatic_invoice_enabled: false
    }
  },
  created() {
    document.title = this.$t('nav_auto_invoice_settings')
    if(this.$store.state.user) {
      if(!this.$can('master_data_auto_invoice_settings_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.setting().get('automatic_invoice_enabled').then(response => {
      this.automatic_invoice_enabled = (response.value) ? true : false
    })
  },
  methods: {
    save(){
      api.setting().save('automatic_invoice_enabled', (this.automatic_invoice_enabled) ? 1 : 0)
      this.$store.commit('success', 'changes_saved')
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('master_data_auto_invoice_settings_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
