export default class orderApi {

    constructor(axios) {
        this.axios = axios
    }

    paymentMethods( company_id ) {
        return this.axios.get('/payment-method', {params: {company_id: company_id}})
    }

    create(point_of_sale_id, products, campaign, campaign_discount, source) {
        return this.axios.post('/order', {point_of_sale_id, products, campaign, campaign_discount, source, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone})
    }

    getBySecret(id, secret) {
        return this.axios.get('/order/' + id + '/' + secret)
    }

    getByOrdernumberSecret(order_number, secret) {
      return this.axios.get('/order/ordernumber-secret/' + order_number + '/' + secret)
  }

    getTickets(id, secret) {
        return this.axios.get('/order/' + id + '/' + secret + '/tickets')
    }

    save(order) {
        return this.axios.post('/order/' + order.id + '/' + order.secret, order)
    }

    pay(order) {
        return this.axios.post('/order/' + order.id + '/' + order.secret + '/pay')
    }

    updateCustomer(customer) {
        return this.axios.post('/order/customer/' + customer.id + '/update', customer)
    }

    getAll(page, show_expired=false, show_box_office=false, selectedEvents, discountcodeId, search) {
        return this.axios.get('/user/order?page=' + page, {params: {show_expired: show_expired, show_box_office: show_box_office, selected_events: selectedEvents, discountcode_id: discountcodeId, search: search}})
    }

    details(order_number) {
        return this.axios.get('/user/order/' + order_number)
    }

    addNote(id, message) {
        return this.axios.post('/user/order/' + id + '/note', {message})
    }

    cancel(id, body) {
        return this.axios.post('/user/order/' + id + '/cancel', body)
    }

    refund(id, body) {
        return this.axios.post('/user/order/' + id + '/refund', body)
    }

    resentConfirmation(id) {
        return this.axios.post('/user/order/' + id + '/resend-confirmation')
    }

    addCrossSell(order, products) {
        return this.axios.post('/order/' + order.id + '/' + order.secret + '/cross_sell', {products})
    }

    makeExport(exportSettings) {
        return this.axios.post('/user/order/export', exportSettings)
    }

    switchEvent(id, data) {
        return this.axios.post('/user/order/' + id + '/switch-event', data)
    }

    updateProducts(order, products) {
        return this.axios.post('/order/' + order.id + '/' + order.secret + '/products', {products})
    }
}
