<template>
  <div>
    <h3 class="cart-title">{{ $t('cart_title') }}</h3>
    <table class="table" v-if="calculatedCart">
      <tr v-for="product in calculatedCart.products" :key="`cart_product` + product.id">
        <td>{{ product.amount + `x` }} {{ product.name }}</td>
        <td class="text-right">{{ product.total | currency }}</td>
      </tr>
      <tr class="cart-sub-total last-tr">
        <td>{{ $t('cart_sub_total') }}</td>
        <td class="text-right">{{ calculatedCart.productTotal | currency }}</td>
      </tr>
      <tr v-if="calculatedCart.processingFee > 0 || calculatedCart.paymentFee > 0" class="last-tr">
        <td>{{ $t('cart_ticket_fee') }}</td>
        <td class="text-right">{{ calculatedCart.processingFee + calculatedCart.paymentFee | currency }}</td>
      </tr>
      <tr v-if="calculatedCart.discount" class="last-tr">
        <td>{{ $t('cart_discount', cart.discountCode) }}</td>
        <td class="text-right">{{ calculatedCart.discount | currency }}</td>
      </tr>
      <tr v-if="cart.campaignDiscount" class="last-tr">
        <td>{{ $t('cart_discount', {code: ''}) }}</td>
        <td class="text-right">{{ '-' + cart.campaignDiscount | currency }}</td>
      </tr>
      <tr class="cart-total">
        <td></td>
        <td class="text-right"><span class="small-text">{{ $t('cart_incl_vat') }}</span> {{ calculatedCart.total | currency }}</td>
      </tr>
      <tr v-if="cart.timeslot" class="timeslot-tr">
        <td>{{ $t('order_timeslot') }}</td>
        <td class="text-right">{{ cart.timeslot }}</td>
      </tr>
    </table>

    <template v-if="!cart.discountCode && order">
      <h3>{{ $t('discountcode_add_to_cart') }}</h3>
      <b-form-group>
        <b-input-group>
          <b-form-input v-model="discountCode" :state="discountCodeError"></b-form-input>
          <b-input-group-append>
            <b-button variant="info" @click="claimDiscountcode()">{{ $t('discount_code_claim') }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </template>

    <order-timer v-if="order" :order="order"></order-timer>
  </div>
</template>
<script>
import OrderTimer from '@/components/OrderTimer'
import {mapState, mapActions, mapMutations} from 'vuex'
import api from "@/api";

export default {
  components: {
    OrderTimer
  },
  data(){
    return {
      discountCode: null,
      discountCodeError: null,
      timeslot: null
    }
  },
  methods: {
    calculateVatFromEx(price, percentage){
      let vat = price * (percentage / 100)
      return vat
    },
    calculateVat(price, percentage) {
      let excl = price / (1 + percentage / 100)
      let vat = price - excl
      return vat
    },
    claimDiscountcode() {
      this.discountCodeError = null
      if(!this.discountCode){
        this.discountCodeError = false
        return
      }
      api.discountcode().getByOrder(this.$route.params.id, this.$route.params.secret, this.discountCode).then(discountCode => {
        this.$store.commit('setCartDiscountCode', discountCode)
      })
    },
    ...mapActions(['loadCompany']),
    ...mapMutations(['setCartTotal'])
  },
  computed: {
    calculatedCart() {

      if (!this.event) {
        console.log('Event not in state!')
        return null
      }
      if (!this.company) {
        console.warn('Company not in state, maybe still loading!')
        return null
      }

      if (!this.cart) {
        console.warn('Cart not in state!')
        return null
      }

      let productTotal = 0
      let paymentFee = 0
      let processingFee = 0
      let totalProductCount = 0
      let campaignDiscount = 0;

      this.cart.products.forEach(p => {
        productTotal += p.amount * p.price
        totalProductCount += p.amount
      })

      this.cart.products.forEach( product => {
        if(product.service_fee){
          processingFee += (product.service_fee * product.amount)
        }
      })

      this.cart.products.forEach( product => {
        campaignDiscount += ((product.price - product.discount_price) * product.amount)
      })
      this.$store.commit('setCartCampaignDiscount', campaignDiscount)

      // let total = productTotal + paymentFee + processingFee
      let total = productTotal

      let discount = 0
      if(this.cart.discountCode) {
        // if type is per order
        if (this.cart.discountCode.apply_type === 'order') {
          let productsTotal = 0;
          this.cart.products.forEach(p => {
            if (this.cart.discountCode.products && this.cart.discountCode.products.length) {
              if (this.cart.discountCode.products.includes(p.id)) {
                productsTotal += p.amount * p.discount_price
              }
            }
            else {
              productsTotal += p.amount * p.discount_price
            }
          })
          if (this.cart.discountCode.type === 'percentage') {
            discount = 0 - Math.round(((productsTotal * this.cart.discountCode.amount / 100) + Number.EPSILON) * 100) / 100
          }
          else if (this.cart.discountCode.type === 'value') {
            discount = 0 - this.cart.discountCode.amount
          }
        } else { // if type is per ticket
          for (const p of this.cart.products) {
            if (this.cart.discountCode.products && this.cart.discountCode.products.length) {
              if(!this.cart.discountCode.products.includes(p.id)) {
                continue;
              }
            }
            if (this.cart.discountCode.type === 'percentage') {
              discount -= ((Math.round(((p.discount_price * this.cart.discountCode.amount / 100) + Number.EPSILON) * 100) / 100) * p.amount)
            }
            else if (this.cart.discountCode.type === 'value') {
              discount -= (this.cart.discountCode.amount * p.amount)
            }
          }
        }
      }

      if(campaignDiscount) {
        total -= campaignDiscount;
      }

      total += discount

      if (total < 0) {
        total = 0
      }

      let paymentFeeVat = 0

      /**
       * Payment method calculation:
       * Payment fee fixed is already inclusive VAT
       * Payment fee percentage is without VAT
       *
       * Calculation:
       * 1) Add the payment fee fixed
       * 2) Calculate fee percentage = (total products + total ticket fee + fixed fee) * percentage
       * 3) Add VAT for the company to the total
       * 4) Round the total payment fee
       */
      if (this.cart.paymentMethod) {

        // Payment Fee fixed is inclusive VAT
        paymentFee = this.cart.paymentMethod.fee_fixed

        // Calculate the fee that is needed for the percentage
        let feePercentage = 0
        if (this.cart.paymentMethod.fee_percentage > 0) {
          feePercentage = (processingFee + total + this.cart.paymentMethod.fee_fixed) * (this.cart.paymentMethod.fee_percentage / 100)
          paymentFee += feePercentage
        }

        // Add the VAT to the payment fee (only from the percentage)
        if(this.company.vat_percentage && paymentFee){
          paymentFeeVat = parseFloat( this.calculateVatFromEx(feePercentage, this.company.vat_percentage) )
          if(paymentFeeVat){
            paymentFee += paymentFeeVat
          }
        }

        // Rounds to two decimals
        if(paymentFee){
          paymentFee = Math.round((paymentFee + Number.EPSILON) * 100) / 100
        }

      }

      total += (paymentFee + processingFee)
      this.setCartTotal(total);
      return {
        total,
        productTotal,
        paymentFee,
        paymentFeeVat: paymentFeeVat,
        paymentFeeVatPerc: this.company.vat_percentage,
        processingFee,
        processingFeeVat: this.calculateVat(processingFee, this.company.vat_percentage),
        processingFeeVatPerc: this.company.vat_percentage,
        discount,
        discountVat: this.calculateVat(discount, this.company.vat_percentage),
        discountVatPerc: this.company.vat_percentage,
        totalProductCount,
        products: this.cart.products.map((p) => {
          return {
            id: p.id,
            amount: p.amount,
            total: p.amount * p.price,
            name: p.name,
            vat: p.vat * p.amount,
            vat_percentage: p.vat_percentage
          }
        })
      }
    },
    ...mapState(['cart', 'event', 'company', 'order', 'campaign'])
  },
  created() {
    this.loadCompany()
  }
}
</script>
<style scoped>
.vat {
  font-size: 12px;
}
.small-text{
  font-size:12px
}
.cart-title{
  font-size:1.3rem;
  font-weight:bold;
  margin-bottom:15px
}
table td{
  padding:3px 0px;
  border-top:0px
}
.cart-sub-total td{
  padding-top:20px;
}
.timeslot-tr td{
  padding-top:10px
}
.last-tr td{
  padding-bottom:10px
}
.cart-total td{
  padding-top:10px;
}
.content-sidebar table tr.cart-total td{
  font-size:1.1rem
}
table tr:first-of-type td{
  padding-top:15px
}
.content-sidebar table tr td, .content-sidebar p{
  font-size:0.8rem
}
</style>
