export default class invoiceApi {

  constructor(axios) {
      this.axios = axios
  }

  getAll(page) {
    return this.axios.get('/invoice?page=' + page)
  }
  getAllByCompany(page){
    return this.axios.get('/invoice/company?page=' + page)
  }
  searchInvoice(search_term) {
    return this.axios.get('/invoice/search', {params: {search_term: search_term}})
  }
  searchInvoiceByCompany(search_term, event_id) {
    return this.axios.get('/invoice/search/company', {params: {search_term: search_term, event_id: event_id}})
  }
  downloadFile(invoiceNumber, type) {
    return this.axios.get('/invoice/' + invoiceNumber + '/download/' + type, {responseType: 'blob'})
  }
  getFileName(invoiceNumber, type) {
    return this.axios.get('/invoice/' + invoiceNumber + '/download/' + type + '?filename=true')
  }
  generateInvoice(invoiceNumber){
    return this.axios.post('/invoice/' + invoiceNumber + '/generate')
  }
  generatePayoutSummary(invoiceNumber){
    return this.axios.post('/payout-summary/' + invoiceNumber + '/generate')
  }

}