export default class BoxOfficePlaceOrder {

    constructor(axios) {
        this.axios = axios
    }

    saveSettings(slug, data) {
        return this.axios.post('/box-office/' + slug + '/place-order/settings', {
            settings: data
        })
    }

    getSettings(slug) {
        return this.axios.get('/box-office/' + slug + '/place-order/settings')
    }

    save(slug, data) {
        return this.axios.post('/box-office/' + slug + '/place-order', data)
    }

    markOrderPaid(slug, order) {
        return this.axios.post('/box-office/' + slug + '/place-order/' + order + '/mark-paid')
    }

    getOrders(slug, page){
      return this.axios.get('/box-office/' + slug + '/order-history/orders?page=' + page)
    }
}
