<template>
  <div id="login">
    <b-row align-v="center">
      <div id="login-wrap">
        <div class="p-3">
          <b-img v-if="whitelabel == 'fairtix'" class="logo" :src="require('../assets/logo-light.png')"></b-img>
          <b-img v-else-if="whitelabel == 'baron'" class="logo" :src="require('../assets/logo-baron-fairtix.png')"></b-img>
        </div>
        <b-alert variant="danger" :show="error">
          {{ $t('login_error') }}
        </b-alert>
        <b-form-group>
          <b-form-input :placeholder="$t('login_email')" type="email" v-model="email"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input :placeholder="$t('login_password')" type="password" @keyup.enter="login()" v-model="password"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-button block class="float-right" variant="primary" @click="login" v-if="!loading">{{ $t('login_button') }}</b-button>
          <b-spinner v-else></b-spinner>
        </b-form-group>
      </div>
    </b-row>
  </div>
</template>
<script>
import api from '../api'
import {mapState, mapActions} from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      error: false,
      whitelabel: 'fairtix'
    }
  },
  computed: {
    ...mapState(['user'])
  },
  created() {

    let curr_url = window.location.href
    if(curr_url && curr_url.indexOf('baron.') >= 1){
      this.whitelabel = 'baron'
    }

    document.title = this.$t('backend_title_login')
    if (this.user) {
      if(this.user.role === 'customer_support') {
        this.$router.push({name: 'CustomerSupportDashboard'})
      }
      else {
        this.$router.push({name: 'Dashboard'})
      }
    }
  },
  methods: {
    login() {
      this.loading = true
      this.error = false
      api.user().login(this.email, this.password).then(async (token) => {
        this.$store.commit('login', token)
        await this.loadUser()
        if(this.$store.state.user && this.$store.state.user.company.signup_agreed_metadata === null) {
            this.$router.push({name: 'SignupConfirmation'})
            return;
        }
        this.$router.push({name: 'Dashboard'})
      }).catch(() => {
        this.error = true
      }).finally(() => {
        this.loading = false
      })
    },
    ...mapActions(['loadUser'])
  }
}
</script>
<style @scoped lang="scss">
  #login, #login > .row {
    height: 100%;
  }
  #login-wrap {
    background: #193E58;
    padding: 50px;
    legend {
      color:white;
    }
    img {
      max-width: 100%;
    }
  }

#login-wrap {
    margin: auto;
}

@media only screen and (max-width: 700px) {
    #login-wrap {
        width: 90%;
    }
}

@media only screen and (min-width: 701px) {
    #login-wrap {
        width: 450px;
    }
}
</style>
