<template>
  <div v-if="event">
    <div>
      <b-alert v-if="event.isArchived" variant="danger" show class="text-center">
        {{ $t('event_is_archived') }}
      </b-alert>
      <div class="d-flex  flex-wrap">
        <div class="bigDiv px-0 pr-lg-3 pl-lg-0 order-2 order-lg-1 mb-3">
          <div class="content-main-container container-distance">
            <div class="title-container">Marketing</div>
            <div class="content-container">
              <b-row>
                <b-col>
                  <b-form-group>
                    <label class="text-black">{{ $t('event_marketing_gtm_id') }} <b-icon id="gtm-id-tooltip" v-b-tooltip.hover class="ml-2" icon="info-circle-fill"></b-icon></label>
                    <b-form-input v-model="event.gtm_id"></b-form-input>
                    <b-tooltip target="gtm-id-tooltip" triggers="hover">
                      {{ $t('event_marketing_gtm_id_tooltip') }}
                    </b-tooltip>
                  </b-form-group>
                </b-col>
                <b-col>
                </b-col>
              </b-row>
            </div>
          </div>
          <b-button :disabled="event.isArchived || loading" @click="save" variant="save" class="float-right">
            <b-icon v-if="loading" icon="three-dots" animation="cylon" class="mr-2"></b-icon> {{ $t('save') }}
          </b-button>
        </div>
        <div class="SmallDiv marginTop0 px-0 pl-lg-3 pr-lg-0 order-1 order-lg-2 mb-4 mb-lg-1">
          <event-nav v-if="event && event.name" :event="event"></event-nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventNav from "@/components/EventNav.vue";
import api from "@/api";
import moment from "moment/moment";

export default {
  name: "EventMarketing",
  data() {
    return {
      event: null,
      loading: false
    }
  },
  mounted() {
    document.title = this.$t('Marketing')
    if(this.$store.state.user) {
      if(!this.$can('event_marketing_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.event().get(this.$route.params.id).then(event => {
      this.event = {...event,isArchived :( moment(event.ends_at).isBefore(moment()) && !event.continuous) || event.status === "archived"}
    })
  },
  methods: {
    save() {
      this.loading = true;
      api.event().updateMarketing(this.event.id, this.event).then(() => {
        this.$store.commit('success', 'event_marketing_updated')
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  components:{
    EventNav
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('event_marketing_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style scoped>
.container-distance,.marginTop0 >div{
  margin-top: 0!important;
}
</style>