export default class DefaultValuesApi {
    constructor(axios) {
        this.axios = axios
    }

    getByKey(key) {
        return this.axios.get('/default-values/' + key);
    }

    save(key, value) {
        return this.axios.post('/default-values/' + key, {value});
    }

}
