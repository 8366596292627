<template>
  <b-container fluid id="app">
    <b-row no-gutters>
      <b-col>
        <div class="nav-wrap">
          <b-row style="height: 45px;">
            <b-col style="line-height:45px">
            </b-col>
            <b-col style="text-align:center;line-height:45px">
                <b-img height="35px" :src="require('../assets/logo-light.png')"></b-img>
            </b-col>
            <b-col style="line-height:45px">
              <a @click="logout" href="javascript:" style="float: right;width:25px">
                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480h64C177.7 480 192 465.7 192 448S177.7 416 160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64C177.7 96 192 81.67 192 64S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256C0 437 42.98 480 96 480zM504.8 238.5l-144.1-136c-6.975-6.578-17.2-8.375-26-4.594c-8.803 3.797-14.51 12.47-14.51 22.05l-.0918 72l-128-.001c-17.69 0-32.02 14.33-32.02 32v64c0 17.67 14.34 32 32.02 32l128 .001l.0918 71.1c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C514.4 264.4 514.4 247.6 504.8 238.5z"/></svg>
              </a>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col id="content">
        <router-view></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: 'App',
  methods: {
    logout() {
      this.$store.commit('scannerTerminalLogout')
    },
    dismissSuccess() {
      this.$store.commit('success', false)
    },
    ...mapActions(['loadScannerTerminalUser'])
  },
  created() {
    this.loadScannerTerminalUser()
  }
}
</script>

<style lang="scss" scoped>

$shadow-color: #52575D19;
$blue-color: #193E58;
$orange-color: #F0B52D;
$text-color: #4D4D4D;
$element-height: 50px;
$container-distance: 2rem;
$default-border-radius: 6px;
$button-border-radius: 6px;
$element-border-color: #F5F5F5;
$row-hover-color: rgba(240, 181, 45, 0.06);
$label-text-color: #4D4D4D;
$label-text-size: 16px;
$label-font-weight: 400;
$field-border-radius: 4px;
$field-height: 50px;
$field-border-thickness: 2px;
$field-border-color: #EEEEEE;
$field-text-color: #4D4D4D;
$field-text-size: 13px;

p, h1, h2, h3, h4, div, span {
  color: $text-color;
}

#app {
  padding: 0;
}
body {
  background-color: #F5F5F5 !important;;
}
#content {
  height: auto;
  min-height: 90vh;
  padding: 0rem;
  background-color: #F5F5F5
}
ul.pagination{
  justify-content: center;
  margin-top:30px
}
ul.pagination .page-item.active .page-link{
  background-color:$blue-color;
  border-color:$blue-color
}
ul.pagination .page-link{
  color:#808080
}

.vertical-align{
  display:flex;
  align-self: center;
}

.container-distance {
  margin-bottom: $container-distance !important;
}

.btn.btn-save {
  background-color: $blue-color;
  color: #FFF;
  border-radius: $button-border-radius;
  padding: 5px 40px;
  font-weight: bold
}

.btn.btn-new {
  background-color: #193E58;
  border-radius: $button-border-radius;
  color: #FFF;
  padding: 15px 20px;
  font-weight: bold
}

.btn.btn-orange{
  background-color:$orange-color;
  border-radius: $button-border-radius;
  color:#FFF;
  border:0px
}
.btn.btn-orange-border{
  background-color:#FFF;
  padding:5px 20px;
  font-size:13px;
  font-weight:bold;
  border:2px solid $orange-color;
  border-radius: $button-border-radius;
  color:#808080
}

.btn.btn-row-edit {
  background-color: #FFF;
  border-radius: $button-border-radius;
  border: 2px solid #F5F5F5;
  color: #808080;
  padding: 5px 20px;
  font-size: 13px;
  font-weight: bold
}

.btn.btn-row-edit:hover {
  border-color: $orange-color;
  background-color: $orange-color;
  color: #FFF
}

.modal-header {
  background-color: $blue-color;

  .modal-title, .close {
    color: #FFF
  }
}

.button-row-included {
  margin-top: 73px
}

.table th, .table td {
  vertical-align: middle !important;
}

.col-form-label {
  font-size: $label-text-size;
  color: $label-text-color;
  font-weight: $label-font-weight;
}

input.form-control, select.form-control, select.custom-select {
  border-radius: $field-border-radius;
  border: $field-border-thickness solid $field-border-color;
  height: $field-height;
  color: $field-text-color;
  font-size: $field-text-size;
}

textarea.form-control {
  border: $field-border-thickness solid $field-border-color;
  color: $field-text-color;
  font-size: $field-text-size;
}

#dropdown-right  {
  width: 50px;
  background-color: #fff;
}
#dropdown-right .dropdown-toggle {
  background-color: #fff;
}
#dropdown-right ul {
  min-width: 36px;
}
.lang-flag, .selected-lang {
  border-radius: 50%;
  width: 36px;
}
.lang-flag {
  border: 3px solid #fff;
}
.selected-lang {
  border: 3px solid #193E58;
}
#dropdown-right a {
  padding: 0.25rem 0.9rem;
}

.content-main-container {

  .title-container {
    background-color: $blue-color;
    color: #FFF;
    border-radius: $default-border-radius $default-border-radius 0 0;
    padding: 10.5px 25px;
    font-weight: bold
  }

  .content-container {
    background-color: #FFF;
    padding: 25px;
    border-radius: 0 0 $default-border-radius $default-border-radius
  }

}

.card {
  border: none !important;

  .card-body {
    padding: 0 !important;
  }

  .card-title {
    background-color: $blue-color;
    color: white;
    font-weight: bold;
    padding: 10px;
    font-size: 1rem;
    border-top-right-radius: $default-border-radius;
    border-top-left-radius: $default-border-radius;
  }

  .card-text {
    padding: 10px;
  }

}


table.b-table {

  thead {
    background-color: $blue-color;

    th {
      border-bottom: 0px;
      padding: 10px 0.75rem
    }

    tr th:first-of-type {
      padding-left: 25px
    }

    tr th:first-child {
      border-top-left-radius: $default-border-radius;
    }

    tr th:last-child {
      border-top-right-radius: $default-border-radius;
    }

    tr th div {
      color: #FFF
    }
  }

  tbody {
    tr {
      background-color: #FFF;
      border-bottom: 1px solid $element-border-color;
    }

    tr:hover {
      background-color: $row-hover-color;

      .btn.btn-row-edit {
        background-color: $orange-color;
        color: #FFF;
        border-color: $orange-color
      }
    }

    tr td:first-of-type {
      padding-left: 25px
    }

    tr:last-of-type {
      border-bottom: 0
    }

    tr td:first-child {
      border-bottom-left-radius: $default-border-radius;
    }

    tr td:last-child {
      border-bottom-right-radius: $default-border-radius;
    }
  }

}

#app-top-bar {
  padding: 5px 30px;
  margin-bottom: $container-distance;

  #app-welcome-message {
    font-size: 18px;
  }

  #app-top-bar-right-col {
    display: flex;
    justify-content: flex-end;


    #app-username {
      align-self: center
    }

    #app-user-nav a:focus, #app-user-nav li:focus {
      border: 0
    }
  }
}

.default-container-layout {
  background-color: #FFF !important;
  border-radius: 6px !important;
  box-shadow: 0px 3px 6px $shadow-color !important;
}
.nav-wrap {
  background: #193E58;
  padding: 0 $container-distance;
}
#nav-wrap {
  background: #193E58;

  .nav-item {
    display: block;
    width: 100%;
    border-bottom: 1px solid #567184
  }

  .nav-link {
    color: white;
    display: block;
    width: 100%;
    height: 70px;
    line-height: 70px;
    padding: 0 20px
  }

  .nav-link img {
    margin-top: -5px;
    margin-right: 15px
  }

  .active {
    background: white;
    color: #193E58;
  }
}

$theme-colors: (
    "primary": #F0B52D
);
// Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap';
// BootstrapVue and its default variables
@import '../../node_modules/bootstrap-vue/src/index.scss';

* {
  font-family: Roboto, sans-serif;
}


@media only screen and (max-width: 600px) {
  #nav-wrap {
    position: absolute;
    width: 230px;
    height: 100vh
  }
}


</style>
