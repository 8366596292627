export default class companyLinksApi {

    constructor(axios) {
        this.axios = axios
    }
    overview(page) {
        return this.axios.get('/company-links/overview?page=' + page)
    }
    create(companyLink) {
        return this.axios.post('/company-link', companyLink)
    }
    save(companyLink) {
        return this.axios.post('/company-link/' + companyLink.id, companyLink)
    }
    delete(companyLink) {
        return this.axios.delete('/company-link/' + companyLink)
    }
}
