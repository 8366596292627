<template>
  <b-container fluid>
    <b-row>
      <b-col align-v="center" class="vh-100">
        <router-view></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Static"
}
</script>

<style scoped>

</style>
