<template>
<b-container fluid>
  <b-row class="mt-3">
    <b-col>
      <label class="p-1 pl-3 default-container-layout">{{ $t('box_office_choose_event_title') }}</label>
    </b-col>
  </b-row>

  <b-row class="mt-4" v-if="events.length">
    <b-col class="text-center mb-3" sm="12" md="6" v-for="event in events" :key="event.id">
      <b-button class="pt-4 pb-4 default-container-layout"
                  :to="{name: 'BoxOfficeEventBoxOffices', params:{event: event.id}}">
          {{ event.name }}
        </b-button>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import api from '../api/boxOfficeApi'
export default {
  name: "BoxOfficeEvents",
  data() {
    return {
      events: []
    }
  },
  mounted() {
    api.event().all().then(response => {
      this.events = response;
    })
  }
}
</script>

<style scoped>
.default-container-layout {
  width: 100%;
  color: #808080;
  font-weight: bold;
  font-size: 18px;
  border: none;
  background-color:#FFF;
  border:0px
}
</style>
