<template>
  <b-container v-if="company">
    <section class="event-section">
      <div class="container">
        
        <div class="main-wrapper bg-dark mb-5" v-if="company.show_calendar">
          <div class="calender-header">
              <button @click="handleDown()" class="button arrow-left">
              </button>
              <div class="month-name-wrap">
                  <p style="font-weight: 700; text-align: center;margin-bottom:0px">{{ months[SELECTED_MONTH].fullname }} - {{ SELECTED_YEAR }}</p>
              </div>
              <button @click="handleUp()" class="button arrow-right">
              </button>
          </div>
          <div class="calendar-wrap">
              
              <!-- Week Days -->
              <div class="week-days" v-for="w in weekdays" :key="JSON.stringify(w) + Math.random()">
                  <span class="mobile">{{ w.name }}</span>
                  <span class="desktop">{{ w.fullname }}</span>
              </div>
              
              <!-- Previous Month Days -->
              <div class="next-month-day-item" 
                  v-for="i in monthStartingDays" :key="JSON.stringify(i) + Math.random()">
                  <div class="prev-date-text-wrap">
                      <p class="prev-date-text" >   {{ prevMonthTotalDays-(monthStartingDays-i) }}</p>
                  </div>
                  <div class="events-wrapper" v-if="events(i).length>0" >
                      <div :class=" (e.imgURL) ? 'event-wrap with-image' : 'event-wrap'"
                          :style="{
                            backgroundImage: (e.imgURL) ? 'url(\'https://cdn.fairtix.io' + e.imgURL + '\')' : 'none',
                            backgroundColor: (!e.imgUrl) ? '#585c60' : 'transparent',
                            textShadow: '0 0 5px #000'
                          }" 
                          v-for="e in events(i,SELECTED_MONTH>0?SELECTED_MONTH-1:11)" :key="JSON.stringify(e) + Math.random()"
                          >
                          <div v-if="e.day===i && company.calendar_sold_ratio" class="event-status" :style="{backgroundColor: statusColor(e.status)}"></div>
                          <a v-if="e.day===i" :href="`/p/` + e.url + ($route.query.ft_campaign_name ? ('/?ft_campaign_name='+$route.query.ft_campaign_name) : '')" class="event-text" target="_blank">{{ e.text }}</a>
                      </div>
                  </div>
              </div>

              <!-- Current Month Days -->
              <div class="curr-month-day-item" 
                  v-for="i in monthTotalDays" :key="JSON.stringify(i) + Math.random()">
                  <div class="curr-date-text-wrap">
                      <p class="curr-date-text" >{{ i }}</p>
                  </div>
                  
                  <div class="events-wrapper" v-if="events(i,SELECTED_MONTH).length > 0">
                      <div :class=" (e.imgURL) ? 'event-wrap with-image' : 'event-wrap'"
                         v-for="e in events(i,SELECTED_MONTH)" :key="JSON.stringify(e) + Math.random()"
                        :style="{
                            backgroundImage: (e.imgURL) ? 'url(\'https://cdn.fairtix.io' + e.imgURL + '\')' : 'none',
                            backgroundColor: (!e.imgUrl) ? '#585c60' : 'transparent',
                            textShadow: '0 0 5px #000'
                        }" 
                      >
                          <div v-if="e.day===i && company.calendar_sold_ratio" class="event-status" :style="{backgroundColor: statusColor(e.status)}"></div>
                          <a v-if="e.day===i" :href="`/p/` + e.url + ($route.query.ft_campaign_name ? ('/?ft_campaign_name='+$route.query.ft_campaign_name) : '')" class="event-text" target="_blank">
                            {{ e.text }}
                          </a>
                      </div>
                  </div>
              </div> 

              <!-- Next Month Days -->
              <div class="next-month-day-item" :class="(monthStartingDays+monthTotalDays)%7>0?'':'hidden'"
                  v-for="i in 7-(monthStartingDays+monthTotalDays)%7 " :key="JSON.stringify(i) + Math.random()">
                  <div class="prev-date-text-wrap">
                      <p class="prev-date-text" >{{ i }}</p>
                  </div>
                  <div class="events-wrapper" v-if="events(i,SELECTED_MONTH<11?SELECTED_MONTH+1:0).length>0 && i===1" >
                      <div :class=" (e.imgURL) ? 'event-wrap with-image' : 'event-wrap'"
                          :style="{
                            backgroundImage: (e.imgURL) ? 'url(\'https://cdn.fairtix.io' + e.imgURL + '\')' : 'none',
                            backgroundColor: (!e.imgUrl) ? '#585c60' : 'transparent',
                            textShadow: '0 0 5px #000'
                          }" 
                          v-for="e in events(i, SELECTED_MONTH<11?SELECTED_MONTH+1:0)" :key="JSON.stringify(e) + Math.random()"
                          >
                          <div v-if="e.day===i && company.calendar_sold_ratio" class="event-status" :style="{backgroundColor: statusColor(e.status)}"></div>
                          <a v-if="e.day===i" :href="`/p/` + e.url + ($route.query.ft_campaign_name ? ('/?ft_campaign_name='+$route.query.ft_campaign_name) : '')" class="event-text" target="_blank">{{ e.text }}</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>



        <!-- pos -->
        <div class="row">
          <div class="col">
            <pos v-for="pos in pointsOfSale" :key="pos.id" :pos="pos"></pos>
          </div>
        </div>
      </div>
    </section>
  </b-container>
</template>

<script>
import api from '@/api'
import {mapState} from 'vuex'

import pos from "@/components/PosCard";

export default {
  components: {
    pos,
  },

  data() {
    return {
      pointsOfSale: [],
      calendarData: {},
      SELECTED_YEAR: new Date().getFullYear(),
      SELECTED_MONTH: new Date().getMonth() ,
      FIRST_YEAR: 1900,
      FIRST_SKIP_DAYS: 4,
      eventData: {}
    }
  },
  methods:{
    loadEvents(){      
      api.pos().getLive(this.company.id).then(pointsOfSale => {
        if(pointsOfSale.length === 1) {
          document.location = '/p/' + pointsOfSale[0].url + (this.$route.query.ft_campaign_name ? ('/?ft_campaign_name='+this.$route.query.ft_campaign_name) : '')
        }
        this.pointsOfSale = pointsOfSale

        pointsOfSale.forEach((pos) => {
          let tmpDate = new Date(pos.event.starts_at)
          let starts_at = tmpDate.getFullYear() + '-' + (String(tmpDate.getMonth() + 1)).padStart(2, '0') + '-' + String(tmpDate.getDate()).padStart(2, '0')
          tmpDate = new Date(pos.event.ends_at)
          let ends_at = tmpDate.getFullYear() + '-' + (String(tmpDate.getMonth() + 1)).padStart(2, '0') + '-' + String(tmpDate.getDate()).padStart(2, '0')

          if(this.company.show_calendar){
            if(this.eventData[starts_at] === undefined){
              this.eventData[starts_at] = {}
            }
            this.eventData[starts_at][pos.event.id] = {
              starts_at: starts_at,
              ends_at: ends_at,
              image: pos.event.image,
              event_name: pos.event.name,
              sold_status: pos.ratio,
              url: pos.url,
              unique_id: pos.event.id
            }
          }
        });
      })
    },
    getLang() {
      const lang = window.navigator.language.toLowerCase();
      if (lang.includes('de')) {
        return 'de';
      }
      if (lang.includes('nl')) {
        return 'nl';
      }
      if (lang.includes('es')) {
        return 'es';
      }

      return 'en';
    },
    isLeapYear(y){
        if(y%100===0){
            return y%400===0
        }else{
            return y%4===0
        }
    },
    events(day, month) {
      const prevMonthEvents = [];
      const currentMonthEvents = [];
      const nextMonthEvents = [];

      for (let date in this.eventData) {
        let eventDate = new Date(date);
        let eventDay = eventDate.getDate();
        let eventMonth = eventDate.getMonth();
        let eventYear = eventDate.getFullYear();

        if (this.SELECTED_YEAR === eventYear) {
          if (this.SELECTED_MONTH - 1 === eventMonth) {
            for (let eventId in this.eventData[date]) {
              let event = this.eventData[date][eventId];
              let status = getStatus(event.sold_status);

              prevMonthEvents.push({
                day: eventDay,
                status: status,
                imgURL: event.image,
                text: event.event_name,
                url: event.url
              });
            }
          } else if (this.SELECTED_MONTH === eventMonth) {
            for (let eventId in this.eventData[date]) {
              let event = this.eventData[date][eventId];
              let status = getStatus(event.sold_status);

              currentMonthEvents.push({
                day: eventDay,
                status: status,
                imgURL: event.image,
                text: event.event_name,
                url: event.url
              });
            }
          } else if (this.SELECTED_MONTH + 1 === eventMonth) {
            for (let eventId in this.eventData[date]) {
              let event = this.eventData[date][eventId];
              let status = getStatus(event.sold_status);

              nextMonthEvents.push({
                day: eventDay,
                status: status,
                imgURL: event.image,
                text: event.event_name,
                url: event.url
              });
            }
          }
        }
      }

      function getStatus(soldStatus) {
        if (soldStatus < 0.01) {
          return 'not busy';
        } else if (soldStatus < 80) {
          return 'medium';
        } else {
          return 'very busy';
        }
      }

      if (month === this.SELECTED_MONTH) {
        return currentMonthEvents.filter(event => {
          return event.day === day;
        });
      } else if (month === this.SELECTED_MONTH - 1) {
        return prevMonthEvents.filter(event => {
          return event.day === day;
        });
      } else if (month === this.SELECTED_MONTH + 1) {
        return nextMonthEvents.filter(event => {
          return event.day === day;
        });
      } else {
        return [];
      }
    },
    handleDown(){
        if(this.SELECTED_MONTH==0){
            this.SELECTED_YEAR--
        }
        this.SELECTED_MONTH = this.SELECTED_MONTH>0?this.SELECTED_MONTH-1:11
    },
    handleUp(){
        if(this.SELECTED_MONTH==11){
            this.SELECTED_YEAR = this.SELECTED_YEAR+1
        }
        this.SELECTED_MONTH = this.SELECTED_MONTH<11?this.SELECTED_MONTH+1:0
    },
    statusColor(status){
        return status==='not busy'?'#00cf03': status==='medium'?'#ff861c': status==='very busy'?'#ff1e00':''
    }
  },
  computed: {

    months(){
        return [
            { name:'Jan', fullname:this.$t('january'), full: true },
            { name:'Feb', fullname:this.$t('february'), feb: true },
            { name:'Mar', fullname:this.$t('march'), full: true },
            { name:'Apr', fullname:this.$t('april'), full: false },
            { name:'May', fullname:this.$t('nay'), full: true },
            { name:'Jun', fullname:this.$t('june'), full: false },
            { name:'Jul', fullname:this.$t('july'), full: true },
            { name:'Aug', fullname:this.$t('august'), full: true },
            { name:'Sept', fullname:this.$t('september'), full: false},
            { name:'Oct', fullname:this.$t('october'), full: true },
            { name:'Nov', fullname:this.$t('november'), full: false },
            { name:'Dec', fullname:this.$t('december'), full: true },
        ]
    },
    weekdays(){
        return [
            {name:'Mon', fullname:this.$t('monday')},
            {name:'Tue', fullname:this.$t('tuesday')},
            {name:'Wed', fullname:this.$t('wednesday')},
            {name:'Thu', fullname:this.$t('thursday')},
            {name:'Fri', fullname:this.$t('friday')},
            {name:'Sat', fullname:this.$t('saturday')},
            {name:'Sun', fullname:this.$t('sunday')},
        ]
    },
    prevMonthTotalDays(){
        let m = this.months[this.SELECTED_MONTH>0?this.SELECTED_MONTH-1:11]
        if(m.feb){
            return this.isLeapYear(this.SELECTED_YEAR)? 29 : 28
        }
        return m.full? 31 : 30
    },
    nextMonthTotalDays(){
        let m = this.months[this.SELECTED_MONTH<11?this.SELECTED_MONTH+1:0]
        if(m.feb){
            return this.isLeapYear(this.SELECTED_YEAR)? 29 : 28
        }
        return m.full? 31 : 30
    },
    monthTotalDays(){
        let m = this.months[this.SELECTED_MONTH]
        if(m.feb){
            return this.isLeapYear(this.SELECTED_YEAR)? 29 : 28
        }
        return m.full? 31 : 30
    },
    monthStartingDays(){

        let skipDays = this.FIRST_SKIP_DAYS;
        let month = 0;
        for(let year = this.FIRST_YEAR; year<=this.SELECTED_YEAR; year++){
            if(year < this.SELECTED_YEAR){
                for(month=0; month<12; month++){
                    let m = this.months[month>0?month-1:11]
                    let days = -1
                    if(m.feb){
                        days  = this.isLeapYear(year)? 29 : 28
                    }else{
                        days =  m.full? 31 : 30
                    }
                    days += skipDays;
                    skipDays = days%7
                }   
            }else{
                for( month=0; month<=this.SELECTED_MONTH; month++){
                    let m = this.months[month>0?month-1:11]
                    let days = -1
                    if(m.feb){
                        days  = this.isLeapYear(year)? 29 : 28
                    }else{
                        days =  m.full? 31 : 30
                    }
                    days += skipDays;
                    skipDays = days%7
                }
            }
        }
        return skipDays




    },
    nextMonthStartingDays(){

        let skipDays = this.FIRST_SKIP_DAYS;
        let month = 0;
        for(let year = this.FIRST_YEAR; year<=this.SELECTED_YEAR; year++){
            if(year < this.SELECTED_YEAR){
                for(month=0; month<12; month++){
                    let m = this.months[month]
                    let days = -1
                    if(m.feb){
                        days  = this.isLeapYear(year)? 29 : 28
                    }else{
                        days =  m.full? 31 : 30
                    }
                    days += skipDays;
                    skipDays = days%7
                }   
            }else{
                for( month=0; month<=this.SELECTED_MONTH; month++){
                    let m = this.months[month]
                    let days = -1
                    if(m.feb){
                        days  = this.isLeapYear(year)? 29 : 28
                    }else{
                        days =  m.full? 31 : 30
                    }
                    days += skipDays;
                    skipDays = days%7
                }
            }
        }
        return skipDays




    },

    years(){
        let y = []
        for(let i=0; i<=100; i++){
            y.push(2000+i)
        }
        return y;
    },

    ...mapState(['company'])
  },
  watch: {
    company() {
      this.loadEvents()
    }
  },
  created(){
    this.loadEvents()
    this.$store.commit('setLanguage', this.getLang())
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

// color
$white: #fff;
$black: #303030;
$black2: #4e4e4e;
$red: #9b0000;
$primary: #40cd8a;
$dark: $black;

// Body
$body-bg: #fcfdff;

// Typography
$font-family-sans-serif: "Poppins", sans-serif;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1166px,
);

.container{
  padding:0px
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.without-focus {
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.img-container {
  width: 100%;
  img {
    max-width: 100%;
  }
}

/**
*
* event page
*
*/
.event-section {
  padding-top: 24px;

  .title {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 25px;
  }
}


body{
    background: #000;
}
.main-wrapper{
    padding: 40px 20px;
    border-radius:11px;
    box-shadow: 0px 0px 10px #00000017;
}
.calender-header{
    width: 100%;
    max-width: 1163px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin: 20px auto;
}
.month-name-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1.25rem/* 20px */;
    padding-right: 1.25rem/* 20px */;
    color: rgb(51,51,51);
    width:50%;
}
.button{
    font-weight: 700;
    color: rgb(232,232,232);
    border-radius: 0.125rem;
    padding-left: 1rem/* 16px */;
    padding-right: 1rem/* 16px */;
    font-size: 1.875rem/* 30px */;
    line-height:1;
    transition: all .15s;
   background: none;
   border: none;
   cursor: pointer;
}
.button:hover{
    cursor:pointer; 
    color: black;
}
.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
.curr-date-text-wrap,
.prev-date-text-wrap{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.prev-date-text{
    font-size: 16px;
    color: rgb(153,153,153) !important;
    padding-left: 0.75rem/* 12px */;
    padding-right: 0.75rem/* 12px */;
    padding-top: 0.25rem/* 4px */;
    padding-bottom: 0.25rem/* 4px */;
    transition: all .15s;
    border-radius: 3px;
}
.curr-date-text:hover,
.prev-date-text:hover{
    background: rgba(204,51,102,0.886);
    color: rgb(232,232,232) !important;
    transition: all .15s;
}
.curr-date-text{
    font-size: 16px;
    color: rgb(51,51,51);
    padding-left: 0.75rem/* 12px */;
    padding-right: 0.75rem/* 12px */;
    padding-top: 0.25rem/* 4px */;
    padding-bottom: 0.25rem/* 4px */;
    transition: all .15s;
    border-radius: 3px;
}


.events-wrapper{
    width: 100%;
    min-height: 32px;
    display: grid;
    grid-template-columns: 1;
    grid-gap: 4px;
}
.event-wrap{
    width: 100%;
    height: 32px;
    background-position: right;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 12px;
    border-radius: 6px;
}
.event-wrap.with-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
}
.event-status{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    right: 4px;
    top: -10px;
}
.event-text{
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    cursor:pointer;
    max-width:152px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    padding:0 8px;
    z-index:299
}

.next-month-day-item{
    width: 100%;
    min-height: 40px;
    color: rgb(232,232,232);   
}
.next-month-day-item{
    width: 100%;
    min-height: 40px;
    color: rgb(51,51,51);   
}
.calendar-wrap{
    width: 100%;
    max-width: 1163px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-gap: 8px;
}
.mobile{
    text-transform: uppercase;
    display: none;
}
.desktop{
    display: block;
}

@media only screen and (max-width: 1230px) {
  .event-text{
    width:110px
  }
}

@media only screen and (max-width: 990px) {
  .event-text{
    width:90px
  }
}

@media only screen and (max-width: 840px) {
    .mobile{
        display: block;
    }
    .desktop{
        display: none;
    }
    .calendar-wrap{
        grid-gap: 4px;
    }
    .month-name-wrap{
      width:70%
    }
}

@media only screen and (max-width: 768px) {
  .event-text{
    width:65px
  }
}

@media only screen and (max-width: 600px) {
  p.curr-date-text, p.prev-date-text{
    margin-bottom:0px
  }
  .event-wrap{
    margin-top:0;
    margin-bottom:8px
  }
}

@media only screen and (max-width: 580px) {
  .event-text{
    width:45px
  }
}

.week-days{
    text-align: right;  
    padding: 20px 0;
    font-weight: 900;
    font-size: 12px;
    color: rgb(55,66,74);
}


.arrow-left {
  position: relative;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid #FFF; /* Adjust color as needed */
}

.arrow-right {
  position: relative;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #FFF; /* Adjust color as needed */
}

</style>
