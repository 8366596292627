<template>
  <b-container fluid id="app" :class="whitelabel_layout">
    <b-row no-gutters>
      <b-col id="nav-wrap" v-if="user" class="col-auto" >
        <sidebar-menu @item-click="onItemClick" :disableHover="true" :collapsed="innerWidth  > 700  ? false:true" :menu="menu">
           <div slot="toggle-icon" id="btn-toggle">
             <div class="innerSpans">
                <b-img class="img-burger-white" :src="require('./../assets/icons/hamburger-white.png')"/>
                <b-img class="img-burger-blue" :src="require('./../assets/icons/hamburger.png')"/>
             </div>
           </div>
        </sidebar-menu>
      </b-col>
      <b-col id="content" >
        <b-alert v-if="user && impersonating" variant="warning" show class="text-center">
          {{ $t('app_impersonating', user) }}
        </b-alert>
        <b-container fluid id="app-top-bar" class="default-container-layout">
          <b-row align-v="center ">
            <b-col cols="12" md="12"  lg="4">
              <span id="app-welcome-message">{{ $t('content_top_welcome_message', this) }}</span>
            </b-col>
            <b-col cols="8" md="6"  lg="6" class="text-right" id="app-top-bar-right-col">
              <span id="app-username" align-v="center" v-if="user">{{ user.name }}</span>
              <b-img class="ml-2" fluid :src="require('./../assets/icons/icon-logout-blue.png')" @click="logout()"/>
            </b-col>
            <b-col cols="4" md="6" lg="2" class="text-right">

              <b-dropdown id="dropdown-right"  variant="primary" :text="languageLabel"  class="m-md-2">
                <template v-slot:button-content>
                  <b-img class="selected-lang" :src="require('./../assets/icons/'+language+'.png')"/>
                </template>
                <b-dropdown-item @click="changeLanguage('nl')"><b-img class="lang-flag" :src="require('./../assets/icons/nl.png')"/></b-dropdown-item>
                <b-dropdown-item @click="changeLanguage('de')"><b-img class="lang-flag" :src="require('./../assets/icons/de.png')"/></b-dropdown-item>
                <b-dropdown-item @click="changeLanguage('en')"><b-img class="lang-flag" :src="require('./../assets/icons/en.png')"/></b-dropdown-item>
                <b-dropdown-item @click="changeLanguage('es')"><b-img class="lang-flag" :src="require('./../assets/icons/es.png')"/></b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-container>
        <router-view></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
  name: 'App',
  data(){
    return{
      innerWidth : window.innerWidth,
      whitelabel_layout: 'fairtix',
      whitelabel_layout_name: 'Fairtix'
    }
  },
  methods: {
    onItemClick(){
      const btnToggle = document.getElementById("btn-toggle");
      if (btnToggle != null && window.innerWidth < 700) {
        btnToggle.click();
      }
    },
    logout() {
      this.$store.commit('logout')
    },
    dismissSuccess() {
      this.$store.commit('success', false)
    },
    changeLanguage(lang) {
      this.$store.commit('setLanguage', lang)
    },
    ...mapActions(['loadUser'])
  },
  watch: {
    user(user){
      if(user){
        this.whitelabel_layout = user.company.whitelabel_layout
        if(user.company.whitelabel_layout == 'baron'){
          this.whitelabel_layout_name = 'BARON Fairtix'
        }
      }
    }
  },
  computed: {
    languageLabel(){
      return this.$t('language_' + this.language)
    },
    menu() {
      let menu = [];
      menu.push({
        title: '',
        icon: {element: 'span', class: 'logo'},
        href: {name: 'Dashboard'}
      })
      menu.push({
        title: this.$t('nav_dashboard'),
        icon: {element: 'span', class: 'dashboard'},
        href: {name: 'Dashboard'},
      })
      if(this.$can('order_overview_view')) {
        menu.push({
          title: this.$t('nav_orders'),
          icon: {element: 'span', class: 'orders'},
          href: {name: 'OrderOverview'}
        })
      }
      if(this.$can('add_attendees_view')) {
        menu.push({
          title: this.$t('add_attendees'),
          icon: {element: 'span', class: 'users'},
          href: {name: 'GuestListOverview'}
        })
      }
      if(this.$can('event_overview_view')) {
        menu.push({
          title: this.$t('nav_events'),
          icon: {element: 'span', class: 'events'},
          href: {name: 'Events'}
        })
      }
      if(this.$can('reports_view')) {
        menu.push({
          title: this.$t('nav_reports'),
          icon: {element: 'span', class: 'reports'},
          href: {name: 'ReportsOverview'}
        })
      }
      if(this.$can('discountcode_view')) {
        menu.push({
          title: this.$t('nav_discountcodes'),
          icon: {element: 'span', class: 'discountcodes'},
          href: {name: 'Discountcodes'}
        })
      }
      if(this.$can('scan_operation_view')) {
        menu.push({
          title: this.$t('nav_scan_operations'),
          icon: {element: 'span', class: 'scanners'},
          href: {name: 'ScanOperationOverview'}
        })
      }
      if(this.$can('ticket_template_view')) {
        menu.push({
          title: this.$t('nav_ticket_templates'),
          icon: {element: 'span', class: 'layouts'},
          href: {name: 'TicketTemplates'}
        })
      }
      if(this.$can('campaigns_view')) {
        menu.push({
          title: this.$t('nav_campaigns'),
          icon: {element: 'span', class: 'campaigns'},
          href: {
            name: 'CampaignsOverview'
          }
        })
      }
      if(this.$can('customer_support_view')) {
        menu.push({
          title: this.$t('customer_support_center'),
          icon: {element: 'span', class: 'orders'},
          href: {name: 'CustomerSupportOverview'}
        })
      }
      if(this.$can('batch_overview_view')) {
        menu.push({
          title: this.$t('nav_batches'),
          icon: {element: 'span', class: 'batches'},
          href: {
            name: 'BatchesOverview'
          }
        })
      }
      if(this.$can('admin_company_overview_view')) {
        menu.push({
          title: this.$t('nav_companies'),
          icon: {element: 'span', class: 'companies'},
          href: {name: 'Companies'}
        })
      }
      if(this.$can('stock_report_view')) {
        menu.push({
          title: this.$t('nav_stock_report'),
          icon: {element: 'span', class: 'companies'},
          href: {name: 'StockReport'}
        })
      }
      if(this.$can('extensions_overview_view')) {
        menu.push({
          title: this.$t('nav_extensions'),
          icon: {element: 'span', class: 'extensions'},
          href: {name: 'ExtensionsOverview'}
        })
      }
      if(this.$can('my_company_view')) {
        menu.push({
          title: this.$t('nav_company'),
          icon: {element: 'span', class: 'company'},
          href: {
            name: 'ManageCompany',
            params: {id: this.user.company.unique_id}
          }
        })
      }
      if(this.$can('master_data_view')) {
        menu.push({
          title: this.$t('nav_master_data'),
          icon: {element: 'span', class: 'company'},
          href: {
            name: 'MasterDataOverview'
          }
        })
      }
      if(this.$can('admin_administration_view')) {
        menu.push({
          title: this.$t('nav_administration'),
          icon: {element: 'span', class: 'company'},
          href: {
            name: 'AdministrationOverview'
          }
        })
      }
      return menu
    },
    ...mapState(['user', 'impersonating', 'success', 'errors', 'language'])
  },
  created() {
    this.loadUser()
  }
}
</script>

<style lang="scss">

$shadow-color: #52575D19;
$blue-color: #193E58;
$blue-active-color: #295f84;
$orange-color: #F0B52D;
$text-color: #808080;
$element-height: 50px;
$container-distance: 2rem;
$default-border-radius: 6px;
$button-border-radius: 6px;
$element-border-color: #F5F5F5;
$row-hover-color: rgba(240, 181, 45, 0.06);
$label-text-color: #4D4D4D;
$label-text-size: 16px;
$label-font-weight: 400;
$field-border-radius: 4px;
$field-height: 50px;
$field-border-thickness: 2px;
$field-border-color: #EEEEEE;
$field-text-color: #808080;
$field-text-size: 13px;
$placeholder-text-color: #a4a4a4;

p, h1, h2, h3, h4, div, span {
  color: $text-color;
}

#app {
  padding: 0;
}

#content {
  height: auto;
  min-height: 100vh;
  padding: $container-distance;
  background-color: #F5F5F5;
  overflow: hidden;
}

#logo {
  margin: 25px 0 25px 15px
}

ul.pagination{
  justify-content: center;
  margin-top:30px
}
ul.pagination .page-item.active .page-link{
  background-color:$blue-color;
  border-color:$blue-color
}
ul.pagination .page-link{
  color:#808080
}

input::placeholder{
  color: $placeholder-text-color !important;
}

.container-distance {
  margin-bottom: $container-distance !important;
}

.btn.btn-save {
  background-color: $blue-color;
  color: #FFF;
  border-radius: $button-border-radius;
  padding: 5px 40px;
  font-weight: bold
}

.btn.btn-new {
  background-color: #193E58;
  border-radius: $button-border-radius;
  color: #FFF;
  padding: 12px 18px;
  font-weight: bold
}

.btn.btn-orange{
  background-color:$orange-color;
  border-radius: $button-border-radius;
  color:#FFF;
  border:0px
}
.btn.btn-orange-border{
  background-color:#FFF;
  padding:5px 20px;
  font-size:13px;
  font-weight:bold;
  border:2px solid $orange-color;
  border-radius: $button-border-radius;
  color:#808080
}

.btn.btn-row-edit {
  background-color: #FFF;
  border-radius: $button-border-radius;
  border: 2px solid #F5F5F5;
  color: #808080;
  padding: 5px 20px;
  font-size: 13px;
  font-weight: bold
}

.btn.btn-row-edit:hover {
  border-color: $orange-color;
  background-color: $orange-color;
  color: #FFF
}

.modal-header {
  background-color: $blue-color;

  .modal-title, .close {
    color: #FFF
  }
}

.button-row-included {
  margin-top: 73px
}

.table th, .table td {
  vertical-align: middle !important;
}

.col-form-label {
  font-size: $label-text-size;
  color: $label-text-color;
  font-weight: $label-font-weight;
}

input.form-control, select.form-control, select.custom-select {
  border-radius: $field-border-radius;
  border: $field-border-thickness solid $field-border-color;
  height: $field-height;
  color: $field-text-color;
  font-size: $field-text-size;
}

textarea.form-control {
  border: $field-border-thickness solid $field-border-color;
  color: $field-text-color;
  font-size: $field-text-size;
}

// Menu layout
.v-sidebar-menu {
  background-color: $blue-color !important;
  position: inherit !important;
  position: relative;
}
.vsm_expanded .vsm--toggle-btn{
  position: relative !important;
  order: 3;
  width: 100% !important;
  height: 60px !important;
  transition: 0.3s width ease;
  left:0px!important
}
.vsm_expanded .vsm--toggle-btn, .v-sidebar-menu .vsm--toggle-btn{
  background-color: #132939!important;
}
.v-sidebar-menu .vsm--toggle-btn img.img-burger-blue{
 display: none;
}
.vsm_expanded .vsm--toggle-btn img.img-burger-white{
 display: inline;
}
.v-sidebar-menu .vsm--link {
  height: 40px;
  line-height: 40px;
  padding:0 15px 0 25px;
  margin: 5px 20px !important;
}
.v-sidebar-menu .vsm--title {
  color: #FFF
}
.v-sidebar-menu .vsm--icon {
  margin-top: 8px;
}

.v-sidebar-menu .vsm--item{
  margin-bottom:10px
}
.v-sidebar-menu .vsm--item:hover .vsm--title {
  color: #FFFFFF;
}
.v-sidebar-menu .vsm--item:first-of-type {
  margin: 25px 0 ;
  border-bottom: 0px
}
.v-sidebar-menu .vsm--item:first-of-type a {
  padding: 0 10px !important;
  width: 80%;
  margin: auto;
}
.v-sidebar-menu .vsm--item:first-of-type a:hover {
  background-color: $blue-color !important
}
.v-sidebar-menu .vsm--item:first-of-type a span.vsm--icon {
  margin: 0px;
  height: 85%;
  background-color: $blue-color !important
}
.v-sidebar-menu .vsm--icon.logo {
  background-color: $blue-color;
  background: url('../assets/logo-light.png') no-repeat;
  background-size: contain;
  width: 100% !important;
}
div#app.baron .v-sidebar-menu .vsm--icon.logo {
  background-color: $blue-color;
  background: url('../assets/logo-baron-fairtix.png') no-repeat;
  background-size: contain;
  width: 100% !important;
}
.v-sidebar-menu .vsm--item:first-of-type:hover {
  background-color: $blue-color !important
}
.v-sidebar-menu.vsm_collapsed .vsm--link {
  padding: 0 15px !important;
  margin: 0 !important;
}
.v-sidebar-menu .vsm--link:hover, .v-sidebar-menu .vsm--item a.router-link-active {
  background-color: $blue-active-color !important;
  box-shadow: none;
  border-radius: 5px;
}
.v-sidebar-menu .vsm--list .vsm--item:first-child a.router-link-active{
  background-color: unset !important;
}
.v-sidebar-menu .vsm--item a.router-link-active span.vsm--title {
  color: #FFFFFF !important
}
.v-sidebar-menu .vsm--scroll-wrapper {
  margin-right: 0px !important
}
.v-sidebar-menu .vsm--item:first-of-type a.router-link-active {
  box-shadow: none !important;
  background-color: $blue-active-color !important;
}
.v-sidebar-menu.vsm_collapsed .vsm--item:hover {
  background-color: $blue-active-color !important;
  color: #FFFFFF !important;
  border-radius: 10px;
}
.v-sidebar-menu.vsm_collapsed .vsm--item .vsm--icon:hover, .v-sidebar-menu.vsm_collapsed .vsm--item:hover .vsm--icon {
  background-color: unset !important;
}
.v-sidebar-menu span.vsm--icon.dashboard {
  background: url('../assets/icons/svg/dashboard.svg') no-repeat;
  background-position:50% 3px
}
.v-sidebar-menu span.vsm--icon.extensions {
  background: url('../assets/icons/icon-extension.png') no-repeat;
  background-position:50% 1px
}
.v-sidebar-menu span.vsm--icon.orders {
  background: url('../assets/icons/svg/list-details.svg') no-repeat;
  background-position:50% 3px
}
.v-sidebar-menu span.vsm--icon.events {
  background: url('../assets/icons/svg/calendar-month.svg') no-repeat;
  background-position:50% 2px
}
.v-sidebar-menu span.vsm--icon.discountcodes {
  background: url('../assets/icons/svg/percentage.svg') no-repeat;
  background-position:50% 3px
}
.v-sidebar-menu span.vsm--icon.reports {
  background: url('../assets/icons/svg/chart-histogram.svg') no-repeat;
  background-position:50% 2px
}
.v-sidebar-menu span.vsm--icon.layouts {
  background: url('../assets/icons/svg/palette.svg') no-repeat;
  background-position:50% 3px
}
.v-sidebar-menu span.vsm--icon.scanners {
  background: url('../assets/icons/svg/barcode.svg') no-repeat;
  background-position:50% 2px
}
.v-sidebar-menu span.vsm--icon.companies {
  background: url('../assets/icons/icon-settings.png') no-repeat;
  background-position:50% 3px
}
.v-sidebar-menu span.vsm--icon.company {
  background: url('../assets/icons/svg/user-cog.svg') no-repeat;
  background-position:50% -2px
}
.v-sidebar-menu span.vsm--icon.users {
  background: url('../assets/icons/svg/user-group-solid.svg') no-repeat;
  background-position:50% 3px
}
.v-sidebar-menu span.vsm--icon.campaigns {
  background: url('../assets/icons/svg/chart-bar.svg') no-repeat;
  background-position:50% 2px
}
.v-sidebar-menu span.vsm--icon.logout {
  background: url('../assets/icons/icon-logout-blue.png') no-repeat;
  background-position:50% 3px
}
.v-sidebar-menu span.vsm--icon.batches {
  background: url('../assets/icons/svg/scan.svg') no-repeat;
  background-position:50% 1px
}
.v-sidebar-menu.vsm_collapsed{

  span.vsm--icon.dashboard {
    background-position:20% 3px;
  }
  span.vsm--icon.extensions {
    background-position:20% 1px;
  }
  span.vsm--icon.orders {
    background-position:20% 3px;
  }
  span.vsm--icon.events {
    background-position:20% 2px;
  }
  span.vsm--icon.discountcodes {
    background-position:20% 3px;
  }
  span.vsm--icon.reports {
    background-position:20% 2px;
  }
  span.vsm--icon.layouts {
    background-position:20% 3px;
  }
  span.vsm--icon.scanners {
    background-position:20% 2px;
  }
  span.vsm--icon.companies {
    background-position:20% 3px;
  }
  span.vsm--icon.company {
    background-position:20% -2px;
  }
  span.vsm--icon.users {
    background-position:20% 3px;
  }
  span.vsm--icon.campaigns {
    background-position:20% 2px;
  }
  span.vsm--icon.logout {
    background-position:20% 3px;
  }
  span.vsm--icon.batches {
    background-position:20% 1px;
  }

}



.v-sidebar-menu .vsm--link_hover{
  background-color: #193E58!important;
}
#dropdown-right  {
  width: 50px;
  background-color: #fff;
}
#dropdown-right .dropdown-toggle {
  background-color: #fff;
}
#dropdown-right ul {
  min-width: 36px;
}
.lang-flag, .selected-lang {
  border-radius: 50%;
  width: 36px;
}
.lang-flag {
  border: 3px solid #fff;
}
.selected-lang {
  border: 3px solid #193E58;
}
#dropdown-right a {
  padding: 0.25rem 0.9rem;
}

.v-sidebar-menu .vsm--link_exact-active, .v-sidebar-menu .vsm--link_active{
  font-weight:300 !important
}

.content-main-container {

  .title-container {
    background-color: $blue-color;
    color: #FFF;
    border-radius: $default-border-radius $default-border-radius 0 0;
    padding: 10.5px 25px;
    font-weight: bold
  }

  .content-container {
    background-color: #FFF;
    padding: 25px;
    border-radius: 0 0 $default-border-radius $default-border-radius
  }

}

.card {
  border: none !important;

  .card-body {
    padding: 0 !important;
  }

  .card-title {
    background-color: $blue-color;
    color: white;
    font-weight: bold;
    padding: 10px;
    font-size: 1rem;
    border-top-right-radius: $default-border-radius;
    border-top-left-radius: $default-border-radius;
  }

  .card-text {
    padding: 10px;
  }

}


table.b-table {

  thead {
    background-color: $blue-color;

    th {
      border-bottom: 0px;
      padding: 10px 0.75rem
    }

    tr th:first-of-type {
      padding-left: 25px
    }

    tr th:first-child {
      border-top-left-radius: $default-border-radius;
    }

    tr th:last-child {
      border-top-right-radius: $default-border-radius;
    }

    tr th div {
      color: #FFF
    }
  }

  tbody {
    tr {
      background-color: #FFF;
      border-bottom: 1px solid $element-border-color;
    }

    tr:hover {
      background-color: $row-hover-color;

      .btn.btn-row-edit {
        background-color: $orange-color;
        color: #FFF;
        border-color: $orange-color
      }
    }

    tr td:first-of-type {
      padding-left: 25px
    }

    tr:last-of-type {
      border-bottom: 0
    }

    tr td:first-child {
      border-bottom-left-radius: $default-border-radius;
    }

    tr td:last-child {
      border-bottom-right-radius: $default-border-radius;
    }
  }

}

#app-top-bar {
  padding: 5px 30px;
  margin-bottom: $container-distance;

  #app-welcome-message {
    font-size: 18px;
  }

  #app-top-bar-right-col {
    display: flex;
    justify-content: flex-end;


    #app-username {
      align-self: center
    }

    #app-user-nav a:focus, #app-user-nav li:focus {
      border: 0
    }
  }
}

.default-container-layout {
  background-color: #FFF;
  border-radius: 6px;
  box-shadow: 0px 3px 6px $shadow-color;

}

#nav-wrap {
  background: #193E58;

  .nav-item {
    display: block;
    width: 100%;
    border-bottom: 1px solid #567184
  }
  table{
    overflow-x: auto!important;
  }
  .nav-link {
    color: white;
    display: block;
    width: 100%;
    height: 70px;
    line-height: 70px;
    padding: 0 20px
  }

  .nav-link img {
    margin-top: -5px;
    margin-right: 15px
  }

  .active {
    background: white;
    color: #193E58;
  }
}

$theme-colors: (
    "primary": #F0B52D
);
// Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap';
// BootstrapVue and its default variables
@import '../../node_modules/bootstrap-vue/src/index.scss';

* {
  font-family: Roboto, sans-serif;
}


@media (max-width:1000px) {
  .v-sidebar-menu .vsm--item:first-of-type a {
  width: 95%;
}
  table.b-table thead tr th:first-child,table.b-table tbody tr td:first-of-type{
    padding-left: 0.75rem;
  }
  .tableBig .table{
    margin-bottom: 40px;
  }
  .tableBig .table th, .tableBig .table td, .tableBig .table, .tableBig .table thead, .tableBig .table tbody, .tableBig .table tr{
    display: block;
  }
  .tableBig .table th{
    margin-bottom: 5px;
  }
	.tableBig  .table tr {
    border: 1px solid #ccc;
    margin: 5px 0;
    border-radius: 14px;

  }
  table.b-table thead{
    border-radius: 14px;
  }
	.tableBig .table td, .tableBig .table th {
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		white-space: normal;
		text-align:center;
    width: 100% !important;
  }
  .tableBig .table th:last-of-type{
    border-bottom:0px
  }
}



@media (max-width:700px) {
  #nav-wrap{
    position:fixed;
    z-index:100
  }
  #nav-wrap div.v-sidebar-menu.vsm_expanded{
    max-width:250px !important
  }
  #nav-wrap div.v-sidebar-menu.vsm_collapsed div.vsm--scroll-wrapper{
    display:none
  }
  #nav-wrap div.v-sidebar-menu.vsm_collapsed{
    background-color:transparent !important
  }
  .v-sidebar-menu.vsm_collapsed .vsm--toggle-btn{
    margin:10px 0 0 10px
  }
}

@media only screen and (max-width: 992px) {
  #content{
    width: 100%;
  }
  .container-fluid{
    position: relative;
  }
  #app-top-bar #app-top-bar-right-col{
    justify-content: center;
    margin: 10px 0;
  }

  #app-top-bar{text-align:center}
}
</style>
