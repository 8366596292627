<template>
  <div>
    <b-row>
      <b-col cols="12" sm="9">
        <h2 class="title text-left">{{ $t('backend_title_default_company_fees') }}</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="6">
        <b-col cols="12" md="12" class="ml-0 pl-0">
          <b-button v-if="$can('master_data_default_values_fees_edit')" variant="new" @click="showAddPaymentCostsModal">{{ $t('admin_company_add_payment_method') }}</b-button>
        </b-col>

        <span>{{ $t('manage_company_without_vat') }}</span>

        <div class="tableBig" >
          <b-table class="table-container" :items="paymentMethodList" :fields="[{key: 'payment_method', label: $t('admin_company_payment_method')},{key: 'payment_method_fees.fee_percentage', label: $t('admin_company_payment_method_fee_percentage')},{key: 'payment_method_fees.fee_fixed_amount', label: $t('admin_company_payment_method_fee_fixed')},{key: 'country', label: $t('company_country')},{key: 'actions', label: $t('order_actions')}]">
            <template v-slot:cell(payment_method)="{item}">
              {{ payment_methods.find(pm => pm.id === item.payment_method).name }}
            </template>
            <template v-slot:cell(payment_method_fees.fee_percentage)="{item}">
              {{ item.fee_percentage }}%
            </template>
            <template v-slot:cell(payment_method_fees.fee_fixed_amount)="{item}">
              {{ item.fee_fixed_amount | currency }}
            </template>
            <template v-slot:cell(actions)="{item}">
              <b-button variant="primary" @click="showEditPaymentFeeModal(item)" class="mr-2">{{ $t('edit') }}</b-button>
            </template>
          </b-table>
        </div>
      </b-col>

      <b-col sm="12" md="6">
        <b-button v-if="$can('master_data_default_values_fees_edit')" variant="new" @click="showAddCompanyFeeModal" class="mb-4">{{ $t('admin_company_add_company_fee') }}</b-button>
        <div class="tableBig">
          <b-table class="table-container" :items="fees" :fields="[{key: 'fee_type', label: $t('admin_company_fee_type')},{key: 'fee', label: $t('admin_company_fee')},{key: 'country', label: $t('company_country')},{key: 'actions', label: $t('order_actions')}]">
            <template v-slot:cell(country)="{item}">
                {{ item.country }}
            </template>
            <template v-slot:cell(fee)="{item}">
              <span v-if="item.fee_type === 'free-tickets-free-percentage'">{{ item.fee + '%' }}</span>
              <span v-if="item.fee_type !== 'free-tickets-free-percentage'">{{ item.fee | currency }}</span>
            </template>
            <template v-slot:cell(actions)="{item}">
              <b-button variant="primary" @click="showEditCompanyFeeModal(item)" class="mr-2">{{ $t('edit') }}</b-button>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>

    <b-modal id="add-company-payment-method" @close="paymentFee = null" :title="$t('admin_company_add_payment_method', company)" size="lg">

      <div v-if="paymentFee">
        <b-form-group :label="$t('admin_company_payment_method')">
          <b-select v-model="paymentFee.payment_method" :options="payment_methods" value-field="id" text-field="name"></b-select>
        </b-form-group>
        {{ $t('admin_company_payment_method_fee_percentage') }}
        <b-input-group style="height:45px">
          <template #prepend>
            <b-input-group-text  style="height:45px">%</b-input-group-text>
          </template>
          <b-form-input  style="height:45px"
                         type="number"
                         v-model="paymentFee.fee_percentage"
                         :step="0.01"
                         :min="0"
          ></b-form-input>
        </b-input-group >
        <br>
        {{ $t('admin_company_payment_method_fee_fixed') }} ( {{ $t('manage_company_without_vat') }} )
        <b-input-group style="height:45px">
          <template #prepend>
            <b-input-group-text  style="height:45px">{{ $t('currency') }}</b-input-group-text>
          </template>
          <b-form-input  style="height:45px"
                         type="number"
                         v-model="paymentFee.fee_fixed_amount"
                         :step="0.01"
                         :min="0"
          ></b-form-input>
        </b-input-group >
        <br>
        <b-form-group :label="$t('company_country')">
          <b-select v-model="paymentFee.country" text-field="name" value-field="code" :options="countries"></b-select>
        </b-form-group>

      </div>
      <template #modal-footer>
        <b-button @click="addPaymentMethodFees()">{{ $t('add') }}</b-button>
      </template>
    </b-modal>

    <b-modal id="add-company-fee-modal" @close="companyFee = null" :title="$t('admin_company_add_company_fee')">

      <div v-if="companyFee">
        <b-form-group :label="$t('admin_company_fee_type')">
          <b-select v-model="companyFee.fee_type" :options="companyFees"></b-select>
        </b-form-group>

        {{ $t('admin_company_fee') }}
        <b-input-group style="height:45px">
          <template #prepend>
            <b-input-group-text style="height:45px">{{ companyFee.fee_type === 'free-tickets-free-percentage' ? '%' : $t('currency') }}</b-input-group-text>
          </template>
          <b-form-input  style="height:45px"
                         type="number"
                         v-model="companyFee.fee"
                         :step="0.01"
                         :min="0"
          ></b-form-input>
        </b-input-group >
        <br>
        <b-form-group :label="$t('company_country')">
          <b-select v-model="companyFee.country" text-field="name" value-field="code" :options="countries"></b-select>
        </b-form-group>
      </div>

      <template #modal-footer>
        <b-button :disabled="loading || !companyFee || !companyFee.fee_type || !companyFee.fee" @click="saveCompanyFee">
          <b-icon v-if="loading" icon="three-dots" animation="cylon" class="mr-2"></b-icon> {{ (companyFee && companyFee.isEdit) ? $t('update') : $t('add') }}
        </b-button>
      </template>
    </b-modal>
    <b-button v-if="$can('master_data_default_values_fees_edit')" @click="save()" :disabled="loading">
      <b-icon v-if="loading" icon="three-dots" animation="cylon" class="mr-2"></b-icon> {{ $t('save') }}
    </b-button>
  </div>
</template>

<script>
import api from "@/api";
import {mapState} from "vuex";

export default {
  name: "DefaultCompanyFees",
  data() {
    return {
      loading: false,
      company: {},
      fees: [],
      payment_methods:[],
      paymentMethodList: [],
      companyFee:null,
      paymentFee:null,
      companyFees: [
        {value: 'ticket-fee', text: this.$t('admin_company_company_fee_ticket_fee')},
        {value: 'box-office-ticket-fee', text: this.$t('admin_company_company_fee_box_office_ticket_fee')},
        {value: 'refund-fee', text: this.$t('admin_company_company_fee_refund_fee')},
        {value: 'free-tickets-free-percentage', text: this.$t('admin_company_company_fee_free_tickets_free_percentage')},
        {value: 'free-ticketfee', text: this.$t('admin_company_company_fee_free_ticketfee')},
      ],
      countries: []
    }
  },
  methods:{
    showAddPaymentCostsModal() {
      this.paymentFee = {
        payment_method: null,
        fee_percentage: null,
        fee_fixed_amount: null
      };
      this.$bvModal.show('add-company-payment-method')
    },
    showEditPaymentFeeModal(item) {
      item.isEdit = true;
      this.paymentFee = item;
      this.$bvModal.show('add-company-payment-method')
    },
    showAddCompanyFeeModal() {
      this.companyFee = {
        fee_type: null,
        fee: null,
        country: null,
      };
      this.$bvModal.show('add-company-fee-modal')
    },
    showEditCompanyFeeModal(item) {
      item.isEdit = true;
      this.companyFee = item;
      this.$bvModal.show('add-company-fee-modal')
    },
    saveCompanyFee() {
      if(!this.companyFee.isEdit) {
        this.fees.push(this.companyFee);
      }
      this.companyFee = null;
      this.$bvModal.hide('add-company-fee-modal')
    },
    addPaymentMethodFees() {
      if(!this.paymentFee.isEdit) {
        this.paymentMethodList.push(this.paymentFee);
      }
      this.$bvModal.hide('add-company-payment-method')
      this.paymentFee = null;
    },
    save() {
      this.loading = true;
      api.defaultValues().save('default-fees', {
        fees: this.fees,
        payment_methods: this.paymentMethodList
      }).then(() => {
        this.loading = false;
        this.$store.commit('success', this.$t('admin_company_company_fee_updated'));
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  created() {
    document.title = this.$t('nav_master_data')
    if(this.$store.state.user) {
      if(!this.$can('master_data_default_values_fees_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    Promise.all([api.company().getAllPaymentMethods(), api.defaultValues().getByKey('default-fees')])
        .then(async ([paymentMethods, values]) => {
          this.payment_methods = paymentMethods;
          const data = values.value;
          this.paymentMethodList = data && data.payment_methods ? data.payment_methods : []
          this.fees = data && data.fees ? data.fees : []
        })
    api.company().countries().then(countries => {
      this.countries = [{code: null, name: this.$t('company_country'), disabled: true}].concat(countries)
    })
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('master_data_default_values_fees_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
