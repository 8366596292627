<template>
  <div v-if="event">
    <div>
      <b-alert v-if="event.isArchived" variant="danger" show class="text-center">
        {{ $t('event_is_archived') }}
      </b-alert>
      <div class="d-flex  flex-wrap">
        <div class="bigDiv px-0 pr-lg-3 pl-lg-0 order-2 order-lg-1 mb-3">
          <h3 v-text="$t('event_timeslot_title', event)" class=" mb-3"></h3>
          <b-row>
            <b-col v-if="event.continuous">
              <b-table :items="openingHoursParsed" :fields="fields">
                <template v-slot:cell(actions)="{item}">
                  <b-button variant="danger" size="sm" @click="remove(item)">{{ $t('delete') }}</b-button>
                </template>
                <template v-slot:cell(spots)="{item}">
                  {{ item.spots }}
                  <span v-if="item.interval === 'hourly'">{{ $t('opening_hours_hourly') }}</span>
                  <span v-else>{{ $t('opening_hours_all_day') }}</span>
                </template>
              </b-table>

              <b-modal :title="$t('opening_hours_create')" id="new-opening-hour" size="lg">
                <b-form-group :label="$t('opening_hours_day_of_week')">
                  <b-form-select
                      v-model="openingHour.day_of_week"
                      :options="daysOfWeek"
                      value-field="id"
                  ></b-form-select>
                </b-form-group>
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('opening_hours_opens_at')">
                      <b-form-input type="time" v-model="openingHour.opens_at"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('opening_hours_closes_at')">
                      <b-form-input type="time" v-model="openingHour.closes_at"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('opening_hours_spots')">
                      <b-form-input v-model="openingHour.spots"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('opening_hour_interval')">
                      <b-form-select
                          v-model="openingHour.interval"
                          :options="intervals"
                          value-field="id"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <template #modal-footer="{}">
                  <b-button @click="cancelCreateOpeningHour()" variant="warning">{{ $t('cancel') }}</b-button>
                  <b-button @click="createOpeningHour()" variant="success">{{ $t('create') }}</b-button>
                </template>
              </b-modal>

              <b-button size="sm" @click="$bvModal.show('new-opening-hour')">
                {{ $t('opening_hour_create') }}
              </b-button>
            </b-col>
            <b-col>
              <b-calendar block
                          :start-weekday="1"
                          :hide-header="true"
                          :date-info-fn="dateInfo"
                          :date-disabled-fn="dateDisabled"
                          @context="getNewHours"
                          v-model="pickedDate"
                          @selected="calendarClicked"
              ></b-calendar>
            </b-col>
          </b-row>
          <b-modal :title="dayModalTitle" id="day-timeslots" size="lg">
            <b-row v-for="(timeslot, i) in dayTimeslots" :key="'ts' + i" class="mb-3 mb-sm-2">
              <b-col  cols="12" lg="4" class="align-middle">{{ timeslot.day + ' ' + timeslot.opens_at | time_format }} - {{ timeslot.day + ' ' + timeslot.closes_at | time_format }}</b-col>
              <b-col cols="10" lg="7"><b-form-input v-model="timeslot.spots"></b-form-input></b-col>
              <b-col cols="2" lg="1" class="align-middle pl-0 pl-sm-2 pl-md-1">
                <b-button v-if="timeslot.timeslot_id" variant="danger" @click="deleteTimeslot(timeslot)">x</b-button>
              </b-col>
            </b-row>
          
            <hr>

            <b-row>
              <b-col cols="12" lg="4">
                <b-form-group :label="$t('opening_hours_opens_at')">
                  <b-form-input type="time" v-model="timeslot.opens_at"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="4">
                <b-form-group :label="$t('opening_hours_closes_at')">
                  <b-form-input type="time" v-model="timeslot.closes_at"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="4">
                <b-form-group :label="$t('opening_hours_spots')">
                  <b-form-input v-model="timeslot.spots"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-button @click="addTimeslot()" class="px-4">{{ $t('add') }}</b-button>

            <template #modal-footer>
              <b-button @click="$bvModal.hide('day-timeslots')">{{ $t('cancel') }}</b-button>
              <b-button variant="success" @click="adjust()">{{ $t('opening_hours_adjust') }}</b-button>
            </template>
          </b-modal>
        </div>
        <div class="SmallDiv px-0 pl-lg-3 pr-lg-0 order-1 mt-0 mt-md-1 order-lg-2 mb-4 mb-lg-1 button-row-included ">
          <event-nav v-if="event && event.name" :event="event"></event-nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventNav from "@/components/EventNav";
import api from "@/api";
import moment from "moment";

export default {
  components:{
    EventNav
  },
  data(){
    return {
      event: null,
      intervals: [
        {id: 'none', text: 'Daily / whole slot'},
        {id: 'hourly', text: 'Hourly'}
      ],
      daysOfWeek: [
        {id: 1, text: 'Mon'},
        {id: 2, text: 'Tue'},
        {id: 3, text: 'Wed'},
        {id: 4, text: 'Thu'},
        {id: 5, text: 'Fri'},
        {id: 6, text: 'Sat'},
        {id: 7, text: 'Sun'}
      ],
      openingHours: [],
      openingHour: {},
      generatedOpeningHours: [],
      pickedDate: null,
      dayTimeslots: [],
      timeslot: {},
      fields: [
        {key: 'day_text', label: this.$t('opening_hours_day_of_week')},
        {key: 'spots', label: this.$t('opening_hours_spots')},
        {key: 'opens_at', label: this.$t('opening_hours_opens_at')},
        {key: 'closes_at', label: this.$t('opening_hours_closes_at')},
        {key: 'actions', label: this.$t('event_timeslots_actions')}
      ]
    }
  },
  methods: {
    adjust() {
      let promises = []

      this.dayTimeslots.forEach(dayTimeslot => {
        let original = this.generatedOpeningHours.find(openingHour => {
          return openingHour.day === this.pickedDate
              && openingHour.opens_at === dayTimeslot.opens_at
              && openingHour.closes_at === dayTimeslot.closes_at
        })

        if (original.spots !== dayTimeslot.spots) {

          let starts_at = this.pickedDate + ' ' + dayTimeslot.opens_at;
          let ends_at = this.pickedDate + ' ' + dayTimeslot.closes_at;
          
          // Convert times to UTC+0 times
          let tmp_starts_at = moment(moment(starts_at).format()).utc()
          starts_at = tmp_starts_at.format('YYYY-MM-DD HH:mm:ss')

          let tmp_ends_at = moment(moment(ends_at).format()).utc()
          ends_at = tmp_ends_at.format('YYYY-MM-DD HH:mm:ss')

          if (dayTimeslot.timeslot_id) {
            // Update an existing timeslot
            promises.push(api.timeslot().save(this.event.id, {
              id: dayTimeslot.timeslot_id,
              starts_at: starts_at,
              ends_at: ends_at,
              amount: dayTimeslot.spots
            }))
          } else {
            // Create a new timeslot for the customisation
            promises.push(api.timeslot().create(this.event.id, {
              starts_at: starts_at,
              ends_at: ends_at,
              amount: dayTimeslot.spots
            }))
          }
        }
      })

      // Reset the input fields
      this.timeslot = {}
      this.get()

      if (promises.length === 0) {
        // no changes
        this.$bvModal.hide('day-timeslots')
        return
      }

      Promise.all(promises).then(() => {
        this.$bvModal.hide('day-timeslots')
        this.$store.commit('success', 'day_adjusted')
      })
    },
    dateDisabled(ymd) {
      if(this.event.continuous){return false}
      let tm_event_start = new Date(this.event.starts_at.split('T')[0]).getTime()
      let tm_event_end = new Date(this.event.ends_at.split('T')[0]).getTime()
      let tm_curr_date = new Date(ymd).getTime()
      if( tm_event_start <= tm_curr_date && tm_event_end >= tm_curr_date ){
        return false
      }
      return true
    },
    calendarClicked(ymd) {
      this.dayTimeslots = this.generatedOpeningHours.filter(openingHour => {
        return openingHour.day === ymd
      })
      this.$bvModal.show('day-timeslots')
    },
    dateInfo(ymd) {
      let open = this.generatedOpeningHours.filter(openingHour => {
        return openingHour.day === ymd
      }).length > 0

      return open ? 'table-success' : ''
    },
    cancelCreateOpeningHour() {
      this.openingHour = {}
      this.$bvModal.hide('new-opening-hour')
    },
    createOpeningHour() {
      api.openingHour().create(this.event.id, this.openingHour).then(() => {
        this.$bvModal.hide('new-opening-hour')
        this.openingHour = {}
        this.get()
      })
    },
    getNewHours(context) {
      let start = moment(context.activeYMD).startOf('month').format('YYYY-MM-DD')
      let end = moment(context.activeYMD).endOf('month').format('YYYY-MM-DD')
      api.openingHour().getGenerated(
          this.event.id,
          start,
          end
      ).then(generated => {
        this.generatedOpeningHours = generated
      })
    },
    remove(openingHour) {
      api.openingHour().delete(this.event.id, openingHour).then(() => {
        this.get()
      })
    },
    get() {
      api.openingHour().get(this.event.id).then(openingHours => {
        this.openingHours = openingHours
      })
      api.openingHour().getGenerated(
          this.event.id,
          moment().startOf('month').format('YYYY-MM-DD'),
          moment().endOf('month').format('YYYY-MM-DD')
      ).then(generated => {
        this.generatedOpeningHours = generated
        if (this.pickedDate) {
          this.dayTimeslots = this.generatedOpeningHours.filter(openingHour => {
            return openingHour.day === this.pickedDate
          })
        }
      })
    },
    addTimeslot() {
      api.timeslot().create(this.event.id, {
        starts_at: moment(new Date(this.pickedDate + ' ' + this.timeslot.opens_at)),
        ends_at: moment(new Date(this.pickedDate + ' ' + this.timeslot.closes_at)),
        amount: this.timeslot.spots
      }).then(() => {
        this.get()
        this.timeslot = {}
      })
    },
    deleteTimeslot(timeslot){
      if(timeslot.timeslot_id){
        api.timeslot().delete(this.event.id, timeslot.timeslot_id).then(() => {
          this.get()
        })
      }
    }
  },
  computed: {
    dayModalTitle() {
      return this.$t('edit_opening_hours_day') + ' ' + this.pickedDate
    },
    openingHoursParsed() {
      let hours = []
      this.daysOfWeek.forEach((dayOfWeek) => {
        hours = hours.concat(this.openingHours.filter(openingHour => {
          return openingHour.day_of_week === dayOfWeek.id
        }).map((openingHour) => {
          openingHour.day_text = dayOfWeek.text
          return openingHour
        }))
      })
      return hours
    }
  },
  created() {
    document.title = this.$t('backend_title_events_timeslots')
    if(this.$store.state.user) {
      if(!this.$can('event_timeslot_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.event().get(this.$route.params.id).then(event => {
      this.event = {...event,isArchived :( moment(event.ends_at).isBefore(moment()) && !event.continuous) || event.status === "archived"}
    }).then(() => {
      this.get()
    })
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('event_timeslot_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.align-middle{
  align-self: center
}
</style>

