<template>
  <div>

    <b-row>
      <b-col>
        <label class="d-inline-block">{{ $t('event_show_archived') }}</label>
        <b-form-checkbox class="d-inline-block ml-3" switch v-model="show_archived" :value="true" :unchecked-value="false" @change="getEvents()"></b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-group v-if="$can('event_create')" class="text-right container-distance">
          <b-button variant="new" v-b-modal.add-event>{{ $t('new') }}</b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <b-modal id="add-event" :title="$t('event_new_modal_title')" size="lg" hide-footer>
      <b-form-group :label="$t('event_name')">
        <b-form-input v-model="event.name" :state="event.name && (event.name.length < 4 || event.name.length > 64) ? false : null" :placeholder="$t('event_name') + '*'" aria-describedby="event-input-feedback"></b-form-input>
        <b-form-invalid-feedback class="mt-2" id="input-live-feedback">
          {{ $t('event_name_64_char') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('date_and_time')">
        <b-row>
          <b-col class="col-md-6 col-12">
            <date-time-picker v-model="event.starts_at" :date-placeholder="$t('start_date')" time-placeholder="00:00"/>
          </b-col>
          <b-col class="col-md-6 col-12">
            <date-time-picker v-model="event.ends_at" :date-placeholder="$t('end_date')" time-placeholder="00:00"/>
          </b-col>
        </b-row>
        <span v-if="event.starts_at && event.ends_at && event.starts_at >= event.ends_at" class="d-block text-danger">{{ $t('end_before_start_date') }}</span>
      </b-form-group>
      <p class="mt-3">{{ $t('event_create_hint') }}</p>
      <b-button class="mt-3 text-white" variant="primary" :disabled="!event.name || !event.starts_at || !event.ends_at || (event.name && (event.name.length < 4 || event.name.length > 64))" @click="createEvent()">{{ $t('event_modal_create') }}</b-button>
    </b-modal>

    <b-row v-if="events.length">
      <b-col class="tableBig">
        <b-table class="table-container" :items="events" :fields="fields">
          <template v-slot:cell(status)="{item}">
            <status-label :status="item.status"></status-label>
          </template>
          <template v-slot:cell(starts_at)="{item}">
            <span v-if="item.starts_at">
              {{ item.starts_at | date_time}}
            </span>
          </template>
          <template v-slot:cell(ends_at)="{item}">
            <span v-if="item.starts_at">
              {{ item.ends_at | date_time}}
            </span>
          </template>
          <template v-slot:cell(actions)="{item}">
            <b-button v-if="$can('event_details_view')" class="mr-2 btnEvent" variant="row-edit" :to="{name:'ManageEvent', params:{id: item.id}}">{{ $t('manage') }}</b-button>
            <b-button v-if="$can('event_duplicate')" class="btnEventDuplicate" variant="row-edit" @click="showDuplicateModal(item)">{{ $t('duplicate') }}</b-button>
            <span class="btnPosUrlContainer ml-2" :id="'popoverTarget' + item.id">
              <b-icon class="btnPosUrl" font-scale="1" icon="link45deg"></b-icon>
            </span>
            <b-popover custom-class="posPopover" :ref="'popoverTarget' + item.id" :target="'popoverTarget' + item.id" triggers="hover" placement="bottom" :title="$t('event_duplicate_point_of_sales')">
              <div class="popoverPosRow" v-for="pos in item.display_points_of_sale" :key="'pos-' + pos.id" @click="copyToClipboard('https://'+ item.company.domain + '/p/' + pos.url);closePopover('popoverTarget' + item.id)">
                https://{{ item.company.domain }}/p/{{pos.url}}
              </div>
            </b-popover>
            <b-icon v-if="$can('event_delete')" @click="showDeleteModal(item)" icon="trash" font-scale="1.5" class="ml-3 text-secondary btnEventDelete" :class="{cursor_pointer: item.orders_count === 0, disabled_danger: item.orders_count}"></b-icon>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-alert v-else show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>

    <span v-if="showCopiedSpan" class="copiedToClipboard">Copied to clipboard!</span>

    <b-modal :title="$t('event_duplicate_event')" id="duplicate-event" size="large">
      <div>
        <b-row>
          <b-col>
            <date-time-picker v-model="event.starts_at" :min="new Date()" :label="$t('event_starts_at')" @input="startDateUpdated"></date-time-picker>

            <date-time-picker v-model="event.ends_at" :min="event.starts_at ? event.starts_at : new Date()" :label="$t('event_ends_at')"></date-time-picker>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('event_new_name')" :description="event.name === originalEventName ? $t('event_name_has_to_be_changed') : ''">
              <b-form-input v-model="event.name"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-3">
          <b-col>{{ $t('event_duplicate_choose_products_to_copy') }}</b-col>
          <b-col class="text-right">
            <b-button @click="allProductsSelected = !allProductsSelected; changeSelectValue(allProductsSelected)" variant="warning" size="sm">
              <span v-if="allProductsSelected" class="text-white">{{ $t('unselect_all') }}</span>
              <span v-else class="text-white">{{ $t('select_all') }}</span>
            </b-button>
          </b-col>
        </b-row>
        <div v-for="group in event.groups" :key="group.id" class="mb-2">
          <span v-if="group.name !== 'event_default_group'" class="group-title d-block p-2 mb-2">{{ group.name }}</span>
          <div class="p-2" v-for="product in group.products" :key="product.id">
            <b-form-checkbox v-model="product.selected">{{ product.name }}</b-form-checkbox>
            <span v-if="product.allowed_events && product.allowed_events.length" class="text-danger small">{{ $t('event_duplicate_product_allowed_events_note') }}</span>
          </div>
        </div>
        <span v-if="event.scan_operation && event.scan_operation.sessions_count > 1">{{ $t('event_scan_operation_session_date_to_change') }}</span>
        <b-row class="mt-3 mb-2">
          <b-col><h4>{{ $t('event_duplicate_point_of_sales') }}</h4></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group v-for="pos in event.pos" :key="pos.id" :label="pos.name">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text>{{ 'https://' + pos.company.domain + '/p/' }}</b-input-group-text>
                </template>
                <b-form-input type="url" v-model="pos.url"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <template #modal-footer>
        <b-button :disabled="!event.name || event.name === originalEventName || loading" variant="primary" @click="duplicateEvent()">
          <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon> {{ $t('duplicate') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('delete')" id="delete-event" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('delete_entry') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button :disabled="loading" @click="deleteEvent" variant="danger">
          <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon> {{ $t('delete') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import StatusLabel from "@/components/StatusLabel";
import DateTimePicker from "@/components/DateTimePicker";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    StatusLabel,
    DateTimePicker
  },
  name: 'Events',
  data() {
    return {
      event: {
        name: null,
        starts_at: '',
        ends_at: '',
      },
      originalEventName: null,
      originalStartDate: null,
      events: [],
      show_archived: false,
      allProductsSelected: true,
      loading: false,
      showCopiedSpan: false,
      fields: [
        {key: 'status', label: this.$t('event_status')},
        {key: 'name', label: this.$t('event_name')},
        {key: 'starts_at', label: this.$t('event_starts_at')},
        {key: 'ends_at', label: this.$t('event_ends_at')},
        {key: 'actions', label: this.$t('order_actions')},
      ]
    }
  },
  created() {
    document.title = this.$t('backend_title_events_overview')
    if(this.$store.state.user) {
      if(!this.$can('event_overview_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.getEvents();
  },
  methods: {
    startDateUpdated() {
      let orgStartDate = moment(this.originalStartDate).format('YYYY-MM-DD')
      let startDate = moment(this.event.starts_at).format('YYYY-MM-DD');
      this.event.name = this.originalEventName.replace(' ' + orgStartDate, '') + " " + startDate;
      let index = 1;
      this.event.pos.forEach((pos) => {
        pos.url = startDate + "-" + this.sanitizeUrlString(this.originalEventName.replace(' ' + orgStartDate, '')) + (index > 1 ? `-${index}` : '');
        index++;
      });
    },
    getEvents() {
      api.event().all(this.show_archived).then(events => {
        this.events = events
      })
    },
    createEvent() {
      this.adding = true
      api.event().create(this.event).then((event) => {
        this.$bvModal.hide('add-event');
        if(this.$can('event_details_view') || this.$can('event_details_edit')) {
          this.$router.push({
            name: 'EventProducts',
            params: {
              id: event.id
            }
          })
        }
        else {
          this.event = {
            name: null,
            starts_at: '',
            ends_at: '',
          };
          this.getEvents();
          this.$store.commit('success', this.$t('event_created'));
        }
      })
    },
    copyToClipboard(textToCopy){
      const el = document.createElement('textarea');
      el.value = textToCopy;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.showCopiedSpan = true
      setTimeout(function(){
        this.showCopiedSpan = false
      }.bind(this), 2000)
    },
    closePopover(el){
      this.$refs[el].$emit('close')
    },
    showDuplicateModal(event) {
      this.event = { ...event };
      if (!this.event.starts_at) {
        this.event.starts_at = new Date();
      }
      if (!this.event.ends_at) {
        this.event.ends_at = new Date();
      }
      this.originalEventName = event.name;
      this.originalStartDate = event.starts_at;

      const productGroups = api.product().getGroups(event.id);
      const eventPos = api.pos().get(event.id);

      axios.all([productGroups, eventPos]).then(axios.spread((...responses) => {
        const productResponse = responses[0]
        const posResponse = responses[1]

        this.event.groups = productResponse.map(group => {
          group.products = group.products.map(product => {
            return Object.assign(product, {selected: true})
          });
          return group;
        });

        this.event.pos = posResponse.filter(pos => pos.status !== 'box-office');

        this.$bvModal.show('duplicate-event');
      }))
    },
    duplicateEvent() {
      // Sanitize URL of poses once more
      if(this.event.pos){
        this.event.pos.map(pos => {
          pos.url = this.sanitizeUrlString(pos.url)
          return pos
        })
      }
      const selectedProducts = [];
      this.event.groups.forEach(group => {
        group.products.forEach(product => {
          if(product.selected) {
            selectedProducts.push(product.id);
          }
        })
      })
      this.loading = true;

      api.event().duplicate(this.event.id, {
        products: selectedProducts,
        point_of_sale: this.event.pos,
        name: this.event.name,
        starts_at: this.event.starts_at,
        ends_at: this.event.ends_at,
      }).then((event) => {
        this.loading = false;
        if(this.$can('event_details_view') || this.$can('event_details_edit')) {
          this.$router.push({
            name: 'ManageEvent',
            params: {
              id: event.id
            }
          })
        }
        else {
          this.event = {};
          this.getEvents();
          this.$store.commit('success', this.$t('event_duplicated'));
        }
      }).catch(() => {
        this.loading = false;
      })
    },
    changeSelectValue(value) {
      for(let group of this.event.groups) {
        for(let product of group.products) {
          product.selected = value;
        }
      }
    },
    sanitizeUrlString(title) {
      let slug = "";
      // Change to lower case
      let titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd');
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '');
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-');
      // Change space to -
      slug = slug.replace('/ /g', '-');
      // Remove strange characters
      slug = slug.replace('@', '');
      slug = slug.replace('!', '');
      slug = slug.replace('$', '');
      slug = slug.replace('^', '');
      slug = slug.replace('*', '');
      slug = slug.replace('(', '');
      slug = slug.replace(')', '');

      return slug;
    },
    deleteEvent() {
      this.loading = true;
      api.event().delete(this.event.id).then(() => {
        this.$store.commit('success', 'event_deleted');
        this.$bvModal.hide('delete-event')
        this.event = {};
        this.getEvents();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },

    showDeleteModal(event) {
      if(event.orders_count) {
        return;
      }
      this.event = event;
      this.$bvModal.show('delete-event')
    },
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('event_overview_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
    '$store.state.language': {
      handler() {
        this.fields = [
          {key: 'status', label: this.$t('event_status')},
          {key: 'name', label: this.$t('event_name')},
          {key: 'starts_at', label: this.$t('event_starts_at')},
          {key: 'ends_at', label: this.$t('event_ends_at')},
          {key: 'actions', label: this.$t('order_actions')},
        ];
      }
    }
  }
}
</script>

<style>
.posPopover.b-popover .popover-header{
  display:block !important;
  font-size:15px;
  padding:6px 7px
}
.posPopover .popover-body{
  padding:0px !important
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cursor_pointer {
  cursor: pointer;
}
.disabled_danger {
  opacity: .4;
}
.group-title {
  background-color: #EEEEEE;
  color: #676666;
}
table.b-table tbody tr:hover .btn.btn-row-edit{
  border-color: #f5f5f5 !important;
}
.btnEvent{
  background-color: transparent!important;
  color: #808090!important;
}
.btnEvent:hover{
  background-color: #F0B52D!important;
  border-color: #F0B52D!important;
  color:white!important;
}
.btnEventDuplicate{
  background-color: transparent!important;
  color: #808090!important;
}
.btnEventDuplicate:hover{
  background-color: #F0B52D!important;
  border-color: #F0B52D!important;
  color:white!important;
}
.btnPosUrlContainer{
  width:33px;
  height:33px;
  border:2px solid #f5f5f5;
  border-radius:6px;
  display:inline-block;
  text-align: center;
  vertical-align: middle;
}
.btnPosUrl{
  font-size:25px;
  margin-top:3px
}
.popoverPosRow{
  cursor:pointer;
  padding:5px 7px;
  border-bottom:2px solid #f5f5f5
}
.popoverPosRow:last-of-type{
  border-bottom:0px
}
.popoverPosRow:hover{
  background-color:#f5f5f5
}
.copiedToClipboard{
  position:fixed;
  left:50%;
  bottom:15%;
  border-radius:40px;
  padding:5px 10px;
  background-color:#193e58;
  color:#FFF;
  font-size:10pt
}
.btnEventDelete {
  vertical-align:middle;
}
.invalid-feedback {
  font-size: 16px!important;
}
.small {
  font-size: 12px;
}
</style>
