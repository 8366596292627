import { render, staticRenderFns } from "./ManageCompany.vue?vue&type=template&id=9816c490&scoped=true"
import script from "./ManageCompany.vue?vue&type=script&lang=js"
export * from "./ManageCompany.vue?vue&type=script&lang=js"
import style0 from "./ManageCompany.vue?vue&type=style&index=0&id=9816c490&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9816c490",
  null
  
)

export default component.exports