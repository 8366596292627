export default class GuestList {

    constructor(axios) {
        this.axios = axios;
    }

    getProductsByEvent(eventId) {
        return this.axios.get('/guest-list/' + eventId + '/products')
    }

    placeOrder(eventId, data) {
        return this.axios.post('/guest-list/' + eventId + '/place-order', data)
    }
}
