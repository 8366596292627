<template>
  <div fluid v-if="order"  class="orderDetails">
    <b-row v-if="order.is_cancelled">
      <b-col>
        <b-alert :show="true" variant="danger">{{ $t('order_is_cancelled') }}</b-alert>
      </b-col>
    </b-row>

    <b-button
        v-if="$route.query.back"
        variant="primary"
        class="mb-4 text-white"
        @click="$router.push({name: 'OrderOverview', query: getBackQuery()})">
      <b-icon icon="chevron-left" /> {{ $t('back') }}
    </b-button>

    <b-row>
      <b-col xl="4" lg="6" class="mb-3">
        <b-card :title="$t('order_details')">
          <b-card-text>
            <table class="order-details-table">
              <tr>
                <td>{{ $t('order_number') }}</td>
                <td>{{ order.order_number }}</td>
              </tr>
              <tr>
                <td>{{ $t('order_created_at') }}</td>
                <td>{{ order.created_at | date_time }}</td>
              </tr>
              <tr>
                <td>{{ $t('order_is_paid') }}</td>
                <td>
                  <b-badge :variant="order.is_paid ? 'success' : 'danger'" v-if="!isRefunded">
                    {{ order.is_paid ? $t('order_paid') : $t('order_not_paid') }}
                  </b-badge>
                  <b-badge variant="warning" v-else>
                    {{ $t('order_is_refunded') }}
                  </b-badge>
                </td>
              </tr>
              <tr>
                <td>{{ $t('order_detail_payment_method') }}</td>
                <td>{{
                    order.pos_payment_method ? order.pos_payment_method.payment_method.name : $t('order_no_payment_method')
                  }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('order_event') }}</td>
                <td>{{ order.event.name }}</td>
              </tr>
              <tr>
                <td>{{ $t('order_total') }}</td>
                <td>{{ order.total | currency }}</td>
              </tr>
              <tr v-if="order.discountcode">
                <td>{{ $t('order_discountcode') }}</td>
                <td>{{ order.discountcode.code }} ( <span v-if="order.discountcode.type == 'value'">€</span>{{ order.discountcode.amount }}<span v-if="order.discountcode.type == 'percentage'">%</span> )</td>
              </tr>
              <tr v-if="order.discount">
                <td>{{ $t('order_discount') }}</td>
                <td>{{ order.discount | currency }}</td>
              </tr>
              <tr v-if="order.timeslot_id">
                <td>{{ $t('order_timeslot') }}</td>
                <td>{{ order.timeslot.starts_at | date_time }} - {{order.timeslot.ends_at | date_time }}</td>
              </tr>
              <tr>
                <td>{{ $t('order_type') }}</td>
                <td>
                  <span v-if="order.order_type === 'box-office'">
                    {{ $t('order_overview_box_office') }}
                  </span>
                  <span v-else-if="order.order_type === 'api'">
                    {{ $t('order_overview_api_order') }}
                  </span>
                  <span v-else-if="order.order_type === 'free-order'">
                    {{ $t('order_overview_free_tickets') }}
                  </span>
                  <span v-else-if="order.order_type === 'manual-order'">
                    {{ $t('order_overview_manual_orders') }}
                  </span>
                  <span v-else-if="order.order_type === 'batch-ticket'">
                    {{ $t('order_overview_batch_ticket') }}
                  </span>
                  <span v-else-if="order.order_type === 'payment-link'">
                    {{ $t('order_overview_payment_link') }}
                  </span>
                  <span v-else>
                    {{ $t('order_overview_online') }}
                  </span>
                </td>
              </tr>
              <tr v-if="order.campaign">
                <td>{{ $t('campaign') }}</td>
                <td>{{ order.campaign.name }}</td>
              </tr>
            </table>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col xl="4" lg="6" class="mb-3">
        <b-card>
          <b-card-title>
            <b-row class="align-items-center">
              <b-col class="text-white">{{ $t('order_customer_details') }}</b-col>
              <b-col v-if="$can('order_detail_change_customer_details')" class="text-right">
                <button v-if="order.customer" @click="showEditCustomerModal" type="button" class="btn btn-primary btn-sm py-0" style="font-size: 13px;">{{ $t('order_details_edit_customer_data') }}</button>
              </b-col>
            </b-row>
          </b-card-title>
          <b-card-text>
            <table class="order-details-table" v-if="order.customer">
              <tr>
                <td>{{ $t('customer_full_name') }}</td>
                <td>{{ order.customer.first_name }} {{ order.customer.last_name }}</td>
              </tr>
              <tr>
                <td>{{ $t('customer_address') }}</td>
                <td>{{ order.customer.addresses[0] ? (order.customer.addresses[0].address ? order.customer.addresses[0].address  : '') + ' ' + (order.customer.addresses[0].house_nr ? order.customer.addresses[0].house_nr : '') : '' }}</td>
              </tr>
              <tr>
                <td>{{ $t('customer_zip') }}</td>
                <td>{{ order.customer.addresses[0] ? order.customer.addresses[0].zip : '' }}</td>
              </tr>
              <tr>
                <td>{{ $t('customer_city') }}</td>
                <td>{{ order.customer.addresses[0] ? order.customer.addresses[0].city : '' }}</td>
              </tr>
              <tr>
                <td>{{ $t('customer_phone') }}</td>
                <td>{{ order.customer.phone }}</td>
              </tr>
              <tr>
                <td>{{ $t('customer_email') }}</td>
                <td>{{ order.customer.email }}</td>
              </tr>
               <tr>
                <td>{{ $t('customer_dob') }}</td>
                <td>{{ getDate(order.customer.dob) }}</td>
              </tr>
            </table>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col xl="4" lg="12" class="mb-3 ">
        <b-card :title="$t('order_actions')" class="pb-3">
          <b-card-text>
            <b-form-group v-if="$can('order_cancel')" class="order-action">
              <b-button :disabled="order.is_cancelled" @click="showCancelOrderModal()" variant="link">{{ $t('order_cancel') }}</b-button>
            </b-form-group>
            <b-form-group v-if="$can('order_refund')" class="order-action">
              <b-button :disabled="order.payment_status !== 'paid' || order.order_type === 'free-order'" @click="showRefundOrderModal()" variant="link">{{ $t('order_refund') }}</b-button>
            </b-form-group>
            <b-form-group v-if="$can('order_order_confirmation')" class="order-action">
              <b-button @click="showResentTicketsModal()" variant="link">{{ $t('order_resend_confirmation') }}</b-button>
            </b-form-group>
            <b-form-group v-if="$can('order_detail_change_event')" class="order-action">
              <b-button  @click="showChangeOrderEventModal()" variant="link">{{ $t('order_details_change_order_event') }}</b-button>
            </b-form-group>
            <b-form-group class="order-action" v-if="order.point_of_sale.status !== 'box-office'">
              <b-button :href="getOrderStatusPageUrl(order.id, order.secret)" target="_blank" variant="link">{{ $t('order_open_customer_status') }}</b-button>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <hr class="my-4 mb-5">
    <b-row>
      <b-col class="mb-3">
        <b-card :title="$t('order_products')" class="order_products">
          <b-card-text class="tableBig">
            <b-table :items="order.order_products" :fields="['status', 'claimed', 'product', 'price', 'email', 'actions']"
                     class="table-container productTable" thead-class="d-none">
              <template v-slot:cell(status)="{item}">
                {{ item.url }}
                <b-badge variant="success" v-if="!item.product.create_eticket">{{ $t('product_successfully_ordered') }}</b-badge>
                <b-badge variant="info" v-else-if="order.point_of_sale.status === 'box-office'">{{ $t('product_successfully_ordered') }}</b-badge>
                <b-badge variant="danger" v-else-if="!item.ticket">{{ $t('ticket_not_found') }}</b-badge>
                <b-badge variant="danger" v-else-if="item.refunded_at === null && item.ticket.status == 'cancelled'">{{ $t('ticket_cancelled') }}</b-badge>
                <b-badge variant="warning" v-else-if="item.refunded_at !== null && item.ticket.status == 'cancelled'">{{ $t('order_status_refunded') }}</b-badge>
                <b-badge variant="warning" v-else-if="!item.ticket.url">{{ $t('ticket_not_generated') }}</b-badge>
                <b-badge variant="success" v-else-if="item.ticket.scanned">{{ $t('ticked_scanned') }}</b-badge>
                <b-badge variant="info" v-else-if="item.ticket.url">{{ $t('ticket_generated') }}</b-badge>
              
              </template>
              <template v-slot:cell(claimed)="{item}">
                <div v-if="item.ticket">
                  <div v-if="item.ticket.status == 'cancelled'">{{ item.refunded_at === null ? $t('ticket_cancelled') : $t('order_status_refunded') }}</div>
                  <div v-else-if="item.ticket.claimed_at"><b-icon icon="check-circle-fill" variant="success"></b-icon> {{  $t('ticket_claimed_at')  }} {{ item.ticket.claimed_at | date_time }}</div>
                  <div v-else>
                    <b-icon icon="circle"></b-icon> {{  $t('ticket_not_claimed')  }}
                    <div v-if="item.ticket.reminded_at">{{ $t('ticket_reminded_at') }} {{ item.ticket.reminded_at | date_time }}</div>
                    <div v-if="item.ticket.invited_at">{{ $t('ticket_invited_at') }} {{ item.ticket.invited_at | date_time }}</div>
                  </div>

                  <div v-if="item.ticket && item.ticket.claimed_at != null">
                    <div v-if="item.ticket.logs && isScanned(item)">
                      <b-icon icon="check-circle-fill" variant="success"></b-icon>
                      {{ $t('order_ticket_scanned_at') }}
                      <span v-html="getScanLogs(item)"></span>
                    </div>
                    <div v-else>
                      <b-icon icon="circle"></b-icon> {{ $t('order_ticket_not_yet_scanned') }}
                    </div>

                  </div>
                </div>
              </template>
              <template v-slot:cell(actions)="{item}">
                
                <template v-if="order.is_paid && item.product.create_eticket && order.point_of_sale.status !== 'box-office'" >
                  <span v-if="item.refunded_at">{{ item.refunded_at | date_time }}</span>
                  <div v-if="order.event.status === 'archived'" class="product-row-button-container">
                    <b-btn variant="info" target="_blank" :href="item.ticket.url" v-if="item.ticket.url && item.ticket.status == 'active'">
                      {{ $t('ticket_view') }}
                    </b-btn>
                  </div>
                  <div v-else class="product-row-button-container">
                    <b-btn variant="primary" @click="generateTicket(item)" v-if="!item.ticket && $can('order_detail_generate_ticket')">
                      {{ $t('ticket_generate') }}
                    </b-btn>
                    <b-btn variant="primary" @click="generateTicket(item)" v-else-if="!item.ticket.url && item.ticket.status == 'active'  && $can('order_detail_generate_ticket')">
                      {{ $t('ticket_generate') }}
                    </b-btn>
                    <b-btn variant="info" target="_blank" :href="item.ticket.url" v-else-if="item.ticket.url && item.ticket.status == 'active'">
                      {{ $t('ticket_view') }}
                    </b-btn>
                    <b-button variant="info" v-if="$can('order_detail_product_change_owner') && item.ticket && !item.ticket.scanned && item.ticket.status == 'active'" :href="'https://' + order.company.domain + '/ticket/' + item.ticket.id + '/' + item.ticket.secret + '/claim?change=1'" target="_blank">
                      {{ $t('ticket_change_owner') }}
                    </b-button>
                    <b-btn variant="danger" @click="cancelTicket(item.ticket)" v-if="item.ticket && item.ticket.status == 'active' && $can('order_detail_product_cancel')">
                      {{ $t('ticket_cancel') }}
                    </b-btn>
                  </div>
                </template>
              </template>
              <template v-slot:cell(product)="{item}">
                <span v-if="item.product.product_group.name !== 'event_default_group'">{{ item.product.product_group.name }}<br></span>
                {{ item.product.name }}
              </template>
              <template v-slot:cell(price)="{item}">
                {{ item.price | currency }}
              </template>
              <template v-slot:cell(email)="{item}">
                <div v-if="item.ticket && order.point_of_sale.status !== 'box-office'">
                  <div v-if="item.ticket.customer">
                    {{ item.ticket.customer.first_name }} {{ item.ticket.customer.last_name }}
                    <br v-if="(item.ticket.customer.first_name || item.ticket.customer.last_name) && item.ticket.customer.email" />
                    {{ item.ticket.customer.email }}
                  </div>
                  <b-badge v-else variant="danger">{{ $t('ticket_customer_not_set') }}</b-badge>
                </div>
              </template>
            </b-table>
          </b-card-text>
        </b-card>

        <b-card class="mt-4 mb-3" :title="$t('order_customer_answers')" v-if="Object.keys(specificQuestions).length > 0">
          <b-card-text>
            <div v-for="(specificQuestion, i) in specificQuestions" :key="'specificQuestion-' + i" class="specific-question-row">
              <strong>{{ i }}</strong>
              <div v-for="(specificAnswer, a) in specificQuestion" :key="'specificAnswer' + a">
                {{ specificAnswer.amount }}x {{ specificAnswer.answer }}
              </div>
            </div>
          </b-card-text>
        </b-card>

      </b-col>
      <b-col xl="4" class="tableBig mb-3">
        <b-table v-if="$can('order_detail_payment_history_view')" :items="payment_logs"
                  :fields="[{key: 'created_at', label: $t('order_payment_created_at')}, {key: 'type', label: $t('order_payment_status')}, {key: 'context', label: $t('order_payment_amount')} ]">
          <template v-slot:cell(created_at)="{item}">
            {{ item.created_at | date_time }}
          </template>
          <template v-slot:cell(type)="{item}">
            <b-badge :variant="item.type">{{ $t(item.message) }}</b-badge>
          </template>
          <template v-slot:cell(context)="{item}">
            {{ item.context.payment_method }} {{ item.context.payment_method ? ' - ' : '' }} {{ item.context.amount | currency }}
          </template>
        </b-table>
        <b-table v-if="$can('order_detail_notes_view')"  :items="order.notes" class="table-container tableEdit " :fields="[{key: 'message', label: $t('order_notes')}]">
          <template v-slot:cell(message)="{item}">
            {{ item.message }}<br>
            <span class="small">{{ item.user.name }} @ {{ item.created_at | date_time }}</span>
          </template>
        </b-table>
        <b-card v-if="$can('order_detail_notes_create')" :title="$t('order_create_note')">
          <b-card-text>
            <b-form-group>
              <b-textarea :disabled="addingNote" v-model="message"></b-textarea>
            </b-form-group>
            <b-form-group class="mt-3">
              <b-btn variant="primary" class="px-4" :disabled="addingNote" @click="addNote()">{{ $t('create') }}</b-btn>
            </b-form-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="$can('order_detail_log_view')">
      <b-col class="mb-3">
        <b-card :title="$t('order_log')" class="mt-4">
          <b-card-text class="inner">
            <b-row class="mb-3" v-for="log in logs" :key="`log` + log.id">
              <b-col cols="12" md="4">
                <b-badge :variant="log.type">{{ $t(log.message) }}</b-badge>
              </b-col>
              <b-col>{{ log.created_at | date_time }}</b-col>
              <b-col class="text-right">
                <b-btn @click="showAuditDetails(log)">{{ $t('audit_show_context') }}</b-btn>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="audit-log" size="lg" >
      <div v-if="auditLog" >
        <div v-if="auditLog.context.email" >
          <h5>{{ $t('email_content') }}</h5>
         <div class="popUp">
            <div v-html="auditLog.context.email" ></div>
         </div>
        </div>
        <div v-if="auditLog.context.amount">
          <h5>{{ $t('amount') }}</h5>
          {{ auditLog.context.amount | currency }}
        </div>
        <div v-if="auditLog.context.payment_method">
          <h5>{{ $t('payment_method') }}</h5>
          {{ auditLog.context.payment_method }}
        </div>
      </div>
    </b-modal>
    <b-modal :title="$t('order_detail_modal_cancel_order_title', {order: order ? order.order_number : ''})" id="cancel-order" size="lg" >
      <div v-if="order" >
        <b-row>
          <b-col>{{ $t('order_detail_cancel_order_confirmation') }}</b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <b-checkbox class="name-checkbox" v-model="order_cancel_send_mail">{{ $t('order_detail_modal_send_mail') }}</b-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button  @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="cancelOrder()" variant="success">{{ $t('order_detail_cancel_order') }}</b-button>
      </template>
    </b-modal>

    <order-details-refund
        ref="refundModal"
        v-if="order"
        :order="order"
        @refunded="loadOrder">
    </order-details-refund>

    <b-modal :title="$t('order_detail_modal_resent_tickets_title', {order: order ? order.order_number : ''})" id="resent-tickets" size="lg">
      <div v-if="order">
        <b-row>
          <b-col>{{ $t('order_detail_resent_tickets_confirmation') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="resentTickets()" variant="success">{{ $t('order_detail_resent_confirmation_tickets') }}</b-button>
      </template>
    </b-modal>
    <b-modal id="edit-customer" @hide="editCustomer = {}" @close="editCustomer = {}" :title="$t('order_details_edit_customer_data')">
      <b-row>
        <b-col>
          <b-form-group :label="$t('first_name')">
            <b-form-input v-model="editCustomer.first_name"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('last_name')">
            <b-form-input v-model="editCustomer.last_name"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('customer_address')">
            <b-form-input v-model="editCustomer.address"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('customer_house_nr')">
            <b-form-input v-model="editCustomer.house_nr"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('customer_zip')">
            <b-form-input v-model="editCustomer.zip"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('customer_city')">
            <b-form-input v-model="editCustomer.city"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('customer_country')">
            <b-form-select text-field="label" value-field="id" :options="countries"
                           v-model="editCustomer.country">
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('customer_phone')">
            <b-form-input v-model="editCustomer.phone"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group>
        <b-form-checkbox :disabled=" editCustomer.optin ? false : true " v-model="editCustomer.optin">{{ $t('customer_optin') }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group :label="$t('customer_email')">
        <b-form-input v-model="editCustomer.email"></b-form-input>
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group :label="$t('customer_gender')">
            <b-form-radio-group :options="genders" v-model="editCustomer.gender">
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('customer_dob')">
            <date-picker v-model="editCustomer.dob"></date-picker>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click="updateCustomer" :disabled="loading">{{ $t('save') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('order_details_change_order_event')" id="change-order-event-modal" size="lg">
      <div v-if="switchEventLoading && !switchEventEvents.length" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <b-form-group :label="$t('order_details_change_event_modal_event')">
          <b-form-select :options="switchEventEvents" @change="switchEventChanged" text-field="name" value-field="id"></b-form-select>
        </b-form-group>
        <hr v-if="switchEventSelectedEvent">
        <b-form-group v-if="switchEventSelectedEvent" :label="$t('order_details_change_event_modal_pos')">
          <b-form-select v-model="switchEventSelectedPos" :options="switchEventSelectedEvent.points_of_sale" @change="setPosProducts" text-field="name" value-field="id" :disabled="switchEventSelectedEvent.points_of_sale.length === 1"></b-form-select>
        </b-form-group>
        <hr v-if="switchEventSelectedEvent">
        <span v-if="switchEventSelectedEvent && switchEventSelectedPos">{{ $t('order_details_change_event_modal_products_title') }}</span>
        <b-row v-for="product in switchEventProducts" :key="product.currentProduct.id" class="mt-3 change-order-event-modal-products">
          <b-col>
            {{ (product.currentProduct.product_group_name !== 'event_default_group' ? (product.currentProduct.product_group_name + ' - ') : '') + product.currentProduct.name }}
          </b-col>
          <b-col>
            <b-form-group>
              <b-form-select v-model="product.toProduct" :options="product.productsList"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <hr v-if="switchEventSelectedEvent">
        <b-form-group v-if="switchEventSelectedEvent">
          <b-form-checkbox v-model="switchEventSendMails">{{ $t('order_detail_modal_send_mail') }}</b-form-checkbox>
        </b-form-group>
      </div>
      <template #modal-footer>
        <b-button :disabled="isSwitchEventModalDisabled" @click="saveSwitchOrderEvent">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import DatePicker from "@/components/DatePicker";
import moment from "moment";
import OrderDetailsRefund from '@/components/OrderDetailsRefund'
import {deepClone, groupBy, getOrderStatusPageUrl} from "@/functions";
import { mapMutations } from 'vuex';

export default {
  methods: {
    getOrderStatusPageUrl() {
      return getOrderStatusPageUrl(this.order.id, this.order.secret);
    },
    showAuditDetails(auditLog) {
      this.auditLog = auditLog
      this.$bvModal.show('audit-log')
    },
    generateTicket(item) {
      api.ticket().generate(item.id).then(() => {
        this.$store.dispatch('success', 'ticket_generation_queued')
        this.loadOrder()
      })
    },
    cancelTicket(item){
      api.ticket().cancel(item.id).then(() => {
        this.$store.dispatch('success', 'order_detail_ticket_cancelled')
        this.loadOrder()
      })
    },
    addNote() {
      this.addingNote = true
      api.order().addNote(this.order.id, this.message).then((note) => {
        this.addingNote = false
        this.message = ''
        if (!this.order.notes) {
          this.order.notes = []
        }
        this.order.notes.push(note)
      })
    },
    showCancelOrderModal(){
      this.$bvModal.show('cancel-order')
    },
    cancelOrder() {
      let body = {sendMail: this.order_cancel_send_mail};
      api.order().cancel(this.order.id, body).then(() => {
        this.loadOrder()
        this.$bvModal.hide('cancel-order')
        this.$store.commit('success', 'order_detail_order_cancelled')
      })
    },
    showResentTicketsModal(){
      this.$bvModal.show('resent-tickets')
    },
    resentTickets() {
      api.order().resentConfirmation(this.order.id).then(() => {
        this.loadOrder()
        this.$bvModal.hide('resent-tickets')
        this.$store.commit('success', 'ticket_confirmation_resent')
      })
    },
    showRefundOrderModal(){
      this.$refs.refundModal.loadOrder();
      this.$bvModal.show('refund-order')
    },
    loadOrder(){
      api.order().details(this.$route.params.order_number).then((order) => {
        this.order = order

        this.isRefunded = this.order.order_products.filter(product => product.refunded_at === null).length === 0

        if(order.customer_answers){
          order.customer_answers.forEach(answer => {
            if (answer.customer_information){
              this.specificQuestions[answer.customer_information.question] = this.specificQuestions[answer.customer_information.question] || [];
              
              let found = this.specificQuestions[answer.customer_information.question].some(el => el.answer === answer.answer);
              if(!found){
                this.specificQuestions[answer.customer_information.question].push({'answer': answer.answer, 'amount': 1})
              } else {
                let index = this.specificQuestions[answer.customer_information.question].findIndex((el => el.answer === answer.answer));
                this.specificQuestions[answer.customer_information.question][index].amount = 1 + this.specificQuestions[answer.customer_information.question][index].amount
              }
            }
          })
        } else {
          this.specificQuestions = null
        }

        api.audit().get('order', this.order.id).then((logs) => {
          this.payment_logs = logs.filter((log) => {
            return log.message.includes('payment_') && !log.message.includes('payment_link')
          })

          const refunds = groupBy(this.order.refunds.filter(r => r.type !== 'refund-fee'), (refund) => {
            return moment(refund.datetime).format('YYYY-MM-DD HH:mm:ss')
          });

          for(const date in refunds) {
            let amount = 0;
            for(const refund of refunds[date]) {
              amount += refund.amount;
            }
            if(amount) {
              this.payment_logs.push({
                created_at: date,
                type: 'danger',
                message: this.$t('order_detail_refund_order'),
                context: {
                  amount: amount,
                  payment_method: null
                }
              });
            }
          }
          this.logs = logs.filter((log) => {
            return !log.message.includes('payment_') || log.message.includes('payment_link')
          })
        })
      })
    },
    getScanLogs(item) {
      const logs = item.ticket.logs.filter(l => l.succeeded);

      if(logs.length === 1) {
        return moment(logs[0].timestamp).format('DD-MM-YYYY HH:mm:ss');
      }
      let txt = '';
      logs.forEach(log => {
        if(txt !== ''){
          txt += '<br/>'
        }
        txt += moment(log.timestamp).format('DD-MM-YYYY HH:mm:ss');
      })

      return txt;
    },
    isScanned(item) {
      return item.ticket.logs.filter(l => l.succeeded).length;
    },
    showEditCustomerModal() {
      this.editCustomer = JSON.parse(JSON.stringify(this.order.customer));
      if(this.order.customer.addresses.length && this.order.customer.addresses[0]) {
        this.editCustomer.address_id = this.order.customer.addresses[0].id;
        this.editCustomer.address = this.order.customer.addresses[0].address;
        this.editCustomer.house_nr = this.order.customer.addresses[0].house_nr;
        this.editCustomer.zip = this.order.customer.addresses[0].zip;
        this.editCustomer.city = this.order.customer.addresses[0].city;
        this.editCustomer.country = this.order.customer.addresses[0].country;
      }
      else {
        this.editCustomer.address = null;
        this.editCustomer.zip = null;
        this.editCustomer.city = null;
      }

      this.$bvModal.show('edit-customer')
    },
    updateCustomer() {
      this.loading = true;
      api.order().updateCustomer(this.editCustomer).then(()=>{
        this.loadOrder()
        this.loading = false;
        this.$bvModal.hide('edit-customer')
        this.$store.commit('success', 'order_details_edit_customer_success_message')
        this.editCustomer = {}
      }).catch(() => {
        this.loading = false;
      })
    },
    getDate(date) {
      if(!date) {
        return '';
      }
      return moment(date).format('YYYY-MM-DD')
    },
    showChangeOrderEventModal() {
      this.switchEventEvents = [];
      this.switchEventLoading = false;
      this.switchEventSelectedEvent = null;
      this.switchEventSelectedPos = null;
      this.switchEventProducts = [];
      this.switchEventSendMails = true;
      this.$bvModal.show('change-order-event-modal');
      this.switchEventLoading = true;
      api.event().getLiveEventsWithProducts(this.order.company_id).then(events => {
        this.switchEventEvents = events.filter(event => {
          return event.id !== this.order.event_id
        });
        this.switchEventLoading = false;
      })
    },
    switchEventChanged(event) {
      const selectedEvent = deepClone(this.switchEventEvents.find(e => e.id === parseInt(event)));
      this.switchEventSelectedEvent = selectedEvent;
      if (selectedEvent.points_of_sale.length === 1) {
        this.switchEventSelectedPos = selectedEvent.points_of_sale[0].id;
        this.setPosProducts(selectedEvent.points_of_sale[0].id);
      }
    },
    setPosProducts(posId) {
      const pos = deepClone(this.switchEventSelectedEvent.points_of_sale.find(p => p.id === parseInt(posId)));
      let products = [];
      for (const group of pos.groups) {
        products = products.concat(pos.products.filter(p => p.product.group_id === group.id));
      }
      this.switchEventProducts = deepClone(this.order.order_products).map(orderProduct => {
        return {
          id: orderProduct.product.id,
          currentProduct: {
            id: orderProduct.product.id,
            name: orderProduct.product.name,
            product_group_name: orderProduct.product.product_group.name,
          },
          // orderProductId: orderProduct.id,
          toProduct: null,
          productsList: products.map(p => {
            return {
              text: (p.product.product_group.name !== 'event_default_group' ? (p.product.product_group.name + ' - ') : '') + p.product.name,
              value: p.product.id,
              disabled: p.product.price !== orderProduct.price
            }
          })
        }
      }).filter((v, i, a) => {
        return a.findIndex(l => l.id === v.id) === i
      });
    },
    saveSwitchOrderEvent() {
      this.switchEventLoading = true;

      const products = deepClone(this.order.order_products).map(orderProduct => {
        const product = this.switchEventProducts.find(p => p.id === orderProduct.product.id)
        return Object.assign(deepClone(product), {orderProductId: orderProduct.id})
      })

      api.order().switchEvent(this.order.id, {
        event_to: this.switchEventSelectedEvent.id,
        products: products,
        pos: this.switchEventSelectedPos,
        send_mail: this.switchEventSendMails
      }).then(() => {
        this.switchEventLoading = false;
        this.switchEventSelectedEvent = null;
        this.switchEventSelectedPos = null;
        this.switchEventProducts = [];
        this.$store.commit('success', this.$t('order_details_event_changed_message'));
        this.$bvModal.hide('change-order-event-modal')
        this.loadOrder();
      }).catch(() => {
        this.switchEventLoading = false;
      })
    },
    getBackQuery() {
      console.log('this.$route.query.back', this.$route.query.back)
      try {
        const url = JSON.parse(this.$route.query.back)
        console.log(url);
        return url;
      }
      catch {
        return {};
      }
    },
    ...mapMutations(['setOrderOverviewQuery'])
  },
  data() {
    return {
      order: null,
      message: '',
      addingNote: false,
      logs: [],
      auditLog: null,
      payment_logs: [],
      order_cancel_send_mail: false,
      order_refund_send_mail: false,
      specificQuestions: {},
      editCustomer: {},
      loading: false,
      countries: [
        {id: 'DE', label: this.$t('country_de')},
        {id: 'NL', label: this.$t('country_nl')},
        {id: 'ES', label: this.$t('country_es')},
        {id: 'BE', label: this.$t('country_be')},
      ],
      genders: [
        {value: 'm', text: this.$t('customer_gender_male')},
        {value: 'f', text: this.$t('customer_gender_female')},
        {value: 'o', text: this.$t('customer_gender_other')}
      ],
      isRefunded: false,
      switchEventEvents: [],
      switchEventLoading: false,
      switchEventSelectedEvent: null,
      switchEventSelectedPos: null,
      switchEventProducts: [],
      switchEventSendMails: true,
    }
  },
  created() {
    document.title = this.$t('backend_title_order_detail', {order_number: this.$route.params.order_number})
    if(this.$store.state.user) {
      if(!this.$can('order_detail_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.loadOrder()
  },
  components: {
    DatePicker,
    OrderDetailsRefund
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('order_detail_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  },
  computed: {
    isSwitchEventModalDisabled() {
      return (this.switchEventLoading && this.switchEventEvents.length) ||
          (this.switchEventProducts.length && this.switchEventProducts.some(p => p.toProduct === null))
    }
  },
  beforeDestroy () {
    this.setOrderOverviewQuery(null)
  }
}
</script>
<style lang="scss" scoped>
.order-details-table {
  width: 100%;
}
table.b-table tbody tr td:first-of-type{
  padding-left: 10px!important;
}

.order-details-table > tr > td:first-child {
  font-weight: bold;
}
.order-details-table > tr > td:last-child {
   font-size: 16px;
}
.order-details-table td {
  padding: 3px;
}
.specific-question-row{
  margin-bottom:20px
}
.specific-question-row:last-of-type{
  margin-bottom:0
}
.product-row-button-container{
  display:flex;
  flex-direction: column;
}
.product-row-button-container .btn{
  width:100%;
  height:25px;
  padding:3px 4px;
  margin-bottom:5px;
  font-size:14px
}
.btn-link{
  font-size: 16px;
}

.form-group{
  margin-bottom: 5px;
}
.card-title{
  padding: 15px 12px;
}
.productTable{
  font-size: 16px;
}
 
@media screen and (max-width: 992px) {
  .card-title{
  margin-bottom: 0px;
}
.orderDetails .order_products{
  background-color: #F5F5F5!important;
}
.orderDetails .order_products .table{
 margin-bottom: 0px;
}
.orderDetails .order_products .card-text{
 padding: 0px;
}
.orderDetails .order_products .tableBig .table tr{
    margin: 5px 0;
    border-radius: 0px!important;
}
}
</style>
<style>

.orderDetails table.b-table tbody tr td:first-child,.orderDetails table.b-table thead tr th:first-child{
  padding-left: 13px;
}
.orderDetails .productTable td{
  padding-right: 7px;
  padding-left: 7px;
}
@media screen and (max-width: 992px) {
.orderDetails .order_products .tableBig .table tr{
    margin: 0px ;
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
}
@media (max-width: 1000px){
  .product-row-button-container .btn{
    max-width: 250px;
    margin: auto;
  }
.orderDetails .tableBig .table tr {
    border: 1px solid #ddd;
    margin: 0px 0 1px;
    border-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.orderDetails table.b-table thead {
    border-radius: 0px!important;
    border-bottom-left-radius: 5px!important;
    border-bottom-right-radius: 5px!important;
}
}

.change-order-event-modal-products option:disabled {
  text-decoration: line-through !important;
  color: #dadada;
}
</style>
