<template>
  <span></span>
</template>
<script>
export default {
  props: ['gtm_id', 'total_price', 'items', 'order_secret'],
  data() {
    return {
    }
  },
  mounted() {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    dataLayer.push({
      'gtm.id': this.gtm_id,
      event: 'gtm.js',
    });

    dataLayer.push({
      transactionId: this.order_secret,
      event: 'purchase',
      currency: 'EUR',
      total: this.total_price,
      items: this.items
    });
  }
}
</script>