<template>
  <div
    class="event-card"
    :class="[pos.event.image == null || pos.event.image == '' ? 'n-img' : '']"
  >
    <div class="img-container" v-if="pos.event.image">
      <img :src="pos.event.image_url" class="cover-img" :alt="pos.event.name" />   
    </div>

    <div class="info-block">
      <h3 class="event-lbl">{{ pos.event.name }}</h3>

      <div class="date-block">
        <div class="icon">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.147"
              height="16.147"
              viewBox="0 0 16.147 16.147"
            >
              <g transform="translate(0.25 0.25)">
                <g transform="translate(7.217 3.63)">
                  <path
                    d="M239.563,124.354l-2.182-1.636v-3.333a.606.606,0,0,0-1.212,0v3.636a.6.6,0,0,0,.242.485l2.424,1.818a.606.606,0,0,0,.727-.97Z"
                    transform="translate(-236.169 -118.779)"
                    fill="#40cd8a"
                    stroke="#40cd8a"
                    stroke-width="0.5"
                  />
                </g>
                <g>
                  <path
                    d="M7.823,0a7.823,7.823,0,1,0,7.823,7.823A7.832,7.832,0,0,0,7.823,0Zm0,14.435a6.611,6.611,0,1,1,6.611-6.611A6.62,6.62,0,0,1,7.823,14.435Z"
                    fill="#40cd8a"
                    stroke="#40cd8a"
                    stroke-width="0.5"
                  />
                </g>
              </g>
            </svg>
          </span>
        </div>

        <div class="date-text" v-if="!pos.event.continuous">{{ pos.event.starts_at | date_time }} - {{ pos.event.ends_at | date_time }}</div>
      </div>

      <div class="content-block">
        <div class="about-block">
          <p
              v-if="pos.event.description"
            class="text"
            :class="[readMoreId ? 'show' : '']"
            v-html="pos.event.description"
          ></p>

          <div class="read-more" v-if="pos.event.description && pos.event.description.length > 80">
            <button
              type="button"
              class="btn without-focus"
              @click="readMore(pos.id)"
            >
              {{ readMoreId ? $t('pos_overview_read_less') : $t('pos_overview_read_more') }}
            </button>
          </div>
        </div>

        <div class="btn-container">
          <a :href="`/p/` + pos.url + ($route.query.ft_campaign_name ? ('/?ft_campaign_name='+$route.query.ft_campaign_name) : '')" class="btn btn-success">{{ $t('pos_open') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pos: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      readMoreId: null,
    };
  },

  methods: {
    readMore(id) {
      if (this.readMoreId) {
        this.readMoreId = null;
      } else {
        this.readMoreId = id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

// color
$white: #fff;
$black: #303030;
$black2: #4e4e4e;
$red: #9b0000;
$primary: #40cd8a;
$dark: $black;

// Body
$body-bg: #fcfdff;

// Typography
$font-family-sans-serif: "Poppins", sans-serif;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1166px,
);


.event-card {
  width: 100%;
  max-width: 1166px;
  margin-left: auto;
  margin-right: auto;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000017;
  border-radius: 11px;
  display: grid;
  padding-inline-start: 15px;
  padding-inline-end: 35px;
  gap: 25px;
  margin-bottom: 40px;

  .cover-img {
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
  }

  .img-container img{
    max-width:100%;
    max-height:100%
  }

  .info-block {

    .event-lbl {
      font-weight: 600;
      font-size: 25px;
    }

    .date-block {
      display: flex;
      gap: 9px;
      align-items: baseline;

      .date-text {
        font-size: 15px;
      }
    }

    .about-block {
      padding-top: 26px;
      width: 100%;

      .text {
        font-size: 15px;
        margin-bottom: 0px;
        line-height: 1.75;
        overflow: hidden;
        max-height: 80px;
        text-overflow: ellipsis;
        transition: max-height 0.35s ease-in-out;

        &.show {
          max-height: 700px !important;
        }
      }

      .read-more {
        padding-top: 5px;

        .btn {
          font-size: 15px;
          font-weight: 500;
          padding: 0;

          &:hover {
            color: lighten($color: $red, $amount: 5%);
          }
        }
      }
    }
  }

  .btn-container {
    .btn {
      box-shadow: 0px 3px 8px #00000029;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      color: $white;
      padding: 10.5px 28px;
      white-space: nowrap;
    }
    .btn.btn-success{
      background-color:#40cd8a
    }
    .btn.btn-success:hover{
      background-color:darken($color:#40cd8a, $amount: 10%)
    }
  }

  .content-block {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
    padding:0;
    box-shadow:none;
    margin-top:auto
  }

  .info-block{
    display:flex;
    flex-direction:column
  }

  &:not(.n-img) {
    grid-template-columns: 290px 1fr;
    padding-top: 16px;
    padding-bottom: 16px;

    .info-block {
      .about-block {
        max-width: 506px;
      }
    }
  }

  &:is(.n-img) {
    grid-template-columns: 1fr;
    padding-top: 30px;
    padding-bottom: 30px;

    .info-block {
      .about-block {
        max-width: 870px;
      }
    }
  }
}

@media (max-width: 991px) {
  .event-card {
    padding-inline-end: 15px;

    &:not(.n-img) {
      grid-template-columns: minmax(180px, 260px) 1fr;
      gap: 20px;
    }

    .cover-img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }

    .content-block {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }

    .info-block {
      padding-top: 10px;
      .event-lbl {
        font-size: 20px;
        margin-bottom: 4px;
      }

      .date-block {
        .date-text {
          font-size: 13px;
        }
      }

      .about-block {
        padding-top: 10px;

        .text {
          font-size: 13px;
          line-height: 1.5;
          max-height: 62px;
        }

        .read-more {
          padding-top: 0;

          .btn {
            font-size: 13px;
          }
        }
      }
    }

    .btn-container {
      .btn {
        font-size: 15px;
        padding: 6.5px 22px;
      }
    }
  }
}

@media (max-width: 767px) {
  .event-card {
    display: flex;
    flex-direction: column;

    .img-container {
      height: 75%;
    }

    .info-block {
      padding-top: 0;

      .event-lbl {
        font-size: 16px;
      }
    }

    .date-block {
      .icon {
        svg {
          width: 12px;
        }
      }
    }
  }
}

@media (max-width: 415px) {
  .event-card {
    .btn-container {
      width: 100%;
      .btn {
        width: 100%;
      }
    }
  }
}

</style>
