<template>
  <div v-if="batch" fluid>
    <div class="content-main-container container-distance">
      <div class="title-container">{{ $t('batch_name', batch) }}</div>
      <div class="content-container">
        <b-row>
          <b-col>
            <b-form-group :label="$t('batch_name')">
              <b-form-input v-model="batch.name"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group :label="$t('ticket_name')">
              <b-form-input v-model="batch.ticket_name"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group :label="$t('ticket_price')">
              <b-form-input type="number" min="0" v-model="batch.ticket_price"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('ticket_vat')">
              <b-form-input type="number" min="0" v-model="batch.ticket_vat"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('tickets_count')">
              <b-form-input type="number" min="0" max="5000" v-model="batch.tickets_count"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <date-time-picker v-model="batch.starts_at" :min="new Date()" :label="$t('batch_detail_starts_at')"></date-time-picker>
          </b-col>
          <b-col>
            <date-time-picker v-model="batch.ends_at" :min="new Date()" :label="$t('batch_detail_ends_at')"></date-time-picker>
          </b-col>
        </b-row>

        <br>
        <b-row>
          <b-col>
            <b-form-group :label="$t('batch_events_or_days')">
              <b-dropdown id="dropdown-Checkbox" :text="batch.events.length ? (batch.events.length + ' ' + $t('permission_events_selected')) : $t('permission_all_events')" ref="dropdown" class="w-100">
                <b-form-group class="allowed-events-container">
                  <b-form-input v-model="searchEvents" :placeholder="$t('search')" size="sm" class="mb-2"></b-form-input>
                  <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="batch.events"
                      name="flavour-2"
                  >
                    <b-form-checkbox v-for="ev in events.filter(e => searchEvents ? e.name.toLowerCase().includes(searchEvents.toLowerCase()) : true)"
                                     :key="ev.id"
                                     :value="ev.id" class="w-100">{{ ev.name }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-dropdown>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox-group
                  v-model="batch.days"
                  :options="days"
              ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col class="text-right">
            <b-button :disabled="!batch || batch.generated_by || loading" @click="saveBatch()" variant="success" class="mr-2"><b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon> {{ $t('save') }}</b-button>
            <b-button v-if="batch && !batch.zip_file_path" :disabled="!batch || batch.generated_by || !orgBatch.name || !orgBatch.ticket_name || !orgBatch.tickets_count || loading" @click="generateBatch()" variant="warning"><b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon> {{ $t('batch_generate') }}</b-button>
            <b-button v-if="batch && batch.zip_file_path" :href="batch.zip_file_path" target="_blank" variant="success">{{ $t('batch_download') }}</b-button>
          </b-col>
        </b-row>

      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
import DateTimePicker from "@/components/DateTimePicker";
import moment from 'moment'
import {deepClone} from "@/functions";

export default {
  components: {
    DateTimePicker,
  },
  data() {
    return {
      batch: null,
      orgBatch: null,
      events: [],
      searchEvents: null,
      days: [
        this.$t('day_mon'),
        this.$t('day_tue'),
        this.$t('day_wed'),
        this.$t('day_thu'),
        this.$t('day_fri'),
        this.$t('day_sat'),
        this.$t('day_sun')
      ],
      loading: false,
    }
  },
  methods: {
    saveBatch() {
      this.loading = true;      
      api.batch().updateBatch(this.$route.params.id, this.batch).then((batch) => {
        this.loading = false;
        this.batch = batch
        this.orgBatch = deepClone(batch)
        if (!this.batch.events){
          this.batch.events = [];
        }
        if (!this.batch.days){
          this.batch.days = [];
        }
        this.$store.commit('success', 'batch_updated')
      }).catch(() => {
        this.loading = false;
      })
    },
    generateBatch() {
      this.loading = true;
      
      api.batch().generateBatch(this.$route.params.id).then((batch) => {
        this.batch = batch
        this.orgBatch = deepClone(batch)
        this.loading = false;
        this.$store.commit('success', 'batch_generated')
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  created() {
    document.title = this.$t('edit_batch_title')
    if(this.$store.state.user) {
      if(!this.$can('batch_edit')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.batch().findBatch(this.$route.params.id).then((batch) => {
      this.batch = batch
      this.orgBatch = deepClone(batch)
      if (!this.batch.starts_at){
        this.batch.starts_at = moment();
      }
      if (!this.batch.ends_at){
        this.batch.ends_at = moment();
      }
      if (!this.batch.events){
        this.batch.events = [];
      }
      if (!this.batch.days){
        this.batch.days = [];
      }
    });
    api.event().all(false).then(response => {
      this.events = response;
    });
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('batch_edit')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.show{
  display:block !important
}
</style>
