<template>
  <div v-if="event">
    <div>
      <b-alert v-if="event.isArchived" variant="danger" show class="text-center">
        {{ $t('event_is_archived') }}
      </b-alert>
      <div class="d-flex  flex-wrap">
        <div class="bigDiv  px-0 pr-lg-3 pl-lg-0 order-2 order-lg-1 mb-3">

          <div class="content-main-container container-distance">
            <div class="title-container">{{ $t('event_customerinformation_title', event) }}</div>
            <div class="content-container">

              <b-row class="customer-information-container">
                <b-col cols="12" xl="6">
                  <div class="title mt-2 mt-md-0">{{ $t('customer_information_title_general_questions') }}</div>
                  <div class="scrollTable">
                  
                  <b-table class="question-table" :items="customerFields"
                           :fields="questionTableFields">
                    <template v-slot:cell(field)="{item}">
                      {{ $t(item.field) }}
                    </template>
                    <template v-slot:cell(ticket_ask)="{item}">
                      <b-form-checkbox v-model="item.ticket_ask"
                                       :disabled="event.isArchived || !$can('event_customer_information_edit') || (item.field === 'email_confirmation' && !isTicketEmailAsked) "
                                       @change="markTicketAsk(item)"></b-form-checkbox>
                    </template>
                    <template v-slot:cell(order_ask)="{item}">
                      <b-form-checkbox v-model="item.order_ask"
                                       :disabled="item.field === 'email' || item.field === 'first_name' || item.field === 'last_name' || event.isArchived || !$can('event_customer_information_edit')"
                                       @change="markOrderAsk(item)"></b-form-checkbox>
                    </template>
                    <template v-slot:cell(order_mandatory)="{item}">
                      <b-form-checkbox v-if="item.field !== 'optin'"
                                       v-model="item.order_mandatory"
                                       :disabled="item.field === 'email'|| item.field === 'email_confirmation'  || item.field === 'first_name' || item.field === 'last_name' || event.isArchived || !item.order_ask || !$can('event_customer_information_edit')"
                                       @change="markOrderMandatory(item)"></b-form-checkbox>
                    </template>
                    <template v-slot:cell(ticket_mandatory)="{item}">
                      <b-form-checkbox v-if="item.field !== 'optin'"
                                       v-model="item.ticket_mandatory"
                                       :disabled="event.isArchived || !item.ticket_ask || item.field === 'email_confirmation' || item.field === 'email' || !$can('event_customer_information_edit')"
                                       @change="markTicketMandatory(item)"></b-form-checkbox>
                    </template>
                  </b-table>
                  </div>
                </b-col>
                <b-col cols="12"  xl="6">
                  <div class="title mt-2 mt-md-0">{{ $t('customer_information_title_specific_questions') }}</div>
                 <div class="scrollTable">
                 <b-table class="question-table" :items="questions" :fields="specificQuestionsFields">
                    <template v-slot:cell(actions)="{item}">
                      <div class="question-button-container">
                        <b-button v-if="$can('event_customer_information_edit')" class="btn btn-row-edit mr-2" @click="editQuestion(item)">{{ $t('manage') }}</b-button>
                        <b-button v-if="$can('event_customer_information_edit')" class="btn btn-danger" size="sm" :disabled="event.isArchived" @click="showDeleteQuestionModal(item)">{{ $t('delete') }}</b-button>
                      </div>
                    </template>
                  </b-table>
                  </div>
                 <div v-if="$can('event_customer_information_edit')" class="text-right">
                   <b-button class="mt-2 btn btn-orange" @click="newQuestion()" :disabled="event.isArchived">
                    {{ $t('question_add') }}
                  </b-button>
                 </div>
                </b-col>
              </b-row>

            </div>
          </div>

          <b-modal :title="$t('customer_information_modal_edit', question)" id="edit-question" size="lg">
            <b-form-group :label="$t('customer_information_question')">
              <b-form-input v-if="question" v-model="question.question"></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('customer_information_question_type')">
              <b-select v-if="question" v-model="question.question_type" :options="[
                    {value:'input', text: $t('textfield')},
                    {value:'select', text: $t('select_box')},
                    {value:'radio-buttons', text: $t('radio_buttons')},
                    {value:'checkboxes', text: $t('checkboxes')}]"
              ></b-select>
            </b-form-group>

            <div class="mb-4" :label="$t('customer_information_question')"
                 v-if="question && question.question_type && question.question_type !== 'input'">

              <b-row class="mb-2" v-for="answer in question.answers" :key="answer.id">
                <b-col>
                  <b-form-input v-if="question" v-model="answer.answer"></b-form-input>
                </b-col>
                <b-col cols="1">
                  <b-button variant="danger" @click="deleteAnswer(answer.id)">x</b-button>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col>
                  <b-form-input v-if="question" v-model="question.new_answer"></b-form-input>
                </b-col>
                <b-col cols="1">
                  <b-button class="" v-if="question.new_answer !== ''" variant="primary"
                            @click="addAnswer(question.new_answer)">+
                  </b-button>
                </b-col>
              </b-row>
            </div>

            <b-form-group>
              <b-form-checkbox v-if="question" v-model="question.mandatory">{{ $t('customer_information_mandatory') }}
              </b-form-checkbox>
            </b-form-group>

            <b-form-group :label="$t('customer_information_where_to_show')">
              <b-select v-if="question" v-model="question.where_to_show" :options="[
                    {value:'order', text: $t('customer_information_show_order')},
                    {value:'products', text: $t('customer_information_show_products')}
                ]"></b-select>
            </b-form-group>

            <div v-if="question && question.where_to_show === 'products'">
              <label>{{ $t('customer_information_which_products') }}</label>
              <b-row v-for="product in products" :key="'product' + product.id">
                <b-col>
                  <b-form-group>
                    <b-checkbox v-model="product.target">{{ product.name }}</b-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div v-if="question && question.where_to_show === 'order'">
              <b-form-group>
                <b-checkbox v-model="question.show_at_specific_prod">{{ $t('customer_information_specific_tickets') }}</b-checkbox>
              </b-form-group>
            </div>

            <div v-if="question && question.where_to_show === 'order' && question.show_at_specific_prod">
              <label>{{ $t('customer_information_which_products') }}</label>
              <b-row v-for="product in products" :key="'product' + product.id">
                <b-col>
                  <b-form-group>
                    <b-checkbox v-model="product.target">{{ product.name }}</b-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <template #modal-footer="{cancel}">
              <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
              <b-button @click="saveQuestion(question.new_answer)" :disabled="event.isArchived" variant="success">{{ $t('save') }}</b-button>
            </template>
          </b-modal>
          <b-modal :title="$t('customer_information_modal_create')" id="new-question" size="lg">

            <b-form-group :label="$t('customer_information_question')">
              <b-form-input v-if="question" v-model="question.question"></b-form-input>
            </b-form-group>

            <template #modal-footer="{cancel}">
              <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
              <b-button @click="createQuestion()" :disabled="event.isArchived" variant="success">{{ $t('create') }}</b-button>
            </template>
          </b-modal>
          <b-button v-if="$can('event_customer_information_edit')" @click="save()" :disabled="event.isArchived" variant="save" class="float-right">{{ $t('save') }}</b-button>

        </div>
        <div v-if="event && event.name" class="SmallDiv marginTop0 px-0 pl-lg-3 pr-lg-0 order-1 order-lg-2 mb-4 mb-lg-1 ">
          <event-nav :event="event"></event-nav>
        </div>
      </div>
    </div>
    <b-modal :title="$t('delete')" id="delete-question" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('delete_entry') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button :disabled="saving" @click="deleteQuestion" variant="danger">{{ $t('delete') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import EventNav from "@/components/EventNav";
import moment from "moment";

export default {

  components: {
    EventNav
  },
  name: 'EventCustomerInformation',
  data() {
    return {
      saving: false,
      event: null,
      questions: [],
      answers: [],
      products: [],
      originalProducts: [],
      question: null,
      customerFields: [],
      selectedQuestion: null,
    }
  },
  created() {
    document.title = this.$t('backend_title_events_customer_information')
    if(this.$store.state.user) {
      if(!this.$can('event_customer_information_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.event().get(this.$route.params.id).then(event => {
      this.event = {...event,isArchived :( moment(event.ends_at).isBefore(moment()) && !event.continuous) || event.status === "archived"}
    })
    this.getQuestions()
    api.product().get(this.$route.params.id).then(products => {
      this.originalProducts = products
    })
  },
  methods: {
    markOrderMandatory(item) {
      if (!item.order_ask && item.order_mandatory) {
        item.order_ask = true
      }
    },
    markTicketMandatory(item) {
      if (!item.ticket_ask && item.ticket_mandatory) {
        item.ticket_ask = true
      }
    },
    markTicketAsk(item) {
      if(!item.ticket_ask) {
        item.ticket_mandatory = false;
        if(item.field === 'email') {
          for(const cf of this.customerFields) {
            if(cf.field === 'email_confirmation') {
              cf.ticket_mandatory = false;
              cf.ticket_ask = false;
            }
          }
        }
      }
      else if(item.field === 'email') {
        item.ticket_mandatory = true;
      }
      else if(item.field === 'email_confirmation') {
        item.ticket_mandatory = item.ticket_ask;
      }
    },
    markOrderAsk(item) {
      if(!item.order_ask) {
        item.order_mandatory = false;
      }
      if(item.field === 'email_confirmation') {
        item.order_mandatory = item.order_ask;
      }
    },
    addAnswer(answer) {

      if(!answer || !answer.length) {
        return;
      }

      if (!('answers' in this.question)) {
        this.question.answers = []
      }

      this.question.answers.push({"answer": answer})
      this.question.new_answer = ''
    },
    deleteAnswer(answer_id) {
      this.question.answers = this.question.answers.filter(function (obj) {
        return obj.id !== answer_id;
      });
    },
    newQuestion() {
      this.question = {}
      this.$bvModal.show('new-question')
    },
    getQuestions() {
      api.customerInformation().get(this.$route.params.id).then(questions => {
        this.questions = questions
      })
      api.customerInformation().getCustomerFields(this.$route.params.id).then(fields => {
        this.customerFields = fields
      })
    },
    editQuestion(question) {
      this.question = Object.assign({}, question)
      this.products = this.originalProducts.map(product => {

        product.target = false;

        // If no products are set, return the default product
        if (!this.question.products) {
          return product
        }

        let questionProduct = this.question.products.find(qProduct => {
          return qProduct.product_id === product.id
        })

        product.target = !!questionProduct
        return product
      })

      this.$bvModal.show('edit-question')
    },
    createQuestion() {
      api.customerInformation().create(this.$route.params.id, this.question).then((question) => {
        this.getQuestions()
        this.$bvModal.hide('new-question')
        this.editQuestion(question)
      })
    },
    showDeleteQuestionModal(question) {
      this.selectedQuestion = question;
      this.$bvModal.show('delete-question')
    },
    deleteQuestion() {
      this.saving = true;
      api.customerInformation().delete(this.$route.params.id, this.selectedQuestion.id).then(() => {
        this.$bvModal.hide('delete-question')
        this.saving = false;
        this.getQuestions()
      }).catch(() => {
        this.saving = false;
      })
    },
    saveQuestion(answer) {

      if (answer !== '' && answer !== null && answer !== undefined) {
        this.addAnswer(answer)
      }

      // Map the products
      this.question.products = this.products.map((prod) => {
        return {product_id: prod.id, target: prod.target}
      })

      api.customerInformation().save(this.$route.params.id, this.question).then(() => {
        this.getQuestions()
        this.$bvModal.hide('edit-question')
        this.question = null
        this.$store.commit('success', 'customer_information_saved')
      })
    },
    save() {
      api.customerInformation().saveCustomerFields(this.$route.params.id, this.customerFields).then((fields) => {
        this.customerFields = fields
        this.$store.commit('success', 'customer_fields_saved')
      })
    }
  },
  computed: {
    isTicketNamesAsked() {
      let firstName = false;
      let lastName = false;

      for(const cf of this.customerFields) {
        if(cf.field === 'first_name') {
          firstName = cf.ticket_ask;
        }
        if(cf.field === 'last_name') {
          lastName = cf.ticket_ask;
        }
      }

      return firstName && lastName;
    },

    isTicketEmailAsked() {
      for(const cf of this.customerFields) {
        if(cf.field === 'email' && cf.ticket_ask) {
          return true;
        }
      }
      return false;
    },
    questionTableFields() {
      return [
        {label: this.$t('customer_information_field'), key: 'field'},
        {label: this.$t('customer_information_order_ask'), key: 'order_ask'},
        {label: this.$t('customer_information_order_mandatory'), key: 'order_mandatory'},
        {label: this.$t('customer_information_ticket_ask'), key: 'ticket_ask'},
        {label: this.$t('customer_information_ticket_mandatory'), key: 'ticket_mandatory'},
      ];
    },
    specificQuestionsFields() {
      return [
        {label: this.$t('customer_information_question'), key: 'question'},
        {label: this.$t('customer_information_question_type'), key: 'question_type'},
        {label: this.$t('actions'), key: 'actions'},
      ];
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('event_customer_information_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>
<style scoped>

 .container-distance,.marginTop0 >div{
    margin-top: 0!important;
  }
  @media (max-width:1710px) {
    .customer-information-container{
    display: block;
  }
  .customer-information-container > div{
    width: 100%!important;
    max-width: 100%!important;
    margin: 0!important;
  }
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
table.b-table.question-table {
  margin-top: 1.9rem
}

table.b-table.question-table thead {
  background-color: #FFF;
  font-weight: bold
}

table.b-table.question-table thead th {
  border-top: 0px;
}

table.b-table.question-table thead th div {
  color: #4D4D4D
}

.question-button-container{
  display:flex;
  flex-direction: column;
}
.question-button-container .btn{
  width:100%;
  height:25px;
  padding:0px;
  line-height:25px;
  margin-bottom:5px;
  font-size:10pt
}


.scrollTable{
  overflow-x: auto;
}
.Rowhead,.Rowbody{
  border-radius: 8px!important;
}
@media (max-width:576px) {
  #edit-pos h3{
    font-size: 1.5rem!important;
  }
}
</style>
