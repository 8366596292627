<template>
  <div>
    <div>
      <b-card >
        <b-card-title class="px-4">{{ $t('extension_mailchimp') }}</b-card-title>
        <b-card-body>
          <b-row class="px-4">
            <b-col cols="12" lg="12">
              <p>{{ $t('extension_mailchimp_description') }}</p>
            </b-col>
          </b-row>
          <b-row class="px-4">
            <b-col cols="12" lg="6">
              <b-form-group :label="$t('extension_mailchimp_api_key')">
                <b-form-input v-model="extension.api_key"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group :label="$t('extension_mailchimp_audience_id')">
                <b-form-input v-model="extension.audience_id"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="px-4">
            <b-col cols="12" lg="12">
              <b-button @click="save" variant="save" class="mb-4">
                {{ $t('save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import {mapState} from "vuex";

export default {
  data() {
    return {
      extension: {
        api_key: '',
        audience_id: ''
      }
    }
  },
  methods: {
    get(){
      api.extension().getByCompany('mailchimp').then(extension => {  
        let data = JSON.parse(extension.value);
        this.extension.api_key = data.api_key;
        this.extension.audience_id = data.audience_id;
      })
    },
    save(){
      api.extension().save('mailchimp', this.extension).then(() => {
        this.$store.commit('success', this.$t('saved_successfully'));
      })
    }
  },
  created() {
    document.title = this.$t('extension_mailchimp')
    if (this.$store.state.user) {
      if (!this.$can('extension_mailchimp')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.get();
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {
    '$store.state.user': {
      handler() {
        if (!this.$can('extension_mailchimp')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
  }
}
</script>

<style scoped>

</style>