export default class openingHourApi {

    constructor(axios) {
        this.axios = axios
    }

    get(eventId) {
        return this.axios.get('/event/' + eventId + '/opening-hour')
    }

    delete(eventId, openingHour) {
        return this.axios.delete('/event/' + eventId + '/opening-hour/' + openingHour.id)
    }

    create(eventId, openingHour) {
        return this.axios.post('/event/' + eventId + '/opening-hour', openingHour)
    }

    getGenerated(eventId, start, end) {
        return this.axios.get('/event/' + eventId + '/generate-timeslots?start=' + start + '&end=' + end)
    }

    getByOrder(orderId, orderSecret, start, end) {
        return this.axios.get('/order/' + orderId + '/' + orderSecret + '/opening-hours?start=' + start + '&end=' + end)
    }

    makeTimeslotForOrder(orderId, orderSecret, option) {
        return this.axios.post('/order/' + orderId + '/' + orderSecret + '/make-timeslot', {option})
    }
}
