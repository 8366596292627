export default class batchesApi {

    constructor(axios) {
        this.axios = axios
    }

    createBatch(name) {
        return this.axios.post('/batches', {name})
    }

    getBatches(page) {
        return this.axios.get('/batches?page=' + page)
    }

    findBatch(unique_id) {
        return this.axios.get('/batches/' + unique_id)
    }

    updateBatch(unique_id, batch) {
        return this.axios.post('/batches/' + unique_id, batch)
    }

    generateBatch(unique_id) {
        return this.axios.post('/batches/' + unique_id + '/generate')
    }

}
