<template>
  <div>
        <label class="d-block mb-0 black">{{ $t('customer_support_free_search') }}</label>
          <b-row>
            <b-col cols="12" md="12" lg="8" xl="9" class="font-weight-bold">
               <b-input-group class="free-search mt-1 mb-3 mb-lg-0">
                  <template #append>
                    <b-input-group-text class="cursor-pointer" @click="getOrders">
                     <b-icon icon="search" color="#b3b3b3"></b-icon>
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="filters.search" @keyup.enter="getOrders" :placeholder="$t('customer_support_search')"></b-form-input>
                </b-input-group>
            </b-col>
            <b-col  cols="12" md="12" lg="4" xl="3" class="flexing">
              <div class="alignCenter btnReset">
                   <div class="font-weight-bold black">
                      <span class="cursor-pointer block" @click="resetFilters">{{ $t('customer_support_reset_filter') }} <b-icon icon="x" scale="1.5"
                                                                                            class="font-weight-bold "></b-icon></span>
                    </div>
                    
                    <div class="text-right">
                    <b-button class="pl-3 pr-3 py-1 text-white text-uppercase font-weight-bold btnFlter" pill variant="warning" type="button"
                          id="popover-button-sync">
                          <b-icon icon="sliders" class="mr-2 font-weight-bold"></b-icon> {{ $t('customer_support_filters') }}
                      </b-button>
                    <b-popover  :show.sync="showFilterPopover" placement="topleft" target="popover-button-sync" title="Filter" triggers="click" >
                      <div class="filterPopUp">
                        <b-form-group
                            :label="$t('customer_support_payment_status')">
                          <b-form-checkbox-group
                              v-model="filters.payment_status"
                              :options="payment_statuses"
                              value-field="value"
                              text-field="label"
                              switches
                              stacked
                          ></b-form-checkbox-group>
                        </b-form-group>
                        <b-row>
                          <b-col>
                            <b-form-group :label="$t('report_date_range_start')" class="mb-0">
                                <date-picker v-model="filters.date_start"></date-picker>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group :label="$t('report_date_range_end')" class="mb-0">
                                <date-picker v-model="filters.date_end"></date-picker>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-form-group class="mb-3" :label="$t('customer_support_company')">
                          <v-select taggable multiple push-tags v-model="filters.company"  :options="companies" label="name"
                                    @change="filters.event = []"></v-select>
                        </b-form-group>

                        <b-form-group class="mb-3" v-if="events.length" :label="$t('customer_support_event')">
                          <v-select multiple  push-tags v-model="filters.event" :options="events" label="name"></v-select>
                        </b-form-group >
                       
                        <div class="text-right">
                          <b-button :disabled="loading" @click="getOrders()" variant="warning" class="pl-3 pr-3 py-1 font-weight-bold">
                            <b-icon v-if="loading" icon="three-dots" animation="cylon" class="mr-2"></b-icon> {{ $t('submit') }}
                          </b-button>
                        </div>
                      </div>
                    </b-popover>
                   
                  </div> </div>
            </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="12" class="font-weight-bold black">{{ $t('customer_support_filters') }}</b-col>
          <b-col>
            <p v-if="filters.company.length" class="filters-tag ">
              <span class="font-weight-bold mr-1">{{ $t('customer_support_company') }}</span>
              <span class="mr-1 p-1 bg-gray rounded" v-for="cmp in filters.company" :key="cmp.id">{{ cmp.name }}</span>
              <b-icon @click="filters.company = [];filters.event = [];getOrders()" class="cursor-pointer ml-2" icon="x"
                      scale="1.5"></b-icon>
            </p>
            <p v-if="filters.event.length" class="filters-tag">
              <span class="font-weight-bold mr-1">{{ $t('customer_support_event') }}</span>
              <span class="mr-1 p-1 bg-gray rounded" v-for="evt in filters.event" :key="evt.id">{{ evt.name }}</span>
              <b-icon @click="filters.event = [];getOrders()" class="cursor-pointer ml-2" icon="x" scale="1.5"></b-icon>
            </p>
            <p v-if="filters.date_start" class="filters-tag">
              <span class="font-weight-bold mr-1">{{ $t('report_date_range_start') }}:</span>
              <span>{{ filters.date_start }}</span>
              <b-icon @click="filters.date_start = null;getOrders()" class="cursor-pointer ml-2" icon="x" scale="1.5"></b-icon>
            </p>
            <p v-if="filters.date_end" class="filters-tag">
              <span class="font-weight-bold mr-1">{{ $t('report_date_range_end') }}:</span>
              <span>{{ filters.date_end }}</span>
              <b-icon @click="filters.date_end = null;getOrders()" class="cursor-pointer ml-2" icon="x" scale="1.5"></b-icon>
            </p>
            <p v-for="(status, index) in filters.payment_status" :key="index" class="filters-tag">
              <span class="font-weight-bold mr-1">{{ $t('order_status_' + status) }}:</span>
              <span>{{ $t('yes') }}</span>
              <b-icon @click="filters.payment_status.splice(index, 1);getOrders()" class="cursor-pointer ml-2" icon="x"
                      scale="1.7"></b-icon>
            </p>
            <p v-if="filters.box_office === true || filters.box_office === 'true'" class="filters-tag">
              <span class="font-weight-bold mr-1">{{ $t('order_overview_box_office') }}:</span>
              <span>{{ $t('yes') }}</span>
              <b-icon @click="filters.box_office = null;getOrders()" class="cursor-pointer ml-2" icon="x" scale="1.5"></b-icon>
            </p>
            <p v-if="filters.is_expired === true || filters.is_expired === 'true'" class="filters-tag">
              <span class="font-weight-bold mr-1">{{ $t('pos_product_expired') }}:</span>
              <span>{{ $t('yes') }}</span>
              <b-icon @click="filters.is_expired = null;getOrders()" class="cursor-pointer ml-2" icon="x" scale="1.5"></b-icon>
            </p>
          </b-col>
        </b-row>

    <b-row class="mt-2 filterTable">
      <b-col v-if="orders.length" class="tableBig ">
        <b-table 
                 :items="orders"
                 :fields="fields"
                 :sort-by.sync="filters.sortBy"
                 :sort-desc.sync="filters.sortDesc"
                 :no-local-sorting="true">
          <template #cell(date)="{item}" >
            {{ formatDate(item.created_at) }}
          </template>
          <template #cell(payment_status)="{item}">
            <b-badge variant="warning" v-if="item.is_refunded">{{ $t('order_is_refunded') }}</b-badge>
            <b-badge v-else-if="item.payment_status === 'paid'" variant="success">{{ $t('order_status_paid') }}</b-badge>
            <b-badge v-else-if="item.payment_status === 'new'" variant="danger">{{ $t('order_status_new') }}</b-badge>
            <b-badge v-else variant="secondary">{{ $t('order_status_pending') }}</b-badge>
          </template>
          <template #cell(actions)="{item}">
            <b-button v-if="$can('order_detail_view')" variant="row-edit"
                      :to="{name: 'OrderDetails', params:{id: item.id, order_number: item.order_number}}">
              {{ $t('order_details') }}
            </b-button>
          </template>
        </b-table>
      </b-col>
      <b-col v-else class="text-center">
        <h1>{{ $t('no_data_to_show') }}</h1>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import api from "@/api";
import moment from "moment";
import DatePicker from "@/components/DatePicker";

export default {
  name: "CustomerSupportOverview",
  data() {
    return {
      fields: [
        { key: 'order_number', label: this.$t('order_number'), sortable: true },
        { key: 'customer_name', label: this.$t('order_full_name'), sortable: true },
        { key: 'company_name', label: this.$t('customer_company_name'), sortable: true },
        { key: 'event_name', label: this.$t('event_name'), sortable: true },
        { key: 'date', label: this.$t('timeslot_date'), sortable: true },
        { key: 'payment_status', label: this.$t('customer_support_payment_status').replaceAll(':', ''), sortable: true },
        { key: 'actions', label: this.$t('order_actions'), sortable: false}
      ],
      showFilterPopover: false,
      loading: false,
      companies: [],
      filters: {
        company: [],
        event: [],
        payment_status: [],
        search: null,
        date_start: null,
        date_end: null,
        sortBy: null,
        sortDesc: false,
        box_office: null,
        is_expired: null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      orders: [],
      payment_statuses: [
        {
          value: 'new',
          label: this.$t('order_status_new')
        },
        {
          value: 'pending',
          label: this.$t('order_status_pending')
        },
        {
          value: 'paid',
          label: this.$t('order_status_paid')
        }
      ]
    }
  },
  mounted() {
    if(this.$store.state.user) {
      if(!this.$can('customer_support_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    return api.customerSupport().dependencies().then(response => {
      this.companies = response.companies;
    })
  },
  methods: {
    resetFilters() {
      this.filters = {
        company: [],
        event: [],
        payment_status: [],
        search: null,
        date_start: null,
        date_end: null,
        sortBy: null,
        sortDesc: false,
        box_office: null,
        is_expired: null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      this.orders = [];
    },
    getOrders() {
      if(this.loading) {
        return;
      }
      if(!this.isFiltersValid()) {
        this.orders = [];
        return;
      }
      this.loading = true;
      api.customerSupport().orders(this.filters).then(response => {
        this.orders = response.map(order => {
          return Object.assign(order, {is_refunded: order.order_products.filter(p => p.refunded_at === null).length === 0})
        })
        this.showFilterPopover = false;
        this.loading = false;
      })
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    isFiltersValid() {
      return (this.filters.company.length ||
          this.filters.event.length ||
          this.filters.payment_status.length ||
          this.filters.box_office !== null ||
          this.filters.is_expired !== null ||
          (this.filters.search && this.filters.search.length));

    }
  },
  watch: {
    'filters.sortBy'() {
      this.getOrders();
    },
    'filters.sortDesc'() {
      this.getOrders();
    },
    filters: {
      deep: true,
      handler() {
        // this.getOrders();
      }
    },
    '$store.state.user': {
      handler() {
        if(!this.$can('customer_support_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
    '$store.state.language': {
      handler() {
        this.fields = [
          { key: 'order_number', label: this.$t('order_number'), sortable: true },
          { key: 'customer_name', label: this.$t('order_full_name'), sortable: true },
          { key: 'company_name', label: this.$t('customer_company_name'), sortable: true },
          { key: 'event_name', label: this.$t('event_name'), sortable: true },
          { key: 'date', label: this.$t('timeslot_date'), sortable: true },
          { key: 'payment_status', label: this.$t('customer_support_payment_status').replaceAll(':', ''), sortable: true },
          { key: 'actions', label: this.$t('order_actions'), sortable: false}
        ]
      }
    }
  },
  computed: {
    events() {
      let events = [];
      if(this.filters.company.length) {
        for(const company of this.filters.company) {
          events = events.concat(company.events);
        }
        return events;
      }
      if(!this.companies.length) {
        return [];
      }

      for(const company of this.companies) {
        events = events.concat(company.events);
      }
      return events;
    }
  },
  components: {
    DatePicker
  },
}
</script>

<style scoped lang="scss">

.bg-gray {
  background-color: #d7d7d7;
}
.DateRange {
  display: flex !important;
  justify-content: space-between;
}
.DateRange >div{
  width: 48%;
}
.badge{
  min-width: 70px;
}
.filterPopUp{
  width: 100%;
}
.popover.b-popover{
  padding-top: 10px;
  width: 350px;
  max-width: 350px;
  border: 3px solid #193e58;
}
.flexing{
  display: flex;
  align-items: center;
}

.alignCenter{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.btnFlter{
  font-size: 15px;
}
.btnReset{
  font-size: 13px;
}
p,span,h1,h2,h3,h4,.black{
  color: #505050!important;
}

.filters-tag {
  font-size: 13px;
  display: inline-block;
  border: 1px solid #193e58;
  padding: 3px 15px 3px 9px;
  border-radius: 90px;
  margin-right:.7rem;
  margin-bottom:.7rem;
  color: #505050!important;
  span:last-of-type{
     color: #193e58!important;
  }
  span:first-of-type{
    color: #505050!important;
  }
}
.badge {
  color: #ffffff !important;
}

.free-search {
  max-width: 300px;
  border-radius:5px;
  .input-group-text {
    background-color: #ffffff;
    border: 2px solid #EEEEEE;
    border-left: 0;
    height: 50px;
    border-radius:5px;
  }

  input.form-control {
    border-right: 0;

  }
  input.form-control::placeholder{
    color: #b3b3b3;
  }
}
@media (max-width:992px) {
  .free-search {
    width: 100%;
    max-width: unset;
  }
}
@media (max-width:500px) {
 .popover.b-popover{
  width: 250px;
  max-width: 250px;
}
.DateRange {
 display: block!important;
}
.DateRange >div{
  width: 99%;
}
}
</style>
