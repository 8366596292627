export default class settingsApi {

  constructor(axios) {
      this.axios = axios
  }

  get(reference){
    return this.axios.get('/setting/' + reference)
  }

  save(reference, value){
    return this.axios.post('/setting/' + reference + '/' + value)
  }

}
