<template>
  <b-select v-model="model" text-field="label" value-field="value" :options="countries"></b-select>
</template>

<script>
import countries from 'i18n-iso-countries'

export default {
  name: 'CountrySelection',
  props: {
    value: String
  },
  computed: {
    countries () {
      countries.registerLocale(require('i18n-iso-countries/langs/'+this.lang+'.json'))

      const list = countries.getNames(this.lang, { select: 'official' })
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    },
    model: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    }
  },
  data () {
    return {
      lang: this.$store.state.language
    }
  }
}
</script>