<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="info-card mb-2">
          <b-row>
            <b-col cols="12" md="6" lg="2">
              <b-form-group>
                {{ $t('report_event') }}
                <b-select @change="eventSelected" v-model="filter_event" :options="events"></b-select>
                <b-form-checkbox class="mt-2 report-switch-small" switch v-model="includeArchivedEvents" :value="true" :unchecked-value="false">{{ $t('reports_include_archived_events') }}</b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="2">
             <label class="mb-0">{{ $t('report_tickets') }}</label>
              <b-dropdown id="dropdown-Checkbox" :text="filter_products && filter_products.length ? (filter_products.length + ' ' + $t('report_tickets_selected')) : $t('report_select_ticket')" ref="dropdown" class="w-100">
                <b-form-checkbox v-model="event_all_selected" @change="toggleSelected">{{ event_all_selected ? $t('unselect_all') :  $t('select_all') }}</b-form-checkbox>
                <b-form-group>
                  <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="filter_products"
                      name="flavour-2"
                  >
                    <b-form-checkbox v-for="product in products" :key="product.id" :value="product.id" class="w-100">{{ product.name }}</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-dropdown>
            </b-col>
            <b-col  cols="12" md="6" lg="3">
              {{ $t('report_date_range_start') }}
              <date-picker v-model="date_range.start"></date-picker>
            </b-col>
            <b-col  cols="12" md="6" lg="3">
              {{ $t('report_date_range_end') }}
              <date-picker v-model="date_range.end"></date-picker>
            </b-col>
            <b-col  cols="12" md="12 " lg="2">
              <b-button @click="filter" class=" text-right btn-filter" variant="new" :disabled="loading || loadingOrders || loadingRevenue">
                <b-icon v-if="loading || loadingOrders || loadingRevenue" icon="three-dots" animation="cylon" class="mr-2"></b-icon> {{ $t('report_filter_btn') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row  v-if="data">
      <b-col lg="4" sm="6" cols="12">
        <b-card class="info-card center">
          <span>{{ data.orders_count }}</span><br/>
          <span>{{ $t('nav_orders') }}</span>
        </b-card>
      </b-col>
      <b-col lg="4" sm="6" cols="12">
        <b-card class="info-card center">
          <span>{{ data.ticket_count }}</span><br/>
          <span>{{ $t('report_tickets_sold') }}</span>
        </b-card>
      </b-col>
      <b-col lg="4" sm="6" cols="12">
        <b-card class="info-card center">
          <span>{{ data.total_revenue | currency }}</span><br/>
          <span>{{ $t('report_in_worth_of') }}</span>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col v-if="orders && orders.length" class="tableBig">
        <b-table class="orderTable" :fields="fields" :items="orders">
          <template v-slot:cell(created_at)="{item}">
            {{ item.created_at | date_time }}
          </template>
          <template v-slot:cell(actions)="{item}">
            <b-button v-if="$can('order_detail_view')" variant="row-edit"
                      :to="{name: 'OrderDetails', params:{id: item.order_id, order_number: item.order_number}}">
              {{ $t('order_details') }}
            </b-button>
          </template>
        </b-table>
        <pagination v-if="page_data" :limit="3" :data="page_data" @pagination-change-page="getData"></pagination>
      </b-col>
      <b-col v-else>
        <b-alert show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>
      </b-col>
    </b-row>

    <b-row v-if="groups && groups.length" class="mt-5">
      <b-col lg="6" sm="12">
        <b-card>
          <b-card-title>{{ $t('report_orders_grouped_by_tickets') }}</b-card-title>
          <b-card-body>
            <b-table class="groups-table text-center" :fields="[{key: 'grouped', label: $t('report_tickets_per_order')}, {key: 'count', label: $t('report_number_of_orders')}]" :items="groups">
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import api from "@/api";
export default {
  name: "ReportSalesPerTickettype",
  data() {
    return {
      loading: false,
      loadingOrders: false,
      loadingRevenue: false,
      options_event: [],
      filter_event: null,
      includeArchivedEvents: false,
      data: {
        orders_count: 0,
        ticket_count: 0,
        total_revenue: 0,
      },
      date_range: {
        start: null,
        end: null,
      },
      products: [],
      filter_products: [],
      orders: [],
      page_data: null,
      fields: [
        {key: 'order_number', label: this.$t('order_number')},
        {key: 'created_at', label: this.$t('report_order_placed')},
        {key: 'customer_name', label: this.$t('order_full_name')},
        {key: 'email', label: this.$t('email')},
        {key: 'product_name', label: this.$t('report_ticket_type')},
        // {key: 'city', label: this.$t('customer_city')},
        {key: 'actions', label: ''},
      ],
      groups: [],
      event_all_selected: false
    }
  },
  mounted() {
    if(this.$store.state.user) {
      if(!this.$can('reports_sales_by_tickettype_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    if(!this.$route.query.event || !this.$route.query.ticket) {
      this.loading = true;
      api.event().all(true).then(events => {
        this.options_event = events.map(event => {
          return {value: event.id, text: event.name, status: event.status, starts_at:event.created_at};
        })
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
      return;
    }
    this.loading = true;
    let eventId = this.$route.query.event;
    eventId = parseInt(eventId.toString());
    this.filter_event = eventId;
    this.filter_products = [parseInt(this.$route.query.ticket.toString())];
    api.event().all(true).then(events => {
      this.options_event = events.map(event => {
        if(event.id === eventId) {
          this.date_range.start = new Date(event.created_at);
          this.date_range.end = new Date();
          if(event.status === 'archived') {
            this.includeArchivedEvents = true;
          }

        }
        return {value: event.id, text: event.name, status: event.status, starts_at: event.created_at};
      })
      this.getProducts();
      this.getData();
      this.getOrdersGroupedByTickets();
      this.getRevenue();
      this.getOrders();
    });
  },
  methods: {
    filter() {
      this.getData();
      this.getOrdersGroupedByTickets();
      this.getRevenue();
      this.getOrders();
    },
    getProducts() {
      api.event().products(this.filter_event).then(products => {
        this.products = products.map(p => {
          if(p.product_group && p.product_group.name !== 'event_default_group') {
            p.name = p.product_group.name + ' - ' + p.name;
          }
          return p;
        }).sort();
      })
    },
    getData(page = 1) {
      if (!page) {
        page = 1
      }
      this.loading = true;      
      api.report().getSalesPerTickets(this.filter_event, {
        tickets:  this.filter_products,
        date_range: this.date_range,
        page
      }).then(response => {
        this.data.ticket_count = response.total;
        this.orders = response.data;
        this.page_data = response;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    getRevenue() {
      this.loadingRevenue = true;
      api.report().getSalesPerTicketsRevenue(this.filter_event, {
        tickets:  this.filter_products,
        date_range: this.date_range
      }).then(response => {
        this.data.total_revenue = response;
        this.loadingRevenue = false;
      }).catch(() => {
        this.loadingRevenue = false;
      })
    },
    getOrders() {
      this.loadingOrders = true;
      api.report().getSalesPerTicketsOrders(this.filter_event, {
        tickets:  this.filter_products,
        date_range: this.date_range
      }).then(response => {
        this.data.orders_count = response;
        this.loadingOrders = false;
      }).catch(() => {
        this.loadingOrders = false;
      })
    },
    getOrdersGroupedByTickets() {
      api.report().getOrdersGroupedByTickets(this.filter_event, {
          tickets:  this.filter_products,
          date_range: this.date_range
        }).then(response => {
          this.groups = response;
      })
    },
    eventSelected() {
      const event = this.events.find(e => e.value === this.filter_event);
      if(event) {
        this.date_range.start = new Date(event.starts_at);
      }
      this.date_range.end = new Date();
      this.filter_products = [];
      this.getProducts();
    },
    toggleSelected() {
      this.filter_products = this.event_all_selected ? this.products.map(p => p.id) : [];
    }
  },
  computed: {
    events() {
      if(this.includeArchivedEvents) {
        return this.options_event;
      }
      return this.options_event.filter(event => event.status === 'live')
    },
  },
  components: {
    DatePicker,
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('reports_sales_by_tickettype_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style scoped>
.info-card.center .card-body {
  text-align: center;
}

.info-card span:first-of-type {
  font-size: 28pt
}

.btn-filter{
  margin-top: 23px;
  height:50px;
  width: 100%;
  text-align: center!important;
}


</style>

<style>
#dropdown-Checkbox {
margin-bottom:1rem;
height: 50px;
}
#dropdown-Checkbox .dropdown-menu{
 padding: .8rem .7rem .4rem!important;
 font-size: 12px;
 min-width: 100%;
}
#dropdown-Checkbox .custom-control-label{
padding-top: 2px;
cursor: pointer;
}
#dropdown-Checkbox .dropdown-toggle{
background-color: #fff!important;
color: #808080;
border: 2px solid #EEEEEE;
text-align: left;
 font-size: 14px;
 width: 100%;
}
#dropdown-Checkbox .dropdown-toggle::after{
position: absolute;
top: 50%;
right: 10px;
}

.vs__dropdown-toggle {
  height: 50px !important;
}
.groups-table thead {
  background-color: #fff !important;

}
.groups-table thead tr th div {
  color: #808080 !important;
  font-weight: bold;
}
.groups-table thead tr th {
  border-top: none !important;
}
</style>
