export default class extensionApi {

  constructor(axios) {
      this.axios = axios
  }

  getByCompany(type) {
      return this.axios.get('/extension/' + type)
  }

  save(type, extension) {
      return this.axios.post('/extension/' + type, extension)
  }

}
