<template>
  <div id="cs-dashboard">
    <h1 class="text-center">{{ $t('dashboard_title') }}</h1>
  </div>
</template>
<script>
export default {
  name: 'CustomerSupportDashboard',
  created(){
    document.title = this.$t('backend_title_dashboard')
  }
}
</script>
<style @scoped>
#cs-dashboard {
  padding-top: 50vh;
}
</style>
