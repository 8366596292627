<template>
  <div id="event-nav-container" class="content-main-container p-0" v-if="event">
    <div class="title-container" >{{ $t('event_nav_title') }}</div>
    <div class="content-container" v-if="event" >
      <div v-for="(mod, i) in modules" :key="mod.text">
        <div class="module-row"  v-b-toggle="'accordion-' + i">
          {{ (i + 1) }}: {{ $t(mod.text) }}
        </div>
        <b-collapse visible  class="module-inner-container" :id="'accordion-' + i">
          <b-nav-item v-for="sub_mods in mod.inner_modules" :key="sub_mods.routeName"
                      :to="{name: sub_mods.routeName, params: {id: $route.params.id}}">
            <div :completed="sub_mods.completed" :active="$route.name === sub_mods.routeName">
              <div v-if="sub_mods.completed" class="checkmark_container">
                <div class="checkmark_stem"></div>
                <div class="checkmark_kick"></div>
              </div>
            </div>
            <span>{{ $t(sub_mods.text) }}</span>
          </b-nav-item>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['event'],
  data(){
    return {
      hasProducts: false,
      hasTimeslots: false,
      hasCustomerInformation: false,
      hasPointsOfSale: false,
      modules: []
    }
  },
  created() {
    this.hasProducts = this.event.products_count > 0;
    this.hasTimeslots = this.event.timeslots_count > 0;
    this.hasPointsOfSale = this.event.points_of_sale_count > 0;
    this.hasCustomerInformation = this.event.customer_information_count > 0;
    this.initModules();
  },
  methods: {
    initModules() {
      this.modules = [];
      // Basic
      const event_module_name_basic = [];
      event_module_name_basic.push({routeName: 'ManageEvent', text: 'event_nav_manage', completed: this.event.status !== null});
      if(this.$can('event_products_view')) {
        event_module_name_basic.push({routeName: 'EventProducts', text: 'event_nav_products',  completed: this.hasProducts})
      }
      if(this.$can('event_timeslot_view')) {
        event_module_name_basic.push({routeName: 'EventTimeslots', text: 'event_nav_timeslots', completed: this.hasTimeslots})
      }
      if(this.$can('event_customer_information_view')) {
        event_module_name_basic.push({routeName: 'EventCustomerInformation', text: 'event_nav_customer_information', completed: this.hasCustomerInformation})
      }
      this.modules.push({
        text: "event_module_name_basic",
        inner_modules: event_module_name_basic
      })

      // Sales
      if(this.$can('event_point_of_sale_view')) {
        this.modules.push({
          text: "event_module_name_sales",
          inner_modules: [
            {routeName: 'EventPointsOfSale', text: 'event_nav_pos', completed: this.hasPointsOfSale},
          ]
        });
      }

      // Marketing
      if(this.$can('event_marketing_view')) {
        this.modules.push({
          text: "event_module_name_marketing",
          inner_modules: [
            {routeName: 'EventMarketing', text: 'event_module_name_marketing'},
          ]
        });
      }

      // visitors
      if(this.$can('event_visitor_list_view')) {
        this.modules.push({
          text: "event_module_name_organise",
          inner_modules: [
            {routeName: 'EventVisitorList', text: 'event_print_visitor_list'},
          ]
        });
      }
    }
  },
  watch: {
    '$store.state.user': {
      handler() {
        this.initModules();
      }
    }
  }
}
</script>
<style lang="scss">
.content-main-container#event-nav-container {

  div.content-container {
    padding: 0
  }

  .module-row {
    background-color: #193E58;
    border-top: 1px solid #F5F5F5;
    color: #FFF;
    padding: 6px 25px;
    font-size: 16px
  }

  .module-row:last-of-type {
    border-radius: 0 0 6px 6px
  }

  .module-inner-container {

    li.nav-item {
      list-style-type: none;

      a {
        font-size: 14px;
        color: #4D4D4D;
        display: flex;

        div {
          width: 25px;
          height: 25px;
          border-radius: 100px;
          border: 2px solid #EEEEEE
        }

        div[active=true] {
          border-color: #F0B52D;
        }

        div[completed=true] {
          background: #F0B52D;
          border-color:#F0B52D;
          position:relative
        }

        .checkmark_container{
          display:inline-block;
          -ms-transform: rotate(45deg); /* IE 9 */
          -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
          transform: rotate(45deg);
          border:0px
        }

        .checkmark_stem {
            position: absolute;
            width:2px;
            height:10px;
            background-color:#fff;
            left:10px;
            top:5px;
        }

        .checkmark_kick {
            position: absolute;
            width:8px;
            height:2px;
            background-color:#fff;
            left:6px;
            top:14px;
        }

        span {
          align-self: center;
          padding-left: 15px
        }
      }
    }
  }

}
.content-main-container{
  margin-top: 4.5rem;
}
</style>
