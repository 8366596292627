<template>
  <div>
    <div v-if="user && $can('dashboard_view')">
      <customer-support-dashboard v-if="user.role === 'customer_support'"/>
      <normal-dashboard v-else/>
    </div>
    <div v-else>
      <h3>Hello</h3>
    </div>
  </div>
</template>
<script>

import NormalDashboard from "@/pages/NormalDashboard";
import CustomerSupportDashboard from "@/pages/CustomerSupportDashboard";
import {mapState} from "vuex";
export default {
  name: 'Dashboard',
  created(){
    document.title = this.$t('backend_title_dashboard')
  },
  computed: {
    ...mapState(['user'])
  },
  components: {
    NormalDashboard,
    CustomerSupportDashboard
  }
}
</script>
<style @scoped lang="scss">
.bg-blue {
  background-color: #1B3E58 !important;
}
.event-statistics {
  border: solid 1px #efefef;
  background: #fff;
  box-shadow: 0 3px 6px #52575d19;
  border-radius: 6px;
  margin-bottom: 2em;

  .spinner-body {
    font-size: 2rem
  }

  .event-statistics-title{
    border-bottom:2px solid #F5F5F5;
    padding:1em;
    color:#666666;
    font-size:13pt;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius:6px 6px 0 0;
  }

  .event-statistics-content{
    padding:2em;

    .tickets-sold{
      color:#B3B3B3;
      font-size:11pt
    }
  }

  .event-statistics-stats{
    padding:15px 1em 0;

    .row{
      div.col span:first-of-type{
        color:#808080;
        margin-bottom: 5px;
      }
      div.col span:last-of-type{
        color:#B3B3B3;
        font-size:13px
      }
    }
  }
}
</style>
