<template>
  <div v-if="template" fluid>
    <div class="content-main-container container-distance">
      <div class="title-container">{{ $t('template_title', template) }}</div>
      <div class="content-container">
        <b-row>
          <b-col class="mb-3">
            <b-button variant="warning" @click="generatePreview()" v-if="!generating">{{ $t('generate_preview') }}</b-button>
            <b-spinner v-else></b-spinner>
          </b-col>
          <b-col class="mb-3 text-right">
            
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('ticket_template_name')">
              <b-form-input v-model="template.name"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <h4>{{ $t('template_background') }}</h4>
            <p>{{ $t('template_background_hint') }}</p>
            <p>{{ $t('template_background_jpg_hint') }}</p>
            <b-row>
              <b-col>
                <b-form-group :label="$t('ticket_template_background')">
                  <input type="file" id="image" aria-describedby="image-feedback" accept="image/jpg,image/jpeg" ref="image" v-on:change="onImageChange()"/>
                  <span class="invalid-feedback" :class="this.state.image === false ? 'show' : ''">{{ $t('edit_ticket_template_err_no_jpg') }}</span>
                  <span class="invalid-feedback" :class="this.reachedLimit === true ? 'show' : ''">{{ $t('edit_ticket_template_reached_limit') }}</span>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-img fluid v-if="template.background" :src="template.background"></b-img>
              </b-col>
            </b-row>
            <h4 class="mt-4">{{ $t('template_qr') }}</h4>
            <p>{{ $t('template_position_hints') }}</p>
            <b-row>
              <b-col>
                <b-form-group :label="$t('top')" label-cols="3">
                  <b-form-input type="number" v-model="template.qr.top" @input="checkQRPosition" :state="state.qr_top"></b-form-input>
                </b-form-group>
                <b-form-group :label="$t('width')" label-cols="3">
                  <b-form-input type="number" v-model="template.qr.width" @input="checkQRPosition" :state="state.qr_width"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('left')" label-cols="3">
                  <b-form-input type="number" v-model="template.qr.left" @input="checkQRPosition" :state="state.qr_left"></b-form-input>
                </b-form-group>
                <b-form-group :label="$t('height')" label-cols="3">
                  <b-form-input type="number" v-model="template.qr.height" @input="checkQRPosition" :state="state.qr_height"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <span v-if="state.qr_top === false || state.qr_left === false" class="d-block text-danger">{{ $t('ticket_template_qr_code_4cm') }}</span>
            <span v-if="state.qr_width === false || state.qr_height === false" class="d-block text-danger">{{ $t('ticket_template_qr_size') }}</span>
          </b-col>
          <b-col>

            <h4>{{ $t('template_texts') }}</h4>
            <p>{{ $t('template_texts_hint') }}</p>
            <b-form-group>
              <b-button variant="warning" @click="addTextBlock()">{{ $t('template_add_text') }}</b-button>
            </b-form-group>

            <hr>
            <div v-for="(text, i) in template.texts" :key="`text` + i">
              <b-row>
                <b-col cols="5">
                  <b-form-group :label="$t('top')" label-cols="3">
                    <b-form-input type="number" v-model="text.top"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="5">
                  <b-form-group :label="$t('left')" label-cols="3">
                    <b-form-input type="number" v-model="text.left"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="2" class="text-right">
                  <b-button variant="danger" @click="deleteText(i)" size="sm">{{ $t('delete') }}</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group :label="$t('template_text_block')">
                    <b-textarea v-model="text.content"></b-textarea>
                  </b-form-group>
                  <b-button size="sm" class="mr-1 mb-1" v-for="placeholder in placeholders" :key="placeholder"
                            @click="text.content += '[' + placeholder + ']'">
                    {{ '[' + placeholder + ']' }}
                  </b-button>
                  <hr>
                </b-col>
              </b-row>
            </div>

          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col class="text-right">
            <b-button @click="saveTemplate()" variant="success" :disabled="!template.background || !this.template.texts.length || this.state.qr_left === false || this.state.qr_top === false || this.state.qr_width === false || this.state.qr_height === false">{{ $t('template_save') }}</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
import axios from "axios";

export default {
  data() {
    return {
      template: null,
      generating: false,
      reachedLimit: false,
      state: {
        image: null,
        qr_top: null,
        qr_left: null,
        qr_width: null,
        qr_height: null
      },
      placeholders: [
        'event_name',
        'event_date',
        'customer_first_name',
        'customer_last_name',
        'product_name',
        'order_number',
        'ticket_secret',
        'product_group_name',
        'timeslot',
        'event_start_time',
        'event_end_time',
        'location'
      ]
    }
  },
  methods: {
    addTextBlock() {
      this.template.texts.push({content: ''})
    },
    generatePreview() {
      this.generating = true
      api.ticket().previewTemplate(this.template, {
        ticket: {
          id: this.template.id + new Date().getTime(),
          secret: this.template.id + 'PREVIEW',
          customer: {
            first_name: 'Jan',
            last_name: 'Janssen',
            company_name: 'Janssen BV'
          },
          order_product: {
            product: {
              name: 'VIP Ticket'
            }
          }
        },
        background: this.template.background,
        qr: Object.assign(this.template.qr, {
          data: {
            id: this.template.id + 12345667,
            secret: this.template.id + 'PREVIEW',
          }
        }),
        texts: this.template.texts
      }).then(url => {
        window.open(url, '_blank')
      }).catch(() => {
        this.$store.commit('errors', ['unable_to_generate_template'])
      }).finally(() => {
        this.generating = false
      })
    },
    onImageChange() {
      this.state.image = null;
      if( this.$refs['image'].files[0].type != 'image/jpeg' ){
        this.state.image = false;
        this.$store.commit('errors', [this.$t('edit_ticket_template_err_no_jpg')])
        return; 
      }

      if (this.$refs['image'].files[0].size < 300000) {
        this.reachedLimit = false
        api.cdn().signUpload(this.$refs['image'].files[0].name).then((response) => {
          // Upload the file
          axios.create().put(response.upload_url, this.$refs['image'].files[0], {
            headers: {
              'Content-Type': this.$refs['image'].files[0].type
            }
          }).catch((e) => {
            // Upload failed...
            console.error(e)
          }).then(() => {
            // upload done, create the video in the database
            this.template.background = response.target_url
          })
        })
      } else {
        this.reachedLimit = true
      }
    },
    deleteText(index) {
      this.template.texts.splice(index, 1)
    },
    saveTemplate() {
      this.state.image = null;
      if( this.$refs['image'].files[0] && this.$refs['image'].files[0].type != 'image/jpeg' ){
        this.state.image = false;
        this.$store.commit('errors', [this.$t('edit_ticket_template_err_no_jpg')])
        return; 
      }

      for(const t of this.template.texts) {
        if(!t.top || !t.left || !t.content) {
          this.$store.commit('errors', [this.$t('edit_ticket_template_err_textbox_field')])
          return;
        }
      }

      // Check if a textblock has been set
      if(!this.template.texts[0]){
        this.$store.commit('errors', [this.$t('edit_ticket_template_err_no_textbox')])
        return;
      }

      api.ticket().saveTemplate(this.template).then((template) => {
        this.template = template
        this.$store.commit('success', 'ticket_template_updated')
      })
    },
    checkQRPosition() {
      this.state.qr_top = null;
      this.state.qr_left = null;
      this.state.qr_width = null;
      this.state.qr_height = null;

      if(this.template.qr.width < 100 || this.template.qr.width > 225) {
        this.state.qr_width = false;
      }
      if(this.template.qr.height < 100 || this.template.qr.height > 225) {
        this.state.qr_height = false;
      }

      const cm = this.cmToPx(1.5);
      if(this.template.qr.top < cm) {
        this.state.qr_top = false;
      }
      if(this.template.qr.left < cm) {
        this.state.qr_left = false;
      }

      const pageWidth = 595;
      const pageHeight = 842;

      if((parseInt(this.template.qr.width) + parseInt(this.template.qr.left)) > (pageWidth - cm)) {
        this.state.qr_left = false;
      }

      if((parseInt(this.template.qr.height) + parseInt(this.template.qr.top)) > (pageHeight - cm)) {
        this.state.qr_top = false;
      }
    },
    cmToPx(cm) {
      return cm / 0.026458;
    }
  },
  created() {
    document.title = this.$t('backend_title_edit_ticket_templates')
    if(this.$store.state.user) {
      if(!this.$can('ticket_template_edit')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.ticket().getTemplate(this.$route.params.id).then((template) => {
      if (!template.qr) {
        template.qr = {
          width: 175,
          height: 175,
          top: 350,
          left: 175
        }
      }
      if (!template.texts) {
        template.texts = []
      }
      this.template = template
    })
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('ticket_template_edit')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.show{
  display:block !important
}
</style>
