export default class ScannerTerminalScan {

  constructor(axios) {
      this.axios = axios
  }

  scan( event, payload ){
    let id = payload.id
    let secret = payload.secret

    return this.axios.post('/scanner-terminal/' + event + '/scan/ticket', {id, secret})
  }

  tickets(event, page, search) {
    return this.axios.get('/scanner-terminal/' + event + '/tickets', {
      params: {
        page,
        search
      }
    });
  }

  stats(event) {
    return this.axios.get('/scanner-terminal/' + event + '/stats');
  }
}
