export default class timeslotApi {

    constructor(axios) {
        this.axios = axios
    }

    get(eventId) {
        return this.axios.get('/event/' + eventId + '/timeslot')
    }

    save(eventId, timeslot) {
        return this.axios.post('/event/' + eventId + '/timeslot/' + timeslot.id, timeslot)
    }

    delete(eventId, timeslotId) {
        return this.axios.delete('/event/' + eventId + '/timeslot/' + timeslotId)
    }

    create(eventId, timeslot) {
        return this.axios.post('/event/' + eventId + '/timeslot', timeslot)
    }

    getByOrder(orderId, orderSecret) {
        return this.axios.get('/order/' + orderId + '/' + orderSecret + '/timeslot')
    }

}
