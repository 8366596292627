<template>
  <b-container>
    <b-row>
      <b-col class="content-block text-center pr-5 pl-5">
        <div class="pt-5 pb-5">
          <b-icon icon="check-circle-fill" font-scale="6" variant="success"></b-icon>
        </div>
        <h1 class="title">{{ $t('ticket_changed') }}</h1>
        <p class="subtitle m-5">{{ $t('ticket_changed_text') }}</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  created(){
    if(document.body.contains(document.getElementById('company-header'))){
      document.getElementById("company-header").scrollIntoView();
    }
    document.title = this.$t('order_thank_you_title')
  }
}
</script>
<style scoped>
h1.title {
  font-size: 2em;
}

p.subtitle {
  font-size: 1.4em;
}
</style>
