<template>
  <b-container fluid>
    <div class="select-events-container">
     <div v-if="!loading && events.length" class="center-div">
       <b-form-group :label="$t('scan_terminal_choose_event_title')" class="select-event">
         <b-form-select :options="events" v-model="event" text-field="name" value-field="id"></b-form-select>
       </b-form-group>
       <b-button @click="openScanEvent" class="submit-btn" variant="success" :disabled="!event">{{ $t('submit') }}</b-button>
     </div>
      <div v-if="!loading && !events.length" class="center-div">
        <label>{{ $t('no_events_to_show') }}</label>
      </div>
    </div>
  </b-container>
</template>

<script>


import api from "@/api";

export default {
  name: "ScannerTerminalEvents",
  data() {
    return {
      events: [],
      event: null,
      loading: false,
    }
  },
  mounted() {
    this.loading = true;
    api.event().live().then(response => {
      this.events = response;
      this.loading = false;
    }).catch(() => {
      this.loading = false;
    })
  },
  methods: {
    openScanEvent() {
      this.$router.push({name: 'ScannerTerminalScan', params:{event: this.event}});
    }
  }
}
</script>

<style scoped>
.select-events-container {
  position: relative;
  height: 100vh;
}
.submit-btn {
  height: 50px !important;
  display: inline;
}
.select-event {
  display: inline;
  margin-right: 15px;
}
.center-div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>