<template>
  <div>
    <b-form-group v-if="$can('campaigns_create')" class="text-right container-distance">
      <b-button variant="new" class="mr-3" @click="$bvModal.show('link-generator')">{{ $t('campaign_overview_btn_link_generator') }}</b-button>
      <b-button variant="new" @click="$bvModal.show('new-campaigns')">{{ $t('campaign_create') }}</b-button>
    </b-form-group>


    <div class="tableBig">
      <b-table class="campaigns-table" v-if="campaigns.length" :items="campaigns"
               :fields="[{key: 'name', label: $t('campaign_name')}, {key: 'times_used', label: $t('campaign_times_used')}, {key: 'total_revenue', label: $t('campaign_total_revenue')}, {key: 'actions', label: $t('order_actions')}]">
        <template v-slot:cell(total_revenue)="{item}">
          {{ item.total_revenue | currency }}
        </template>

        <template v-slot:cell(actions)="{item}">
          <b-button v-if="$can('campaigns_edit')" variant="primary" :to="{name: 'CampaignDetails', params: {id: item.unique_id}}"
                    class="mr-2">{{ $t('edit') }}
          </b-button>

          <b-icon v-if="$can('campaigns_delete')" @click="showDeleteModal(item)" icon="trash"
                  font-scale="1.5"
                  class="ml-3 mr-3 text-secondary cursor-pointer"></b-icon>
        </template>
      </b-table>
    </div>
    <pagination v-if="page_data" :limit="3" :data="page_data" @pagination-change-page="getCampaigns"></pagination>
    <b-alert v-if="!campaigns.length" show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>
    <span v-if="showCopiedSpan" class="copiedToClipboard">Copied to clipboard!</span>
    <b-modal :title="$t('campaign_modal_create')" id="new-campaigns" size="lg">
      <b-form-group v-if="campaign" :label="$t('campaign_name')">
        <b-form-input v-model="campaign.name"></b-form-input>
      </b-form-group>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="createCampaign()" variant="success" :disabled="loading || !campaign.name">
          <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon>
          {{ $t('create') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('delete')" id="delete-campaign" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('delete_entry') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button :disabled="loading" @click="deleteCampaign" variant="danger">
          <b-icon v-if="loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon>
          {{ $t('delete') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('campaign_link_modal_title')" id="link-generator" size="lg">

      <b-form-group :label="$t('campaign_campagne')">
        <b-form-select @change="selectCampaign()" v-model="campaign_link.campaign" :options="modal_campaigns"></b-form-select>
      </b-form-group>

      <b-form-group v-if="campaign_link.campaign" :label="$t('campaign_link_modal_link_type')">
        <b-button :style="(link_type == 'event-overview') ? 'background-color:green' : ''" @click="selectEventOverview();link_type = 'event-overview'">{{ $t('campaign_link_modal_event_overview') }}</b-button>
        <b-button class="ml-3" :style="(link_type == 'specific-pos') ? 'background-color:green' : ''" @click="selectSpecificEvent();link_type = 'specific-pos'">{{ $t('campaign_link_modal_specific_pos') }}</b-button>
      </b-form-group>

      <b-form-group v-if="link_type == 'specific-pos'" :label="$t('campaign_event')">
        <b-form-select v-model="campaign_link.event" :options="events" @change="selectEvent()"></b-form-select>
      </b-form-group>

      <b-form-group v-if="link_type == 'specific-pos'" :label="$t('campaign_pos')">
        <b-form-select v-model="campaign_link.pos" :options="posses" @change="selectPos()"></b-form-select>
      </b-form-group>

      <b-form-group :label="$t('campaign_link_modal_link')" v-if="user.company">
        <b-form-input v-model="campaign_link.link"></b-form-input>
      </b-form-group>

      <b-form-group v-if="campaign_link.link" :label="$t('qr_code')">
        <qr-code
            :text="campaign_link.link"
            :description="$t('campaign_qr_description')"
        ></qr-code>
      </b-form-group>

      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import {mapState} from "vuex";
import QrCode from "@/components/QrCode.vue";

export default {
  data() {
    return {
      link_type: null,
      campaigns: [],
      page_data: null,
      campaign: {},
      loading: false,
      showCopiedSpan: false,
      campaign_link: {
        campaign: null,
        event: null,
        pos: null,
        link: null
      },
      modal_campaigns: [],
      allEvents: null,
      events: null,
      posses: null,
      activeCampaign: null
    }
  },
  components: {
    QrCode
  },
  methods: {
    cancel() {
      this.$bvModal.hide('new-campaigns')
      this.campaign = {}
    },
    selectEventOverview(){
      this.campaign_link.event = null
      this.campaign_link.pos = null
      this.campaign_link.link = 'https://' + this.user.company.domain + '/?ft_campaign_name=' + this.activeCampaign.slug
    },
    selectEvent(){
      this.campaign_link.link = ''
      this.posses = this.allEvents.find(event => event.id == this.campaign_link.event).points_of_sale.map(pos => {
        return {
          value: pos.id,
          text: pos.name
        }
      })  
    },
    selectPos(){
      let posUrl = this.allEvents.find(event => event.id == this.campaign_link.event).points_of_sale.find(pos => pos.id == this.campaign_link.pos).url
      this.campaign_link.link = 'https://' + this.user.company.domain + '/p/' + posUrl + '?ft_campaign_name=' + this.activeCampaign.slug
    },
    selectCampaign(){
      this.activeCampaign = this.campaigns.find(campaign => campaign.id == this.campaign_link.campaign)
      this.link_type = null
      this.campaign_link.event = null
      this.campaign_link.pos = null
      this.campaign_link.link = null
    },
    getDayOfWeek(datetime) {
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const date = new Date(datetime);
      const dayOfWeekIndex = date.getUTCDay();
      return daysOfWeek[dayOfWeekIndex];
    },
    async selectSpecificEvent(){
      this.campaign_link.link = null

      if (!this.allEvents) {
        let events = await api.event().getLiveEventsWithPos();
        this.allEvents = events;
      }

      this.events = this.allEvents.map(event => {
        if ( ( (!this.activeCampaign.events || this.activeCampaign.events.length === 0) && (!this.activeCampaign.days || this.activeCampaign.days.length === 0) ) || this.activeCampaign.events.includes(event.id) || this.activeCampaign.days.includes( this.getDayOfWeek(event.starts_at) ) ) {
          return {
            value: event.id,
            text: event.name
          };
        }
      }).filter(event => event !== undefined);
      
    },
    createCampaign() {
      this.loading = true;
      api.campaign().create(this.campaign.name).then(campaign => {
        this.loading = false;
        this.$bvModal.hide('new-campaigns')
        if (this.$can('campaigns_edit')) {
          this.$router.push({
            name: 'CampaignDetails',
            params: {
              id: campaign.unique_id
            }
          })
        } else {
          this.getCampaigns();
        }
      }).catch(() => {
        this.loading = false;
      })
    },
    showDeleteModal(campaign) {
      this.campaign = campaign;
      this.$bvModal.show('delete-campaign')
    },
    deleteCampaign() {
      this.loading = true;
      api.campaign().delete(this.campaign.unique_id).then(() => {
        this.getCampaigns();
        this.loading = false;
        this.$store.commit('success', this.$t('campaign_deleted'))
        this.$bvModal.hide('delete-campaign')
      }).catch(() => {
        this.loading = false;
      })
    },
    getCampaigns(page = 1) {
      api.campaign().all(page).then(campaigns => {
        this.campaigns = campaigns.data;
        this.page_data = campaigns;

        this.modal_campaigns = campaigns.data.map(campaign => {
          return {
            value: campaign.id,
            text: campaign.name
          }
        })
        this.modal_campaigns.unshift({
          value: null,
          text: this.$t('select')
        })
      })
    },
    copyToClipboard(textToCopy) {
      const el = document.createElement('textarea');
      el.value = textToCopy;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.showCopiedSpan = true
      setTimeout(function () {
        this.showCopiedSpan = false
      }.bind(this), 2000)
    },
    closePopover(el) {
      this.$refs[el].$emit('close')
    },
  },
  created() {
    document.title = this.$t('nav_campaigns')
    if (this.$store.state.user) {
      if (!this.$can('campaigns_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    this.getCampaigns();
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {
    '$store.state.user': {
      handler() {
        if (!this.$can('campaigns_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    },
  }
}
</script>

<style scoped>
table.b-table tbody tr:hover .btn.btn-row-edit {
    border-color: #f5f5f5 !important;
}

/deep/ .posPopover.b-popover .popover-header {
    display: block !important;
    font-size: 15px;
    padding: 6px 7px
}

/deep/ .posPopover .popover-body {
    padding: 0px !important
}

.btnCampaignUrlContainer {
    width: 33px;
    height: 33px;
    border: 2px solid #f5f5f5;
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}

.btnCampaignUrl {
    font-size: 25px;
    margin-top: 3px
}

.popoverPosRow {
    cursor: pointer;
    padding: 5px 7px;
}

.popoverPosRow:hover {
    background-color: #f5f5f5
}

/deep/ .campaigns-table td:last-child, /deep/ .campaigns-table th:last-child {
    text-align: right;
    padding-right: 5rem;
}

.copiedToClipboard{
    position:fixed;
    left:50%;
    bottom:15%;
    border-radius:40px;
    padding:5px 10px;
    background-color:#193e58;
    color:#FFF;
    font-size:10pt
}

/deep/ .qr-show .btn.dropdown-toggle {
    background-color: #FFF;
    border-radius: 6px;
    border: 2px solid #ddd;
    color: #808080;
    padding: 5px 20px;
    font-size: 13px;
    font-weight: bold;
}

/deep/ .qr-show .btn.dropdown-toggle::after {
    margin-bottom: -2px !important;
}

/deep/ .qr-show-container .b-dropdown-text {
    text-align: center;
    padding: 0.5rem !important;
    width: 300px;
}

/deep/ .qr-show-container p.b-dropdown-text div img {
    margin: auto;
}
</style>