import axios from 'axios'
import userApi from './user'
import store from '../store'
import companyApi from "@/api/company";
import eventApi from "@/api/event";
import posApi from "@/api/pos";
import productApi from "@/api/product";
import timeslotApi from "@/api/timeslot";
import orderApi from "@/api/order";
import customerInformationApi from "@/api/customerInformation";
import discountcodeApi from "@/api/discountcode";
import scannerApi from "@/api/scanner";
import scanOperationApi from "@/api/scanOperation";
import scanOperationSessionApi from "@/api/scanOperationSession";
import ticketApi from "@/api/ticket";
import auditApi from "@/api/audit";
import reportApi from "@/api/report";
import cdnApi from "@/api/cdn";
import mailApi from "@/api/mail";
import openingHourApi from "@/api/openingHour";
import ScanOperationPrivilegesApi from "@/api/scanOperationPrivileges";
import customerSupportApi from "@/api/customerSupport"
import guestListApi from '@/api/guestList'
import permissionApi from "@/api/permission";
import invoiceApi from "@/api/invoice";
import administrationApi from "@/api/administration";
import DefaultValuesApi from "@/api/defaultValues";
import CampaignApi from "@/api/campaign";
import batchesApi from "@/api/batch";
import settingsApi from "@/api/setting";
import extensionApi from "@/api/extension";
import venuesApi from "@/api/venues";
import companyLinksApi from "@/api/companyLinks";


if (window.location.host === 'localhost:8080') {
    // Local development environment
    axios.defaults.baseURL = 'http://api.fairtix.local'
} else if (window.location.host === 'frontend.staging.fairtix.io') {
    // Test environment Staging (staging-ft)
    axios.defaults.baseURL = 'https://api.staging.fairtix.io'
} else {
    axios.defaults.baseURL = 'https://api.fairtix.io'
}

axios.interceptors.request.use((config) => {
    let token = store.state.token
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
}, (error) => {
    // Do something with request error
    document.location = '/'
    return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
    return response.data
}, (error) => {
    // Do something with response error
    if (error.response.status === 401) {
        store.commit('logout')
    }
    if (error.response.status === 400) {
        store.commit('errors', error.response.data)
    }
    return Promise.reject(error)
})

const user = () => {
    return new userApi(axios)
}

const company = () => {
    return new companyApi(axios)
}

const event = () => {
    return new eventApi(axios)
}

const product = () => {
    return new productApi(axios)
}

const pos = () => {
    return new posApi(axios)
}
const timeslot = () => {
    return new timeslotApi(axios)
}

const order = () => {
    return new orderApi(axios)
}

const customerInformation = () => {
    return new customerInformationApi(axios)
}

const discountcode = () => {
    return new discountcodeApi(axios)
}

const scanner = () => {
    return new scannerApi(axios)
}

const scanOperation = () => {
    return new scanOperationApi(axios)
}
const scanOperationSession = () => {
    return new scanOperationSessionApi(axios)
}
const scanOperationPrivileges = () => {
    return new ScanOperationPrivilegesApi(axios)
}

const ticket = () => {
    return new ticketApi(axios)
}
const audit = () => {
    return new auditApi(axios)
}
const report = () => {
    return new reportApi(axios)
}
const cdn = () => {
    return new cdnApi(axios)
}
const mail = () => {
    return new mailApi(axios)
}

const openingHour = () => {
    return new openingHourApi(axios)
}

const customerSupport = () => {
    return new customerSupportApi(axios)
}

const guestList = () => {
    return new guestListApi(axios)
}

const permission = () => {
    return new permissionApi(axios)
}

const invoice = () => {
  return new invoiceApi(axios)
}

const administration = () => {
    return new administrationApi(axios);
}

const defaultValues = () => {
    return new DefaultValuesApi(axios)
}

const campaign = () => {
    return new CampaignApi(axios);
}

const batch = () => {
    return new batchesApi(axios)
}

const setting = () => {
  return new settingsApi(axios)
}

const extension = () => {
return new extensionApi(axios)
}

const venues = () => {
    return new venuesApi(axios)
}

const companyLinks = () => {
    return new companyLinksApi(axios)
}
export default {
    user,
    company,
    event,
    product,
    pos,
    timeslot,
    order,
    customerInformation,
    scanner,
    scanOperation,
    scanOperationSession,
    scanOperationPrivileges,
    ticket,
    discountcode,
    audit,
    report,
    cdn,
    mail,
    openingHour,
    customerSupport,
    guestList,
    permission,
    invoice,
    administration,
    defaultValues,
    campaign,
    batch,
    setting,
    extension,
    venues,
    companyLinks
}

